// cms forms
.module-form {
    @include grid-max-width(10, 5, 4);

    .text-super {
        @include grid-bottom-spacing(s);
    }

    .head-wrapper {
        padding-bottom: 1em;
    }

    @include respond-to(decent) {
        .text-super {
            @include grid-bottom-spacing(m);
        }
    }
}

.footer-newsletter-register {
    &.show-full-newsletter-form {
        .newsletter-signup__extend {
            display: block;
        }
    }
}

.watch-registration-form {
    .form-grid__row--email-login {
        flex-direction: column;

        .form-grid__cell {
            width: 100%;
        }

        .login-hint {
            @include grid-spacing(xs);
        }
    }

    .form-grid__cell.has-help {
        width: auto;
    }
}