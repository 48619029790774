.payment-method-icons {
    margin-top: grid-spacing(grid-s, m);
    transform-origin: left top;
    transform: scale(0.8);
    display: flex;
    gap: 1.3em 1.5em;
    flex-wrap: wrap;
    align-items: center;
    margin-right: -20%; // Compensate for scale and add mor space


    // hide additional logos on bag page
    .page-checkout-bag footer & {
        display: none;
    }

    .sidebar-cart__footer-wrapper & {
        @include grid-spacing(xs);
    }

    @include respond-to(grid-s) {
        .cart-listing__footer & {
            transform: none;
            display: flex !important;
            margin-right: 0;
        }
    }

    @include respond-to(decent) {
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 1em;
        transform-origin: right center;
        transform: none;
        justify-content: flex-end;
        gap: 1.2em 1.8em;

        .cart-listing__footer & {
            transform: scale(0.95);
            transform-origin: left center;
            justify-content: flex-start;
            display: flex !important;
            flex-wrap: nowrap;
        }
    }

    @include respond-to(grid-m) {
        word-spacing: 1em;
        transform: scale(0.9);
        transform-origin: right center;

        .cart-listing__footer & {
            line-height: 4;
            transform-origin: left center;
        }
    }
}

.payment-method-icons__break {
    // force a line break
    flex-basis: 100%;
}

.payment-logos-list {
    display: flex;
    flex-wrap: wrap;

    li {
        display: inline-block;

        > span {
            margin: 0 0.6em;

            @include respond-to(grid-l) {
                margin: 0 1em;
            }
        }
    }

    // Inside checkout panel (e.g. Checkout/Confirm)
    .checkout-panel & {
        position: absolute;
        top: 2rem;
        right: -0.6em;
        transform: scale(0.9);
        transform-origin: top right;

        @include respond-to(decent) {
            top: 0;
            right: 0;
        }
    }
}