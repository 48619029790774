// Voucher input
.voucher-form {
    display: block;
    margin-top: grid-spacing(grid-s, s);
    @include grid-bottom-spacing(m);

    &[aria-hidden="true"] {
        display: none;
    }

    & > [aria-hidden="true"] {
        display: none;
    }

    @include respond-to(decent) {
        margin-top: 0;
    }
}

.voucher-form__existing {
    margin-top: 1.5em;

    .btn {
        margin-top: 0.8em;
    }
}

.voucher-form__input {
    margin-top: 1.5em;
    display: flex;

    .voucher-form__input__submit {
        background: $back-color--secondary;
        padding: 0.5em 0.5em 0.5em 1em;
        transition: background $trans-time--m $trans-func--default;

        .btn {
            background: none;
            margin: 0;
            white-space: nowrap;
        }
    }

    input:focus + .voucher-form__input__submit {
        background-color: $back-color--secondary-dark;
    }

    input.has-error + .voucher-form__input__submit {
        background-color: $back-color--error;

        .btn {
            border-color: rgba($text-color--default, 0.8);
        }
    }
}

.voucher-form__hint {
    display: block;
    margin-top: 0.4em;

    p {
        @include textstyle--s;
    }

    @include respond-to(grid-l) {
        max-width: grid-inner-width(grid-l, 3);
    }
}

.voucher-form__response {
    margin-top: 0.4em;
}
