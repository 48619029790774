.splitit-payment__container {
    // Splitit overwrites
    --spt-color-primary: #{$text-color--primary};
    --spt-color-labels: #{$text-color--primary};
    --spt-color-border-idle: #{$border-color--secondary};
    --spt-color-link: #{$text-color--primary};
    --spt-color-main-shade: #{$back-color--tertiary};

    // Custom properties
    --_spt-field-mt: 2.8rem;
    --_spt-field-h: #{$input-height-default};
    --_spt-field-line-height: 18.5px; // use splitit iframe values for calculation
    --_spt-field-px: calc((var(--_spt-field-h) - var(--_spt-field-line-height)) / 2);

    // Default typography settings
    .spt-styles {
        font-family: $type-fam--gotham-narrow;
        font-weight: $type-style--light;
        line-height: $line-height;
    }

    div.spt-fields-cc-group {
        margin-bottom: 2.4em;

        .spt-field + .spt-error-message {
            font-size: $fontsize--s;
            line-height: $line-height;
        }
    }

    div.spt-field {
        margin-top: var(--_spt-field-mt);
        border-radius: 0;
        width: 100%;
        height: var(--_spt-field-h);
        padding: var(--_spt-field-px) 3rem var(--_spt-field-px) 0.6em;

        label {
            @include textstyle--default;
            @include textstyle--xs;
            @include textstyle--uppercase;
            top: calc(var(--_spt-field-mt) * 0.8 * -1);
            left: 0;
        }

        .icon-wrapper {
            top: 50%;
            right: 0.6rem;
            transform: translateY(-50%);
        }

    }

    .spt-tooltip {
        font-size: inherit;
        box-shadow: $box-shadow--s;
        border-color: $border-color--secondary;
        padding: 1.4rem;
        color: inherit;

        span {
            @include textstyle--default;
            @include textstyle--s;
            line-height: $line-height;
            display: block;
        }
    }

    .spt-tooltip-arrow {
        border-color: $border-color--secondary;

        &:before {
            border-color: $border-color--secondary;
        }
    }

    section.spt-box {
        border-radius: 0;
        padding: 0;
        border: none;
        margin-bottom: 2em;
        box-shadow: none;

        // Box header
        // missing class here
        section > .flex.justify-between {
            margin-bottom: 0.6em;
            align-items: baseline;

            // Label: Zahlungsplan auswählen
            > span {
                @include textstyle--teaser;
                @include textstyle--teaser-xs;
                display: block;

                img {
                    display: none;
                }

                @include respond-to(decent) {
                    font-size: $fontsize--teaser-default;
                }
            }

            // Button: Mehr erfahren
            [role="button"] {
                display: inline-block;
                white-space: nowrap;

                img {
                    display: none;
                }

                &:after {
                    content: '›';
                    margin-left: 0.5ch;
                    display: inline-block;
                }
            }
        }

    }

    div.spt-installment-button {
        border: 1px solid var(--spt-color-border-idle) !important;
        margin-top: 0.4em;
        margin-bottom: 0.4em;
        min-height: 4em;
        border-radius: 0;
        font-size: $fontsize--default;
        line-height: $line-height;
        color: var(--spt-color-primary);
        outline-color: var(--spt-color-primary);
        padding: 0.8em 0.8em;
        justify-content: center;
    }

    div.spt-selected-item {
        border-color: var(--spt-color-primary) !important;
    }

    .spt-flex-fields-container {
        max-width: none;
    }

    div.spt-total-container {
        display: flex;
        margin-top: 0.6em !important;
        padding-bottom: 1em !important;
        flex-direction: row !important;

        .svelte-hu0sw8:not([hidden]) ~ .svelte-hu0sw8:not([hidden]) {
            margin-left: 0.5ch;
        }

        .spt-total-text {
            font-size: $fontsize--m !important;
            line-height: $line-height !important;
        }

        .spt-total-price {
            margin-left: auto !important;
            font-weight: $type-style--book !important;
            font-size: $fontsize--m !important;
            line-height: $line-height !important;
        }
    }

    .spt-terms-container {
        margin: 0 !important;
        flex-direction: row !important;

        p,
        span {
            font-size: $fontsize--s !important;
            line-height: $line-height !important;
        }

        p + div {
            margin-top: 1em;
        }

        .items-center {
            align-items: flex-start;
        }

        [role="button"] {
            // We implement our own checkbox style here
            // Needs to stay for clickability
            svg {
                opacity: 0;
            }
        }

        .checkbox {
            top: 0 !important;
            margin-right: 0.4em !important;
            border: none !important;
            border-radius: 0 !important;
            width: 2rem !important;
            height: 2rem !important;
            transform: translateY(0.1em);
            background: svg-inline(icon--nom-check-no) center center no-repeat;
        }

        input:checked + .checkbox {
            background: svg-inline(icon--nom-check-yes) center center no-repeat;
        }

        // Checkbox label
        [role="menuitem"] {
            [role="tabpanel"] {
                border-bottom: 1px solid rgba($text-color--default, 0.8);
                color: inherit;
            }
        }
    }

    .spt-terms-container + .spt-btn {
        display: none;
    }

    @include respond-to(decent) {
        max-width: grid-inner-width(grid-l, 5);

        section.spt-box {
            // Box header
            // missing class here
            section > .flex.justify-between {
                margin-bottom: 1em;

                // Label: Zahlungsplan auswählen
                > span {
                    font-size: $fontsize--teaser-default;
                }
            }
        }

        div.buttons-container {
            display: block; // remove grid here to display top to bottom
        }

        div.spt-total-container {
            margin-top: 1.2em !important;
        }

        .spt-terms-container {
            span {
                font-size: $fontsize--m !important;
            }

            p + div {
                margin-top: 1.4em;
            }
        }
    }

    @include respond-to(grid-m) {
        max-width: grid-inner-width(grid-m, 5);
    }
}
