/* Nomos CMS banner
 * Used as a CMS module
 */
.banner {
    position: relative;

    @include respond-to(decent) {
        height: $banner-height-l; // have specific height to prevent jumping on slideshow content // 3 rows text

        .is-chrome & {
            transform: translate3d(0, 0, 0); // prevent rendering issues on main content while sticky in chrome
        }
    }

    @include respond-to(grid-m) {
        height: $banner-height-m; // have specific height to prevent jumping on slideshow content // 4 rows text
    }
}

.banner--inverted {
    color: $text-color--inverted;

    .btn {
        color: inherit;
    }

    .svg-ico {
        stroke: $text-color--inverted;
    }
}

.banner__inner {
    // add styles for sticked banner // desktop only
    @include respond-to(decent) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: $banner-height-l;
        overflow: hidden;
        transition: transform $trans-time--s $trans-func--easing-out,
                    height $trans-time--s $trans-func--easing-out;
        pointer-events: all;

        .is-chrome & {
            clip-path: inset(0 0 0 0); // fix rendering issues with chrome
        }

        .is-safari & {
            width: 100vw; // maybe this fixes an issue on legacy safari
        }

        .has-sticky-banner & {
            &:not(:hover) {
                height: $action-bar-height-desktop + $banner-offset;
                transition: transform $trans-time--m $trans-func--easing-out,
                            height $trans-time--m $trans-func--easing-out;
            }
        }
    }

    @include respond-to(grid-m) {
        height: $banner-height-m;
    }
}

.banner__slideshow {
    ol.flickity-page-dots { // Add ol to overcome specifity
        bottom: 1rem;
    }

    @include respond-to(decent) {
        .flickity__slide {
            height: $banner-height-l;
        }

        ol.flickity-page-dots { // Add ol to overcome specifity
            bottom: 3rem;
        }
    }

    @include respond-to(grid-m) {
        .flickity__slide {
            height: $banner-height-m;
        }
    }
}

.banner__item {
    background: $back-color--default;
    height: 100%;
    width: 100vw;

    // Overcome equal height issue in flickity
    // https://github.com/metafizzy/flickity/issues/534
    .banner__slideshow & {
        &:after {
            @include pseudo();
            top: 0;
            left: 0;
            right: 0;
            background: inherit;
            height: 200%;
            z-index: -1;
        }
    }
}

.banner__content {
    width: 100%;

    @include respond-to(decent) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-right: grid-width(grid-l) + grid-gutter(grid-l);
        height: 100%;
    }

    @include respond-to(grid-m) {
        margin-right: 0;
    }
}

.banner__img {
    @include respond-to(grid-s) {
        width: grid-width(grid-s, 6);
        margin-left: grid-width(grid-s, 3);
    }

    @include respond-to(grid-m) {
        width: grid-width(grid-m, 4.5);
        margin-right: grid-width(grid-m, 0.5);
    }
}

.banner__text {
    margin-bottom: grid-spacing(grid-s, l);

    h4 {
        transition: opacity $trans-time--s $trans-func--default;
        opacity: 0;
    }

    .head-wrapper {
        margin-top: -2rem;
        transition: transform $trans-time--m $trans-func--easing-out,
                    opacity $trans-time--m $trans-func--easing-out;
    }

    .text-ultra {
        h3 {
            @include textstyle--teaser;
        }
    }

    @include respond-to(decent) {
        margin-top: 4rem; // needs to be rem here to get sticky position right
        margin-bottom: 0;

        .head-wrapper {
            margin-top: -1.8rem; // needs to be rem here to get sticky position right
        }

        .text-ultra {
            h3 {
                @include textstyle--teaser-xl;
            }
        }

        .has-sticky-banner .banner:not(:hover) & {
            h4 {
                opacity: 1;
                transition: opacity $trans-time--m $trans-func--default $trans-time--s;
            }

            .head-wrapper {
                transform: translate3d(0, -2rem, 0);
                opacity: 0;
            }
        }
    }

    @include respond-to(grid-m) {
        width: grid-width(grid-m, 5);

        .head-wrapper {
            margin-top: -3rem; // prevent blitzer in clsoed version
        }

        .text-ultra {
            h3 {
                @include textstyle--teaser-l;
            }
        }
    }
}

/* banner actions // desktop only */
.banner__actions {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 4rem;

    .grid-container {
        display: flex;
        flex-direction: row-reverse;
    }
}

.banner__hover-hint {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 1.4em, 0) rotate(90deg);
    opacity: 0;
    transition: opacity $trans-time--m $trans-func--default;

    .has-sticky-banner .banner:not(:hover) & {
        opacity: 1;
        transition: opacity $trans-time--l $trans-func--default;
    }
}

.banner__remove {
    margin-top: 0.4em;
    opacity: 0;

    .has-sticky-banner & {
        opacity: 1;
        transition: opacity $trans-time--m $trans-func--default;
    }
}

/* Nomos Global banner
 * Used for staying on top off specific sides
 */

.global-banner {
    height: $global-banner-height-s;
    overflow: hidden;
    position: relative;

    @include respond-to(decent) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: $global-banner-height-l;
        z-index: map-get($z-index, global-banner);
        transition: transform $trans-time--m $trans-func--easing-out;

        // remove scrolling up and main menu is shown
        .is-scrolled.is-scrolled-up-decent & {
            transform: translate3d(0, ($global-banner-height-l * -1), 0);
        }
    }
}

.global-banner__slideshow {
    .flickity-page-dots {
        display: none !important;
    }
}

.global-banner__item {
    width: 100vw;
}

.global-banner__content {
    height: $global-banner-height-s;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    h4 {
        white-space: nowrap;
        margin-right: 1rem; // visually compensate for closing icon
        padding-right: 1rem;
        max-width: 88%; // dont spread across full width to not interfere with closing icon
        mask-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 1rem); // mask overflow text
    }

    @include respond-to(decent) {
        height: $global-banner-height-l;

        h4 {
            max-width: 92%; // dont spread across full width to not interfere with closing icon
            padding-right: 0;
            margin-right: 0;
            mask-image: none;
        }
    }
}

.global-banner__img {
    height: $global-banner-height-s;
    width: $global-banner-height-s;
    margin-right: 0.2rem;
    position: relative;

    & ~ h4 {
        max-width: calc(88% - #{$global-banner-height-s}); // reduce max-width of h4 if img is also shown
    }

    .media-box {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    @include respond-to(decent) {
        height: $global-banner-height-l;
        width: $global-banner-height-l;
        margin-right: 0.6rem;

        & ~ h4 {
            max-width: calc(92% - #{$global-banner-height-s}); // reduce max-width of h4 if img is also shown
        }
    }
}

.global-banner__link {
    letter-spacing: 0;

    &:after {
        content: '›';
        display: inline-block;
        transition: transform $trans-time--m $trans-func--default;
        padding-left: 0.15rem;
    }

    @include hover {
        &:after {
            transition: transform $trans-time--s $trans-func--default;
            transform: translate3d(-0.25rem, 0, 0);
        }
    }
}

.global-banner__remove {
    position: absolute;
    top: 50%;
    right: 0;
    transform: scale(0.85) translate3d(0, -50%, 0); // we need to scale this smaller cause we have a real small type size here
    transform-origin: top right;

    @include respond-to(grid-s) {
        .svg-ico {
            transform: scale(0.8); // we need to scale this smaller cause we have a real small type size here
        }
    }

    @include respond-to(decent) {
        transform: scale(0.7) translate3d(0, -50%, 0); // we need to scale this smaller cause we have a real small type size here
    }
}

/* Control main menu when global banner is shown */
.has-global-banner {
    @include respond-to(decent) {
        // make banner behind clickable
        .main-header,
        .main-menu,
        .main-menu__wrapper {
            pointer-events: none;
        }

        .main-menu__head .grid-container,
        .modal--main-menu {
            pointer-events: all;
        }

        // handle main menu position on shown banner
        .main-menu__head {
            .grid-container {
                transition: transform $trans-time--m $trans-func--easing-out;
            }
        }

        &:not(.is-scrolled-up-decent):not(.is-scrolled-beyond-header),
        &.is-scrolled-up-decent:not(.is-scrolled) {
            .main-menu__head {
                .grid-container {
                    transform: translate3d(0, $global-banner-height-l, 0);
                }

                // remove white background right away
                &:before {
                    transition: none;
                }
            }
        }

        /* // Aproach Two
        .main-menu__head {
            .grid-container {
                transition: transform $trans-time--m $trans-func--easing-out;
                transform: translate3d(0, $global-banner-height-l, 0);
            }
        }

        &.is-scrolled.is-scrolled-up-decent {
            .main-menu__head {
                .grid-container {
                    transition: transform $trans-time--s $trans-func--easing-out;
                    transform: none;
                }
            }
        } */

        // adjust back hero
        .hero--back-img {
            padding-top: $global-banner-height-l;
        }
    }
}


/* Fading bahaviour for flickity */
.global-banner__slideshow,
.banner__slideshow {
    // Implement fading behaviour
    &.flickity-enabled {
        .flickity-slider {
            transform: none !important;
        }

        .flickity__slide {
            left: 0 !important;
            transition: opacity 1s $trans-func--default 1s;
            opacity: 0;
            z-index: -1;

            &.is-selected {
                transition: opacity 1s $trans-func--default;
                opacity: 1;
                z-index: 0
            }
        }
    }
}
