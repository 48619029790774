
// Which shop?
.store-indicator {
    background: $back-color--tertiary;

    .text-container {
        padding-top: grid-padding-v();
        padding-bottom: grid-padding-v();
    }

    p {
        line-height: 1.6;
    }

    span {
        display: block;
    }

    @include respond-to(decent) {
        background: none;

        .text-container {
            margin-top: -1.5em;
            padding-top: 0;
            padding-bottom: 0;
        }

        p {
            line-height: inherit;
        }

        span {
            display: inline;
        }

        a {
            display: inline-block;
            margin-left: 2rem;
        }
    }
}

// checkout layout sizes
// mobile
$item-width-mobile: grid-inner-width(grid-s, 6);

// grid-l
$item-width-name-l: grid-width-gutter(grid-l, 4);
$item-width-options-l: grid-width-gutter(grid-l, 2);
$item-width-delivery-l: grid-inner-width(grid-l, 2);
$item-width-quantity-l: grid-width-gutter(grid-l);
$item-width-total-l: grid-width-gutter(grid-l);

// grid-m
$item-width-name-m: grid-width-gutter(grid-m, 5);
$item-width-options-m: grid-width(grid-m, 3) - grid-padding-h(grid-m);
$item-width-delivery-m: grid-width-gutter(grid-m, 2) - grid-padding-h(grid-m);
$item-width-quantity-m: grid-width(grid-m, 1.5) - grid-padding-h(grid-m);
$item-width-total-m: grid-inner-width(grid-m, 2);

// Checkout Cart Overview Listing
.cart-listing {
    display: block;
    transition: opacity $trans-time--m $trans-func--default;
    opacity: 1;

    // Common Header/Item/Footer
    .cart-listing__header {
        @include grid-spacing(m);
        padding-bottom: 1.5em;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        border-bottom: solid 1px $border-color--secondary;

        h3 {
            @include textstyle--default;
            @include textstyle--xs;
            @include textstyle--uppercase;
        }
    }

    .cart-listing__item {
        @include grid-spacing(m);
        @include grid-bottom-spacing(l);
        border-bottom: solid 1px $border-color--secondary;
        flex-wrap: wrap;
        position: relative;
        @include clearfix();

        @include respond-to(decent) {
            margin-top: grid-spacing(grid-l, m);
            @include grid-bottom-spacing(m);
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }

        @include respond-to(grid-l) {
            flex-wrap: nowrap;
        }
    }

    .cart-listing__header__cell,
    .cart-listing__item__cell {
        display: inline-block;
    }

    .cart-listing__header__cell {
        h3 {
            @include textstyle--default;
            @include textstyle--xs;
            @include textstyle--uppercase;
        }
    }

    .cart-listing__header__cell--name {
        width: $item-width-name-l;

        @include respond-to(grid-m) {
            width: $item-width-name-m;
        }
    }

    .cart-listing__header__cell--options {
        width: $item-width-options-l;

        @include respond-to(grid-m) {
            display: none;
        }
    }

    .cart-listing__header__cell--delivery {
        width: $item-width-delivery-l;

        @include respond-to(grid-m) {
            width: $item-width-delivery-m;
        }
    }

    .cart-listing__header__cell--total {
        width: $item-width-total-l;

        @include respond-to(grid-m) {
            width: $item-width-total-m;
        }
    }

    .cart-listing__header__cell--quantity {
        width: $item-width-quantity-l;

        @include respond-to(grid-m) {
            width: $item-width-quantity-m;
        }
    }

    .cart-listing__header__cell--total {
        width: $item-width-total-l;
        text-align: right;

        @include respond-to(grid-m) {
            width: $item-width-total-m;
        }
    }

    .cart-listing__item__cell__image {
        @include grid-width(4, 2, 1.5);
        margin-left: grid-padding-h() * -1;
        float: left;
        position: sticky;
        margin-bottom: 3rem;
        top: 0;

        .media-box {
            height: grid-width(grid-s, 4);
        }

        @include respond-to(decent) {
            float: none;
            position: relative;
            margin-bottom: 0;
            top: auto;
            margin-left: grid-padding-h(grid-l) * -1;
        }

        @include respond-to(grid-l) {
            margin-top: -3em;

            .media-box {
                height: grid-width(grid-l, 1.5);
            }
        }

        @include respond-to(m) {
            margin-top: -2em;

            .media-box {
                height: grid-width(grid-m, 2);
            }
        }
    }

    .cart-listing__item__cell__title {
        width: $item-width-mobile;
        margin-top: 3em;
        float: right;

        h2 {
            @include textstyle--default;
            @include textstyle--s;
            @include textstyle--uppercase;
        }

        p {
            @include textstyle--default;
            margin-top: 0.2em;
        }

        @include respond-to(decent) {
            float: none;
            width: grid-width-gutter(grid-l, 2.5) + grid-padding-h(grid-l);
            padding-right: grid-padding-h(grid-l) * 2;
            padding-left: 1em;
            margin-top: 0;

            h2 {
                @include textstyle--m;
            }

            p + p {
                margin-top: 0;
            }
        }

        @include respond-to(grid-m) {
            width: grid-width-gutter(grid-m, 3) + grid-padding-h(grid-m);
            padding-right: grid-padding-h(grid-m) * 2;
            padding-left: 0;
            margin-bottom: 5em;
            margin-left: grid-gutter-width(grid-m);
        }
    }

    .cart-listing__item__cell--options {
        width: $item-width-mobile;
        margin-top: 0.8em;
        float: right;

        @include respond-to(decent) {
            width: $item-width-options-l;
            margin-top: 0;
            float: none;
            padding-right: grid-padding-h(grid-l) * 2;
        }

        @include respond-to(grid-m) {
            width: $item-width-options-m;
            padding-right: 0;
            margin-top: -5em;
            margin-left: grid-width-gutter(grid-m, 5);
            order: 10; // put below
        }
    }

    .strap-length-picker-mobile {
        display: flex;

        .select--s {
            padding: 0 1.6em 0 0.8em; // keep everything visible within this small dropdown
            background-position: calc(100% - 0.6em) center;
        }
    }

    .strap-length-picker-desktop {
        .radio-wrapper {
            margin-top: 0.5em;
            margin-bottom: 0.5em;
        }
    }

    .cart-item-comment {
        margin-top: 0.4em;
    }

    .cart-listing__item__cell--delivery {
        width: $item-width-mobile;
        margin-top: 1.8em;
        float: right;

        .product__purchase-info {
            margin-top: 0;
        }

        span {
            display: block;
        }

        @include respond-to(decent) {
            width: $item-width-delivery-l;
            margin-top: 0;
            float: none;
            padding-right: grid-padding-h(grid-l);
            margin-bottom: 3em; // prevent kissing the remove button
        }

        @include respond-to(grid-m) {
            width: $item-width-delivery-m;
            padding-right: grid-padding-h(grid-m);
        }
    }

    .cart-listing__item__cell--quantity {
        width: $item-width-mobile;
        float: right;

        .input-button-group {
            margin-top: 1.4rem;
        }

        input[type="number"] {
            text-align: center;
            padding-left: 0;
            padding-right: 0;
            width: 4rem;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        @include respond-to(decent) {
            width: $item-width-quantity-l;
            float: none;
            padding-right: grid-padding-h(grid-l);

            .input-button-group {
                margin-top: -0.2rem;
            }
        }

        @include respond-to(grid-m) {
            width: $item-width-quantity-m;
            padding-right: grid-padding-h(grid-m);

            input[type="number"] {
                width: 2.6rem;
            }
        }
    }

    .cart-listing__item__cell--total {
        width: $item-width-mobile;
        margin-top: 1.8em;
        float: right;

        p {
            @include textstyle--default;
        }

        @include respond-to(decent) {
            text-align: right;
            width: $item-width-total-l;
            margin-top: 0;
            float: none;

            p {
                white-space: nowrap;
            }
        }

        @include respond-to(grid-m) {
            width: calc(#{$item-width-total-m} - 1px); // Compensate for subpixel render issues
        }
    }

    .cart-listing__item__cell--remove {
        position: absolute;
        bottom: 3.6em;
        left: 0;
        margin-bottom: -0.7rem; // center with totals
        width: grid-inner-width(grid-s, 4);
        display: flex;
        justify-content: center;

        @include respond-to(decent) {
            left: $item-width-name-l + $item-width-options-l;
            display: block;
            bottom: 3.6em;
        }

        @include respond-to(grid-m) {
            left: grid-width-gutter(grid-m, 2) - grid-padding-h(grid-m);
            bottom: 4em;
        }
    }

    // Specific styles for engraving
    .cart-listing__item--engraving {
        @include respond-to(grid-s) {
            margin-top: 0;
        }

        .cart-listing__item__cell__title h2 {
            @include textstyle--xs;

            @include respond-to(decent) {
                @include textstyle--s;
            }
        }

        .cart-listing__item__cell__image {
            &:before {
                @include pseudo();
                top: -2px;
                left: 0;
                background: $back-color--primary;
                height: 3px;
                width: grid-width-gutter(grid-s, 4) + grid-padding-h(grid-s);

                @include respond-to(decent) {
                    left: grid-padding-h(grid-l);
                    width: grid-width(grid-l, 1.5) - 1em;
                }

                @include respond-to(grid-m) {
                    width: grid-width-gutter(grid-m, 2) - grid-padding-h(grid-m);
                }
            }
        }

        @at-root {
            .cart-engraving-text {
                // Use font with extended charset
                @include textstyle--engraving-figures;

                span {
                    display: block;
                }
            }
        }
    }

    // Specific footer style
    .cart-listing__footer {
        @include grid-spacing(s);

        @include respond-to(decent) {
            @include grid-container();
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin: 0 auto;

            .grid-container {
                width: auto;
                margin: 0;
            }
        }
    }

    .cart-listing__footer__cell {
        display: inline-block;
    }

    .cart-listing__footer__cell--voucher,
    .cart-listing__footer__cell--message {
        width: 100%;
        display: block; // fix vertical rhythm

        @include respond-to(decent) {
            width: grid-inner-width(grid-l, 4);
        }

        @include respond-to(grid-m) {
            width: grid-inner-width(grid-m, 4);
        }
    }

    .cart-listing__footer__cell--message {
        @include grid-bottom-spacing(m);

        textarea {
            margin-top: 0.4em;
            width: 100%;
        }
    }

    // Cart Footer Summary
    .cart-listing__summary-wrapper {
        background: $back-color--tertiary;
        @include grid-bottom-spacing(xxl);
        border-bottom: 1px solid $border-color--secondary;

        @include respond-to(decent) {
            background: none;
            border-bottom: none;
        }
    }

    .cart-listing__footer__cell--summary {
        @include grid-spacing(s);

        @include respond-to(decent) {
            margin-top: 0;
            width: grid-inner-width(grid-l, 4);
        }

        @include respond-to(grid-m) {
            width: grid-inner-width(grid-m, 5);
        }
    }

    .cart-listing__summary {
        @include pseudo-list;
        display: block;

        .cart-listing__summary__entry {
            display: block;

            &[aria-hidden="true"] {
                display: none;
            }
        }

        .cart-listing__summary__entry__hint {
            @include grid-spacing(s);

            @include respond-to(decent) {
                max-width: grid-width(grid-l, 3);
                margin-top: 2em;
            }

            @include respond-to(grid-m) {
                max-width: grid-width(grid-m, 3);
            }
        }

        .cart-listing__summary__entry--voucher {
            padding-bottom: 1em;

            .voucher-code {
                .btn {
                    margin-left: 0.2em;
                    @include textstyle--default;
                    @include textstyle--s;
                    border-bottom: 1px solid rgba($text-color--default, 0.8);
                }
            }
        }

        .cart-listing__summary__entry--total,
        .cart-listing__summary__entry--price-to-pay {
            margin-top: 1em;

            span {
                @include textstyle--book;
            }

            p {
                @include textstyle--s;
            }
        }

        .cart-listing__summary__entry--fee + .cart-listing__summary__entry--subtotal,
        .cart-listing__summary__entry--discount-us + .cart-listing__summary__entry--subtotal {
            margin-top: 1em;
        }

        .cart-listing__summary__entry--proceed {
            @include grid-spacing(m);
        }

        .cart-listing__summary__entry--restricted {
            @include grid-spacing(s);

            p {
                @include textstyle--m;
                color: $text-color--error;
            }
        }

        .cart-listing__summary__splitit-info {
            display: flex;
            justify-content: flex-end;

            .btn--help {
                margin-right: -0.4em;
            }
        }

        dl {
            display: flex;
            padding: 0;
            margin: 0;
            justify-content: space-between;

            dd {
                text-align: right;
                margin: 0 0 0 2em;
            }

            span {
                @include textstyle--default;
                display: block;

                &.is-small {
                    @include textstyle--xs;
                }
            }
        }

        .payment-method-icons {
            @include respond-to(decent) {
                margin-top: 3em;
            }
        }
    }

    .response-message + .btn {
        @include grid-spacing(xs);
    }

    // Content is reloading
    &[aria-busy="true"] {
        pointer-events: none;
        opacity: 0.3;
    }
}
