// Handle groups of boxes in store
.store-box-group {
    // handle items top spacing
    > div  {
        @include grid-spacing(xs); // tight gutter width grid
    }

    @include respond-to(decent) {
        // boxes flex to the sides on desktop
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
