.awesomplete [hidden] {
    display: none;
}

.awesomplete .visually-hidden {
    position: absolute;
    clip: rect(0, 0, 0, 0);
}

.awesomplete {
    display: inline-block;
    position: relative;
    width: 100%;
}

.awesomplete > input {
    display: block;
}

.awesomplete > ul {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;
    box-sizing: border-box;
    list-style: none;
    padding: 0.5em 0 2em;
    margin: 0;
    background: $back-color--default;
    transition: transform $trans-time--s $trans-func--default,
                opacity $trans-time--s $trans-func--default,
                visibility 0s $trans-func--default $trans-time--s;

    &[hidden],
    &:empty {
        opacity: 0;
        transform: transform(0, -1em, 0);
        visibility: hidden;
        transition: transform $trans-time--s $trans-func--default,
                    opacity $trans-time--s $trans-func--default;
    }

    > li {
        position: relative;
        cursor: pointer;
        @include fade-cover();
        transition: background $trans-time--s $trans-func--default;

        > span {
            white-space: nowrap;
            display: block;
            padding: 0 1em;
            width: 100%;
            @include textstyle--button;
            line-height: 2;
            overflow: hidden;
        }

        &:after {
            transition: background $trans-time--s $trans-func--default;
            width: 4rem;
        }

        &[aria-selected="true"] {
            background: $back-color--tertiary;

            &:after {
                background: linear-gradient(to right, rgba($back-color--tertiary, 0), $back-color--tertiary);
            }
        }

        @include hover {
            background: $back-color--tertiary;

            &:after {
                background: linear-gradient(to right, rgba($back-color--tertiary, 0), $back-color--tertiary);
            }
        }
    }

    @include hover {
        > li[aria-selected="true"]:not(:hover) {
            background: transparent;

            &:after {
                background: linear-gradient(to right, rgba($back-color--primary, 0), $back-color--primary);
            }
        }
    }

    mark {
        @include textstyle--book;
        background: transparent;
    }
}
