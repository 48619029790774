// Nomos Compare
$max-compare: 3;

// sticky compare head
.compare-head {
    position: relative; // IE fallback
    position: sticky;
    top: 0;
    transform: translateY(-1px); // prevent blitzer
    background: $back-color--default;
    z-index: 2;
    padding-bottom: 1em;

    .modal & {
        top: $modal-header-mobile;
    }

    @include respond-to(decent) {
        height: grid-width(grid-l, 1) + 3em;
        margin-bottom: (grid-width(grid-l, 1) + 3em) * -1;
        visibility: hidden;
        transform: translate3d(0, -2em, 0);
        opacity: 0;

        .modal & {
            transition: transform $trans-time--s $trans-func--default,
                        opacity $trans-time--s $trans-func--default,
                        visibility 0s $trans-func--default $trans-time--s;
            top: $modal-header-desktop;
        }

        .has-compare-header .modal & {
            transform: translateY(-1px); // prevent blitzer
            visibility: visible;
            opacity: 1;
            transition: transform $trans-time--s $trans-func--default,
                        opacity $trans-time--s $trans-func--default;
        }
    }
}

.compare-head__item {
    margin-right: grid-gutter(grid-s);
    position: relative;
    overflow: hidden;

    // add button aesthetics and functions for mobile
    @include respond-to(grid-s) {
        cursor: pointer;
        clip-path: inset(0 0 1px 0); // prevent blitzer

        img {
            transform: translate3d(0, 1.2em, 0) scale(0.95);
            transition: transform $trans-time--m $trans-func--easing-out;
        }

        &:after {
            @include pseudo();
            bottom: -1px; // prevent blitzer
            left: grid-padding(grid-s);
            right: grid-padding(grid-s);
            height: 2.8em;
            border-top: 1px solid black;
            background: $back-color--default;
            background-size: 0.8em * 1.7 1.28em * 1.7;
            background-position: center 0.2em;
            transform: translate3d(0, 0, 0); // Fix display for iOS
        }

        @for $i from 1 through $max-compare {
            [data-compare-active="#{$i}"] & {

                &:nth-child(#{$i}) {
                    img {
                        transform: translate3d(0, -0.2em, 0) scale(0.95);
                    }

                    &:after {
                        // transition: transform $trans-time--s $trans-func--easing-out;
                        transform: translate3d(0, 100%, 0);
                        border: none;
                    }
                }
            }
        }
    }

    @include respond-to(decent) {
        width: grid-width(grid-l, 3);
        padding: 0 grid-padding(grid-l);
        margin-right: grid-gutter(grid-l);
        @include clearfix();

        &:last-child {
            margin-right: 0;
        }

        h3 {
            @include textstyle--default;
            @include textstyle--uppercase;
        }

        &:nth-child(2) {
            .compare-head__item-img .media-box img {
                transition-delay: 0.1s;
            }
        }

        &:nth-child(3) {
            .compare-head__item-img .media-box img {
                transition-delay: 0.2s;
            }
        }
    }

    @include respond-to(grid-m) {
        width: grid-width(grid-m, 3);
        padding: 0 grid-padding(grid-m);
        margin-right: grid-gutter(grid-m);
    }
}

.compare-head__item-name {
    @include respond-to(decent) {
        float: left;
        width: grid-inner-width(grid-l, 2);
        margin-top: 1em;
        height: $fontsize--default * $line-height * 2;
        overflow: hidden;
    }

    @include respond-to(grid-m) {
        width: 100%;
    }
}

.compare-head__item-img {
    .media-box {
        width: grid-width(grid-s, 2.5);
        height: grid-width(grid-s, 3);

        img:nth-child(2) {
            display: none;
        }
    }

    @include respond-to(decent) {
        float: right;

        .media-box {
            width: grid-width(grid-l, 1);
            height: grid-width(grid-l, 1);

            img:nth-child(2) {
                display: block;
                pointer-events: none; // hide title as well
                opacity: 0;
                transition: opacity $trans-time--l $trans-func--easing-out;

                .has-compare-header--caliber & {
                    opacity: 01;
                }
            }
        }
    }

    @include respond-to(grid-m) {
        .media-box {
            width: grid-width(grid-m, 1);
            height: grid-width(grid-m, 1);

            img:nth-child(2) {
                display: none;
            }
        }
    }
}

.compare-head__item-action {
    @include respond-to(decent) {
        float: left;
        margin-top: 0.8rem;

        .btn.btn--secondary {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }

    @include respond-to(grid-m) {
        .btn.btn--secondary {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}

// main header list
.hero--compare {
    margin-top: 0;

    @include respond-to(decent) {
        margin-top: grid-spacing(grid-l, m);
    }
}

.compare-list__wrapper {
    overflow: hidden;
}

.compare-list {
    width: 100%; // fix for flex and float parents
    display: flex;

    @include respond-to(grid-s) {
        // make room for multiple items extending view width
        width: grid-width(grid-s, 10) * $max-compare;
        transition: transform $trans-time--l $trans-func--easing-out;
    }

    @include respond-to(decent) {
        padding-top: $menu-height-desktop;
    }
}

.compare-list__item-header {
    @include respond-to(grid-s) {
        h3 {
            @include textstyle--m;
        }

        > a {
            margin-top: 0;
            margin-right: grid-width-gutter(grid-s, 2) - grid-padding(grid-s);
        }
    }
}

.compare-list__item-img {
    @include respond-to(grid-s) {
        order: 1;
        width: 90%;
        margin: grid-spacing(grid-s, s) auto grid-spacing(grid-s, m);
    }
}

.compare-list__item-action {
    @include respond-to(grid-s) {
        padding-top: 0;
    }

    @include respond-to(decent) {
        flex-wrap: wrap;

        > * {
            margin-top: 2px; // in case we have an unfortunate line break // rem/em would leed to different gaps
        }
    }
}

// compare body
.compare-body {
    section {
        width: 100%;
    }

    .section-title {
        @include respond-to(decent) {
            margin-top: grid-spacing(grid-l, l);

            .modal & {
                position: sticky;
                top: calc(14em + #{$modal-header-desktop});
                background: $back-color--default;
                z-index: 1;
            }
        }
    }

    .specs-list {
        @include grid-padding;

        @include respond-to(grid-s) {
            overflow: hidden;
        }

        // reset dd to be able to set with sizes
        dd {
            font-size: inherit;
        }
    }

    dl {
        @include clearfix();

        @include respond-to(grid-s) {
            // make room for multiple items extending view width
            width: grid-width(grid-s, 10) * $max-compare;
        }

        @include respond-to(decent) {
            padding: 1.6em 0 4em 0;
            outline: 0.8rem solid transparent;
            border-top: 1px solid $border-color--light-1;
            transition: background $trans-time--l $trans-func--easing-out,
            outline $trans-time--l $trans-func--easing-out;

            @include hover {
                background: $back-color--tertiary;
                outline: 0.8rem solid $back-color--tertiary;
            }
        }
    }

    dt {
        @include respond-to(grid-s) {
            max-width: grid-inner-width(grid-s, 10);
        }
    }

    dd {
        float: left;
        @include grid-inner-width(10, 3, 3);
        font-size: inherit;
        margin-bottom: 2em;
        padding-right: grid-width-gutter(grid-s, 2) - grid-padding(grid-s);

        span {
            font-size: $fontsize--m;
        }

        @include respond-to(decent) {
            margin-bottom: 0;
        }

        @include respond-to(grid-l) {
            padding-right: 0;

            &:not(:last-child) {
                margin-right: grid-gutter(grid-l) + grid-padding-h(grid-l) * 2;
            }

            span {
                font-size: $fontsize--l;
            }

            > span {
                font-size: $fontsize--l;
                max-width: 36rem; // Limit line length for big viewports
                display: inline-block;
            }
        }

        @include respond-to(grid-m) {
            padding-right: 0;

            &:not(:last-child) {
                margin-right: grid-gutter(grid-m) + grid-padding-h(grid-m) * 2;
            }

            span {
                font-size: $fontsize--default;
            }
        }
    }
}

// compare caliber
.compare-body__caliber-head-wrapper {
    overflow: hidden;
}

.compare-body__caliber-head {
    display: flex;

    @include respond-to(grid-s) {
        // make room for multiple items extending view width
        width: grid-width(grid-s, 10) * $max-compare;
        transition: transform $trans-time--l $trans-func--easing-out;
    }
}

.compare-body__caliber-item {
    display: flex;
    flex-direction: column;
    @include grid-width(10, 3, 3);

    @include respond-to(decent) {
        margin-right: grid-gutter(grid-l);

        &:last-child {
            margin-right: 0;
        }
    }

    @include respond-to(grid-m) {
        margin-right: grid-gutter(grid-m);

        &:last-child {
            margin-right: 0;
        }
    }
}

.compare-body__caliber-name {
    @include grid-padding();
    flex: 1 1 auto;

    h3 {
        @include textstyle--default;
        @include textstyle--m;
        @include textstyle--uppercase;
        max-width: 81%;

        @include respond-to(grid-l) {
            @include textstyle--l;
        }
    }

    p {
        @include textstyle--default;
        max-width: 81%;
        margin-top: 0.2em;

        @include respond-to(grid-l) {
            @include textstyle--l;
        }
    }
}

.compare-body__caliber-img {
    width: 90%;
    margin: 0 auto;
    flex: 0 0 auto;

    .media-box {
        @include ratio-box(100%);
    }

    @include respond-to(decent) {
        width: 100%;
    }
}

// Handle mobile content view
@include respond-to(grid-s) {
    @for $i from 1 through $max-compare - 1 {
        // Handle visibility for mobile compare header
        [data-compare-active="#{$i + 1}"] ~ .hero--compare {
            .compare-list {
                transform: translate3d(100% / $max-compare * -1 * $i, 0, 0);
            }
        }

        // Handle visibility for mobile caliber header
        [data-compare-active="#{$i + 1}"] ~ .main-content {
            .compare-body__caliber-head {
                transform: translate3d(100% / $max-compare * -1 * $i, 0, 0);
            }
        }
    }

    // Handle visibility for mobile content lists
    @for $i from 1 through $max-compare {
        [data-compare-active] ~ .main-content {
            dd {
                opacity: 0;
                visibility: hidden;
                transition: opacity $trans-time--l $trans-func--easing-out,
                transform $trans-time--l $trans-func--easing-out;

                &:nth-of-type(#{$i + 1}) {
                    // only animate 20% of the full width
                    margin-left: grid-inner-width(grid-s, 10) * 0.8 * -1;
                }
            }
        }

        [data-compare-active="#{$i}"] ~ .main-content {
            dd {
                // only animate 20% of the full width
                transform: translate3d(20% * -1 * ($i - 1), 0, 0);

                &:nth-of-type(#{$i}) {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
