// nomos sections

// Make footer stick to bottom
.ajax-container {
    @include respond-to(decent) {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
}

// content stuff
.main-content {
    // make main-content fill .ajax-container to make footer sticky
    @include respond-to(decent) {
        flex: 1 0 auto;
    }

    // prevent overflows
    // No overflow might break mobile views
    // overflow hidden on parent element breaks sticky bar
    section {
        // overflow-y: hidden; makes a funny scrollbar on METRO IM DETAIL Section
        // overflow: hidden;
    }

    // let the footer breath on everypage
    > :last-child:not(.section-fond) {
        margin-bottom: grid-spacing(grid-s, xxl);

        @include respond-to(grid-s) {
            &.form-grid {
                margin-bottom: 0;
            }
        }
    }

    @include respond-to(grid-l) {
        > :last-child:not(.section-fond) {
            margin-bottom: grid-spacing(grid-l, xxl);
        }
    }

    @include respond-to(grid-m) {
        > :last-child:not(.section-fond) {
            margin-bottom: grid-spacing(grid-m, xxl);
        }
    }

    // there is no footer on modals
    // therefore no padding here
    .modal & {
        > :last-child {
            margin-bottom: 0;
        }
    }
}

.section-title {
    width: 100%;
    @include clearfix();
    @include grid-spacing(l);

    i {
        float: right;
        padding-left: 0.5rem;
    }

    h4 {
        display: inline-block;
    }

    i + h4 {
        max-width: 65%;
    }

    * + .head-wrapper {
        @include grid-spacing(s);
    }

    &.section-title--no-gap {
        margin-top: 0;
    }

    @include respond-to(grid-l) {
        i + h4 {
            max-width: 80%;
        }

        * + .head-wrapper {
            @include grid-spacing(m, grid-l);
        }
    }

    @include respond-to(grid-m) {
        i + h4 {
            max-width: 80%;
        }

        * + .head-wrapper {
            @include grid-spacing(m, grid-m);
        }
    }
}

.section-bottom-space {
    padding-bottom: grid-spacing(grid-s, l);

    @include respond-to(grid-l) {
        padding-bottom: grid-spacing(grid-l, l);
    }

    @include respond-to(grid-m) {
        padding-bottom: grid-spacing(grid-m, l);
    }
}

.section-bottom-space-l {
    padding-bottom: grid-spacing(grid-s, xl);

    @include respond-to(grid-l) {
        padding-bottom: grid-spacing(grid-l, xl);
    }

    @include respond-to(grid-m) {
        padding-bottom: grid-spacing(grid-m, xl);
    }
}

.section-gap {
    @include grid-spacing(xl);
}

.section-gap-l {
    @include grid-spacing(xl);

    @include respond-to(decent) {
        @include grid-spacing(xxl);
    }
}

.section-gap-s {
    @include grid-spacing(m);
}

// pulling up a section to visually merge it with the section before
.section-pull {
    margin-bottom: grid-spacing(grid-s, xxl) * 3 * -1;
    padding-bottom: grid-spacing(grid-s, xxl) * 3;

    @include respond-to(grid-l) {
        margin-bottom: grid-spacing(grid-l, xl) * 4 * -1;
        padding-bottom: grid-spacing(grid-l, xl);
    }

    @include respond-to(grid-m) {
        margin-bottom: grid-spacing(grid-m, xxl) * 3 * -1;
        padding-bottom: grid-spacing(grid-m, xxl) * 3;
    }

    // make links and teaser clickable even if next content lies on top
    .teaser,
    a {
        z-index: 1;
        position: relative;
    }
}

// background element colors
.section-fond {
    background: $back-color--tertiary;
    transition: background $trans-time--m $trans-func--easing-out;
}

.section-fond--warm {
    background: $back-color--warm-grey;
}

// background partly colored at the bottom of an element
// use to make a non-background section into a background-section
.section-fond-bottom {
    position: relative;

    .section-fond-bottom__color,
    &:not(.section-fond-custom):before {
        @include pseudo();
        bottom: 0;
        left: 0;
        width: 100%;
        height: 22rem;
        background: $back-color--tertiary;
    }

    @include respond-to(grid-l) {
        .section-fond-bottom__color,
        &:not(.section-fond-custom):before {
            height: 20rem;
        }
    }

    @include respond-to(grid-m) {
        .section-fond-bottom__color,
        &:not(.section-fond-custom):before {
            height: 8rem;
        }
    }
}

// add stickyness to sections
.section-sticky {
    @include respond-to(decent) {
        @supports (position: sticky) {
            position: sticky;
            top: $banner-offset * -1; // align title to navigation
            z-index: map-get($z-index, section-sticky);
            transition: transform $trans-time--m $trans-func--easing-out;

            // do we have a action bar before?
            // then we need to place the sticky section a little further down
            .action-bar ~ & {
                top: $action-bar-height-desktop - $banner-offset;
            }

            // prevent pointer event if sticky because section might cover following sections
            &.has-sticky-banner {
                pointer-events: none;
            }

            // remove stickyness if main menu shows
            .is-scrolled-up-decent:not(.delay-menu-reshow) &,
            .is-scrolled-up-much.delay-menu-reshow & {
                position: relative;
                top: auto;
            }
        }
    }
}
