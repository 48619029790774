// Nomos lang and country switch

.localisation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    height: 100%;

    h4 {
        @include textstyle--uppercase;
        @include textstyle--s;
    }

    p {
        margin-top: 0;
    }

    .head-wrapper {
        @include grid-bottom-spacing(s);
    }

    // lang-selection / button
    fieldset:nth-child(2),
    fieldset:nth-child(3) {
        @include grid-spacing(s);
    }

    // make button stick to bottom on mobile
    @include respond-to(s--m) {
        fieldset {
            width: 100%;
        }

        fieldset:nth-child(1) {
            padding-bottom: 12rem; // make room for sticky button // needed for iOS
        }

        fieldset:nth-child(2) {
            padding-bottom: grid-spacing(grid-s, m);
            order: -1;
            margin-top: 0;

            .option-group {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                padding-bottom: 0;

                .option-group-item {
                    width: calc(50% - 2em);
                }
            }

        }

        fieldset:nth-child(3) {
            position: sticky;
            bottom: -1px;
            z-index: 1; // prevent select field cover from going over
            background: $back-color--default;
            padding-top: grid-spacing(grid-s, s);
            padding-bottom: grid-spacing(grid-s, m);

            &:after {
                @include pseudo();
                top: -6rem;
                left: 0;
                right: 0;
                height: 6rem;
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
                pointer-events: none;
            }
        }
    }

    @include respond-to(grid-l) {
        .head-wrapper {
            @include grid-bottom-spacing(m);
        }

        // country-selection
        fieldset:nth-child(1) {
            width: grid-width(grid-l, 3);
        }

        // lang-selection
        fieldset:nth-child(2) {
            width: grid-width(grid-l, 2);
            margin-top: 0;
        }

        // lang-selection / button
        fieldset:nth-child(3) {
            margin-top: 0;
        }
    }

    // make button stick to bottom on mobile
    @include respond-to(grid-m) {

        fieldset:nth-child(1) {
            padding-bottom: 8rem; // make room for sticky button // needed for iOS
        }

        fieldset:nth-child(3) {
            padding-top: grid-spacing(grid-m, s);
            padding-bottom: grid-spacing(grid-m, m);
        }
    }

}

