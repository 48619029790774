/*------------------------------------*\
  #PROSE / Markdown text boxes
\*------------------------------------*/

.prose {
    overflow-wrap: break-word;
    max-width: 100%;

    h4 {
        @include textstyle--uppercase;
        @include textstyle--s;

        &:not(:first-child) {
            margin-top: 2em * $line-height; // two lines
        }

        & + p {
            margin-top: 0.1em;
        }
    }

    hr {
        background: none;
        border: none;
        height: 4em;
        margin: 0;
    }

    ul {
        &:not(:first-child) {
            margin-top: 0.5em * $line-height; // half a line
        }
    }

    li {
        @include textstyle--default;
        position: relative;
        display: block;
        padding-left: 1em;

        &:not(:first-child) {
            margin-top: 0.15em; // add some spacing
        }

        &:before {
            content: '\00b7';
            font-size: inherit;
            width: 1em;
            display: inline-block;
            margin-left: -1em;
        }
    }

    strong,
    em {
        @include textstyle--book;
        font-style: normal;
    }

    &.text-super {
        li {
            @include textstyle--teaser;
            @include textstyle--teaser-xs;
            hyphens: none;
        }
    }

    &.text-ultra {
        li {
            @include textstyle--teaser;
        }
    }

    @include respond-to(decent) {
        &.text-big {
            h4 {
                @include textstyle--m;
            }

            li {
                @include textstyle--l;
            }
        }

        &.text-ultra,
        &.text-super {
            h4 {
                @include textstyle--m;
            }
        }
    }

    @include respond-to(grid-l) {
        &.text-super {
            li {
                @include textstyle--teaser-m;
            }
        }

        &.text-ultra {
            li {
                @include textstyle--teaser-xl;
            }
        }
    }

    @include respond-to(grid-m) {
        &.text-super {
            li {
                @include textstyle--teaser;
            }
        }

        &.text-ultra {
            li {
                @include textstyle--teaser-l;
            }
        }
    }

}