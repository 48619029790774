// full bleed boxes
.full-bleed-box {
    position: relative;
    height: 80vh;
    min-height: 56vw; // prevent subtitles of being cropped
    overflow: hidden; // needed for rellax

    .section-title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;

        h3 {
            @include textstyle--teaser;

            @include respond-to(grid-l) {
                @include textstyle--teaser-xl;
            }

            @include respond-to(grid-m) {
                @include textstyle--teaser-l;
            }
        }
    }

    // styles for full bleed media
    .media-box {
        height: 100%;
    }

    // is media-box rellax-box?
    .rellax {
        // is initiated?
        .has-rellax .ajax-content & {
            // transitions -12px / -12px with speed -2 no matter which viewport
            margin-top: -12px;
            height: calc(100% + 24px);

            @include respond-to(decent) {
                // transitions -25px / -25px with speed -4 no matter which viewport
                margin-top: -25px;
                height: calc(100% + 50px);
            }
        }
    }

    // if box is last box in last section before footer pull him up to leave no gap
    .main-content > :last-child & {
        &:last-child {
            margin-bottom: grid-spacing(grid-s, xxl) * -1;

            @include respond-to(grid-l) {
                margin-bottom: grid-spacing(grid-l, xxl) * -1;
            }

            @include respond-to(grid-m) {
                margin-bottom: grid-spacing(grid-m, xxl) * -1;
            }
        }
    }

    @include respond-to(grid-s) {
        min-height: 80vw; // take care of small landscape viewports

        &.video-box {
            height: 100vw;
            min-height: 1px;
        }
    }

    @include respond-to(port) {
        &.video-box {
            height: 100vw;
            min-height: 1px;
        }
    }
}

// hudge full bleed box
.full-bleed-box--l {
    height: 100vh;

    // is media-box rellax-box?
    .rellax {
        // is initiated?
        .has-rellax .ajax-content & {
            // transitions +15px / -15px with speed -2 no matter which viewport
            margin-top: -15px;
            height: calc(100% + 30px);

            @include respond-to(decent) {
                // transitions -32px / -32px with speed -4 no matter which viewport
                margin-top: -32px;
                height: calc(100% + 64px);
            }
        }
    }

    @include respond-to(grid-s) {
        &.video-box {
            height: 100vw;
        }
    }

    @include respond-to(port) {
        &.video-box {
            height: 100vw;
            min-height: 1px;
        }
    }

    @include respond-to(decent) {
        height: 140vh;
    }
}

// small mobile full bleed box
.full-bleed-box--s {
    @include respond-to(grid-s) {
        &,
        &.video-box {
            height: 62vw;
            min-height: 62vw;
        }

        .section-title {
            @include grid-spacing(s);

            * + .head-wrapper {
                @include grid-spacing(xs);
            }
        }
    }

    @include respond-to(port) {
        &,
        &.video-box {
            height: 62vw;
            min-height: 62vw;
        }
    }
}
