// set global varibale for viewport height
// will be changed via java script if ther is an offset
html {
    --view-height-offset: 1;
}

// div which will programatically added to dom to inspect 100vh
// and eventually calculate an offset from window.height
.view-height-measure {
    width: 1px;
    height: 100vh;
    position: fixed;
    top: -200vh;
}