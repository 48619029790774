// General checkout styles
.checkout {
    display: block;
    width: 100%;
    margin-bottom: 0 !important;
}


.checkout-container, // Checkout / Delivery/Payment/Confirm
.register-container { // General customer/account registration
    justify-content: space-between;
    opacity: 1;
    transition: opacity $trans-time--m $trans-func--default;

    .checkout--payment-settings .form-grid,
    .form-grid > fieldset {
        &:not(:first-child) {
            @include grid-spacing(l);

            // add lines to gird-s sections
            @include respond-to(grid-s) {
                margin-top: grid-spacing(grid-s, m);
                padding-top: grid-spacing(grid-s, m);
                border-top: 1px solid $border-color--light-1;
            }

            @include respond-to(grid-l) {
                margin-top: grid-spacing(grid-l, l) - grid-spacing(grid-l, s);
            }
        }
    }

    fieldset,
    .form-grid__section {
        h2 {
            @include textstyle--l;
        }
    }

    &[aria-busy="true"] {
        opacity: 0.3;
        pointer-events: none;
    }

    @include respond-to(grid-s) {
        .checkout--sidebar-cart {
            margin-top: grid-spacing(grid-s, m);
        }
    }

    @include respond-to(decent) {
        > *:first-child {
            width: grid-inner-width(grid-l, 6);
        }

        .checkout--sidebar-cart {
            width: grid-width(grid-l, 4);
            margin-top: calc((#{$action-bar-height-desktop} + #{grid-spacing(grid-l, m)}) * -1);

            // compensate for missing action bar
            .page-checkout-confirm--payment & {
                margin-top: calc(#{$action-bar-height-desktop} * -1);
            }

            > * {
                height: 100%; // Make direct child 100% height to enable sticky mode
            }
        }
    }

    @include respond-to(grid-m) {
        > *:first-child {
            width: grid-width(grid-m, 5);

            .form-grid--l .form-grid__section {
               width: grid-inner-width(grid-m, 5);
            }
        }

        .checkout--confirm {
            width: grid-width(grid-m, 6);
        }

        .checkout--sidebar-cart {
            width: grid-width(grid-m, 4);
            margin-top: calc((#{$action-bar-height-desktop} + #{grid-spacing(grid-m, m)}) * -1);

            // compensate for missing action bar
            .page-checkout-confirm--payment & {
                margin-top: calc(#{$action-bar-height-desktop} * -1);
            }
        }
    }
}

// contact email and login section
.address__fieldset--contactmail {
    @include respond-to(s--m) {
        .form-grid__cell--1-2 {
            width: 100%;

            &:not(:first-child) {
                margin-top: 0.4em;
            }
        }
    }

    .login-hint {
        p span {
            display: block;
        }
    }
}

// Payment settings
.checkout-payment-selection {
    .payment-methods-introduction {
        @include grid-spacing(s);

        p {
            @include textstyle--default;
        }

        max-width: grid-inner-width(grid-s, 8);

        @include respond-to(decent) {
            max-width: grid-inner-width(grid-l, 4);
        }
    }
}

// Checkout confirm
.checkout--confirm {
    .text-ultra {
        @include respond-to(grid-l) {
            max-width: grid-inner-width(grid-l, 5);

            p {
                @include textstyle--teaser-l;
            }
        }

        @include respond-to(grid-m) {
            max-width: grid-inner-width(grid-m, 5.5);

            p {
                @include textstyle--teaser-m;
            }
        }
    }
}

.checkout-confirm-refurbished + .checkout-confirm-agb {
    @include grid-spacing(s);
}

.checkout-confirm-agb {
    margin-bottom: 2em;
}

// Finish page
.finish--content {
    > * {
        @include grid-spacing(m);
        @include grid-bottom-spacing(m);
    }

    @include respond-to(grid-l) {
        max-width: grid-width(grid-l, 4);
    }

    @include respond-to(grid-m) {
        max-width: grid-width(grid-m, 7);
    }

    strong {
        @include textstyle--book;
    }
}

// empty cart
.checkout--cart-empty {
    min-height: 25vh;
}
