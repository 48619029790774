.cookie-dialog {
    pointer-events: none;
}

.modal__body.modal__body--cookie-dialog {
    @include respond-to(s) {
        justify-content: flex-end;
    }

    @include respond-to(decent) {
        justify-content: flex-end;
        align-items: flex-end;
    }
}

.modal__content.modal__content--cookie-dialog {
    height: auto;
    pointer-events: all;
    box-shadow: $box-shadow--m;
    max-height: 70vh;

    @include respond-to(s) {
        padding-top: grid-width-gutter(grid-s) * 0.5;
        width: grid-width(grid-s, 10);
        margin-bottom: grid-margin(grid-s, false);
    }

    @include respond-to(m) {
        width: grid-width(grid-m, 7);
        margin-right: grid-spacing(grid-m, s);
        margin-bottom: $action-bar-height-desktop;
        padding: 3em 3em 0;
        min-height: 1rem;
    }

    @include respond-to(grid-l) {
        width: grid-width(grid-l, 5);
        margin-right: $action-bar-height-desktop;
        margin-bottom: $action-bar-height-desktop;
        padding: 3em 3.5em 0;
    }

    @include respond-to(xl) {
        width: grid-width(grid-l, 4);
    }
}

.cookie-dialog__head {
    h3 {
        @include textstyle--m;
    }
}

.cookie-dialog__info {
    @include grid-spacing(xs);

    p {
        @include textstyle--s;
    }
}

.cookie-dialog__settings {
    @include grid-spacing(s);

    h4 {
        @include textstyle--s;
        margin-top: 0.2rem; // Align with checkbox
    }

    p {
        @include textstyle--s;

        * + & {
            margin-top: 0.2rem;
        }
    }

    li {
        display: block;

        &:not(:first-child) {
            @include grid-spacing(xs);
        }
    }
}

.cookie-dialog__settings-item {
    transition: color $trans-time--m $trans-func--default;

    &.is-disabled {
        label {
            cursor: not-allowed;
        }
    }
}

.cookie-dialog__imprint {
    @include grid-spacing(s);

    p {
        @include textstyle--s;
    }
}

.cookie-dialog__footer {
    margin-top: 3rem;
    position: relative;
    z-index: 1; // prevent select field cover from going over
    background: $back-color--default;

    .no-ie &,
    .no-edge & {
        position: sticky;
        bottom: -1px;
    }

    ul {
        display: flex;
        flex-direction: column;
    }

    .btn,
    p {
        @include textstyle--s
    }

    .btn {
        width: 100%;
        text-align: center;
    }

    li:not(:first-child) {
        margin-top: 0.5rem;
    }

    &:after {
        @include pseudo();
        top: -4rem;
        left: 0;
        right: 0;
        height: 4rem;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        pointer-events: none;
    }

    @include respond-to(s) {
        padding-bottom: grid-spacing(grid-s, s);
    }

    @include respond-to(grid-l) {
        padding-bottom: 3.5em;
        margin-top: 2rem;

        &:after {
            top: -3rem;
            height: 3rem;
        }
    }

    @include respond-to(grid-m) {
        padding-bottom: 3em;
    }
}