// Input fields
input,
.input,
select,
textarea,
.select {
    @include textstyle--default;
    color: $text-color--default;
    outline: none;
    background: $back-color--secondary;
    width: 100%;
    height: $input-height-default;
    line-height: $input-height-default - $baseline-offset;
    padding: 0 1em $baseline-offset;
    margin: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    transition: background $trans-time--m $trans-func--default;
    outline-offset: -1px; // prevents some strange clipping-bugs in chrome

    &::placeholder {
        font-family: inherit;
        line-height: inherit;
        color: $text-color--secondary-dark;
    }

    &.input--clean {
        background: none;
        padding-left: 0;
        padding-right: 0;
    }

    &:focus {
        background-color: $back-color--secondary-dark;
    }

    &input {
        &[readonly],
        &:read-only {
            color: $text-color--secondary;
        }
    }

    &[type="search"] {
        appearance: none;
    }

    &[type="number"] {
        @include textstyle--tabular-figures;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &.select--s,
    &.input--s {
        @include textstyle--s;
        height: $input-height-s;
        line-height: $input-height-s - $baseline-offset;
    }

    // add input icons
    &.input--search {
        background: $back-color--secondary svg-inline(icon--nom-search) no-repeat;
        background-size: 1em 1.6em;
        background-position: calc(100% - 1em) center;
        padding-right: 2.4em;
    }

    &.input--email {
        background: $back-color--secondary svg-inline(icon--nom-mail) no-repeat;
        background-size: 1em 1.6em;
        background-position: calc(100% - 1em) center;
        padding-right: 2.4em;
    }

    // validation
    &.has-value {
        // outline: 1px dotted rgba(0,0,0,0);
    }

    &.has-error,
    &.is-error,
    &.is-invalid {
        // empty and invalid
        background-color: $back-color--error; // Use background color in case of select fields
        color: $text-color--error;

        &::placeholder {
            color: rgba($text-color--error, 0.7);
        }
    }

    &[disabled] {
        opacity: 0.75;
    }
}

textarea {
    height: auto;
    resize: none;
    line-height: 1.4em;
    padding: 0.6em 1em;
    min-height: 16rem;
}

.textarea-s {
    padding: 0.4em 0.6em;
    min-height: 9rem;
    @include textstyle--s;
}

label {
    @include textstyle--default;
    @include textstyle--xs;
    @include textstyle--uppercase;
    display: block;

    &.plain-label {
        @include textstyle--default;
        @include textstyle--xs;

        @include respond-to(decent) {
            @include textstyle--s;
        }
    }

    .form-grid__cell > & {
        width: 100%;
    }
}

select,
.select {
    appearance: none;
    width: 100%;
    line-height: $input-height-default - $baseline-offset;
    padding: 0 1em;
    padding-right: 2.8em;
    max-width: 100%;
    @include dropdown-arrow($back-color--secondary, true);

    &:read-only,
    option:not([disabled]) {
        color: $text-color--primary;
    }

    &.select--s {
        @include textstyle--s;
        height: $input-height-s;
        line-height: $input-height-s - $baseline-offset;
    }

    &.select--clean {
        border: 0;
        padding-left: 0;
        padding-right: 2.5rem;
        position: relative;
        @include dropdown-arrow();
    }

    &.select--outline {
        background-color: transparent;
        border: 1px solid $border-color--light-1;
    }

    &.select--numbers {
        option {
            @include textstyle--tabular-figures;
        }
    }
}

// optional hint
.optional-hint {
    display: inline-block;
    @include textstyle--default;
    @include textstyle--s;
    color: $text-color--secondary;
    letter-spacing: 0.04em;

    &:before {
        content: ' – ';
    }
}

// disabled styles
form {
    .is--disabled {
        color: $text-color--secondary;
        pointer-events: none;
    }
}

// Option groups
.fake-radio-button,
.option-group {
    display: flex;
    padding-bottom: 1em;

    h4 + &,
    label + & {
        padding-top: 1em;
    }

    &.option-group--column {
        flex-direction: column;

        label {
            line-height: 1.6;
            margin-bottom: 0.6em;
        }
    }

    .option-group-item {
        position: relative;

        &:not(:last-child) {
            margin-right: 2em;
        }
    }

    // Hide radio button
    input[type="checkbox"],
    input[type="radio"] {
        @include hide;
    }

    label {
        display: inline-block;
        cursor: pointer;
        position: relative;
        padding: 0 0 0 2.4rem;
        @include textstyle--default;

        // input style
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            width: 2rem;
            height: 2rem;
            transform: translateY(-50%);
            background: svg-inline(icon--nom-radio-no) center center no-repeat;
        }
    }

    // Radio button is checked
    input[type="checkbox"]:checked + label,
    input[type="radio"]:checked + label {
        &:after {
            background: svg-inline(icon--nom-radio-yes) center center no-repeat;
        }
    }

    // Disabled style
    input[type="checkbox"]:disabled + label,
    input[type="radio"]:disabled + label {
        color: $text-color--secondary;
        cursor: not-allowed;
    }

    // @todo: add focus styles
    //  input[type="radio"]:focus + label::after {
    //      outline: rgb(59, 153, 252) auto 5px;
    //  }

    &.fake-radio-button {
        padding-bottom: 0;

        label {
            @include textstyle--button;
            text-transform: none;
        }
    }

    &.option-group--text {
        label {
            padding: 0;
            @include textstyle--button;
            text-transform: none;

            &:after {
                display: none;
            }
        }

        // Radio button is checked
        input[type="radio"]:checked + label {
            @include textstyle--book;
            @include underline;
        }
    }

    &.option-group--panel {
        display: flex;
        flex-direction: column;
        @include grid-inner-width(10, 7, 5);

        .option-group-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            &:not(:first-child) {
                @include grid-spacing(xs);
            }
        }

        label {
            margin-bottom: 0;
            padding: 1.2em 1.2em 1.2em 3em;
            width: 100%;
            @include textstyle--teaser;
            @include textstyle--teaser-xs;
            border: solid 1px $border-color--secondary;
            transition: border $trans-time--m $trans-func--default,
                        background $trans-time--m $trans-func--default;

            &:after {
                left: 1em;
            }
        }

        // Radio button is checked
        input[type="radio"]:checked + label {
            border: solid 1px $border-color--primary;
            // background: $back-color--default;
        }
    }
}



// advanced checkboxes
.advanced-checkbox {
    position: relative;

    // hide checkbox
    input[type="checkbox"] {
        @include hide;
        top: 2.2rem; // position for correct browser validate hint placement
        left: 1rem;
    }

    label {
        display: inline-block;
        cursor: pointer;
        position: relative;
        padding: 0 0 0 3rem;
        @include textstyle--default;

        &.form-small {
            @include textstyle--xs;
        }

        .form-grid & {
            margin-left: 0; // overwrite default form-grid label styles
        }

        // Links should have an underline inside labels
        a {
            border-bottom: 1px solid rgba($text-color--default, 0.8);
        }

        // input style
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 2rem;
            height: 2rem;
            transform: translateY(0.1em);
            background: svg-inline(icon--nom-check-no) center center no-repeat;
        }

        small {
            @include textstyle--default;
            @include textstyle--xs;
            display: block;
            margin-top: 0.5rem;
        }
    }

    // Radio button is checked
    input[type="checkbox"]:checked + label {
        &:after {
            background: svg-inline(icon--nom-check-yes) center center no-repeat;
        }
    }

    & + & {
        margin-top: 0.4em;
    }

    input[type="checkbox"]:focus:invalid:not(:checked),
    &.has-error input[type="checkbox"]:not(:checked),
    input[type="checkbox"].is-invalid:not(:checked) {
        & + label {
            color: $text-color--error;

            a {
                border-color: rgba($text-color--error, 0.8);
            }

            &:after {
                background-color: $back-color--error;
            }
        }
    }
}

.toggle-checkbox {
    // hide checkbox
    input[type="checkbox"] {
        @include hide;
        top: 2.2rem; // position for correct browser validate hint placement
        left: 1rem;
    }

    label {
        position: relative;
        display: block;
        width: 100%;
        cursor: pointer;

        &:before {
            content: '';
            display: inline-block;
            width: 4rem;
            height: 2rem;
            background-color: $back-color--warm-grey;
            border: solid 1px $border-color--default;
            border-radius: 1.25rem;
            transition: background-color $trans-time--s $trans-func--default,
                        border $trans-time--s $trans-func--default;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0.3rem;
            left: 0.4rem;
            width: 1.4rem;
            height: 1.4rem;
            background-color: $back-color--default;
            border-radius: 50%;
            border: solid 1px $border-color--default;
            transition: transform $trans-time--s $trans-func--default,
                        border $trans-time--s $trans-func--default;
        }

        &.has-label {
            span {
                @include textstyle--s;
                font-family: $type-fam--gotham;
                text-transform: none;
                position: absolute;
                left: 5rem;
                top: 0.35rem;
                line-height: 1;
                transition: opacity $trans-time--s $trans-func--default,
                            transform $trans-time--s $trans-func--default;
                opacity: 1;
                transform: none;
            }
        }
    }

    input[type="checkbox"]:not(:checked) + label {
        &:before {
            background-color: transparent;
            border-color: $back-color--dark-grey;
        }

        &:after {
            transform: translate3d(1.8rem, 0, 0);
            border-color: $back-color--dark-grey;
        }
    }

    input[type="checkbox"]:checked + .has-label span:last-child {
        opacity: 0;
        transform: translate3d(0.5rem, 0, 0);
    }

    input[type="checkbox"]:not(:checked) + .has-label span:first-child {
        opacity: 0;
        transform: translate3d(-0.5rem, 0, 0);
    }
}

.form-small {
    @include textstyle--xs;
    margin-top: 0.5rem;
}

// selecting a product in a slideshow
// advanced checkbox with a full product to select
.product-select {
    padding-bottom: 5em;
    margin-bottom: 2em;
    height: 100%;

    &.teaser--product {
        overflow: visible;
    }

    // handle two-colord images
    img:first-child {
        visibility: hidden;
    }

    // handle media-box overflow
    .media-box-wrapper {
        margin: 0.5em;
        overflow: hidden;
    }

    // hide checkbox
    input[type="radio"] {
        @include hide;
    }

    // trigger layout for label on none enabled slideshows
    .slideshow > .flickity__slide & {
        transform: translate3d(0,0,0);
    }

    label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: inherit;
        color: transparent;

        // background
        &:before {
            @include pseudo();
            position: absolute;
            top: 0.5em;
            left: 0.5em;
            width: calc(100% - 1em);
            height: calc(100% - 1em);
            z-index: -1;
            background: $back-color--default;
            box-shadow: $box-shadow--s;
            transform: none;
            opacity: 0;
            transition: transform $trans-time--s $trans-func--default;

            @include respond-to(decent) {
                transform: scale(0.95);
            }
        }

        // fake radio
        &:after {
            content: '';
            position: absolute;
            bottom: 3rem;
            left: 50%;
            display: block;
            width: 2rem;
            height: 2rem;
            transform: translateX(-50%);
            background: svg-inline(icon--nom-radio-no-2) center center no-repeat;
        }
    }

    // Radio button is checked
    input[type="radio"]:checked {
        & ~ .media-box-wrapper {
            img:first-child {
                visibility: visible;
            }

            img:last-child {
                visibility: hidden;
            }
        }

        & ~ label {

            &:before {
                transform: scale(1.05);
                opacity: 1;

                @include respond-to(decent) {
                    transform: none;
                }
            }

            &:after {
                background: svg-inline(icon--nom-radio-yes) center center no-repeat;
            }
        }

    }
}

.select--inline-label {
    position: relative;
    display: flex;
    align-items: center;
    align-content: stretch;
    border-bottom: 1px solid $border-color--light-1;
    border-top: 1px solid $border-color--light-1;

    & + & {
        border-top: none;
    }

    > label {
        @include textstyle--default;
        min-width: 6.4em;
        padding-right: 0.5em;
        flex: 0 0 auto; // dont grow, dont shrink
    }

    &.select--inline-label--s {
        > label {
            min-width: 4.8em;
        }
    }

    .radio-wrapper,
    .select-wrapper {
        width: auto;
        flex: 1 1 auto; // grow and shrink as needed
    }

    .select-wrapper {
        position: relative;
        @include fade-cover();

        // fade cover tweaks
        &:after {
            right: calc(2.5rem - 1px); // prevent blitzer
            top: 2px; // dont overflow border
            bottom: 2px;
            width: 2.5rem;
            background: linear-gradient(to right, rgba($back-color--primary, 0), $back-color--primary);

            .section-fond & {
                background: linear-gradient(to right, rgba($back-color--tertiary, 0), $back-color--tertiary);
            }

            @include respond-to(decent) {
                .product-main-info:not(.product-main-info--watch) & {
                    background: linear-gradient(to right, rgba($back-color--tertiary, 0), $back-color--tertiary);
                }
            }
        }
    }

    // styling for lug width — rewrite if needed somewhere else
    .select-wrapper--with-info {
        select {
            max-width: 8rem;
        }

        &:after {
            display: none;
        }
    }
}

.radio-wrapper {
    display: flex;

    .fake-radio-button {
        label {
            height: $input-height-default - $baseline-offset; // extend clickarea
            margin-bottom: $baseline-offset;
            display: flex;
            align-items: center;
            font-size: inherit;
            padding: 0 1em;
            width: auto;

            span {
                @include textstyle--default;
                line-height: 1.6;
            }
        }

        .select--inline-label & {
            &:first-child {
                margin-left: -1em;
            }
        }
    }

    // Radio button is checked
    input[type="radio"]:checked + label {
        &:before {
            display: none !important;
        }

        span {
            @include underline;
            @include textstyle--book;
        }
    }

    // radio-wrapper without .select--inline-label label
    &.radio-wrapper--single {
        border: 1px solid $border-color--light-1;
        justify-content: space-around;
        padding: 0 1em;
    }
}

// Input with calculated unit placeholder

.input--with-ghost-unit + * {
    position: absolute;
    top: 0;
    right: 0;
    @include textstyle--default;
    @include textstyle--tabular-figures;
    margin: 0.8rem 0; // have border not be full height
    height: $input-height-default - 1.6rem;
    line-height: $input-height-default - $baseline-offset - 1.6rem;
    padding: 0 1em $baseline-offset;
    display: none;
    border-left: 1px solid darken($border-color--light-1, 5%);
    transition: opacity $trans-time--m $trans-func--easing-out,
                transform $trans-time--m $trans-func--easing-out;

    // only do if pointer events are possible
    @supports (pointer-events: none) {
        pointer-events: none;
        display: block;
    }

    &:empty {
        transform: translateX(1rem);
        opacity: 0;
    }

    span {
        // hide input content
        &:first-child {
            display: none;
        }
    }
}

// Input groups
.input-group {
    @include respond-to(grid-l) {
        max-width: grid-inner-width(grid-l, 3);
    }

    p + & {
        @include grid-spacing(m);
    }
}

.input-button-group {
    display: flex;

    &.input-button-group--quantity-selection {
        input {
            line-height: 3rem;
            height: 3rem;
            padding: 0;
            width: 4rem;
            color: $text-color--default;
        }
    }
}

input + .btn--text {
    @include grid-spacing(xs);
}

// Form grid
.form-grid {
    max-width: 100%;

    label {
        margin-left: 0.2rem; // optical randausgleich
    }

    label + input,
    label + select,
    label + textarea,
    .form-grid__cell--group-label ~ .form-grid__cell {
        margin-top: 0.3em;
    }

    [aria-hidden="true"] {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        margin-bottom: 0;
        margin-top: 0;
    }

    fieldset {
        position: relative;
    }

    &[aria-busy] {
        opacity: 1;
        transition: opacity $trans-time--m $trans-func--default;
    }

    &[aria-busy="true"] {
        opacity: 0.3;
        pointer-events: none;
    }
}

.form-grid__section {
    @include grid-inner-width(8, 6, 3);
    max-width: 100%;

    .form-grid--l & {
        @include grid-inner-width(8, 6, 4);
    }

    .form-grid--xl & {
        @include grid-inner-width(10, 5, 5);
    }

    &.form-grid__section--l {
        @include grid-inner-width(8, 6, 6);
    }

    &:not(:first-child):not(.variant--group) {
        @include grid-spacing(m);

        h2 + &,
        h3 + & {
            margin-top: 0;
        }
    }

    @include respond-to(grid-m) {
        .footer-newsletter-register & {
            width: grid-inner-width(grid-m, 4);
        }
    }
}

// One row in a form grid
// Wrapper for Form-Cells
.form-grid__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 1.5em;

    // Swap order / eg. for Zip/City based on country selection
    &.is-reversed {
        flex-direction: column-reverse;

        @include respond-to(decent) {
            flex-direction: row-reverse;
        }
    }

    &.form-grid__row--birthday {
        label {
            display: block;
            width: 100%;
        }

        label ~ .form-grid__cell {
            margin-top: 0.3em;
        }
    }
}

// help button along form-elements
.form-grid__help {
    position: absolute;
    top: 0;
    right: -4em;
}

// Single Cell containing inputs, selects, …
.form-grid__cell {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    p {
        margin-left: 0.2rem; // optical randausgleich
    }

    * + .plain-label,
    * + p {
        margin-top: 0.4em;
    }

    // single cell
    &:first-child:last-child {
        width: 100%;
    }

    // fractioned cell should be full width on mobile
    &.form-grid__cell--1-4,
    &.form-grid__cell--3-4,
    &.form-grid__cell--1-3,
    &.form-grid__cell--2-3 {
        width: 100%;
    }

    // grid cell half width
    &.form-grid__cell--1-2 {
        width: 50%;
    }

    // form grid cell with info button
    &.form-grid__cell--info {
        &,
        &:last-child {
            width: calc(100% - 3rem - 1.2em); // compensate for info button
        }
    }

    .form-grid__cell__response {
        margin-top: 0.3em;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: opacity $trans-time--s $trans-func--default,
                    max-height $trans-time--s $trans-func--default;

        &.is-error {
            color: $text-color--error;
        }

        &[aria-hidden="false"] {
            max-height: 16rem;
            opacity: 1;
        }
    }

    // State selection
    .country-state-selection-holder {
        display: block;
        width: 100%;
    }

    &.is-hint {
        p {
            @include textstyle--xs;
        }
    }

    &.has-help {
        flex-wrap: nowrap;
        align-items: flex-start;
    }

    // give some top spacing if cell groups are spread across multiple lines
    @include respond-to(grid-s) {
        &:not(:first-child) {
            input,
            select {
                margin-top: 0.3em;
            }
        }

        // grid cell one quarter width
        &.form-grid__cell--1-4 {
            &:not(:first-child) {
                width: calc(50% - #{grid-gutter-width(grid-s) / 2});
            }
        }

        // give second full width cell some top spacing on mobile
        &.form-grid__cell--1-4,
        &.form-grid__cell--3-4,
        &.form-grid__cell--1-3,
        &.form-grid__cell--2-3,
        &.form-grid__cell--2-5,
        &.form-grid__cell--3-5 {
            &:not(:first-child) {
                margin-top: 1.5em;
            }

            // streamline top spacing for reversed order items
            .is-reversed & {
                &:not(:first-child) {
                    margin-top: 0;
                }

                &:first-child {
                    margin-top: 1.5em;
                }
            }
        }

        &.form-grid__cell--group-label ~ .form-grid__cell {
            margin-top: 0.3em;
            width: 100%;
        }
    }

    @include respond-to(grid-l) {
        width: calc(50% - #{grid-gutter-width(grid-l) / 2});

        // grid cell one quarter width
        &.form-grid__cell--1-4 {
            width: calc(25% - #{grid-gutter-width(grid-l) / 2});
        }

        // grid cell three quarter width
        &.form-grid__cell--3-4 {
            width: calc(75% - #{grid-gutter-width(grid-l) / 2});
        }

        // grid cell half width
        &.form-grid__cell--1-2 {
            width: calc(50% - #{grid-gutter-width(grid-l) / 2});
        }

        // grid cell one third width
        &.form-grid__cell--1-3 {
            width: calc(33% - #{grid-gutter-width(grid-l) / 2});
        }

        // grid cell two third width
        &.form-grid__cell--2-3 {
            width: calc(67% - #{grid-gutter-width(grid-l) / 2});
        }

        &.form-grid__cell--2-5 {
            width: calc(40% - #{grid-gutter-width(grid-l) / 2});
        }

        &.form-grid__cell--3-5 {
            width: calc(60% - #{grid-gutter-width(grid-l) / 2});
        }

        &.form-grid__cell--group-label {
            width: 100%;
        }

        // big rows
        .form-grid__section--l & {
            // grid cell half width
            &.form-grid__cell--1-2 {
                width: calc(50% - #{grid-gutter-width(grid-l) / 2} - #{grid-padding-h(grid-l)});
            }
        }
    }

    @include respond-to(grid-m) {
        width: calc(50% - #{grid-gutter-width(grid-m) / 2});

        // grid cell one quarter width
        &.form-grid__cell--1-4 {
            width: calc(25% - #{grid-gutter-width(grid-m) / 2});
        }

        // grid cell three quarter width
        &.form-grid__cell--3-4 {
            width: calc(75% - #{grid-gutter-width(grid-m) / 2});
        }

        // grid cell half width
        &.form-grid__cell--1-2 {
            width: calc(50% - #{grid-gutter-width(grid-m) / 2});
        }

        // grid cell one third width
        &.form-grid__cell--1-3 {
            width: calc(33% - #{grid-gutter-width(grid-m) / 2});
        }

        // grid cell two third width
        &.form-grid__cell--2-3 {
            width: calc(67% - #{grid-gutter-width(grid-m) / 2});
        }

        &.form-grid__cell--2-5 {
            width: calc(40% - #{grid-gutter-width(grid-l) / 2});
        }

        &.form-grid__cell--3-5 {
            width: calc(60% - #{grid-gutter-width(grid-l) / 2});
        }

        &.form-grid__cell--group-label {
            width: 100%;
        }
    }
}

// don’t allow to big spacings for cms headlines
.text-container + .module-form {
    .text-container {
        padding-top: 0;
    }
}

// Form response list wrapped in .form-response used for showing ajax response messages. (eg. Login, Voucher ...)
.response-list {
    @include pseudo-list;

    li {
        display: block;

        span {
            @include textstyle--s;
        }

        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }

    &.is-error {
        li {
            color: $text-color--error;
        }
    }
}

// General response messages that are defined in frontend/_includes/messages.tpl
.response-message {
    display: block;

    ul {
        @include pseudo-list;
        margin: 0;
    }

    li {
        display: block;
    }

    .response-message__base {
        padding-bottom: 1em;

        & ~ .response-message__single,
        & ~ .response-message__list {
            padding-top: 1em;
        }
    }

    // Remove padding from nested text container
    .text-container {
        .text-container & {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.is-error {
        color: $text-color--error;

        a {
            color: $text-color--error;
            border-color: $text-color--error;
        }
    }

    @include respond-to(grid-l) {
        > * {
            max-width: grid-width(grid-l, 4);
        }
    }

    @include respond-to(grid-m) {
        > * {
            max-width: grid-width(grid-m, 6);
        }
    }
}

// Just add some spacing/padding when the response container is not part of a group
.grid-container > .form-response-container {
    @include grid-spacing(m);
    @include grid-bottom-spacing(l);
}

.form-response-message {
    @include textstyle--default;

    @include respond-to(decent) {
        @include textstyle--l;
    }
}
