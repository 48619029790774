// Nomos media boxes

.media-box {
    position: relative;
    overflow: hidden;

    @supports (object-fit: cover) {
        background: $back-color--tertiary;
    }
    //! don’t use flex box here or it will break firefox and edge

    picture {
        width: 100%;
        display: block;
    }

    img {
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: 50% 50%;
        transition: transform $trans-time--l $trans-func--default,
                    opacity $trans-time--l $trans-func--default,
                    object-position $trans-time--m $trans-func--default; // prevent flicker on start by animating object-position as well

        .ie & {
            font-family: 'object-fit: cover; object-position: 50% 50%;'; // ie fix probably
        }

        .teaser--soldier &,
        .teaser--product:not(.teaser--product-double):not(.teaser--product-single) & {
            width: 100%;
            height: auto;
            object-fit: fill;
        }
    }

    // Hide Lazy images at first
    .lazy {
        opacity: 0;
        transform: scale(#{$zoom-scale});

        // just fade in fullscreen images and bog watches to not interfere with other animations
        .teaser--product-single &,
        .teaser--product-double &,
        .full-bleed-box & {
            transform: none;
        }
    }

    // then aninmate in
    .lazy--loaded {
        opacity: 1;
        transform: none;
    }

    // don't show backgrounds for big images on colored canvas and on custom backgrounds
    .section-fond-custom &,
    .section-fond .box-xxxl & {
        background: none;
    }

    // clipped boxes don’t have backgrounds
    // and show other animations
    &.media-box--clipped {
        background: none;

        .lazy {
            transform: scale(#{$zoom-scale-inverted});

            // alternate animations for already scaled images
            .teaser--product-cross-selling & {
                // transform: scale(#{$zoom-scale-inverted * $soldier-crosseling-scale-m});
            }

            @include respond-to(decent) {
                .teaser--soldier & {
                    // transform: scale(#{$zoom-scale-inverted * $soldier-scale});  // remove soldier scale for new soldiers
                }

                .teaser--product-cross-selling & {
                    // transform: scale(#{$zoom-scale-inverted * $soldier-crosseling-scale-d});
                }
            }

            @include respond-to(grid-l) {
                .teaser--product-overview.teaser--soldier & {
                    // transform: scale(#{$zoom-scale-inverted * $soldier-overview-scale}); // remove soldier scale for new soldiers
                }
            }
        }

        .lazy--loaded {
            transform: none;

            // alternate animations for already scaled images
            .teaser--product-cross-selling & {
                // transform: scale(#{$soldier-crosseling-scale-m});
            }

            @include respond-to(decent) {
                .teaser--soldier & {
                    // transform: scale(#{$soldier-scale});  // remove soldier scale for new soldiers
                }

                .teaser--3d-watch & {
                    // transform: scale(#{$three-d-watch-scale});
                }

                .teaser--product-cross-selling & {
                    // transform: scale(#{$soldier-crosseling-scale-d});
                }
            }

            @include respond-to(grid-l) {
                .teaser--product-overview.teaser--soldier & {
                    // transform: scale(#{$soldier-overview-scale}); // remove soldier scale for new soldiers
                }
            }
        }
    }

    .media-center-box {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        img {
            position: relative;
            top: auto;
            left: auto;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
}

// media-box with lazy-loaded img
// class set with js
// pulse animation
.lazy-child {
    transition: background $trans-time--l $trans-func--default;

    // show animations via intersection-observer
    &:after {
        .gallery &,
        .modal--popup &,
        .intersection-observer .intersect-inview & {
            @include loading-pulse(darken($back-color--secondary, 4%), 2rem, 1s, 1s);
        }
    }

    // show animations via loading class for non-intersection-observers
    .no-intersection-observer & {
        &.lazy-child--loading {
            &:after {
                @include loading-pulse(darken($back-color--secondary, 4%), 2rem, 1s, 1s);
            }
        }
    }

    // Do not show pulse animation on videos
    .video-box &:after {
        display: none;
    }

    // Show pulse animation on videos within plyr container
    .video-box .plyr--video &:after {
        display: block;
    }

    &.lazy-child--loaded {
        &:after {
            display: none !important;
            animation: none !important;
        }
    }

    &.media-box--clipped {
        &:after {
            background: darken($back-color--secondary, 3%);
        }
    }
}

// fix chrome bug where background flickers on rollover
.lazy-child--loaded {
    background: transparent;
}

// define some image ratios for boxes
// remember to not define these on flex items
// please wrap!

.portrait-box {
    @include ratio-box($portrait-ratio);
}

.portrait-box-l {
    @include ratio-box($portrait-ratio-l);
}

.landscape-box {
    @include ratio-box($landscape-ratio);
}

.landscape-box-l {
    @include ratio-box($landscape-ratio-l);
}

.square-box {
    @include ratio-box($square-ratio);
}

.wide-screen-box {
    @include ratio-box($wide-screen-ratio);
}

// shwo two media boxes side-by-side
.media-box-couple {
    display: flex;
    justify-content: space-between;

    > * {
        width: calc(50% - #{grid-gutter(grid-s)} / 2);
        padding-bottom: $portrait-ratio / 2;

        @include respond-to(grid-l) {
            width: calc(50% - #{grid-gutter(grid-l)} / 2);
        }

        @include respond-to(grid-m) {
            width: calc(50% - #{grid-gutter(grid-m)} / 2);
        }
    }
}