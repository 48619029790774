.svg-ico {
    display: inline-block;
    fill: transparent;
    stroke: currentColor;
    width: 2rem;
    height: 2rem;

    & > svg {
        position: relative;
        width: 100%;
        height: 100%;
    }
}

// icons with fill instead of outline
.svg-ico--nom-logo,
.svg-ico--nom-facebook,
.svg-ico--nom-twitter,
.svg-ico--nom-instagram,
.svg-ico--nom-youtube,
.svg-ico--nom-pinterest {
    stroke: transparent;
    fill: currentColor;
}

.svg-ico--nom-logo {
    width: 12rem;
    height: 3.2rem;

    @include respond-to(decent) {
        width: 15rem;
        height: 4rem;
    }

    // increase logo size on bigger desktop > 1400px
    @include respond-to(xl) {
        width: 16.875rem;
        height: 4.5rem;
    }
}

.svg-ico--nom-close-small {
    width: 1.6rem;
    height: 1.6rem;
}

.svg-ico--nom-next,
.svg-ico--nom-prev {
    width: 1rem;
    height: 3rem;
}

.svg-ico--nom-zoom {
    width: 2.6rem;
    height: 2.6rem;
}

.svg-ico--nom-play {
    width: 5rem;
    height: 5rem;
    stroke: $text-color--inverted;
}

.svg-ico--nom-dot {
    width: 1rem;
    height: 1rem;
}

.svg-ico--nom-more-big {
    width: 4rem;
    height: 4rem;
}

.svg-ico--nom-show-all-old {
    // width: 2.4rem;
}

.svg-ico--nom-show-all {
    width: 3.4rem;
}

.svg-ico--nom-remove {
    width: 0.8rem;
}

.svg-ico--nom-remove-big,
.svg-ico--nom-location-small {
    width: 1.1rem;

    .add--to-wishlist & {
        width: 1.4rem; // same with as like icon
    }

    .teaser__actions .add--to-compare &,
    .teaser__actions .add--to-wishlist & {
        height: 1.76rem;
        width: 1.12rem;

        @include respond-to(decent) {
            height: 1.8rem;
            width: 1.26rem;
        }
    }
}

.svg-ico--nom-contact,
.svg-ico--nom-chat,
.svg-ico--nom-home {
    width: 1.8rem;
}

.svg-ico--nom-phone {
    width: 1.5rem;
}

.svg-ico--nom-compare,
.svg-ico--nom-like,
.svg-ico--nom-compare-active,
.svg-ico--nom-like-active {
    width: 2.2rem;
}

.svg-ico--nom-location,
.svg-ico--nom-like-small,
.svg-ico--nom-like-small-active,
.svg-ico--nom-more-small {
    width: 1.4rem;

    .teaser__actions & {
        height: 1.76rem;
        width: 1.12rem;

        @include respond-to(decent) {
            height: 1.8rem;
            width: 1.26rem;
        }
    }
}

.svg-ico--nom-account-small,
.svg-ico--nom-bag-small {
    width: 1.4rem;
}

.svg-ico--nom-compare-small,
.svg-ico--nom-compare-small-active {
    width: 1.7rem;

    .teaser__actions & {
        height: 1.76rem;
        width: 1.25rem;

        @include respond-to(decent) {
            height: 1.8rem;
            width: 1.53rem;
        }
    }
}

.svg-ico--nom-share-small {
    width: 0.8rem;
}

.svg-ico--nom-secure {
    width: 1.6rem;

    .teaser__actions & {
        height: 1.76rem;
        width: 1.18rem;

        @include respond-to(decent) {
            height: 1.8rem;
            width: 1.44rem;
        }
    }
}

.svg-ico--nom-facebook,
.svg-ico--nom-twitter,
.svg-ico--nom-instagram,
.svg-ico--nom-youtube,
.svg-ico--nom-pinterest,
.svg-ico--nom-share-mail {
    width: 3rem;
    height: 3rem;

    @include respond-to(decent) {
        .article-share & {
            width: 4rem;
            height: 4rem;
        }
    }
}

.svg-ico--nom-reload, {
    width: 2.4rem;
    height: 2.4rem;

    path[fill="none"] {
        stroke: currentColor;
    }

    path:not([fill="none"]) {
        fill: currentColor;
    }
}

// payment icons
.svg-ico--nom-payment-visa,
.svg-ico--nom-payment-mastercard,
.svg-ico--nom-payment-mastercard-2,
.svg-ico--nom-payment-paypal,
.svg-ico--nom-payment-sofort,
.svg-ico--nom-payment-vorkasse,
.svg-ico--nom-payment-prepayment,
.svg-ico--nom-payment-jcb,
.svg-ico--nom-payment-splitit,
.svg-ico--nom-payment-dhl,
.svg-ico--nom-payment-go-green,
.svg-ico--nom-payment-secure,
.svg-ico--nom-payment-fedex,
.svg-ico--nom-payment-amex-square {
    stroke: transparent;
    fill: $icon-color--payment;
}

.svg-ico--nom-payment-visa {
    width: 4.8rem;
}

.svg-ico--nom-payment-mastercard {
    width: 10.2rem;
}

.svg-ico--nom-payment-mastercard-2 {
    width: 2.6rem;
}

.svg-ico--nom-payment-paypal {
    width: 6.6rem;
}

.svg-ico--nom-payment-sofort {
    width: 3rem;
}

.svg-ico--nom-payment-vorkasse {
    width: 4.6rem;
}

.svg-ico--nom-payment-prepayment {
    width: 5.6rem;
}

.svg-ico--nom-payment-jcb {
    width: 2.9rem;
}

.svg-ico--nom-payment-secure {
    // bump up secure payment icon a little
    width: 6.72rem; // original: 5.6rem
    height: 2.4rem;
}

.svg-ico--nom-payment-paypal-c {
    width: 5.1rem;
    height: 1.6rem;
}

.svg-ico--nom-payment-splitit {
    width: 4.6rem;
}

.svg-ico--nom-payment-dhl {
    width: 9rem;
}

.svg-ico--nom-payment-go-green {
    width: 5.7rem;
}

.svg-ico--nom-payment-fedex {
    width: 5.8rem;
}

.svg-ico--nom-payment-splitit-c {
    width: 4rem;
    height: 3.2rem;
}

.svg-ico--nom-payment-amex-square {
    width: 2.2rem;
    height: 2.2rem;

    .amex-text {
        fill: transparent;
    }
}

// Handle colored logos
.svg-ico--nom-payment-sofort-c,
.svg-ico--nom-payment-vorkasse-c,
.svg-ico--nom-payment-prepayment-c,
.svg-ico--nom-payment-mastercard-c,
.svg-ico--nom-payment-visa-c,
.svg-ico--nom-payment-visa-2-c,
.svg-ico--nom-payment-splitit-c,
.svg-ico--nom-payment-paypal-c,
.svg-ico--nom-payment-amex-square-c,
.svg-ico--nom-payment-splitit-amex-c {
    stroke: transparent;
}

// increase size by 1.5
$_increase-icon: 1.3;
$_increase-icon-l: 1.5;

.svg-ico--nom-payment-sofort-c {
    height: 2rem * $_increase-icon;
    width: 3rem * $_increase-icon;

    @include respond-to(grid-l) {
        height: 2rem * $_increase-icon-l;
        width: 3rem * $_increase-icon-l;
    }
}

.svg-ico--nom-payment-vorkasse-c {
    height: 2rem * $_increase-icon;
    width: 4.6rem * $_increase-icon;

    @include respond-to(grid-l) {
        height: 2rem * $_increase-icon-l;
        width: 4.6rem * $_increase-icon-l;
    }

    html:not(:lang(de)) & {
        display: none;
    }
}

.svg-ico--nom-payment-prepayment-c {
    height: 2rem * $_increase-icon;
    width: 5.6rem * $_increase-icon;

    @include respond-to(grid-l) {
        height: 2rem * $_increase-icon-l;
        width: 5.6rem * $_increase-icon-l;
    }

    html:lang(de) & {
        display: none;
    }
}

.svg-ico--nom-payment-mastercard-c {
    height: 2.2rem * $_increase-icon;
    width: 2.8rem * $_increase-icon;

    @include respond-to(grid-l) {
        height: 2.2rem * $_increase-icon-l;
        width: 2.8rem * $_increase-icon-l;
    }
}

.svg-ico--nom-payment-visa-c {
    height: 2rem * $_increase-icon;
    width: 3.2rem * $_increase-icon;

    @include respond-to(grid-l) {
        height: 2rem * $_increase-icon-l;
        width: 3.2rem * $_increase-icon-l;
    }
}

.svg-ico--nom-payment-visa-2-c {
    height: 2rem * $_increase-icon;
    width: 3.9rem * $_increase-icon;

    @include respond-to(grid-l) {
        height: 2rem * $_increase-icon-l;
        width: 3.9rem * $_increase-icon-l;
    }
}

.svg-ico--nom-payment-amex-square-c {
    width: 2.2rem * $_increase-icon;
    height: 2.2rem * $_increase-icon;

    @include respond-to(grid-l) {
        height: 2.2rem * $_increase-icon-l;
        width: 2.2rem * $_increase-icon-l;
    }
}

.svg-ico--nom-payment-paypal-c {
    height: 1.6rem * $_increase-icon;
    width: 5.1rem * $_increase-icon;

    @include respond-to(grid-l) {
        height: 1.6rem * $_increase-icon-l;
        width: 5.1rem * $_increase-icon-l;
    }
}

.svg-ico--nom-payment-jcb-c {
    stroke: transparent;
    width: 2.9rem * $_increase-icon;
    height: 2.2rem * $_increase-icon;

    @include respond-to(grid-l) {
        width: 2.9rem * $_increase-icon-l;
        height: 2.2rem * $_increase-icon-l;
    }
}

.svg-ico--nom-payment-splitit-c {
    height: 3.2rem * $_increase-icon;
    width: 4rem * $_increase-icon;

    // hide additional card icons
    .checkout-panel & {
        clip-path: inset(0 0 calc(100% - 1.7rem * #{$_increase-icon}) 0);
    }

    @include respond-to(grid-l) {
        height: 3.2rem * $_increase-icon-l;
        width: 4rem * $_increase-icon-l;

        // hide additional card icons
        .checkout-panel & {
            clip-path: inset(0 0 calc(100% - 1.7rem * #{$_increase-icon-l}) 0);
        }
    }
}

.svg-ico--nom-payment-splitit-amex-c {
    height: 3.2rem * $_increase-icon;
    width: 4.6rem * $_increase-icon;

    // hide additional card icons
    .checkout-panel & {
        clip-path: inset(0 0 calc(100% - 1.7rem * #{$_increase-icon}) 0);
    }

    @include respond-to(grid-l) {
        height: 3.2rem * $_increase-icon-l;
        width: 4.6rem * $_increase-icon-l;

        // hide additional card icons
        .checkout-panel & {
            clip-path: inset(0 0 calc(100% - 1.7rem * #{$_increase-icon-l}) 0);
        }
    }
}

.svg-ico--nom-payment-splitit {
    height: 1.5rem * $_increase-icon;
    width: 3.9rem * $_increase-icon;

    @include respond-to(grid-l) {
        height: 1.5rem * $_increase-icon;
        width: 3.9rem * $_increase-icon-l;
    }
}

.svg-ico--nom-payment-visa-verified,
.svg-ico--nom-payment-mastercard-securecode,
.svg-ico--nom-payment-secure    ,
.svg-ico--nom-payment-jcb-secure {
    stroke: transparent;
    fill: darken($icon-color--payment, 10%);
}

.svg-ico--nom-payment-visa-verified,
.svg-ico--nom-payment-mastercard-securecode {
    height: 3rem;
    width: 6rem;
}

.svg-ico--nom-payment-mastercard-securecode {
    width: 9.2rem;
}

.svg-ico--nom-payment-jcb-secure {
    width: 5.2rem;
    height: 3rem;
}

// Inline Icons / See: https://github.com/TrySound/postcss-inline-svg
@svg-load icon--nom-dropdown url(../icons/nom-dropdown.svg) {
    stroke: $text-color--default;
    fill: transparent;
}

@svg-load icon--nom-dropdown-reverse url(../icons/nom-dropdown-reverse.svg) {
    stroke: $text-color--default;
    fill: transparent;
}

@svg-load icon--nom-dropdown-big url(../icons/nom-dropdown-big.svg) {
    stroke: $text-color--default;
    fill: transparent;
}

@svg-load icon--nom-dropdown-big-reverse url(../icons/nom-dropdown-big-reverse.svg) {
    stroke: $text-color--default;
    fill: transparent;
}

@svg-load icon--nom-more-small url(../icons/nom-more-small.svg) {
    stroke: $text-color--default;
    fill: transparent;
}

@svg-load icon--nom-search url(../icons/nom-search.svg) {
    stroke: $text-color--default;
    fill: transparent;
}

@svg-load icon--nom-mail url(../icons/nom-mail.svg) {
    stroke: $text-color--default;
    fill: transparent;
}

@svg-load icon--nom-bag-small url(../icons/nom-bag-small.svg) {
    stroke: $text-color--default;
    fill: transparent;
}

@svg-load icon--nom-remove url(../icons/nom-remove.svg) {
    stroke: $text-color--default;
    fill: transparent;
}

@svg-load icon--nom-zoom url(../icons/nom-zoom.svg) {
    stroke: $text-color--default;
    fill: transparent;
}

@svg-load icon--nom-map-plus url(../icons/nom-map-plus.svg) {
    stroke: $text-color--default;
    fill: transparent;
}

@svg-load icon--nom-filter-yes url(../icons/nom-filter-yes.svg) { }

@svg-load icon--nom-filter-no url(../icons/nom-filter-no.svg) { }

@svg-load icon--nom-check-yes url(../icons/nom-check-yes.svg) { }

@svg-load icon--nom-check-no url(../icons/nom-check-no.svg) { }

@svg-load icon--nom-radio-yes url(../icons/nom-radio-yes.svg) { }

@svg-load icon--nom-radio-no url(../icons/nom-radio-no.svg) { }

@svg-load icon--nom-radio-no-2 url(../icons/nom-radio-no-2.svg) { }

@svg-load icon--nom-help url(../icons/nom-help.svg) { }

@svg-load icon--nom-next-step url(../icons/nom-next-step.svg) { }
