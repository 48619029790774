// series-preview
.family-list {
    li {
        @include list-hover();
    }

    a {
        white-space: nowrap; // prevent line-breaks

        // x-icon
        &:after {
            content: '';
            display: inline-block;
            transform: translateY(0.5rem); // position vertically
            width: 2rem;
            height: 2rem;
            background: svg-inline(icon--nom-remove) center center no-repeat;
            opacity: 0;
            transition: opacity $trans-time--s $trans-func--default;
            pointer-events: none;
        }
    }

    .is-active a {
        span {
            @include textstyle--book;
            letter-spacing: 0.15rem; // compensate for thicker font
        }

        &:after {
            opacity: 1;
            transition: opacity $trans-time--l $trans-func--default 0.1s;
        }
    }

    .all-watches-link {
        margin-top: 1.6rem;
    }

    .family-overview-link {
        position: absolute;
        top: 2.4rem;
    }

    @include respond-to(decent) {
        float: left;
        position: sticky;
        top: $menu-top-padding-desktop;
        width: grid-width(grid-l, 2);
        padding-top: 7.4rem;

        a {
            @include textstyle--button;
            line-height: 2;
        }

        // Fix the issues where family list is too long for extreme landscape formats
        @media (min-aspect-ratio: 1000/620) and (max-width: 1599px), (min-aspect-ratio: 1600/850) and (min-width: 1600px) and (max-width: 1899px), (min-aspect-ratio: 1900/960) and (min-width: 1900px) and (max-width: 2399px), (min-aspect-ratio: 2400/1000) and (min-width: 2400px) {
            position: relative;
            top: 0;
        }
    }

    @include respond-to(grid-m) {
        left: grid-margin(grid-m, false);
        width: grid-width(grid-m, 2);
    }
}

.has-sidebar-page--quickview .product-group,
.quickview-teasers,
.family-teasers  {
    .teaser--product-family {
        margin-right: 0 !important;
        @include grid-width(5, 4, 3);
    }

    @include respond-to(decent) {
        .teaser--soldier {
            .media-box img {
                // transform: scale(#{$soldier-scale});  // remove soldier scale for new soldiers
            }

            .text-container {
                // padding-top: 0;
            }
        }
    }
}

.quickview-teasers,
.family-teasers {
    transition: opacity $trans-time--l $trans-func--easing-out $trans-time--s;

    .is-loading & {
        opacity: 0.1;
    }

    // Fixes an overflow issue on IE 11
    .ie.no-edge & {
        & > section {
            width: 100%;
        }
    }

    // remove extra space for first section head
    header + section {
        .section-title:first-child {
            margin-top: 0;
        }
    }

    @include respond-to(decent) {
        width: grid-width(grid-l, 6);
        float: right;
    }

    @include respond-to(grid-m) {
        width: grid-width(grid-m, 8);
    }
}

.quickview-hero-wrapper {
    // remove extra space for first section head
    & + section {
        .section-title:first-child {
            margin-top: 0;
        }
    }
}

.quickview-hero {
    width: 100%;

    h3 {
        @include textstyle--uppercase;
    }

    .btn {
        color: inherit;
        margin-top: 0.25em * $line-height !important; // half a line
    }

    .media-box + .text-container {
        justify-content: flex-start;
    }

    & + .section-title {
        @include grid-spacing(m);
    }

    .modal--sidebar & {
        margin-bottom: grid-spacing(grid-s, m);

        h3 {
            display: none;
        }

        .btn {
            margin-top: 0 !important;
        }

        .no-mobile.no-touch & {
            .btn {
                opacity: 0;
                transition: opacity $trans-time--l $trans-func--default;;
            }

            &:hover {
                .btn {
                    opacity: 1;
                }
            }
        }
    }

    @include respond-to(grid-s) {
        @include ratio-box($landscape-ratio);
    }

    @include respond-to(decent) {
        .modal--sidebar & {
            margin-bottom: grid-spacing(grid-l, m);
            margin-top: $modal-header-desktop;
        }
    }

    @include respond-to(grid-m) {
        .modal--sidebar & {
            margin-bottom: grid-spacing(grid-m, m);
        }
    }
}
