// Panel-Box
// Combination of Interaction Elements and a couple of buttons
.panel-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    align-content: space-between;
    position: relative;

    .section-title {
        @include grid-spacing(m);
        @include grid-width(10, 6, 4);
    }

    .btn-group {
        @include grid-spacing(m);
    }

    .section-fond & {
        padding-bottom: grid-spacing(grid-s, l);
    }

    @include respond-to(grid-l) {
        justify-content: space-between;

        // Reorder button and titles
        .section-title,
        > .btn-group {
            order: -1;
        }

        h2,
        h3 {
            @include textstyle--teaser-m;
        }

        .btn-group {
            flex-direction: row-reverse;

            .btn:not(:last-child) {
                margin-right: 0;
                margin-left: 4rem;
            }
        }

        .section-fond & {
            padding-bottom: grid-spacing(grid-l, l);
        }
    }

    @include respond-to(grid-m) {
        .section-fond & {
            padding-bottom: grid-spacing(grid-m, l);
        }
    }
}

.panel-box--store {
    .head-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;

        h4 {
            @include textstyle--xs;
            @include textstyle--uppercase;
            text-align: right;
            margin-top: 0.4rem;
            margin-left: 1rem;
            white-space: nowrap;
        }
    }

    .section-title {
        margin-top: 0 !important;
    }

    @include respond-to(decent) {
        // reduce space between head and label
        .head-wrapper {
            .text-container + .text-container {
                padding-left: 0;
            }
        }
    }

    @include respond-to(grid-l) {
        .text-container {
            padding: grid-padding-v(grid-l) grid-padding-v(grid-l) 0;
        }

        .head-wrapper {
            h4 {
                @include textstyle--s;
            }
        }
    }

    @include respond-to(grid-m) {
        .text-container {
            padding: grid-padding-v(grid-m) grid-padding-v(grid-m) 0;
        }
    }
}

.panel-box--store-with-head {
    align-content: space-between;

    // Center slideshow vertically
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
    }
}

.panel-box--store-without-head {
    align-content: center;
}



