// press release
$press-wrapper-limit: 2;

.press-release-wrapper {
    @include respond-to(grid-l) {
        h3 {
            font-size: $fontsize--teaser-m;
        }

        &.box-group .box-couple { // needs tsome power to overwrite
            .text-container {
                width: grid-width(grid-l, 3);
            }
        }
    }
}
