// Two boxes, side-by-side, GO!
// use for image-teaser with additional text-container, image + imagecaption, etc
.box-couple {
    width: grid-width(grid-s, 8);

    > div {
        position: relative;
    }

    h3 {
        @include textstyle--m;
    }

    @include respond-to(decent) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        nav & {
            width: 100%;
        }

        h3 {
            @include textstyle--l;
        }

        &.teaser {
            flex-direction: row;

            .text-super {
                @include grid-spacing(m, grid-l);
            }
        }

        // styles for jumping box-couples
        // every second one has its boxes reversed (unless defined to be straight)

        // first and all odd couple of boxes
        // or all even couple of boxes within reversed order parent
        // direction following the markup (mostly mediabox + textbox)
        &:nth-child(odd),
        .box-group--reverse &:nth-child(even) {
            flex-direction: row;

            .text-container {
                width: grid-width(grid-l, 4);

                nav & {
                    width: grid-width(grid-l, 3);
                }
            }

            // limit text-containeres for figure-couples
            &.box-couple--figure {
                .text-container {
                    width: grid-width(grid-l, 3);
                    margin-right: grid-width-gutter(grid-l);
                }
            }

            // specific box treatments
            .box-xxl + .text-container {
                margin-right: 0;
                width: grid-width(grid-l, 2);
            }

            .box-xxl--s + .text-container {
                width: grid-width(grid-l, 3);
            }
        }

        // all even couple of boxes
        // direction following the markup (mostly mediabox + textbox)
        // also reversed boxes
        :not(.box-group--reverse):not(.box-group--straight) > &:nth-child(even),
        .box-group--reverse:not(.box-group--straight) &:nth-child(odd),
        .box-group--straight.box-group--reverse & {
            flex-direction: row-reverse;

            .text-container {
                width: grid-width(grid-l, 4);
            }

            // specific box treatments
            .box-xxl + .text-container {
                width: grid-width(grid-l, 2);
            }

            .box-xxl--s + .text-container {
                width: grid-width(grid-l, 3);
            }
        }

        .box-group--straight--reverse & {
            flex-direction: row-reverse;
        }

        .box-group--straight & {
            .text-container {
                width: grid-width(grid-l, 4);
            }
        }

        &.box-couple--bottom {
            align-items: flex-end;
        }
    }

    @include respond-to(grid-m) {
        .text-container {
            .main-content & {
                padding-top: grid-padding-v(grid-m);
                padding-bottom: grid-padding-v(grid-m);
            }
        }

        .text-big p {
            @include textstyle--default;
        }

        .text-super {
            @include grid-spacing(m, grid-m);
        }

        &:nth-child(odd),
        .box-group--reverse &:nth-child(even) {
            .text-container {
                width: grid-width(grid-m, 4);

                nav & {
                    width: grid-width(grid-m, 4);
                }
            }

            // limit text-containeres for figure-couples
            &.box-couple--figure {
                .text-container {
                    width: grid-width(grid-m, 4);
                    margin-right: 0;
                }
            }

            // specific box treatments
            .box-xxl--s + .text-container,
            .box-xxl + .text-container {
                width: grid-width(grid-m, 3);
            }
        }

        :not(.box-group--reverse):not(.box-group--straight) > &:nth-child(even),
        .box-group--reverse:not(.box-group--straight) &:nth-child(odd) {
            .text-container {
                width: grid-width(grid-m, 4);

                nav & {
                    width: grid-width(grid-m, 5);
                }
            }

            // specific box treatments
            .box-xxl--s + .text-container,
            .box-xxl + .text-container {
                width: grid-width(grid-m, 3);
            }
        }

        .box-group--straight & {
            .text-container {
                width: grid-width(grid-m, 4);
            }
        }
    }
}

