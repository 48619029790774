// Nomos media boxes
// Transitioning slide box

.slide-box {
    position: relative;
    overflow: hidden;
    background: $back-color--tertiary;

    fieldset {
        padding-bottom: $square-ratio;
    }

    // hide radio buttons
    input {
        display: none;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .label-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        top: 0;
        width: 50%;
        padding: grid-padding-v(grid-s) grid-padding-v(grid-s);
        z-index: 1;
        display: flex;
        align-items: flex-end;

        @include respond-to(grid-l) {
            padding: grid-padding-v(grid-l) grid-padding-v(grid-l);
        }

        @include respond-to(grid-m) {
            padding: grid-padding-v(grid-m) grid-padding-v(grid-m);
        }
    }

    .label-wrapper--2 {
        left: auto;
        right: 0;
        justify-content: flex-end;
    }

    label {
        cursor: pointer;
        @include textstyle--default;
        @include textstyle--button-s;
        display: block;
        @include underline-active;
        user-select: none;

        /* variation rollover
        // prevent click interaction on no-touch
        .no-touch & {
            pointer-events: none;
        }*/

        @include respond-to(decent) {
            @include textstyle--button;
        }
    }

    // show active state
    /* variation rollover
    .label-wrapper:hover label,
    &:not(:hover) input:checked + .label-wrapper label */
    input:checked + .label-wrapper label {
        &:before {
            transform: none;
            opacity: 1;

            html:not(.ie) & {
                transition: transform 0.15s $trans-func--default,
                opacity 0.15s $trans-func--default;
            }
        }
    }

    // hide second button in first state
    input:nth-of-type(1):checked ~ .zoom-trigger--2 {
        display: none;
    }

    .media-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity $trans-time--l $trans-func--easing-out,
        transform $trans-time--l $trans-func--easing-out;
        background: transparent !important; // fix render issue on chrome

        &:last-child {
            transform: scale(1.05);
            opacity: 0;
        }
    }

    // show second state
    /* variation rollover: .label-wrapper--2:hover ~ .media-box:last-child, */
    input:nth-of-type(2):checked ~ .media-box:last-child {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    /* variaiton rollover: .label-wrapper--2:hover ~ .media-box:not(:last-child), */
    input:nth-of-type(2):checked ~ .media-box:not(:last-child) {
        transform: scale(1.05);
        opacity: 0;
    }

    // Show different style for soldaten
    &.slide-box--watch {
        .media-box {
            top: 2%;
            left: 4%;
            width: 92%;
            height: 92%;

            &:last-child {
                transform: scale(0.7) translate3d(0, 140%, 0);
                opacity: 1;
            }
        }

        // show second state
        /* variation rollover: .label-wrapper--2:hover ~ .media-box:last-child, */
        input:nth-of-type(2):checked ~ .media-box:last-child {
            transform: translate3d(0, 0, 0);
        }

        /* variaiton rollover: .label-wrapper--2:hover ~ .media-box:not(:last-child), */
        input:nth-of-type(2):checked ~ .media-box:not(:last-child) {
            transform: scale(0.7) translate3d(0, -140%, 0);
        }
    }
}
