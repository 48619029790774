// Nomos product

.main-content--product {
    @include respond-to(grid-s) {
        display: flex;
        flex-direction: column;
    }
}

.product-title {
    @include grid-padding;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 14.4em;
    margin-bottom: -5em; // pull up image for share and compare links to sit on top of it

    > * {
        width: 100%;
    }

    .head-wrapper {
        @include grid-spacing(m, grid-s);
        @include grid-inner-width(8.5,6,5);
        min-height: 2.8em; // have single-lined headlines sit a little higher than multiline heads
    }

    nav {
        padding-top: 2em;
        position: relative;
        z-index: 1;
        min-height: calc(#{$button-height} + 2em); // compensate for missing buttons
    }

    h1 {
        @include textstyle--teaser-xs;
        @include textstyle--uppercase;
    }

    @include respond-to(grid-s) {
        // restyle product-title if .nav-product-actions are missing or absolute positioned
        // this only needs to be done for grid-s
        .hero--product-strap &,
        .hero--product-other & {
            margin-bottom: 0;
            min-height: 9.4em;

            .head-wrapper {
                margin-bottom: 1em;
            }
        }

        // reposition missing watch modal link to bottom right of image to stay in context
        .hero--product-strap & {
            .nav-product-actions {
                position: absolute;
                left: 0;
                bottom: calc(-102vw - 4em); // media-box height plus some more
                padding: 0 grid-padding(grid-s);
            }
        }
    }

    @include respond-to(decent) {
        min-height: calc(#{$hero-top-height-product});
        margin-bottom: 0;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        > * {
            width: auto;
        }

        .head-wrapper {
            min-height: 1px;
            margin-top: $menu-height-desktop;
        }

        nav {
            padding-top: 0;
            margin-bottom: -0.2em; // push to baseline baseline
            min-height: 0px;
        }

        h1 {
            @include textstyle--teaser;
            @include textstyle--uppercase;
        }

        // global banner on full page product
        .has-global-banner .ajax-content & {
            min-height: calc(#{$hero-top-height-product} + #{$global-banner-height-l});
        }

        // product title is within the modal-title here
        .modal & {
            min-height: calc(#{$hero-top-height-product-modal});

            // still forcing the same space for the modal title
            .head-wrapper {
                visibility: hidden;
                margin-top: 2rem;
                width: grid-inner-width(grid-l, 5);
            }
        }
    }

    @include respond-to(grid-l) {
        // product title is within the modal-title here
        .modal & {
            nav {
                visibility: hidden;
            }
        }
    }

    @include respond-to(grid-m) {
        min-height: calc(#{$hero-top-height-product-grid-m});

        h1 {
            @include textstyle--l;
        }

        // global banner on full page product
        .has-global-banner .ajax-content & {
            min-height: calc(#{$hero-top-height-product-grid-m} + #{$global-banner-height-l});
        }

        .modal & {
            .head-wrapper {
                width: grid-inner-width(grid-m, 7);
            }
        }
    }
}

.product-main-info {
    @include respond-to(grid-s) {
        .grid-container {
            width: auto;
        }
    }

    @include respond-to(decent) {
        min-height: calc(100vh - #{$action-bar-height-desktop} - (#{$hero-top-height-product}));
        background: $back-color--tertiary;
        display: flex;
        align-items: center;

        // global banner on full page product
        .has-global-banner .ajax-content & {
            min-height: calc(100vh - #{$action-bar-height-desktop} - (#{$hero-top-height-product}) - #{$global-banner-height-l});
        }

        .modal & {
            min-height: calc(100vh - #{$action-bar-height-desktop} - (#{$hero-top-height-product-modal}));
        }

        // fix for mobile safari vh-issue
        @supports(--css: variable) {
            .is-safari.touch & {
                min-height: calc((var(--view-height-offset) * 100vh) - #{$action-bar-height-desktop} - (#{$hero-top-height-product}));
            }

            // global banner on full page product
            .is-safari.touch .has-global-banner .ajax-content & {
                min-height: calc((var(--view-height-offset) * 100vh) - #{$action-bar-height-desktop} - (#{$hero-top-height-product}) - #{$global-banner-height-l});
            }

            .is-safari.touch .modal & {
                min-height: calc((var(--view-height-offset) * 100vh) - #{$action-bar-height-desktop} - (#{$hero-top-height-product-modal}));
            }
        }

        .grid-container {
            justify-content: space-between;
            flex-wrap: nowrap;
            align-items: center;
            align-content: flex-start;
            height: 100%;
            padding-bottom: 2vh; // center vertically
        }
    }

    @include respond-to(grid-m) {
        @include respond-to(port) {
            // make some adjustments for portrait tablets
            min-height: 65vw;

            .is-safari.touch .has-global-banner .ajax-content & {
                min-height: 65vw;
            }

            .modal & {
                min-height: 60vw;
            }

            // fix for mobile safari vh-issue
            @supports(--css: variable) {
                .is-safari.touch .has-global-banner .ajax-content &,
                .is-safari.touch & {
                    min-height: 65vw;
                }
            }
        }
    }
}

// we have a slideshow instead of a full banner on watch detail pages
.product-main-info--watch {
    @include respond-to(decent) {
        background: transparent;
        align-items: stretch;

        .grid-container {
            padding-bottom: 0;
            height: auto; // height 100% goes against align stretch
        }
    }
}

.product-main-img {
    background: $back-color--tertiary;
    width: 100vw;
    padding: 0 1em;
    position: relative;

    .media-box {
        padding-bottom: $square-ratio;
        background: transparent;
    }

    @include respond-to(grid-s) {
        overflow: hidden; // fix overflow

        .hero--product-strap & {
            padding: 1em 1em 5em;
        }
    }

    @include respond-to(decent) {
        padding: 0;
    }

    @include respond-to(grid-l) {
        width: grid-width(grid-l, 5);
        margin-left: grid-width(grid-l, 1);

        .modal & {
            margin-left: grid-width(grid-l, 0.5);
        }

        .media-box {
            // handle images differently to make them adjust to the available space inside the viewpox
            padding-bottom: 0;
            height: calc(90vh - #{$action-bar-height-desktop} - (#{$hero-top-height-product}) - 5vh);
            min-height: calc(44em - 5vh);

            .modal & {
                height: calc(100vh - #{$action-bar-height-desktop} - (#{$hero-top-height-product-modal}) - 5vh);
            }

            img {
                object-fit: contain;
                font-family: 'object-fit: contain; object-position: 50% 50%;'; // ie fix probably
            }

            // fix for mobile safari vh-issue
            @supports(--css: variable) {
                .is-safari.touch & {
                    height: calc((var(--view-height-offset) * 100vh) - #{$action-bar-height-desktop} - (#{$hero-top-height-product}) - 5vh);

                    .is-safari.touch .modal & {
                        height: calc((var(--view-height-offset) * 100vh) - #{$action-bar-height-desktop} - (#{$hero-top-height-product-modal}) - 5vh);
                    }
                }
            }
        }
    }

    @include respond-to(grid-m) {
        width: grid-width(grid-m, 5.5);
        margin-left: grid-width(grid-m, 0.25);
    }
}

.product-main-action {
    @include grid-padding;
    @include grid-container;
    margin: 0 auto;

    .btn-wrapper {
        @include grid-spacing(xs);
    }

    .btn--help {
        margin-right: -0.7em; // visually align to the right
    }

    @include respond-to(decent) {
        width: grid-width(grid-l, 3);
        margin: 0;

        .product-main-info--watch & {
            margin-bottom: 2vh; // optically center
        }
    }

    @include respond-to(grid-m) {
        width: grid-width(grid-m, 4);

        // make size options fit into row
        .fake-radio-button {
            &:first-child {
                margin-left: -0.6em;
            }

            label {
                padding: 0 0.6em;
            }
        }
    }
}

.product-credentials {
    margin-top: grid-spacing(grid-s, s);

    .product__main-ref,
    .product--price {
        @include textstyle--default;
    }

    .product__jp-ref {
        @include textstyle--default;
        @include textstyle--s;
        padding-left: 0.6em;
    }

    .product-price--saved {
        @include textstyle--book;
        @include textstyle--s;
        padding-left: 0.6em;
        color: $text-color--price-saved;
    }

    @include respond-to(decent) {
        margin-top: 0;
    }

    @include respond-to(grid-l) {
        .product__main-ref,
        .product--price {
            @include textstyle--l;
        }

        .product__jp-ref {
            @include textstyle--s;
        }
    }

    @include respond-to(grid-m) {
        .product__main-ref,
        .product--price {
            @include textstyle--m;
        }

        .product__jp-ref {
            @include textstyle--xs;
        }
    }
}

.product__price {
    display: flex;
    align-items: baseline;

    .price--accessories {
        @include textstyle--s;

        @include respond-to(grid-l) {
            @include textstyle--default;
        }
    }
}

.product__price--with-accessories {
    flex-wrap: wrap; // let .price--accessories flwo in next line if .price--discount is displayed

    .price--default {
        margin-right: 0.4em; // add spacing before .price--accessories
    }
}

// add spacing between name/price and selections
.product__main-ref + .product--buybox,
.product__price + *,
.product__price + *[aria-hidden="true"] + * {
    @include grid-spacing(m);

    @include respond-to(decent) {
        @include grid-spacing(s);
    }
}

.product__splitit-price {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 0;
    @include grid-bottom-spacing(s);

    span {
        @include textstyle--s;
    }

    .btn {
        margin-top: -1.25em; // center with text and reduce top gap of wrapper
        margin-bottom: -1.25em; // reduce bottom gap
    }

    // hide if engraving price is shown
    .product__price--with-accessories ~ & {
        span,
        .btn {
            display: none;
        }
    }

    @include respond-to(grid-l) {
        span {
            @include textstyle--default;
        }

        .btn {
            margin-top: -1em; // center with text and reduce top gap of wrapper
            margin-bottom: -1em; // reduce bottom gap
        }
    }

    @include respond-to(grid-m) {
        .btn {
            margin-top: -1.5em; // center with text and reduce top gap of wrapper
            margin-bottom: -1.5em; // reduce bottom gap
        }
    }
}

.product__price-hint {
    @include grid-bottom-spacing(s);

    span {
        @include textstyle--s;
    }

    .product__splitit-price + & {
        margin-top: -1em;
    }

    .product__price + & {
        margin-top: 0;
    }

    @include respond-to(grid-l) {
        .product__splitit-price + & {
            margin-top: -1.8em;
        }
    }

    @include respond-to(grid-m) {
        .product__splitit-price + & {
            margin-top: -1.4em;
        }
    }
}

.product__mandatory-product {
    margin-bottom: 3em;
    border-bottom: 1px solid $border-color--light-1;
    border-top: 1px solid $border-color--light-1;

    > p {
        @include textstyle--s;
        padding: 0.5em 0 0;
    }

    a {
        display: block;
        background: svg-inline(icon--nom-more-small) no-repeat;
        background-size: 1.2em 1.2em;
        background-position: right center;
        line-height: $input-height-default - $baseline-offset !important;
        width: calc(100% + 0.3rem); // center icon below select arrow
    }
}

.variant--group {
    & + & {
        .select--inline-label {
            border-top: none;
        }
    }

    .variant--group__value {
        @include textstyle--default;
    }
}

.product__purchase-info {
    @include grid-spacing(m);

    p {
        margin-top: 0;
    }

    small {
        display: block;
        @include textstyle--default;
        @include textstyle--s;
    }

    @include respond-to(decent) {
        @include grid-spacing(s);

        .tax-info--tax-free {
            html:lang(en) & {
                max-width: 32rem; // make line break a little nicer
            }
        }
    }

    @include respond-to(grid-l) {
        .product-main-action & {
            margin-top: grid-spacing(grid-l, xs);

            p {
                @include textstyle--s;
            }
        }
    }

    @include respond-to(grid-m) {
        p {
            @include textstyle--s;
        }
    }
}

$_color-variant-label: 8.16em; // try to correspond with inline labels

// color variants
.color-variants {
    margin-top: grid-spacing(grid-s, l);

    @include respond-to(grid-s) {
        margin-left: calc(#{grid-margin()} * -1);
        margin-right: calc(#{grid-margin()} * -1);
        padding-bottom: grid-spacing(grid-s, l);
        overflow: hidden;
        background: $back-color--tertiary;

        .color-variants__label {
            padding: grid-padding-h();
        }
    }

    @include respond-to(decent) {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $border-color--light-1;
        border-top: 1px solid $border-color--light-1;
        margin-top: 3em;
        padding: 1.2rem 0;

        .color-variants__label {
            min-width: $_color-variant-label;
            padding-right: 0.5em;
            padding-bottom: 0.4rem;
            flex: 1 0 auto; // dont shrink
        }

        .label {
            @include textstyle--default;
        }
    }
}

.color-variants__slideshow {
    @include respond-to(grid-s) {
        .flickity-page-dots {
            transform: translateY(2.4rem);
        }
    }

    @include respond-to(decent) {
        flex: 0 1 auto; // dont grow, maybe shrink

        .flickity-prev-next-button {
            top: 1rem;
            right: auto;
            left: 0;
            bottom: 1rem;
            padding: 0;
            border-right: 1px solid $border-color--light-1;
            width: (grid-inner-width(grid-l, 3) - $_color-variant-label) / 8; // show four items in the remaining space / 2

            &:active {
                opacity: 1;
            }

            &.previous {
                transform: translateX(-100%);
            }

            &.next {
                transform: translateX(100%);
                left: auto;
                right: 0;
                text-align: right;
                border-left: 1px solid $border-color--light-1;
                border-right: none;
            }

            .svg-ico--next,
            .svg-ico--prev {
                width: 4rem;
                height: 2.4rem;
                transform-origin: center center;
                transform: translateX(-0.6rem) rotate(90deg);
            }

            .svg-ico--next {
                transform: rotate(-90deg);
            }
        }
    }

    @include respond-to(grid-l) {
        &.color-variants__slideshow--grid-l {
            margin: 0 (grid-inner-width(grid-l, 3) - $_color-variant-label) / 8;
        }

        // Prevent multiline if no slideshow and label is too long
        &:not(.color-variants__slideshow--grid-l) {
            display: flex;
        }
    }

    @include respond-to(grid-m) {
        &.color-variants__slideshow--grid-m {
            margin: 0 (grid-inner-width(grid-m, 4) - $_color-variant-label) / 6;
        }

        .flickity-prev-next-button {
            .svg-ico--prev {
                transform: translateX(-0.8rem) rotate(90deg);
            }
        }
    }
}

.color-variants__item {
    display: block;
    width: grid-width(grid-s, 3);
    position: relative;
    padding-bottom: 0.6rem; // add some space for active line and c enter vertically

    &.is-active,
    &[aria-selected="true"] {
        &:after {
            @include pseudo();
            bottom: 0;
            left: 20%;
            right: 20%;
            height: 1px;
            background: $text-color--default;
        }
    }

    .media-box {
        // set height to be higher than square
        @include ratio-box($square-ratio);
        &:before {
            padding-bottom: 105% !important;
        }
    }

    @include respond-to(grid-s) {
        &:not(.is-last) {
            margin-right: (grid-gutter-width(grid-s) * 3 + grid-width(grid-s)) / 2; // align 3 rows on 10 rows
        }
    }

    @include respond-to(decent) {
        width: (grid-inner-width(grid-l, 3) - $_color-variant-label) / 4; // show four items in the remaining space

        .media-box {
            // reset height
            &:before {
                padding-bottom: 102% !important;
            }
        }
    }

    @include respond-to(grid-m) {
        width: (grid-inner-width(grid-m, 4) - $_color-variant-label) / 3; // show three items in the remaining space
    }

    .tooltip {
        bottom: 8em;

        // Variant tooltips are cropped if appearing inside a slideshow
        // We hide them vor now
        // TODO: Think of a better solution
        .flickity-viewport & {
            display: none;
        }
    }

    // Straps are not transparent PNG's so we have to use multiply if it's not a pictogram
    .hero--product-strap & {
        .media-box--pictogram {
            img {
                transform: scale(0.9);
            }
        }

        .media-box:not(.media-box--pictogram) {
            background-color: $back-color--tertiary;

            img {
                mix-blend-mode: multiply;
            }
        }
    }
}

// Alternative strap/clasps
$_strap-list-height--mobile: 5.4rem;
$_strap-list-height--desktop: 4.8rem;
$_clasp-list-height--mobile: 5.4rem;
$_clasp-list-height--desktop: 4.8rem;

.strap-variants,
.clasp-variants {
    display: flex;
    align-items: center;
    align-content: stretch;
    border-top: 1px solid $border-color--light-1;
    border-bottom: 1px solid $border-color--light-1;

    // Hide top border for watches with back selection
    .select--inline-label + & {
        border-top: none;
    }
}

.strap-variants__label,
.clasp-variants__label {
    .label {
        @include textstyle--default;
        min-width: 4.8em; // This feels wrong
        padding-right: 0.5em; // This feels wrong
    }
}

.strap-variants__list,
.clasp-variants__list {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.strap-variants__list {
    height: $_strap-list-height--desktop;

    @include breakpoint(mobile) {
        height: $_strap-list-height--mobile;
    }
}

.clasp-variants__list {
    height: $_clasp-list-height--desktop;

    @include breakpoint(mobile) {
        height: $_clasp-list-height--mobile;
    }
}

.strap-variants__list-item,
.clasp-variants__list-item {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    &:not(:last-child) {
        margin-right: 1.5em;
    }

    &[aria-selected="true"] {
        &:after {
            content: '';
            position: absolute;
            left: 0.2em;
            bottom: 0.4em;
            right: 0.2em;
            border-bottom: solid 1px $border-color--default;
        }
    }
}

.strap-variants__list-item {
    .tooltip {
        bottom: $_strap-list-height--desktop + 1rem;
    }

    @include breakpoint(mobile) {
        .tooltip {
            bottom: $_strap-list-height--mobile + 1rem;
        }
    }
}

.clasp-variants__list-item {
    .tooltip {
        bottom: $_clasp-list-height--desktop + 1rem;
    }

    @include breakpoint(mobile) {
        .tooltip {
            bottom: $_clasp-list-height--mobile + 1rem;
        }
    }
}

.strap-variants__list-item-image,
.clasp-variants__list-item-image {
    display: block;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.strap-variants__list-item-image {
    img {
        transform: scale(1.25);
    }
}

.clasp-variants__list-item-image {
    img {
        transform: scale(1.5);
    }
}

.strap-variants__list-item-image {
    width: $_strap-list-height--desktop - 1.6rem;
    height: $_strap-list-height--desktop - 1.6rem;

    @include breakpoint(mobile) {
        width: $_strap-list-height--mobile - 1.6rem;
        height: $_strap-list-height--mobile - 1.6rem;
    }
}

.clasp-variants__list-item-image {
    width: $_clasp-list-height--desktop - 1.6rem;
    height: $_clasp-list-height--desktop - 1.6rem;

    @include breakpoint(mobile) {
        width: $_clasp-list-height--mobile - 1.6rem;
        height: $_clasp-list-height--mobile - 1.6rem;
    }
}

// content sections
.product-description {
    @include grid-padding-spacing(l); // have anchor scroll correctely

    @include respond-to(grid-l) {
        .grid-container > div {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .text-container:first-child {
                width: grid-width(grid-l, 6);
            }

            .btn-group {
                width: grid-width(grid-l, 3);

                // prevent icon line break in spanish edgecase
                html:lang(es) & {
                    a {
                        max-width: 30rem;
                    }
                }
            }
        }
    }
}

.product-details {
    @include grid-spacing(l, grid-s);

    @include respond-to(decent) {
        margin-top: 0;
    }

    .main-content--product-strap &,
    .main-content--product-other & {
        .box-couple {
            h3 + p {
                margin-top: 0.2em;
            }
        }

        @include respond-to(grid-s) {
            .section-title {
                margin-top: 0;
            }
        }

        @include respond-to(grid-l) {
            .box-couple .text-container {
                width: grid-width(grid-l, 3);
            }
        }
    }
}

.product-impression {
    h3 {
        position: relative; // display on iOS
    }

    @include respond-to(decent) {
        .box-m {
            min-height: 14rem;
        }
    }
}

.product-awards + .product-impression {
    @include respond-to(grid-s) {
        margin-top: 0;
    }
}

.product-trappings,
.product-impression {
    .box-group {
        @include grid-bottom-spacing(xxl);
        @include grid-bottom-spacing(xl, grid-l);
        @include grid-bottom-spacing(xl, grid-m);
    }
}

.product-specs {
    .text-container {
        @include grid-width(10,4,4);
    }

    .specs-list--ref {
        @include grid-spacing(xs);
    }

    @include respond-to(grid-s) {
        .accordion__body {
            .text-container {
                @include clearfix();
            }
        }

        .box-group {
            @include grid-bottom-spacing(xxl, grid-s);
        }

        .slide-box,
        .box-couple {
            width: 100%;
        }

        .list-couple {
            display: block;
            width: grid-inner-width(grid-s, 7);
            float: right;
        }

        .specs-list--ref {
            @include grid-spacing(m, grid-s);
            position: sticky;
            top: calc(#{grid-spacing(grid-s, m)} + #{$action-bar-height-mobile});
            float: left;
        }

        .specs-list:not(.specs-list--ref) {
            @include grid-spacing(m, grid-s);
        }
    }
}

.product-caliber {
    .list-couple {
        @include grid-spacing(m);
    }

    .btn-group {
        @include grid-spacing(s);
    }

    @include respond-to(grid-s) {
        .box-group {
            @include grid-bottom-spacing(xxl, grid-s);
        }

        .box-couple,
        .box-l,
        .text-container {
            width: 100%;
        }

        h3 {
            br {
                display: block;
            }
        }
    }
}

.product-trappings {
    @include respond-to(grid-s) {
        &.accordion {
            margin-top: 0;
        }
    }
}
