.tooltip {
    position: absolute;
    display: block;
    background-color: $back-color--default;
    box-shadow: $box-shadow--m;
    top: auto;
    left: 50%;
    bottom: 4rem; // Pixel perfect position should be done in parent component
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transform: translate3d(-50%, -1em, 0);
    transition: transform $trans-time--m $trans-func--easing-out,
                opacity $trans-time--m $trans-func--easing-out;

    // Caret
    &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: 1px;
        left: calc(50% - 1.1em);
        border-style: solid;
        border-width: 1em;
        border-color: transparent transparent $back-color--default $back-color--default;
        transform-origin: 100% 100%;
        transform: rotate(298deg) scale(1, 0.8) skew(-30deg);
        box-shadow: -0.2em 0.2em 0.2em 0 rgba(0, 0, 0, 0.1);
    }

    .touch & {
        display: none;
    }
}

.tooltip__content {
    padding: 1em 1.5em;
    display: block;
    margin: 0;
    user-select: none;
    text-align: center;
    width: max-content;
    max-width: 15em;

    p {
        @include textstyle--s;
    }

    p + p {
        margin-top: 0.2em;
    }

    @include breakpoint(decent) {
        padding: 1.5em 2em;
        max-width: 30em;

        p {
            @include textstyle--m;
        }
    }
}

// Trigger
.tooltip-trigger:hover {
    .tooltip,
    & + .tooltip {
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
    }
}