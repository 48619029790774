// Nomos base
// Viewport based typography: http://zellwk.com/blog/viewport-based-typography/
// scss-lint:disable MergeableSelector

$almost-stop-resizing: 1920px;
$stop-resizing: 3200px;

// https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
body:before {
    content: 'mobile';
    display: none;
}

body:after {
    content: 'grid-s';
    display: none;
}

@include respond-to(decent) {
    body:before {
        content: 'decent';
    }
}

@include respond-to(grid-l) {
    body:after {
        content: 'grid-l';
    }
}

@include respond-to(grid-m) {
    body:after {
        content: 'grid-m';
    }
}

// Resizing for fontsizes
html {
    // Apply fallback fontsize
    font-size: 10px;

    // Fix for safari bug: Permanent grid scale fails
    // https://css-tricks.com/snippets/css/fluid-typography/#comment-1753016
    &.is-safari {
        min-height: 1vw; // 0vw gets stripped by CSS nano
    }

    &.ios {
        min-height: calc(100vh + 0.0001vw); // fix for permanent grid scale and gallery zoom
    }

    // Grid S
    @include fluid-type(font-size, 240px, $bp--small-e, 9.2px, 11.5px);

    // Grid M
    @include fluid-type(font-size, $bp--medium-s, $bp--medium-e, 7.6px, 10.6px);

    // Grid L
    @include fluid-type(font-size, $bp--large-s, $bp--large-e, 8.2px, 10px);
    @include fluid-type(font-size, $bp--extra-large-s, $almost-stop-resizing, 10px, 12px);
    @include fluid-type(font-size, $almost-stop-resizing + 1, $stop-resizing, 12px, 13.5px);

    // stop resizing above certain threshold
    @media only screen and (min-width: $stop-resizing) {
        font-size: 13.5px;
    }

    // 1px smaller sizes for Japanese fonts
    &:lang(ja) {
        // Apply fallback fontsize
        font-size: 9px;

        // Grid S
        @include fluid-type(font-size, 240px, $bp--small-e, 8.2px, 10.5px);

        // Grid M
        @include fluid-type(font-size, $bp--medium-s, $bp--medium-e, 7px, 10.6px);  // @todo

        // Grid L
        @include fluid-type(font-size, $bp--large-s, $bp--large-e, 7.2px, 9px);
        @include fluid-type(font-size, $bp--extra-large-s, $almost-stop-resizing, 9px, 11px);
        @include fluid-type(font-size, $almost-stop-resizing + 1, $stop-resizing, 11px, 12.5px);

        // stop resizing above certain threshold
        @media only screen and (min-width: $stop-resizing) {
            font-size: 12.5px;
        }
    }
}

// Resizing for grid elements
body,
.grid,
.grid-container {

    // Grid S
    @include fluid-type(font-size, 240px, $bp--small-e, 7px, 16px);

    // Grid M
    @include fluid-type(font-size, $bp--medium-s, $bp--medium-e, 6.6px, 11px); // @todo

    // Grid L
    @include fluid-type(font-size, $bp--large-s, $bp--large-e, 7.4px, 10px);
    @include fluid-type(font-size, $bp--extra-large-s, $almost-stop-resizing, 10px, 13px);
    @include fluid-type(font-size, $almost-stop-resizing + 1, $stop-resizing, 13px, 14.5px);


    // stop resizing above certain threshold
    @media only screen and (min-width: $stop-resizing) {
        font-size: 14.5px;
    }
}

body {
    background-color: $back-color--default;

    // Reset counters
    counter-reset: content-section;

    &:not(.fix-body-scroll-bug) {
        overflow-y: scroll; // this prevents the jumping around on big screens
    }
}

// Grid definitions
.grid,
.grid-container {
    @include grid-container;
    position: relative;
    display: flex;
    margin: 0 auto;
    justify-content: flex-start;
    flex-wrap: wrap;

    // fix nested container
    .grid-container {
        max-width: 100%;
    }

    // definitions for grid-container inside 9-row sidebar
    .modal--sidebar & {
        @include respond-to(decent) {
            @include grid-width(10,9,9);
            margin-left: grid-width-gutter(grid-l, 0.5);
            margin-right: grid-margin(grid-l, false);
        }

        @include respond-to(grid-m) {
            margin-left: grid-width-gutter(grid-m, 0.5);
            margin-right: grid-margin(grid-m, false);
        }
    }

    // definitions for grid-container inside smaller sidebar
    .has-sidebar-page--note .modal--sidebar &,
    .has-sidebar-page--profile .modal--sidebar &,
    .has-sidebar-page--quickview .modal--sidebar & {
        @include respond-to(grid-l) {
            width: grid-width(grid-l, 6);
        }
    }
}

// Container for limiting Elements to the inner text grid
.text-container {
    @include grid-padding;

    // limit most textboxes to a certain width
    section &:not(.text-ultra):not(.type-box):not(header):not(.grid-container):not(.btn-group) {
        @include grid-max-width(10, 8, 6);
    }

    &.grid-container {
        display: block;
    }
}

// hide specific elements
// @todo: put it somewhere, where it makes more sense
.hidden {
    display: none;
}

// hide stuff on grid-m and grid-l
.hidden-on-desktop {
    @include respond-to(decent) {
        display: none !important;
    }
}

// hide stuff on grid-m
.hidden-on-mobile {
    @include respond-to(grid-s) {
        display: none !important;
    }
}

// hidden on grid-m
.hidden-on-small-tablett {
    @include respond-to(grid-m) {
        display: none !important;
    }
}

// hidden on grid-l
.hidden-on-big-desktop {
    @include respond-to(grid-l) {
        display: none !important;
    }
}

// hidden everywhere but on grid-m
.hidden-but-small-tablett {
    @include respond-to(grid-s) {
        display: none !important;
    }

    @include respond-to(grid-l) {
        display: none !important;
    }
}

// hide print elements
.hidden-on-screen {
    @media screen {
        display: none !important;
    }
}

// hide visually but still have it there for accessibility
.hidden-visually {
    @include hide;
}

// hide visually but still have it there for accessibility
.hidden-visually-on-mobile {
    @include respond-to(grid-s) {
        @include hide;
    }
}

// hide visually but still have it there for accessibility
.hidden-visually-on-desktop {
    @include respond-to(decent) {
        @include hide;
    }
}

// truncate text-lines with fading cover
.truncate-cover {
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    @include fade-cover();

    > * {
        display: inline-block;
    }
}

// This is a IE fix for the min-height flexbox problem
.ajax-content {
    .ie & {
        display: flex;
        flex-direction: column;
    }
}

// Base selection
::selection {
    //  color: $text-color--secondary;
    //  background-color: $back-color--primary;
    // color: $text-color--primary;
    background-color: #edeae3;
}

// Use different select color
input::selection,
textarea::selection {
    background-color: $button-super;
}
