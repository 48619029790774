// Nomos catalogue order

.catalogue {
    @include respond-to(decent) {
        @include clearfix();

        > div {
            width: grid-width(grid-l, 4);
            float: left;

            &:last-child {
                float: right;
            }
        }
    }
}

.catalogue__media {
    @include respond-to(decent) {
        width: grid-width(grid-l, 4);
        position: sticky;
        top: $menu-top-padding-desktop;
    }
}
