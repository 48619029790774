// Nomos table of content

.sub-nav {
    margin-left: calc((#{grid-margin(grid-s)} + #{grid-padding-h()}) * -1); // indent to the left viewport site
    margin-right: calc((#{grid-margin(grid-s)} + #{grid-padding-h()}) * -1); // indent to the right viewport site

    // make sub navi scrollable if extending viewport
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    // hide scrollbars
    @include hide-scrollbars();

    // fade cover on the right
    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        pointer-events: none;
        background: linear-gradient(to right, rgba($back-color--default, 0), $back-color--default);
        top: 0;
        bottom: 0;
        width: 6em;
        right: calc(#{grid-margin(grid-s)} * -1);

        // tweak fade if action buttons are on top
        @include respond-to(grid-s) {
            .has-action-buttons-shown .action-bar:not(.is-stuck) & {
                background: linear-gradient(to right, rgba($back-color--default, 0), rgba($back-color--default, 0.8) 6em, $back-color--default);
                width: 10em;
            }
        }
    }

    ul {
        white-space: nowrap; // display flex will prevent ul from being wider than the .toc-nav parent
        padding-left: calc((#{grid-margin(grid-s)} + #{grid-padding-h()})); // compensate for indent

        // force some space after last li
        &:after {
            content: '.';
            color: transparent;
            pointer-events: none;
            display: inline-block;
        }
    }

    li {
        display: inline-block;
        margin: 1rem 1rem 1rem + $baseline-offset 0;
        @include underline-active;

        &:last-child {
            margin-right: 3rem;

            // make last itrem reachable if action btn are in view
            @include respond-to(grid-s) {
                .has-action-buttons-shown & {
                    margin-right: 8rem;
                }
            }
        }
    }

    a {
        @include textstyle--teaser;
        @include textstyle--s;
    }

    @include respond-to(decent) {
        margin-left: calc((#{grid-margin(grid-l)} + #{grid-padding-h(grid-l)}) * -1);
        margin-right: calc((#{grid-margin(grid-l)} + #{grid-padding-h(grid-l)}) * -1); // indent to the right viewport site

        // fade cover on the right as well
        &:before {
            width: 4em;
            right: calc(#{grid-margin(grid-l)} * -1);
        }

        ul {
            padding-left: calc((#{grid-margin(grid-l)} + #{grid-padding-h(grid-l)})); // compensate for indent
        }

        li {
            margin-right: 2.5rem;

            &:last-child {
                margin-right: 2.5rem;
            }
        }

        a {
            @include textstyle--teaser;
        }
    }

    @include respond-to(grid-m) {
        margin-left: calc((#{grid-margin(grid-m)} + #{grid-padding-h(grid-m)}) * -1);
        margin-right: calc((#{grid-margin(grid-m)} + #{grid-padding-h(grid-m)}) * -1); // indent to the right viewport site

        // fade cover on the right as well
        &:before {
            right: calc(#{grid-margin(grid-m)} * -1);
        }

        ul {
            padding-left: calc((#{grid-margin(grid-m)} + #{grid-padding-h(grid-m)})); // compensate for indent
        }

        li {
            margin-right: 1.5rem;

            &:last-child {
                margin-right: 1.5rem;
            }
        }

        a {
            @include textstyle--m;
        }
    }
}
