// Action bar
// used for filter, toc etc
.action-bar {
    position: relative; // IE fallback
    position: sticky;
    top: -1px;
    background: $back-color--default;
    z-index: map-get($z-index, action-bar);
    height: $action-bar-height-mobile;

    .grid-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: stretch;
        height: 100%;
        @include grid-padding-h;
        flex-wrap: nowrap;
        position: relative;
    }

    .checkout-steps__list,
    .head-wrapper {
        flex: 1 1 auto; // use all room available
    }

    h3 {
        @include textstyle--uppercase;
        @include textstyle--s;
    }

    .btn {
        white-space: nowrap;
    }

    // colored background for setup with colored .hero--basic

    &.action-bar--with-background {
        background: transparent; // start transparent for animation purposes

        .is-scrolled-beyond-header & {
            background: $back-color--secondary;

            @supports(--css: variable) {
                --hero-background: $back-color--secondary;
                background: var(--hero-background);
            }
        }
    }

    // reduce gap to upcoming teaser
    &.action-bar--magazine {
        margin-bottom: grid-spacing(grid-s, l) * -1;

        @include respond-to(grid-l) {
            margin-bottom: grid-spacing(grid-l, l) * -1;
        }

        @include respond-to(grid-m) {
            margin-bottom: grid-spacing(grid-m, l) * -1;
        }
    }

    // store sub nav
    &.action-bar--sub-nav {
        // dont stick subnav on watchfinder and product stream pages with filter
        .page-product-stream & {
            position: relative;
        }

        // dont show on modal pages
        .modal & {
            display: none;
        }

        @include respond-to(decent) {
            &:first-child {
                .main-header + .main-content & { // only show top gap and line if no hero available
                    border-top: 1px solid $border-color--light-1;
                    margin-top: $menu-height-desktop + 4rem;
                }
            }
        }
    }

    // account sub nav // mobile only
    &.action-bar--account-nav {
        border-top: 1px solid $border-color--light-1;
        margin-bottom: 2em;
    }

    // filter
    &.action-bar--filter {
        @include respond-to(grid-s) {
            border-top: 1px solid $border-color--light-2;
        }

        // we have a sidebar filter – therefore content needs to be indended
        @include respond-to(grid-l) {
            .grid-container {
                padding-left: grid-width(grid-l, 2.5) + grid-gutter-width(grid-l) / 2 + grid-padding-h(grid-l);
            }
        }

        @include respond-to(grid-m) {
            // show line if below subnavigation
            .action-bar--sub-nav + & {
                border-top: 1px solid $border-color--light-1;
            }

            .grid-container {
                flex-direction: row-reverse;
            }
        }
    }

    // different implementation for mobile product action bar
    // not-mobile-first will be ok here
    &.action-bar--product {
        @include respond-to(grid-s) {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            transition: transform $trans-time--m $trans-func--default;
            transform: translate3d(0, -100%, 0);

            .btn {
                margin-right: 0;
            }

            // show action-bar if scrolled some way already
            .is-scrolled-decent & {
                transform: none;
                transition: transform $trans-time--m $trans-func--easing-out;
            }

            // prevent blitzing on iOS and OS X
            body:not(.is-scrolled) & {
                visibility: hidden;
            }

            // dont show on modal products
            .modal & {
                display: none;
            }
        }
    }

    // Vertical action bar for vertical TOC
    &.action-bar--vertical {
        position: relative;
        height: auto;
    }

    // display action bar in modal
    &:not(&.action-bar--vertical) {
        .modal & {
            top: calc(#{$modal-header-mobile - 0.6rem} - 1px);

            @include respond-to(decent) {
                top: calc(#{$modal-header-desktop - 1.8rem} - 1px);
            }
        }
    }

    @include respond-to(decent) {
        &:not(&.action-bar--vertical) {
            height: $action-bar-height-desktop;
        }

        .grid-container {
            transform: none;
            transition: transform $trans-time--m $trans-func--default,
                        opacity $trans-time--m $trans-func--default;
        }

        // Show header if going back up
        .is-scrolled-up-much:not(.page-product-stream) & {
            &.is-stuck {
                .grid-container {
                    opacity: 0;
                    transform: translate3d(0, 20%, 0);
                    transition: transform $trans-time--m $trans-func--easing-out,
                                opacity $trans-time--m $trans-func--easing-out;
                }
            }
        }
    }
}

// title for .action-bar--filter
// desktop only
.action-bar__title {
    h4 {
        @include textstyle--uppercase;
        @include textstyle--m;
        transition: opacity $trans-time--xs $trans-func--default;
        opacity: 0;
        user-select: none;

        .is-stuck & {
            user-select: all;
            opacity: 1;
        }
    }
}

.action-bar__buttons {
    padding-left: 2em;
    position: relative;
    width: auto;
    flex: 0 0 auto;

    // prevent breaking the layout
    .btn {
        @include grid-max-width(4, 4, 4);
        overflow: hidden;
    }

    // include fade-cover for the leading toc navi
    // don’t position: relative parent or :after will scroll with the overflow
    @include fade-cover(right, 4em);
    &:after {
        // reset to sit on the left side
        right: auto;
        left: 0;
        transform: translate3d(-100%, 0, 0);
    }

    @include respond-to(grid-l) {
        padding-left: 4em;

        // handle display if action buttons are shown
        .has-action-buttons-shown & {
            transition: opacity $trans-time--s $trans-func--default;
            opacity: 0;
        }

        .has-action-buttons-shown.is-scrolled-beyond-action-bar & {
            opacity: 1;
        }
    }

    @include respond-to(grid-m) {
        padding-left: 4em;
    }
}
