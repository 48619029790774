// Nomos slideshows and carropusell

.slideshow,
.no-slideshow,
.banner__slideshow,
.global-banner__slideshow,
.color-variants__slideshow,
.gallery__slideshow {
    width: 100%;
    position: relative;
    transition: opacity $trans-time--l $trans-func--default;
    opacity: 1;

    &.color-variants__slideshow:not(.flickity),
    &.slideshow:not(.flickity-enabled):not(.flickity-sort-of-enabled) {
        opacity: 0;
        white-space: nowrap; // Keep elements in one line // flex solution would lead to different heights
        overflow: hidden;

        > * {
            display: inline-block;
            float: none;
        }
    }

    // Handle display and enabling of slideshows
    // Enable Flickity by default
    &:after {
        content: 'flickity';

        // Add clearfix as well
        display: table;
        visibility: hidden;
        height: 0;
        overflow: hidden;
        clear: both;
        line-height: 0;
        margin-top: -4px; // Fixing the 3px Jog - https://css-tricks.com/all-about-floats/ prevent unwanted height in flow
    }

    // Disable slideshow if there are to less elements in certain viewports
    @include respond-to(grid-s) {
        &.color-variants__slideshow {
            @for $i from 1 through 3 {
                &[data-slideshow-items="#{$i}"] {
                    &:after {
                        content: ' ';
                    }
                }
            }
        }
    }

    @include respond-to(decent) {
        &.slideshow--watches-series,
        &.slideshow--watches-default,
        &.slideshow--press,
        &.color-variants__slideshow {
            @for $i from 1 through 3 {
                &[data-slideshow-items="#{$i}"] {
                    &:after {
                        content: ' ';
                    }
                }
            }
        }

        &.slideshow--watches-double,
        &.slideshow--details,
        &.slideshow--pages {
            @for $i from 1 through 2 {
                &[data-slideshow-items="#{$i}"] {
                    &:after {
                        content: ' ';
                    }
                }
            }
        }

        &.slideshow--pages-small {
            @for $i from 1 through 4 {
                &[data-slideshow-items="#{$i}"] {
                    &:after {
                        content: ' ';
                    }
                }
            }
        }

        &.slideshow--wristshots {
            &[data-slideshow-items="1"] {
                &:after {
                    content: ' ';
                }
            }
        }
    }

    @include respond-to(grid-l) {
        &.color-variants__slideshow,
        &.slideshow--press {
            &[data-slideshow-items="4"] {
                &:after {
                    content: ' ';
                }
            }
        }

        &.slideshow--pages-small {
            &[data-slideshow-items="5"] {
                &:after {
                    content: ' ';
                }
            }
        }

        &.slideshow--product-store {
            .box-m &,
            .box-l & {
                @for $i from 1 through 2 {
                    &[data-slideshow-items="#{$i}"] {
                        &:after {
                            content: ' ';
                        }
                    }
                }
            }
        }

        &.slideshow--product-store {
            .box-xl & {
                @for $i from 1 through 3 {
                    &[data-slideshow-items="#{$i}"] {
                        &:after {
                            content: ' ';
                        }
                    }
                }
            }
        }

        &.slideshow--product-store {
            .box-xxxl & {
                @for $i from 1 through 5 {
                    &[data-slideshow-items="#{$i}"] {
                        &:after {
                            content: ' ';
                        }
                    }
                }
            }
        }
    }

    @include respond-to(xl) {
        &.slideshow--watches-series,
        &.slideshow--watches-default {
            @for $i from 4 through 5 {
                &[data-slideshow-items="#{$i}"] {
                    &:after {
                        content: ' ';
                    }
                }
            }
        }
    }

    @include respond-to(grid-m) {
        &.slideshow--product-store {
            .box-l &,
            .box-xl & {
                @for $i from 1 through 2 {
                    &[data-slideshow-items="#{$i}"] {
                        &:after {
                            content: ' ';
                        }
                    }
                }
            }
        }

        &.slideshow--product-store {
            .box-xxxl & {
                @for $i from 1 through 3 {
                    &[data-slideshow-items="#{$i}"] {
                        &:after {
                            content: ' ';
                        }
                    }
                }
            }
        }
    }

    // Handle display of never enabled slideshows
    > .flickity__slide {
        float: left;
    }

    // Include full clearfix
    &:before {
        content: ' ';
        display: table;
    }

    .teaser > .text-container,
    figcaption {
        transition: opacity $trans-time--m $trans-func--easing-out,
                    transform $trans-time--m $trans-func--easing-out;
    }

    .flickity__slide {
        position: relative;

        img {
            // Avoid grabbing image with mouse up or down
            pointer-events: none;
        }
    }

    &.flickity-enabled.is-draggable {
        .media-box {
            cursor: grab;
        }
    }

    &.flickity-enabled.is-draggable .is-pointer-down {
        .media-box {
            cursor: grabbing;
        }
    }

    &.flickity-enabled {
        .flickity__slide {
            // animate in with intersect
            .no-touch.no-ie.intersection-observer .intersect & {
                @include hide-before-animation();
            }

            .no-touch.no-ie.intersection-observer .intersect-inview--before &,
            .no-touch.no-ie.intersection-observer .intersect-inview & {
                @include show-for-animation();
                animation: animate-element-fade-in $trans-time--l $trans-func--default 1 backwards;  // motion reduced, used to be: animate-element-s-mobile
                @include animation-delay(0.15s);

                /* motion reduced
                @include respond-to(decent) {
                    animation-name: animate-element-s;
                }*/
            }
        }
    }

    .flickity-prev-next-button {
        padding: 2em grid-padding-h();
        background: none;
        cursor: pointer;
        outline: none;
        width: auto;
        height: auto;
        line-height: 1;
        z-index: 1;
        display: none;
        right: 0;
        transform: translate3d(100%, -60%, 0);
        border-radius: 0;
        font-size: inherit;

        // overwrite flickity settings
        svg {
            top: auto;
            left: auto;
        }

        &.previous {
            left: 0;
            right: auto;
            transform: translate3d(-100%, -60%, 0);
        }

        .svg-ico {
            display: inline-block;
            position: relative;
            top: auto;
            left: auto;
            width: 1rem;
            height: 3rem;
        }

        &:disabled {
            opacity: 0;
            pointer-events: none;
        }

        @include respond-to(decent) {
            display: block;

            .flickity-prev-next-button {
                padding: 2em grid-padding-h(grid-l);
            }
        }

        @include respond-to(grid-m) {
            .flickity-prev-next-button {
                padding: 2em grid-padding-h(grid-m);
            }
        }
    }

    &[data-slideshow-dots] {
        padding-bottom: 2rem;
    }

    .flickity-page-dots {
        left: 0;
        bottom: 0;
        height: 2rem;

        .dot {
            background: transparent;
            width: auto;
            height: auto;
            margin: 0;
            padding: 0 0.4rem;

            &:after {
                content: '•';
                font-size: 2.4rem;
                // transform: scale(2.4);
                display: block;
            }

            @include respond-to(decent) {
                padding: 0 0.55rem;
            }
        }
    }

    // hide dots if too many
    @include respond-to(grid-s) {
        @for $i from 17 through 30 {
            &[data-slideshow-items="#{$i}"] {
                .flickity-page-dots {
                    visibility: hidden;
                }
            }
        }
    }

    &.slideshow--watches-select {
        .flickity-page-dots {
            bottom: 1em;

            @include respond-to(grid-l) {
                bottom: 2em;
            }
        }
    }
}

.slideshow--watches-single,
.slideshow--watches-double {
    width: 100vw;
    margin-left: calc(#{grid-margin(grid-s)} * -1);
    margin-right: calc(#{grid-margin(grid-s)} * -1);
    margin-bottom: grid-spacing(grid-s, m);

    .flickity__slide {
        width: 100vw;
    }

    .teaser {
        margin-left: auto;
        margin-right: auto;
    }

    @include respond-to(grid-l) {
        margin-left: calc(#{grid-margin(grid-l)} * -1);
        margin-right: calc(#{grid-margin(grid-l)} * -1);
        margin-bottom: 0;

        .flickity-viewport {
            .is-selected ~ .flickity__slide:not(.is-selected),
            .flickity__slide:not(.is-selected):not(:nth-last-child(-n+2)) {
                opacity: 0;
                transition: opacity $trans-time--m $trans-func--default 0.15s;
            }

            &.is-pointer-down {
                .flickity__slide {
                    opacity: 1 !important;
                    transition: opacity $trans-time--m $trans-func--default;
                }
            }
        }

        .flickity-prev-next-button {
            right: calc(#{grid-margin(grid-l)} + #{grid-width(grid-l, 0.5)});

            &.previous {
                left: calc(#{grid-margin(grid-l)} + #{grid-width(grid-l, 0.5)});
            }
        }

        // make ready to be used inside sidebar modal
        .modal--sidebar & {
            width: calc(#{grid-width(grid-l, 9)} + #{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 0.5)});
            margin-left: calc(#{grid-width-gutter(grid-l, 0.5)} * -1);
            margin-right: calc(#{grid-margin(grid-l, false)} * -1);

            .flickity__slide {
                width: calc(#{grid-width(grid-l, 9)} + #{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 0.5)});
            }
        }
    }

    @include respond-to(grid-m) {
        margin-left: calc(#{grid-margin(grid-m)} * -1);
        margin-right: calc(#{grid-margin(grid-m)} * -1);
        margin-bottom: 0;

        .flickity-prev-next-button {
            right: calc(#{grid-margin(grid-m)} + #{grid-width(grid-m, 0.5)});

            &.previous {
                left: calc(#{grid-margin(grid-m)} + #{grid-width(grid-m, 0.5)});
            }
        }

        // make ready to be used inside sidebar modal
        .modal--sidebar & {
            width: calc(#{grid-width(grid-m, 9)} + #{grid-margin(grid-m)} + #{grid-width-gutter(grid-m, 0.5)});
            margin-left: calc(#{grid-width-gutter(grid-m, 0.5)} * -1);
            margin-right: calc(#{grid-margin(grid-m, false)} * -1);

            .flickity__slide {
                width: calc(#{grid-width(grid-m, 9)} + #{grid-margin(grid-m)} + #{grid-width-gutter(grid-m, 0.5)});
            }
        }
    }
}

.slideshow--watches-double {
    padding-left: grid-margin(grid-s);
    padding-right: grid-margin(grid-s);
    overflow: hidden;

    .flickity__slide {
        @include grid-width(10, 5, 5);
    }

    .flickity-viewport {
        overflow: visible;
    }

    @include respond-to(grid-l) {
        padding-left: grid-margin(grid-l);
        padding-right: grid-margin(grid-l);

        .flickity__slide:not(:last-child) {
            margin-right: grid-gutter(grid-l);
        }

        // make ready to be used inside sidebar modal
        .modal--sidebar & {
            padding-left: grid-width-gutter(grid-l, 0.5);

            .flickity__slide {
                width: grid-width(grid-l, 4.5);
            }
        }
    }

    @include respond-to(grid-m) {
        padding-left: grid-margin(grid-m);
        padding-right: grid-margin(grid-m);
        margin-bottom: 0;

        .flickity-prev-next-button {
            transform: translate3d(50%, -60%, 0);

            &.previous {
                transform: translate3d(-50%, -60%, 0);
            }
        }

        .flickity__slide:not(:last-child) {
            margin-right: grid-gutter(grid-m);
        }

        // make ready to be used inside sidebar modal
        .modal--sidebar & {
            padding-left: grid-width-gutter(grid-m, 0.5);

            .flickity__slide {
                width: grid-width(grid-m, 4.5);
            }
        }
    }
}

.slideshow--watches-default,
.slideshow--watches-series {
    width: 100vw;
    margin-right: calc(#{grid-margin(grid-s)} * -1);
    margin-left: calc(#{grid-margin(grid-s)} * -1);
    padding-left: calc(#{grid-width-gutter(grid-s, 2)} + #{grid-margin(grid-s)});
    overflow: hidden;

    .flickity-viewport {
        overflow: visible;
    }

    @include respond-to(grid-s) {
        // let last slide be centered as well
        .flickity__slide:last-child {
            padding-right: calc(#{grid-width-gutter(grid-s, 2)} + #{grid-margin(grid-s)});
        }
    }

    @include respond-to(decent) {
        width: grid-width(grid-l, 10);
        margin-right: 0;
        margin-left: 0;
        margin-bottom: grid-spacing(grid-l, l);
        padding-left: 0;
        overflow: visible;

        .flickity-prev-next-button {
            transform: translate3d(145%, -60%, 0);

            &.previous {
                transform: translate3d(-145%, -60%, 0);
            }
        }

        .flickity-viewport {
            overflow: hidden;
        }

        // let last obejct fit correctely
        .flickity__slide:last-child {
            .teaser.teaser--product {
                margin-right: 0 !important;
            }
        }
    }

    @include respond-to(grid-l) {
        .modal--sidebar & {
            width: grid-width(grid-l, 8);
        }
    }

    @include respond-to(grid-m) {
        width: grid-width(grid-m, 9);
        margin-bottom: 0;

        .modal--sidebar & {
            .flickity-prev-next-button {
                transform: translate3d(50%, -60%, 0);

                &.previous {
                    transform: translate3d(-50%, -60%, 0);
                }
            }
        }
    }
}

.slideshow--product-store {
    width: 100%;
    overflow: hidden;

    .flickity-viewport {
        overflow: visible;
    }

    &[data-slideshow-dots] {
        padding-bottom: 5rem;
    }

    .flickity-page-dots {
        bottom: 1.6rem;
    }

    @include respond-to(grid-s) {
        padding-left: grid-width-gutter(grid-s, 2);

        // let last slide be centered as well
        .flickity__slide:last-child {
            padding-right: grid-width-gutter(grid-s, 2);
        }
    }

    @include respond-to(decent) {
        // let last obejct fit correctely
        .flickity__slide:last-child {
            .teaser.teaser--product {
                margin-right: 0 !important;
            }
        }

        .flickity-prev-next-button {
            transform: translate3d(-20%, -60%, 0);

            &.previous {
                transform: translate3d(20%, -60%, 0);
            }

            // hide arrows for touch
            .touch & {
                display: none;
            }
        }
    }

    @include respond-to(grid-l) {
        // center slideshow if needed
        .box-l & {
            &[data-slideshow-items="2"] {
                padding-left: grid-width(grid-l, 0.5) + grid-gutter-width(grid-l) / 2;
            }
        }

        .box-xl & {
            &[data-slideshow-items="2"] {
                padding-left: grid-width(grid-l) + grid-gutter-width(grid-l);
            }
        }

        .box-xxxl & {
            &[data-slideshow-items="3"] {
                padding-left: grid-width-gutter(grid-l, 2);
            }

            &[data-slideshow-items="4"] {
                padding-left: grid-width-gutter(grid-l, 1);
            }
        }
    }

    @include respond-to(grid-m) {
        .flickity-prev-next-button {
            transform: translate3d(-10%, -60%, 0);

            &.previous {
                transform: translate3d(10%, -60%, 0);
            }
        }

        // center teaser in boxes
        .box-m & {
            .teaser.teaser--product {
                margin-left: grid-width(grid-m, 0.5) + grid-gutter-width(grid-m) / 2;
                margin-right: grid-width(grid-m, 0.5) + grid-gutter-width(grid-m) / 2 !important; // some specifity trouble here
            }
        }

        .box-l & {
            .teaser--product-small {
                width: grid-width(grid-m, 2.5) - grid-gutter-width(grid-m) / 2;
            }
        }

        .box-xxxl & {
            &[data-slideshow-items="3"] {
                padding-left: grid-width(grid-m, 0.5) + grid-gutter-width(grid-m) / 2;
            }
        }
    }
}

.slideshow--wristshots,
.slideshow--details {
    width: grid-width(grid-s, 10, true);
    margin-right: calc(#{grid-margin(grid-s)} * -1);

    figure {
        @include grid-width(8,4,4);
        margin-right: grid-gutter(grid-s);

        p {
            @include textstyle--s;
        }

        .media-box {
            @include ratio-box($landscape-ratio);
        }
    }

    .flickity__slide {
        &:last-child {
            figure {
                margin-right: calc(#{grid-margin(grid-s)});
            }
        }
    }

    @include respond-to(grid-s) {
        .is-selected + div,
        .flickity__slide:first-child:not(.is-selected) {
            figcaption {
                opacity: 0;
                transform: translate3d(0, 0.4em, 0);
            }
        }
    }

    @include respond-to(grid-l) {
        width: 100vw;
        margin-right: calc(#{grid-margin(grid-l)} * -1);
        margin-left: calc(#{grid-margin(grid-l)} * -1);
        padding-left: calc(#{grid-margin(grid-l)});
        overflow: hidden;

        .flickity-viewport {
            overflow: visible;
        }

        figure {
            margin-right: (grid-width(grid-l) / 2) + (grid-gutter(grid-l) * 1.5);

            p {
                @include textstyle--default;
            }
        }

        .flickity__slide {
            &:last-child {
                figure {
                    margin-right: calc(#{grid-margin(grid-l)});
                }
            }
        }

        figcaption {
            width: grid-width(grid-l, 3);
        }

        // make ready to be used inside sidebar modal
        .modal--sidebar & {
            width: calc(#{grid-width(grid-l, 9)} + #{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 0.5)});
            margin-left: calc(#{grid-width-gutter(grid-l, 0.5)} * -1);
            margin-right: calc(#{grid-margin(grid-l, false)} * -1);
            padding-left: grid-width-gutter(grid-l, 0.5);

            figure {
                margin-right: grid-gutter(grid-l);
            }
        }
    }

    @include respond-to(grid-m) {
        width: 100vw;
        margin-right: calc(#{grid-margin(grid-m)} * -1);
        margin-left: calc(#{grid-margin(grid-m)} * -1);
        padding-left: calc(#{grid-margin(grid-m)});
        overflow: hidden;

        .flickity-viewport {
            overflow: visible;
        }

        figure {
            margin-right: grid-gutter(grid-m);

            p {
                @include textstyle--default;
            }
        }

        .flickity__slide {
            &:last-child {
                figure {
                    margin-right: calc(#{grid-margin(grid-m)});
                }
            }
        }

        // make ready to be used inside sidebar modal
        .modal--sidebar & {
            width: calc(#{grid-width(grid-m, 9)} + #{grid-margin(grid-m)} + #{grid-width-gutter(grid-m, 0.5)});
            margin-left: calc(#{grid-width-gutter(grid-m, 0.5)} * -1);
            margin-right: calc(#{grid-margin(grid-m, false)} * -1);
            padding-left: grid-width-gutter(grid-m, 0.5);
        }
    }
}

.slideshow--wristshots {
    figure {
        @include grid-width(8,4,3.5);

        .media-box {
            height: 39em; // ratio-box does not work savely here because we need to mix different ratios

            &:before {
                display: none !important;
            }
        }
    }

    .slideshow__wristshot-video {
        width: grid-width(grid-s, 10);
    }

    @include respond-to(grid-l) {
        padding-left: calc(#{grid-margin(grid-l)} + #{grid-width(grid-l, 2, false, true, true)});

        figure {
            margin-right: (grid-width(grid-l) / 2);

            .media-box {
                height: 64.5em; // ratio-box does not work savely here because we need to mix different ratios
            }
        }

        &.no-slideshow {
            // fix zoom icon
            figure {
                position: relative;

                &:after {
                    right: 0 !important;
                }
            }
        }

        .slideshow__wristshot-video {
            width: grid-width(grid-l, 7);
        }
    }

    @include respond-to(grid-m) {
        figure {
            .media-box {
                height: 49em; // ratio-box does not work savely here because we need to mix different ratios
            }
        }

        .slideshow__wristshot-video {
            width: grid-width(grid-m, 8);
        }
    }
}

.slideshow--pages,
.slideshow--pages-small {
    width: grid-width(grid-s, 10, true);
    margin-right: calc(#{grid-margin(grid-s)} * -1);

    .is-selected {
        // outline: 1px solid darkred;/
    }

    article {
        @include grid-width(8,4,4);
        margin-right: grid-gutter(grid-s);
    }

    .flickity__slide {
        &:last-child {
            article {
                margin-right: calc(#{grid-margin(grid-s)} - 1px); // -1px: fix subpixel rendering issue in firefox
            }
        }
    }

    @include respond-to(grid-l) {
        width: 100vw;
        margin-right: calc(#{grid-margin(grid-l)} * -1);
        margin-left: calc(#{grid-margin(grid-l)} * -1);
        padding-left: calc(#{grid-margin(grid-l)});
        overflow: hidden;

        .flickity-viewport {
            overflow: visible;
        }

        article {
            margin-right: grid-gutter(grid-l);
        }

        .text-container {
            max-width: grid-inner-width(grid-l, 3) !important;
        }

        .flickity__slide {
            &:last-child {
                article {
                    margin-right: calc(#{grid-margin(grid-l)} - 1px); // -1px: fix subpixel rendering issue in firefox
                }
            }
        }

        // make ready to be used inside sidebar modal
        .modal--sidebar & {
            width: calc(#{grid-width(grid-l, 9)} + #{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 0.5)});
            margin-left: calc(#{grid-width-gutter(grid-l, 0.5)} * -1);
            margin-right: calc(#{grid-margin(grid-l, false)} * -1);
            padding-left: grid-width-gutter(grid-l, 0.5);
        }
    }

    @include respond-to(grid-m) {
        width: 100vw;
        margin-right: calc(#{grid-margin(grid-m)} * -1);
        margin-left: calc(#{grid-margin(grid-m)} * -1);
        padding-left: calc(#{grid-margin(grid-m)});
        overflow: hidden;

        .flickity-viewport {
            overflow: visible;
        }

        article {
            margin-right: grid-gutter(grid-m);
        }

        .flickity__slide {
            &:last-child {
                article {
                    margin-right: calc(#{grid-margin(grid-m)} - 1px); // -1px: fix subpixel rendering issue in firefox
                }
            }
        }

        // make ready to be used inside sidebar modal
        .modal--sidebar & {
            width: calc(#{grid-width(grid-m, 9)} + #{grid-margin(grid-m)} + #{grid-width-gutter(grid-m, 0.5)});
            margin-left: calc(#{grid-width-gutter(grid-m, 0.5)} * -1);
            margin-right: calc(#{grid-margin(grid-m, false)} * -1);
            padding-left: grid-width-gutter(grid-m, 0.5);
        }
    }
}

.slideshow--pages-small {
    article {
        @include grid-width(4,3,2);
    }

    // hide CTA
    .btn {
        display: none;
    }

    .text-container {
        padding-bottom: 0;
    }

    @include respond-to(grid-s) {
        h3 {
            @include textstyle--s;
        }
    }

    @include respond-to(decent) {
        .text-container {
            padding-top: 1.6em;
        }
    }

    @include respond-to(grid-l) {
        // handle different item quantities
        &[data-slideshow-items="2"] {
            article {
                width: grid-width(grid-l, 5);
            }
        }

        @for $i from 3 through 5 {
            &[data-slideshow-items="#{$i}"] {
                article {
                    width: calc(#{grid-width(grid-l, 10, false, false, true) / $i} - #{grid-gutter-width(grid-l) * ($i - 1) / $i});
                }
            }
        }
    }

    @include respond-to(grid-m) {
        h3 {
            @include textstyle--m;
        }

        // handle different item quantities
        @for $i from 3 through 4 {
            &[data-slideshow-items="#{$i}"] {
                article {
                    width: calc(#{grid-width(grid-m, 10, false, false, true) / $i} - #{grid-gutter-width(grid-m) * ($i - 1) / $i});
                }
            }
        }
    }
}

.slideshow--fullscreen {
    width: 100vw;
    margin-left: calc(#{grid-margin(grid-s)} * -1);
    margin-right: calc(#{grid-margin(grid-s)} * -1);
    background: $back-color--secondary;
    overflow: hidden;

    &[data-slideshow-dots] {
        padding-bottom: 0;
    }

    .flickity__slide {
        width: 100vw;
    }

     figure {
         width: 100vw;
         height: 100vh;
         position: relative;
     }

    .media-box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .flickity-page-dots {
        bottom: 2rem;
    }

    .flickity-prev-next-button {
        right: 3vw;
        transform: translate3d(0, -60%, 0);
        border-radius: 0;
        font-size: inherit;

        &.previous {
            left: 3vw;
            right: auto;
            transform: translate3d(0, -60%, 0);
        }
    }

     @include respond-to(grid-l) {
         margin-right: calc(#{grid-margin(grid-l)} * -1);
         margin-left: calc(#{grid-margin(grid-l)} * -1);

         .flickity-page-dots {
             bottom: 4rem;
         }

         // make ready to be used inside sidebar modal
         .modal--sidebar & {
             width: calc(#{grid-width(grid-l, 9)} + #{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 0.5)});
             margin-left: calc(#{grid-width-gutter(grid-l, 0.5)} * -1);
             margin-right: calc(#{grid-margin(grid-l, false)} * -1);
         }
     }

     @include respond-to(grid-m) {
         margin-right: calc(#{grid-margin(grid-m)} * -1);
         margin-left: calc(#{grid-margin(grid-m)} * -1);

         .flickity-page-dots {
             bottom: 3rem;
         }

         // make ready to be used inside sidebar modal
         .modal--sidebar & {
             width: calc(#{grid-width(grid-m, 9)} + #{grid-margin(grid-m)} + #{grid-width-gutter(grid-m, 0.5)});
             margin-left: calc(#{grid-width-gutter(grid-m, 0.5)} * -1);
             margin-right: calc(#{grid-margin(grid-m, false)} * -1);
         }
     }

    @include respond-to(port) {
        figure {
            height: 80vh;
        }
    }
}

.slideshow--fullscreen-s {
    @include respond-to(grid-s) {
        .flickity-page-dots {
            bottom: 1rem;
        }
    }

    @include respond-to(port) {
        figure {
            height: 70vw;
        }
    }
}

.slideshow--fullscreen-l {
    figure {
        height: 140vh;
    }

    @include respond-to(port) {
        figure {
            height: 100vh;
        }
    }
}

.slideshow--hero {
    .teaser {
        width: grid-width(grid-s, 9, true);
        margin-right: grid-gutter(grid-s);
    }

    .teaser-title {
        opacity: 0;
        transform: translate3d(40vw, 0, 0);
        margin-left: grid-margin(grid-s, false);
        transition: opacity $trans-time--s $trans-func--easing-out,
                    transform 0s $trans-func--easing-out $trans-time--m;
    }

    .btn {
        opacity: 0;
    }

    .is-selected {
        .teaser-title {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition: opacity $trans-time--m $trans-func--easing-out,
                        transform $trans-time--m $trans-func--easing-out;
        }

        .btn {
            opacity: 1;
            transition: opacity $trans-time--l $trans-func--easing-out $trans-time--s;
        }
    }

    // style button
    .previous {
        display: none;
    }

    .next {
        display: none;

        @include respond-to(decent) {
            .no-touch & {
                display: block;
                position: absolute;
                top: calc(#{$hero-top-height-l} + #{grid-spacing(grid-l, s)});
                height: 120vw;
                max-height: calc(100vh - 20rem); // limit slideshow height to fit media and text into viewport
                width: calc(#{grid-margin(grid-l)} + #{grid-width(grid-l)});
                right: 0;
                transform: none;
                opacity: 0;

                /*
                background: rgba($back-color--primary, 0.3);
                transition: opacity $trans-time--s $trans-func--default;

                &:hover {
                    opacity: 1;
                }*/

                svg {
                    display: none;
                }

            }
        }
    }

    @include respond-to(grid-l) {
        .teaser {
            width: grid-width(grid-l, 9, true);
            margin-right: grid-gutter(grid-l);
        }

        .teaser-title {
            margin-left: grid-margin(grid-l, false);
        }
    }

    @include respond-to(grid-m) {
        .teaser {
            width: grid-width(grid-m, 9, true);
            margin-right: grid-gutter(grid-m);
        }

        .teaser-title {
            margin-left: grid-margin(grid-m, false);
        }
    }
}

.slideshow--press {
    width: grid-width(grid-s, 10, true);
    margin-right: calc(#{grid-margin(grid-s)} * -1);

    @include respond-to(grid-l) {
        margin-bottom: grid-spacing(grid-l, xl);
        margin-left: grid-width-gutter(grid-l);
        margin-right: 0;
        width: grid-width(grid-l, 8);

        .flickity-prev-next-button {
            &.previous {
                transform: translate3d(-250%, -60%, 0);
            }
        }

        // let last object fit correctly
        .flickity__slide:last-child {
            .teaser--press {
                margin-right: 0;
            }
        }
    }

    @include respond-to(grid-m) {
        width: grid-width(grid-m, 9);
        margin-right: 0;
        margin-left: grid-width-gutter(grid-m) / 2; // center press panel

        // let last object fit correctly
        .flickity__slide:last-child {
            .teaser--press {
                margin-right: 0;
            }
        }

        .modal--sidebar & {
            width: grid-width(grid-m, 6);
        }
    }
}

.slideshow_nav {
    // don’t show nav on mobile and grid-m
    display: none;

    @include respond-to(grid-l) {
        display: block;
        position: absolute;
        right: calc(#{grid-margin(grid-l)} + #{grid-padding-h(grid-l)});
        top: calc(#{$hero-top-height-l} - #{grid-padding-h(grid-l)});
        transform: translate3d(0, -100%, 0);
        z-index: map-get($z-index, slideshow-nav);
    }
}

.slideshow_nav__item {
    @include textstyle--button;
    display: inline-block;
    margin-left: 2rem;
    cursor: pointer;
    position: relative;
}

.slideshow_indicator {
    width: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;

    > .slideshow_indicator__inner {
        height: 1px;
        margin-left: -1px; // prevent blitzer
        width: inherit;
        background: $text-color--default;
        transform: translatex(-100%);
        transition-timing-function: linear !important;
    }
}
