// Help tooltip styles

// small help wrapper
.help-small,
.help-medium {
    display: flex;
    align-items: center;

    > p {
        @include textstyle--xs;

        @include respond-to(decent) {
            @include textstyle--s;
        }
    }

    > div > label {
        line-height: 1; // center icon
    }

    > * {
        position: relative;
    }
}

// Hidden checkbox
.help__checkbox {
    display: none;

    &:checked ~ .help__bg {
        visibility: visible;
        transform: none;
    }

    &:checked ~ .help__content {
        visibility: visible;
        opacity: 1;
        transform: none;
        z-index: map-get($z-index, help) + 10;
    }
}

// Page background
.help__bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: map-get($z-index, help) + 5;
    visibility: hidden;
    transform: translate3d(-100vw, -100vh, 0);
}

.help__content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    max-height: 60vh;
    padding: grid-padding-h() calc(#{grid-margin(grid-s)} + #{grid-width()}) 1.2em calc(#{grid-margin(grid-s)});
    background-color: $back-color--default;
    box-shadow: $box-shadow--m;
    transform: translate3d(0, -4rem, 0);
    transition: transform $trans-time--s $trans-func--easing-out,
    opacity $trans-time--s $trans-func--easing-out;
    opacity: 0;
    visibility: hidden;
    z-index: map-get($z-index, help);
    overflow: auto;

    article {
        position: relative;
        display: block;
        margin: 0;
        @include grid-padding();
        user-select: none;
    }

    @include respond-to(decent) {
        padding: 0;
        @include grid-width(10, 5, 3);
        position: absolute;
        top: auto;
        left: auto;
        bottom: $input-height + 2.6rem;
        right: calc(#{grid-width(grid-l, 0.5) * -1} - 1rem);
        box-shadow: $box-shadow--s;
        overflow: inherit;
        max-height: none;

        // Caret
        &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            bottom: 1px;
            right: calc(#{grid-width(grid-l, 0.5)} + 1.4rem);
            margin-left: 0.8em;
            border-style: solid;
            border-width: 1em;
            border-color: transparent transparent $back-color--default $back-color--default;
            transform-origin: 100% 100%;
            transform: rotate(298deg) scale(1, 0.8) skew(-30deg);
            box-shadow: -0.2em 0.2em 0.2em 0 rgba(0, 0, 0, 0.1);
        }

        article {
            padding: grid-padding-v(grid-l) grid-spacing(grid-l, m) grid-spacing(grid-l, m) grid-padding-v(grid-l);
        }

        .help-small & {
            @include grid-width(10, 3, 2);

            &:before {
                right: calc(#{grid-width(grid-l, 0.5)} + 1.1rem);
            }
        }

        .help-small.help-align-right & {
            right: calc(#{grid-width(grid-l, 0.5) * -2});

            &:before {
                right: calc(#{grid-width(grid-l, 0.5) * 2} + 0.1rem);
            }
        }
    }

    @include respond-to(grid-m) {
        article {
            padding: grid-padding-v(grid-m) grid-spacing(grid-m, m) grid-spacing(grid-m, m) grid-padding-v(grid-m);
        }

        &:before {
            right: calc(#{grid-width(grid-m, 0.5)} + 1.4rem);
        }

        .help-small & {
            &:before {
                right: calc(#{grid-width(grid-m, 0.5)} + 1.1rem);
            }
        }
    }
}

.help__close {
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    padding: 1.5rem 1.5rem 0 0;
    z-index: 1;
    width: auto;

    .svg-ico {
        width: 1.5rem;
        height: 1.5rem;
    }

    @include respond-to(decent) {
        padding-top: 1rem;
        position: absolute;
        top: 0;
    }
}
