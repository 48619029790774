// Customer Collection
.customer-collection {
    @include grid-inner-width(10, 8, 6);
    border-top: solid 1px $border-color--secondary;
    @include grid-bottom-spacing(m);

    // No watches found
    &[data-count="0"] {
        @include grid-padding;
        text-align: center;
        border: solid 1px $border-color--secondary;
    }

    // Only one watch found
    &[data-count="1"] {
        border-top: none;
    }

    & + .account-panel-group {
        border-top: none; // no additional line here on mobile
    }
}

.big-accordion__head--customer-collection {
    background-position: 100% 50%;
}

.head-wrapper.customer-collection__head {
    width: 100%;

    @include respond-to(decent) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 2em;
    }
}

.customer-collection__head-name {
    flex: 1 1 auto;
    @include textstyle--teaser-xs;

    @include respond-to(decent) {
        @include textstyle--teaser;
    }
}

.customer-collection__detail {
    display: flex;
    gap: grid-spacing(grid-s, m);
    justify-content: flex-start;
    align-items: flex-start;
    @include grid-bottom-spacing(s);

    // single watch display
    .customer-collection__head ~ & {
        @include grid-spacing(s);
    }

    @include respond-to(grid-l) {
        gap: grid-spacing(grid-l, m);
    }

    @include respond-to(grid-m) {
        gap: grid-spacing(grid-m, m);
    }
}

.customer-collection__detail-image {
    flex-basis: 50%;

    img {
        width: 100%;
    }
}

.customer-collection__detail-info {
    flex-basis: 100%;

    h4 {
        @include textstyle--xs;
        @include textstyle--uppercase;
        line-height: $line-height * 1.4 / 1.2; // have same line-height as dd ($fontsize--s / $fontsize--xs)
    }

    p {
        @include textstyle--s;
        @include textstyle--tabular-figures;
        margin: 0;
    }

    @include respond-to(decent) {
        h4 {
            @include textstyle--s;
            line-height: $line-height * 1.7 / 1.4; // have same line-height as dd ($fontsize--m / $fontsize--s)
        }

        p {
            @include textstyle--default;
        }
    }
}

.customer-collection__detail-group:not(:first-child) {
    @include grid-spacing(s);
}

.customer-collection__detail-group.customer-collection__pending-hint {
    @include grid-spacing(m);
}

// Info without image should be full width
.customer-collection__detail-image + .customer-collection__detail-info {
    flex-basis: 50%;
}