// Main Header for Logo and Main Menu
.main-header {
    position: relative;
    z-index: map-get($z-index, header);

    @include respond-to(decent) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    nav :not(p):not(h3) > a:not(.btn) {
        display: block;
    }

    @include respond-to(grid-s) {
        // hide view on body for motion scroll (eg iOS)
        &:before {
            @include pseudo();
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            height: 14em;
            background: $back-color--tertiary;
            transition: transform 0s $menu-trans-easing-in $menu-trans-timing-in;

            body:not(.has-main-navi) & {
                transform: translate3d(0, 100%, 0);
                transition: none;
                visibility: hidden;
            }
        }

        // Prevent clicks on watchfinder page if filter is shown
        .page-product-stream.has-filter &,
        .page-watchfinder.has-filter & {
            transition: visibility 0s linear $menu-trans-timing-in;
            visibility: hidden;
        }
    }
}

// Main Menu
.main-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    pointer-events: none;

    @include respond-to(decent) {
        position: relative;
        bottom: auto;
        left: auto;
        right: auto;
        margin-top: 0;
        transform: none;
        height: $menu-height-desktop;
        pointer-events: auto;
    }
}

// Main Menu wrapper
// used for scrolling on mobile
.main-menu__wrapper {
    height: 100vh;
    overflow: hidden;
    transform: translate3d(0, calc(100vh - #{$menu-height-mobile} - 0.5em), 0); // 0.5em: make room for the shadow
    transition: transform $menu-trans-timing-out $menu-trans-easing-out;
    pointer-events: auto;

    // fix for mobile safari vh-issue
    @include respond-to(grid-s) {
        @supports (--css: variable) {
            .is-safari.touch & {
                height: calc(var(--view-height-offset) * 100vh);
            }

            .is-safari.touch & {
                transform: translate3d(0, calc((var(--view-height-offset) * 100vh) - #{$menu-height-mobile} - 0.5em), 0); // 0.5em: make room for the shadow
            }
        }
    }

    .has-main-navi & {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        transform: translate3d(0, 0, 0);
        transition: transform $menu-trans-timing-in $menu-trans-easing-in;

        // fix for mobile safari vh-issue
        @include respond-to(grid-s) {
            @supports (--css: variable) {
                .is-safari.touch & {
                    transform: translate3d(0, 0, 0);
                }
            }
        }
    }

    @include respond-to(decent) {
        overflow: inherit;
        transform: inherit;
        transition: inherit;
        height: auto;

        .has-main-navi & {
            overflow: inherit;
            transform: inherit;
        }
    }
}

// Main Menu inner wrapper
// used for offset navigation inside scrollcontainer on mobile
.main-menu__inner {
    margin-top: $menu-peak-mobile;
    transition: transform $menu-trans-timing-out $menu-trans-easing-out;
    min-height: 100vh;

    body:not(.has-main-navi) & {
        transform: translate3d(0, #{0.5em - $menu-peak-mobile},0);
    }

    @include respond-to(decent) {
        margin-top: 0;
        transition: none;
        min-height: 1px;

        body:not(.has-main-navi) & {
            transform: none;
        }
    }
}

// Visible Header of Main Navigation
// mobile and desktop
.main-menu__head {
    background-color: $back-color--default;
    z-index: map-get($z-index, modal-header);
    position: relative;

    .has-main-navi--fully-opened & {
        position: sticky;
        top: 0;
    }

    .grid-container {
        height: $menu-height-mobile;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
    }

    // Drop Shadow for mobile nav
    &:before {
        content: '';
        height: 0.3em;
        background: black;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        margin-top: -0.3em;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.03) 50%, rgba(0, 0, 0, 0.08));
    }

    @include respond-to(decent) {
        z-index: inherit;
        background-color: transparent;

        .has-main-navi--fully-opened & {
            position: inherit;
            top: 0;
        }

        .grid-container {
            height: $menu-height-desktop;
            padding-bottom: 2rem;
            justify-content: space-between;
            align-items: center;
            background: transparent;
        }

        &:before {
            display: none;
        }
    }

    @include respond-to(grid-m) {
        .grid-container {
            justify-content: flex-end;
        }
    }
}

// Initially Hidden Part of Main Navigation
// mobile and desktop
.main-menu__body {
    background-color: $back-color--default;

    @include respond-to(grid-s) {
        margin-top: -1px; // prevent for blitzer
        padding-top: 2px;
    }

    @include respond-to(decent) {
        min-height: 0;
        margin-top: 0;
        padding-top: 0;
    }
}


// Primary Navigation Options on the left
// mobile and desktop
.main-menu__head-primary {
    a {
        @include textstyle--teaser;
        @include textstyle--m;
    }

    @include respond-to(decent) {
        li:not(:last-child) {
            margin-right: 2.5rem;
        }

        a {
            @include textstyle--teaser;
        }
    }

    @include respond-to(grid-m) {
        a {
            @include textstyle--m;
        }
    }
}

// Primary Navigation Options: Watches, Manufacture, Service
// mobile and desktop
.primary-nav-head {
    @include grid-padding-h;
    min-width: 25rem;

    a {
        white-space: nowrap;
    }

    ul {
        display: flex;
        justify-content: space-between;
    }

    li {
        @include underline-active;

        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    @include respond-to(grid-s) {
        :lang(en) & {
            li:not(:last-child) {
                margin-right: 0.8rem;
            }
        }
    }

    @include respond-to(decent) {
        ul {
            justify-content: flex-start;
        }

        li:not(:last-child) {
            margin-right: 2.5rem;
        }
    }

    @include respond-to(grid-m) {
        padding-right: 0;

        li:not(:last-child) {
            margin-right: 1.5rem;
        }

        // Streamlinging main navigation for different languages
        :lang(ja) & {
            a {
                font-size: 1.7rem;
            }
        }

        .has-checkout-header & {
            min-width: 1px;
            margin-right: 1.5em;
        }
    }

    // Streamlinging bottom mobile navigation for different languages
    @include respond-to(xs) {
        :lang(fr) &,
        :lang(it) &,
        :lang(en) & {
            a {
                font-size: 1.5rem;
            }
        }
    }

    // Streamline extended nav with shoplink ($hasCountryShop}) for different languages
    @mixin mobile-nav-adjust($country, $gap, $font-size) {
        :lang(#{$country}) .has-country-shop & {
            li:not(:last-child) {
                margin-right: $gap;
            }

            a {
                font-size: $font-size;
            }

            @supports (font-size: #{'min(2vw, 2rem)'}) {
                li:not(:last-child) {
                    margin-right: #{'min(1.5rem, #{$gap})'};
                }

                a {
                    font-size: #{'min(1.7rem, #{$font-size})'};
                }
            }
        }
    }

    @include respond-to(grid-s) {
        :lang(ja) & {
            &:not(:last-child) {
                margin-right: 1.5rem;
            }

            a {
                font-size: 1.45rem;
            }
        }

        // Streamline extended nav with shoplink
        @include mobile-nav-adjust(de, 2.1vw, 4.6vw);
        @include mobile-nav-adjust(en, 1.8vw, 3.9vw);
        @include mobile-nav-adjust(fr, 1.8vw, 3.9vw);
        @include mobile-nav-adjust(it, 1.8vw, 3.6vw);
        @include mobile-nav-adjust(es, 2.1vw, 4.4vw);
        // ja, zh-hans, zh-hant is all good
    }
}

// Wrapper for Secondary Navigation Options and Search
// desktop and mobile
.main-menu__head-secondary {
    @include respond-to(decent) {
        display: flex;
    }
}

// Meta Navigation on the the right
// desktop only
.meta-nav {
    // Bag in meta navigation
    .nav-link__account,
    .nav-link__bag {
        position: relative;
    }

    @include respond-to(decent) {
        display: flex;
        align-items: center;

        ul {
            display: flex;
            align-items: center;
        }

        .nav-link__retailer-search,
        .nav-link__store {
            margin-right: 2.5em;
        }

        a {
            @include textstyle--teaser;
        }

        .nav-link__account,
        .nav-link__bag {
            margin-left: -0.5em;
            margin-right: -0.5em;
            transition: margin $trans-time--s $trans-func--easing-out,
                        background $trans-time--s $trans-func--default;

            .btn.btn--single-icon {
                padding: 0;
                width: $action-button-size;
                height: $action-button-size;
            }

            &.is-active {
                box-shadow: $box-shadow--s;
                margin-left: 0;
                margin-right: 0;
                z-index: 1;

                body.has-split-screen-hero:not(.is-scrolled-beyond-header) & {
                    box-shadow: $box-shadow--s-dark;
                }
            }
        }

        .nav-link__bag {
             margin-right: -1.5em; // compensate for search icons non

             &.is-active {
                 margin-right: -1em;
             }
         }

        // clip shadow of mulitple activ elements
        .is-active + .nav-link__bag {
            clip-path: inset(-0.5em -0.5em -0.5em -1px);
        }

        .nav-link__account {
            .has-cart-items & {
                clip-path: inset(-0.5em 0 -0.5em -0.5em);
            }
        }

    }

    @include respond-to(grid-m) {
        ul {
            margin-left: 1.5rem;
        }

        // Streamlinging navigation for different languages
        :lang(ja) & {
            a {
                font-size: 1.7rem;
            }
        }
    }
}

// Tab-Navigation for Main Navigation options
// desktop only
.primary-nav-tabs {
    @include respond-to(decent) {
        @include grid-padding-h;
        max-width: calc(100% - 5em);
        overflow: hidden;
        position: relative;
        @include fade-cover();

        ul {
            display: flex;

            &[aria-hidden="true"] {
                display: none;
            }
        }

        li {
            @include underline-active;

            &:not(:first-child) {
                margin-left: 2.5rem;
            }
        }

        a {
            @include textstyle--teaser;
            white-space: nowrap;
        }
    }

    @include respond-to(grid-l) {
        a {
            html:lang(it) &,
            html:lang(fr) &,
            html:lang(es) & {
                @include textstyle--m;
            }
        }
    }

    @include respond-to(grid-m) {
        li:not(:first-child) {
            margin-left: 1.5rem;
        }

        a {
            @include textstyle--teaser;
            @include textstyle--teaser-xs;

            html:lang(it) &,
            html:lang(fr) &,
            html:lang(es) & {
                @include textstyle--s;
            }
        }
    }
}

// Wrapper for Sub-Links for Main Navigation options
// mobile and desktop
.modal__body--main-menu { // @todo does this belong to modal.scss as a global declaration?
    @include respond-to(decent) {
        overflow-y: hidden;

        &.has-scroll {
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }
    }
}

// Content for Sub-Links for Main Navigation options
// mobile and desktop
.modal__content--main-menu {
    @include respond-to(decent) {
        position: relative;
        width: calc(#{grid-width(grid-l, 8)} + #{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 0.5)});
        background: $back-color--default;
        padding: 0 grid-width-gutter(grid-l, 0.5) 0 grid-margin(grid-l, false);
        min-height: 100vh;
    }

    @include respond-to(grid-m) {
        // grid-m modal should be full width
        width: 100vw;
        padding: 0 grid-margin(grid-m, false);
        margin: 0 auto;
    }
}

// Main Content Sections for navigation modal
// mobile and desktop
.primary-nav-body {
    // Sub-Sections for each Primary Navigation Option: Watches, Manufacture, Service
    > ul {
        @include grid-container();
        margin: 0 auto;

        &[aria-hidden="true"] {
            pointer-events: none;
            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            height: 0;
            overflow: hidden;
            transform: translateX(-100%);

            @include respond-to(grid-s) {
                li {
                    opacity: 0;
                    transform: translate3d(0, 2rem, 0);
                }
            }
        }

        @include respond-to(grid-s) {
            li {
                transition: transform $trans-time--m $trans-func--default,
                opacity $trans-time--m $trans-func--default;
                @include transition-delay(0.05s);
            }
        }

        @include respond-to(decent) {
            width: auto;
        }
    }
}

// Sub-Sections for Tab (Desktop) and Accordion (Mobile) Links
// mobile and desktop
.main-menu__tab {
    padding-bottom: grid-spacing(grid-s, l);

    // Sub-Section Wrapper
    > li {
        position: relative;

        // show a line below
        &:after {
            @include pseudo();
            position: absolute;
            bottom: 0;
            left: 0;
            width: grid-inner-width(grid-s, 8);
            height: 1px;
            margin: 0 grid-padding-h();
            background: $border-color--light-1;
            z-index: 1;
        }

        // Accordion Trigger and Direct Links
        // mobile only
        > div:first-child {
            padding: grid-padding-h();
            padding-right: calc(#{grid-width-gutter(grid-s, 2)} + #{grid-padding-h()});
            background: $back-color--default;
            position: sticky;
            top: calc(#{$menu-height-mobile} - #{grid-padding-h()});
            z-index: 1;

            a {
                @include textstyle--button;
            }
        }
    }

    // Link styles for Accordion and Direct Links
    .accordion__head {
        a {
            @include dropdown-arrow(transparent, false, true);
        }
    }

    .direct-link {
        a {
            &:after {
                content: '›';
                display: inline-block;
                padding-left: 0.35em;
            }
        }
    }

    // Handle Sub-Section display
    // Accordion for mobile
    [aria-expanded="false"] {
        [data-container] {
            height: 0;
            overflow: hidden;
        }

        .accordion__head {
            a {
                @include dropdown-arrow();
            }
        }
    }

    // show loader for accordion head
    [aria-expanded="true"][aria-busy="true"] {
        .accordion__head {
            a {
                position: relative;

                // loading pulse
                &:after {
                    @include loading-pulse(darken($back-color--secondary, 4%), 1.7rem);
                    left: auto;
                    right: -0.2rem;
                }

                // cover arrow
                &:before {
                    @include pseudo();
                    right: -0.2rem;
                    top: 0;
                    bottom: 0;
                    width: 2rem;
                    background: $back-color--default;
                    opacity: 0;
                    animation: animate-element-fade-in 0.25s 0.6s ease-out forwards;
                }
            }
        }
    }

    // Tab Navigation for Desktop
    @include respond-to(decent) {
        padding-bottom: 0;

        > li {
            // hide line
            &:after {
                display: none;
            }
        }

        [aria-expanded="false"] {
            pointer-events: none;
            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            height: 0;
            overflow: hidden;
            transform: translateX(-100%);
        }

        [data-container] {
            height: auto;
            overflow: inherit;
        }
    }
}

// Sub Section Wrapper
.main-menu__section {
    padding-top: grid-spacing(grid-s, s);
    padding-bottom: grid-spacing(grid-s, xl);

    @include respond-to(decent) {
        padding-top: $menu-top-padding-desktop;
        padding-bottom: grid-spacing(grid-l, l);

        @include clearfix();
    }

    @include respond-to(grid-m) {
        padding-bottom: grid-spacing(grid-m, l);
    }

    // style for content if not loaded inside menu modal
    :not([data-container]) > & {
        @include grid-container();
        margin: 0 auto;
    }
}

// Home Link on mobile Nav
// mobile only
.main-menu__img-teasers {
    position: relative;
    transform: translate3d(0, 20em, 0);
    transition: transform 0s $menu-trans-easing-out $menu-trans-timing-out * 1.5;
    display: flex;

    a {
        flex: 1 1 auto;
    }

    .has-main-navi & {
        transform: translate3d(0, 0, 0);
        transition: transform $menu-trans-timing-in * 1.2 $menu-trans-easing-in;
    }

    .media-box {
        height: 18vh; // show it banner-style
        min-height: 12em;

        &:after {
            background: darken($back-color--dark, 20%);
        }

        img {
            transform: scale(1.4);
            transition: transform $menu-trans-timing-out $menu-trans-easing-out;

            .has-main-navi & {
                transform: translate3d(0, 0, 0);
                transition: transform $menu-trans-timing-in * 1.2 $menu-trans-easing-in;
            }
        }
    }

    .text-container {
        position: absolute;
        top: 0;
        left: 0;
        margin: calc(#{grid-margin(grid-s)});
        padding-top: 0.4rem;
        transform: translate3d(0, 0, 0); // fix display bug in iOS 13
    }

    h3 {
        @include textstyle--button;
    }
}

// Tertiary Navigation on mobile Flyout-Menu
// mobile only
.main-menu__tertiary {
    background: $back-color--tertiary;
}

.tertiary-nav {
    @include grid-container;
    margin: 0 auto;
    overflow: hidden; // fix iOS Safari bug where is overflow is calculated too big after transform

    ul {
        width: grid-width(grid-s, 8);
        padding-bottom: grid-spacing(grid-s, xl);
        transform: translate3d(0, 40em, 0);
        transition: transform 0s $menu-trans-easing-out $menu-trans-timing-out * 1.5;

        .has-main-navi & {
            transform: translate3d(0, 0, 0);
            transition: transform $menu-trans-timing-in * 1.5 $menu-trans-easing-in;
        }

        li {
            transform: translate3d(0, 4rem, 0);
            opacity: 0;
            transition: transform 0s $menu-trans-easing-out $menu-trans-timing-out * 1.5,
                        opacity 0s $menu-trans-easing-out $menu-trans-timing-out * 1.5;

            .has-main-navi & {
                transform: translate3d(0, 0, 0);
                opacity: 1;
                transition: transform $menu-trans-timing-in * 1.5 $menu-trans-easing-in,
                            opacity $menu-trans-timing-in * 1.5 $menu-trans-easing-in;
                @include transition-delay(0.075s);
            }
        }
    }

    .hotline {
        padding: grid-spacing(grid-s, m) 0 grid-spacing(grid-s, s);

        h3 {
            line-height: 2;
        }

        .hotline-link {
            @include textstyle--teaser;
            word-spacing: -0.05em;
        }
    }
}

@import 'header/header-content';
@import 'header/header-toggle';
@import 'header/header-invert';
