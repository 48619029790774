// Checkout order Overview Listing
.order-listing,
.collection-listing {
    @include grid-inner-width(10, 8, 6);

    // Common Header/Item/Footer
    .order-listing__header,
    .collection-listing__header {
        padding-bottom: 1.5em;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        border-bottom: solid 1px $border-color--secondary;

        h3 {
            @include textstyle--default;
            @include textstyle--xs;
            @include textstyle--uppercase;
        }
    }

    .account__intro + & {
        @include grid-spacing(m);
    }

    .order-listing__item,
    .collection-listing__item {
        @include grid-spacing(s);
        @include grid-bottom-spacing(s);
        border-bottom: solid 1px $border-color--secondary;
        position: relative;
        display: flex;
        flex-wrap: wrap;

        @include respond-to(decent) {
            flex-wrap: nowrap;
        }
    }

    .order-listing__header__cell,
    .order-listing__item__cell,
    .collection-listing__header__cell,
    .collection-listing__item__cell {
        &:first-child {
            width: 100%;
        }

        &:nth-child(2) {
            flex: 1;
        }

        @include respond-to(grid-l) {
            &:first-child {
                width: grid-width-gutter(grid-l, 3);
            }
        }

        @include respond-to(grid-m) {
            &:first-child {
                width: grid-width-gutter(grid-m, 3.5);
            }
        }
    }

    .order-listing__item__cell__order {
        display: flex;

        h2 {
            @include textstyle--default;
            @include textstyle--s;
            @include textstyle--uppercase;
        }

        * + p {
            margin-top: 0;
            margin-left: 1em;
        }

        @include respond-to(decent) {
            padding-top: 0.4rem;
            padding-right: 1em;

            h2 {
                @include textstyle--m;
                min-width: 10rem;
            }
        }
    }

    .order-listing__item__cell__status {
        padding-top: 0.4rem;
        padding-right: 1em;
    }

    .collection-listing__item__cell {
        padding-top: 0.4rem;
        padding-right: 1em;
    }
}

