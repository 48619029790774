// Nomos table of content

.toc-nav {
    flex: 1 1 auto;

    &:not(.toc-nav--vertical) {
        overflow: hidden;
        margin-left: calc((#{grid-margin(grid-s)} + #{grid-padding-h()}) * -1); // indent to the left viewport site

        // go full width if there are no buttons to the right
        &.toc-nav--without-btn {
            width: 100vw;
            margin-right: calc((#{grid-margin(grid-s)} + #{grid-padding-h()}) * -1); // indent to the right viewport site

            // fade cover on the right as well
            &:before {
                content: '';
                position: absolute;
                z-index: 1;
                pointer-events: none;
                background: linear-gradient(to right, rgba($back-color--default, 0), $back-color--default);
                top: 0;
                bottom: 0;
                width: 1.6em;
                right: calc(#{grid-margin(grid-s)} * -1);
            }
        }
    }

    .touch & {
        // make toc-animation interactable only on touch
        overflow: visible;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        // hide scrollbars
        @include hide-scrollbars();
    }

    // include fade-cover on the left
    // don’t position: relative parent or :after will scroll with the overflow
    /* this does not work as exepcted - therefore leave it for now
    @include fade-cover(left);
    &:after {
        // reset to the real left side
        left: calc(#{grid-margin(grid-s)} * -1);
    }*/

    // Default horizontal TOC
    &:not(.toc-nav--vertical) {
        ul {
            white-space: nowrap; // display flex will prevent ul from being wider than the .toc-nav parent
            padding-left: calc((#{grid-margin(grid-s)} + #{grid-padding-h()})); // compensate for indent

            // force some space after last li
            &:after {
                content: '.';
                color: transparent;
                pointer-events: none;
                display: inline-block;
            }
        }

        li {
            display: inline-block;
            margin: 1rem 1.5rem 1rem + $baseline-offset 0;
            @include underline-active;

            &:last-child {
                margin-right: 3rem;
            }
        }
    }

    // Vertical TOC
    &.toc-nav--vertical {
        @include grid-padding-spacing(m);
        @include grid-bottom-spacing(m);

        ul {
            display: flex;
            flex-direction: column;
        }

        li {
            display: inline-block;

            &:not(:first-child):not(.toc-nav__group-spacer) {
                @include grid-spacing(xs);

                &.toc-nav__group-title {
                    @include grid-spacing(s);
                }
            }

            &.toc-nav__grouped-item {
                @include grid-padding-h;
            }

            &.toc-nav__group-spacer {
                height: 0.7em;
            }
        }

        a {
            border-bottom: solid 1px $border-color--secondary;
            transition: border $trans-time--s $trans-func--default;


            @include hover {
                border-color: $border-color--default;
            }
        }
    }

    .toc-link-buy {
        display: none;
    }

    a {
        @include textstyle--button-s;
    }

    @include respond-to(decent) {
        &:not(.toc-nav--vertical) {
            margin-left: calc((#{grid-margin(grid-l)} + #{grid-padding-h(grid-l)}) * -1);

            // go full width if there are no buttons to the right
            &.toc-nav--without-btn {
                margin-right: calc((#{grid-margin(grid-l)} + #{grid-padding-h(grid-l)}) * -1); // indent to the right viewport site

                // fade cover on the right as well
                &:before {
                    width: 4em;
                    right: calc(#{grid-margin(grid-l)} * -1);
                }
            }

            ul {
                padding-left: calc((#{grid-margin(grid-l)} + #{grid-padding-h(grid-l)})); // compensate for indent
            }

            li {
                margin-right: 2rem;

                &:last-child {
                    margin-right: 2rem;
                }
            }

            // adjust indent for modal use
            .modal & {
                margin-left: calc((#{grid-width-gutter(grid-l, 0.5)} + #{grid-padding-h(grid-l)}) * -1);

                ul {
                    padding-left: calc(#{grid-width-gutter(grid-l, 0.5)} + #{grid-padding-h(grid-l)}); // compensate for indent
                }
            }

        }

        a {
            @include textstyle--button;
        }
    }

    @include respond-to(grid-m) {
        &:not(.toc-nav--vertical) {
            margin-left: calc((#{grid-margin(grid-m)} + #{grid-padding-h(grid-m)}) * -1);

            // go full width if there are no buttons to the right
            &.toc-nav--without-btn {
                margin-right: calc((#{grid-margin(grid-m)} + #{grid-padding-h(grid-m)}) * -1); // indent to the right viewport site

                // fade cover on the right as well
                &:before {
                    right: calc(#{grid-margin(grid-m)} * -1);
                }
            }

            ul {
                padding-left: calc((#{grid-margin(grid-m)} + #{grid-padding-h(grid-m)})); // compensate for indent
            }

            // adjust indent for modal use
            .modal & {
                margin-left: calc((#{grid-width-gutter(grid-m, 0.5)} + #{grid-padding-h(grid-m)}) * -1);

                ul {
                    padding-left: calc(#{grid-width-gutter(grid-m, 0.5)} + #{grid-padding-h(grid-m)}); // compensate for indent
                }
            }
        }
    }
}
