// Nomos strap advisor

.strap-advisor-step,
.services-step {
    // devider lines - hr renders better than :before, which sometimes gets missing
    hr {
        @include grid-inner-width(10, 10, 10);
        margin: 0 auto;
        border: none;
        border-top: 1px solid $border-color--light-1;
        @include grid-bottom-spacing(s);
    }

    // take care of section titles
    .section-title {
        h4 span {
            @include respond-to(decent) {
                min-width: 2.5rem;
                display: inline-block;
            }
        }
    }

    &:not(:first-child) {
        .step-head > .section-title {
            transition: opacity $trans-time--l $trans-func--default;
            margin-top: 0;
            padding-top: 0;
        }
    }

    // handle step bodys
    .step-body {
        @include grid-bottom-spacing(l);
    }

    &[aria-expanded="false"] {
        .step-head > .section-title {
            opacity: $fade-disable;
        }

        .step-body {
            height: 0;
            padding: 0;
            overflow: hidden;
        }
    }
}

.watch-select-wrapper {
    @include grid-spacing(m);
    overflow: hidden;
    position: relative;
    margin-bottom: calc(-3.6em - 2px); // pull up grid-bottom-spacing(l) and cover hr

    // initial loading from url
    // give height to prevent markup jumping while loading
    .is-watch-listing-loading &,
    &[aria-hidden="false"]:empty {
        min-height: 47em;

        // show loading pulse while loading
        &:after {
            @include loading-pulse();
        }

        @include respond-to(grid-l) {
            min-height: 68em;

            // center spinner above watch
            &:not(:empty) {
                &:after {
                    left: calc(50% - 1rem - #{grid-width-gutter(grid-l) / 2});
                }
            }
        }

        @include respond-to(xl) {
            min-height: 56em;
        }

        @include respond-to(grid-m) {
            min-height: 58em;

            // center spinner above watch
            &:not(:empty) {
                &:after {
                    left: calc(50% - 1rem - #{grid-width-gutter(grid-m) / 2});
                }
            }
        }

        // fade grid container on load
        .grid-container {
            opacity: 0.3;
            transition: opacity $trans-time--m $trans-func--default;
        }
    }

    // dont show before model family was choosen
    &[aria-hidden="true"] {
        body:not(.is-watch-listing-loading) & {
            height: 0;
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    // give min-height even if no slideshow is loaded
    &[aria-hidden="false"] {
        min-height: 10rem;

        @include respond-to(decent) {
            min-height: 20em;
        }
    }

    .section-title {
        @include grid-spacing(s);
        overflow: hidden;
        transition: margin $trans-time--m $trans-func--default,
                    opacity $trans-time--l $trans-func--default;

        // hide if model was selected
        &[aria-hidden="true"] {
            opacity: 0;
            pointer-events: none;
            user-select: none;
            margin-bottom: -6em; // pull up slideshow

            transition: margin $trans-time--m $trans-func--easing-out,
            opacity $trans-time--s $trans-func--default;
        }

        // watch and count / desktop only
        i {
            span:not(:first-child) {
                margin-left: 0.5em;
            }
        }
    }

    .slideshow {
        @include grid-bottom-spacing(m);
        margin-bottom: 0;
    }

    @include respond-to(grid-l) {
        margin-bottom: calc(-8em - 2px); // pull up grid-bottom-spacing(l) and cover hr

        .section-title {
            &[aria-hidden="true"] {
                margin-bottom: -12em; // pull up slideshow
            }
        }
    }

    @include respond-to(grid-m) {
        margin-bottom: calc(-4.8em - 2px); // pull up grid-bottom-spacing(l) and cover hr

        .section-title {
            &[aria-hidden="true"] {
                margin-bottom: -10em; // pull up slideshow
            }
        }
    }
}

// step 1
.step-1 {
    z-index: 1; // cover hr from step-2
    position: relative;
}

// step 2
.step-2 {
    min-height: 1px;

    &[aria-expanded="false"] {
        // step loading
        .is-circumference-listing-loading & {
            min-height: 24em;
            position: relative;
            transition: min-height $trans-time--l $trans-func--easing-out;

            // show loading pulse while loading
            &:after {
                @include loading-pulse();
            }
        }
    }
}

.wrist-box {
    .plain-label {
        min-height: 2rem;

        .is-circumference-listing-loading & {
            transition: opacity $trans-time--m $trans-func--default;
            opacity: 0;
        }
    }
}

.fit-choice-box {
    > * {
        transition: opacity $trans-time--m $trans-func--default,
        transform $trans-time--m $trans-func--default;
        @include transition-delay();
    }

    &[aria-hidden="true"] {
        margin: 0;
        padding: 0;
        height: 0;
        overflow: hidden;

        > * {
            opacity: 0;
            margin: 0;
            transform: translate3d(0, 4rem, 0);
        }
    }

    .form-grid__section {
        margin-top: 0;
    }

    @include respond-to(s--m) {
        float: left !important;
    }

    @include respond-to(decent) {
        .option-group {
            margin-top: -3rem; // align visually
        }
    }

    @include respond-to(grid-l) {
        position: relative;
        margin-right: grid-width-gutter(grid-l);

        &[aria-hidden="true"] {
            > * {
                transform: translate3d(-4rem, 0, 0);
            }
        }

        // create arrow icon
        // cannot be a pseudo element because of intersect animations
        .next-step-icon {
            position: absolute;
            top: 2.5rem;
            left: calc((#{grid-width-gutter(grid-l, 0.5)} + 4rem) * -1);
            height: 8rem;
            width: 8rem;
            background: svg-inline(icon--nom-next-step) center center no-repeat;
        }

    }
}

.show-straps-box {
    clear: both;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: opacity $trans-time--m $trans-func--default,
                transform $trans-time--m $trans-func--default;

    &[aria-hidden="true"] {
        opacity: 0;
        height: 0;
        overflow: hidden;
        transform: translate3d(0, 2rem, 0);
    }
}

// step 3
.step-3 {
    min-height: 1px;

    &[aria-expanded="false"] {
        .section-title {
            i {
                display: none;
            }
        }

        .is-strap-listing-loading & {
            min-height: 24em;
            position: relative;
            transition: min-height $trans-time--l $trans-func--easing-out;

            // show loading pulse while loading
            &:after {
                @include loading-pulse();
            }
        }
    }
}

.step-result {
    .section-title {
        @include grid-spacing(s);

        i + .head-wrapper {
            margin-top: 0;
        }
    }

    & + & {
        .section-title {
            @include grid-spacing(xl);
        }
    }
}

.metall-hint-box {
    margin-top: grid-spacing(grid-s, s);
    margin-bottom: grid-spacing(grid-s, s);
    @include grid-width(10, 5, 4);

    @include respond-to(grid-l) {
        margin-top: grid-spacing(grid-l, xl);
        margin-right: grid-width(grid-l, 1, false, true);
        margin-left: auto;

        p {
            @include textstyle--l;
        }
    }

    @include respond-to(grid-m) {
        margin-top: grid-spacing(grid-m, xxl);
        margin-right: grid-width(grid-m, 1);
    }
}

