// Nomos sorting

// sorting
.sort-selection {
    position: relative;

    label {
        @include textstyle--default;
    }

    // handle sorting select for mobile
    // no mobile first to make it less complicated
    @include respond-to(grid-s) {
        // Style label like a button
        label {
            @include textstyle--button-s;
            @include dropdown-arrow();
            padding-right: 1.2em;
            display: inline-block;
            line-height: calc(#{$button-height} + 2px); // take care of top aligned dropdown
        }

        // hide actual select field on mobile
        // browser select field will be hidden by select2 if js is enabled
        select {
            position: absolute;
            top: 0;
            left: 0;
            color: transparent; // don't show content;
            padding-right: 0;
            background: none;
            height: 100%;
            line-height: 1;
        }

        // select2 styles
        .select2-container {
            position: absolute;
            top: 0;
            left: 0;
            height: calc(#{$button-height} + 2px); // take care of top aligned dropdown
        }

        .select2-selection__rendered {
            color: transparent;
        }

        .select2-selection__arrow {
            display: none;
        }

    }

    @include respond-to(decent) {
        transition: opacity $trans-time--s $trans-func--default;
        display: flex;
        align-items: center;
        min-width: 30em;

        .has-filter & {
            opacity: 0;
        }

        label {
            padding-right: 0.5em;
        }

        select {
            width: auto;
            text-align: right;
            direction: rtl;
            cursor: pointer;
        }

        .select2-selection__rendered {
            @include textstyle--button;
            text-align: right;

            span {
                @include textstyle--default;
            }
        }
    }
}

// select wrapper for sorting options
// needs to sit on root to have sorting dropdown on top of active-filters
.select2-wrapper {
    position: relative; // IE fallback
    position: sticky;
    top: calc(#{$action-bar-height-mobile} - 1px);
    z-index: map-get($z-index, select-dropdown);


    @include respond-to(decent) {
        top: calc(#{$action-bar-height-desktop} - 1px);
    }

    // Fix positioning for IE
    .ie.no-edge & {
        top: auto;
    }
}
