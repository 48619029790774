// Active filters

// show active filters
.active-filters {
    position: relative; // IE fallback
    position: sticky;
    top: calc(#{$action-bar-height-mobile} - 2px);
    background: $back-color--primary;
    z-index: map-get($z-index, action-bar);
    padding-top: 0.4em;
    padding-bottom: 1em;
    min-height: calc(#{$button-height-s} + 1.4em);
    transition: transform $trans-time--m $trans-func--default,
                opacity $trans-time--m $trans-func--default,
                margin $trans-time--m $trans-func--easing-out;

    i {
        position: absolute; // take out of flex flow
    }

    // hide if not filtered yet
    body:not(.is-filtered) & {
        opacity: 0;
        pointer-events: none;
        margin-bottom: calc((#{$button-height-s} + 1.4em) * -1); // prevent jumps after appearing
    }

    @include respond-to(decent) {
        margin-top: -2em;
        top: calc(#{$action-bar-height-desktop} - 2px - 2em);
        padding-bottom: 1.2em;
        float: right;
        transition: margin $trans-time--m $trans-func--easing-out;
        width: grid-width(grid-l, 7.5) - grid-gutter-width(grid-l) / 2;

        // hide if not filtered yet
        body:not(.is-filtered) & {
            margin-bottom: calc((#{$button-height-s} * -1) - 0.8em); // prevent jumps after appearing
        }

        i {
            position: relative;
            white-space: nowrap;
            padding-left: 4rem;

            // take up the remaining space to align to the right side
            text-align: right;
            flex-grow: 1;
        }
    }

    @include respond-to(grid-m) {
        float: none;
        width: auto;
    }
}

.active-filters__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
    height: 100%;
    @include grid-padding-h;
    flex-wrap: nowrap;
    position: relative;

    @include respond-to(grid-s) {
        padding-right: 0;
    }
}

// Hide active filter list if header is shown after scrolling up
.is-scrolled-up-much {
    .is-stuck ~ .store-listing .active-filters {
        opacity: 0;
        transform: translate3d(0, -30%, 0);
        transition: transform $trans-time--m $trans-func--easing-out,
                    opacity $trans-time--m $trans-func--easing-out;
    }
}

// Filter list
.active-filters__list {
    overflow: hidden;
    margin-left: calc((#{grid-margin(grid-s)} + #{grid-padding-h()}) * -1); // indent to the left viewport site
    flex: 1 1 auto;

    .touch & {
        // make filter-list-overflow interactable only on touch
        overflow: visible;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        // hide scrollbars
        @include hide-scrollbars();
    }

    // include fade-cover on the left
    // don’t position: relative parent or :after will scroll with the overflow
    @include fade-cover(left);

    &:after {
        // reset fade-cover to the real left side
        left: calc(#{grid-margin(grid-s)} * -1);
    }


    @include respond-to(grid-s) {
        .tag {
            margin-top: 0;
            margin-bottom: 0;

            &:last-child {
                margin-right: 1rem;
            }
        }
    }

    @include respond-to(decent) {
        margin-left: grid-padding-h(grid-l) * -1; // indent to the left viewport site
        flex: 0 1 auto;

        &:after {
            // reset fade-cover to the real left side
            left: 0;
        }

        .tag {
            &:last-child {
                margin-right: 0.8em;
            }
        }
    }

    @include respond-to(grid-m) {
        margin-left: calc((#{grid-margin(grid-m)} + #{grid-padding-h(grid-m)}) * -1); // indent to the left viewport site

        &:after {
            // reset fade-cover to the real left side
            left: calc(#{grid-margin(grid-m)} * -1);
        }
    }
}

.active-filters__list-inner {
    white-space: nowrap; // display flex will prevent ul from being wider than the .toc-nav parent
    padding-left: calc((#{grid-margin(grid-s)} + #{grid-padding-h()})); // compensate for indent

    @include respond-to(decent) {
        padding-left: grid-padding-h(grid-l); // compensate for indent

        .no-touch & {
            overflow: hidden;
        }
    }

    @include respond-to(grid-m) {
        padding-left: calc((#{grid-margin(grid-m)} + #{grid-padding-h(grid-m)})); // compensate for indent
    }
}

.active-filters__reset {
    padding-left: 1.5em;
    padding-right: grid-padding();
    position: relative;

    &:before {
        @include pseudo();
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 1px;
        background: $border-color--secondary;
    }

    @include respond-to(decent) {
        padding-right: 0;

        .btn {
            white-space: nowrap;
            padding: 1rem 0;
        }

        &:before {
            background: linear-gradient(to right, rgba($back-color--default, 0), $back-color--default);
            top: 0;
            width: 1.5em;
            left: -1.5em;
        }
    }
}