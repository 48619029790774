// Search bar
.search-nav {
    @include grid-padding-h;

    @include respond-to(grid-s) {
        opacity: 0;
        // transition: opacity $menu-trans-timing-out $menu-trans-easing-out; // cannot see it anyway, so why bother?

        .has-main-navi & {
            opacity: 1;
            // transition: transform $menu-trans-timing-in $menu-trans-easing-in;
        }
    }

    @include respond-to(decent) {
        padding: 0;
    }
}
