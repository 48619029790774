/* styleguide stuff */


.section-title {
    .page-styleguide .grid-container > & {
        border-top: 1px solid $border-color--light-1;

        & + * {
            @include grid-spacing(l);
        }

        @include respond-to(grid-l) {
            padding-top: $action-bar-height-desktop;
        }
    }
}

[data-style-info] {
    @include show-style-info();
}

// fontsizes
.styleguide-box {
    min-height: 10em;
    @include grid-padding();
    background: $back-color--tertiary;

    @include respond-to(decent) {
        min-height: 34em;
    }

    div {
        &:not(:first-child) {
            @include grid-spacing(xs);
        }
    }

    // Gotham Light
    &.font-regular {
        h1 {
            @include textstyle--teaser;

            @include respond-to(grid-l) {
                @include textstyle--teaser-xl;
            }

            @include respond-to(grid-m) {
                @include textstyle--teaser-l;
            }
        }

        h2 {
            @include textstyle--teaser-xs;

            @include respond-to(grid-l) {
                @include textstyle--teaser-l;
            }

            @include respond-to(grid-m) {
                @include textstyle--teaser-m;
            }
        }

        h3 {
            @include respond-to(grid-s) {
                display: none;
            }

            @include respond-to(grid-l) {
                @include textstyle--teaser-m;
            }

            @include respond-to(grid-m) {
                @include textstyle--teaser;
            }
        }

        h4 {
            @include respond-to(grid-s) {
                display: none;
            }

            @include respond-to(grid-l) {
                @include textstyle--teaser;
            }

            @include respond-to(grid-m) {
                @include textstyle--teaser-xs;
            }
        }
    }

    // Gotham narrow
    &.font-narrow {
        h1 {
            @include textstyle--default;
            @include textstyle--l;
        }

        h2 {
            @include textstyle--default;
            @include textstyle--m;
        }

        h3 {
            @include textstyle--default;
            @include textstyle--s;
        }

        h4 {
            @include textstyle--default;
            @include textstyle--xs;
        }
    }

    // Gotham narrow
    &.font-extralight {
        h1 {
            @include textstyle--quote;

            @include respond-to(grid-l) {
                font-size: $fontsize--quote-l;
            }
        }
    }

    &,
    &.font-regular,
    &.font-narrow,
    &.font-extralight {
        h1,
        h2,
        h3,
        h4,
        p {
            margin: 0 0 0.1em;

            @include respond-to(decent) {
                display: inline-block;
            }

            // reset debug info
            &:before {
                display: inline-block;
                color: inherit;
                font-size: 1em;
                letter-spacing: 0;
                background: transparent;
                position: relative;
                left: auto;
                bottom: auto;
                padding: 0 0 0 0.3em;
                float: right;
                transform: translate3d(0, 0, 0);
                z-index: 1;
            }
        }
    }
}

