// Group of products
.product-group {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%; // fix for flex and float parents

    &.product-group--with-box {
        justify-content: space-between;
        align-items: center;

        @include respond-to(grid-m) {
            .box-m {
                width: grid-width(grid-m, 4);
                @include ratio-box($square-ratio);
            }
        }
    }

    &.product-group--teaser {
        @include respond-to(grid-s) {
            // too much of an effort to make this mobile-first
            // works without these declarations as well
            .teaser--product {
                @include product-crop;
            }
        }

        @include respond-to(decent) {
            // Animate teaser as watch sidebar comes in
            .teaser {
                transform: translate3d(0, 0, 0);
                transition: transform $trans-time--m $trans-func--default,
                            opacity $trans-time--m $trans-func--default;
            }

            // translate and fade if inside main content
            .has-sidebar .ajax-container & {
                .teaser {
                    transform: translate3d(-8em, 0, 0);
                    opacity: 0;
                }

                .teaser:nth-child(1) {
                    transform: translate3d(-16em, 0, 0);
                }

                .teaser:nth-child(2) {
                    transform: translate3d(-12em, 0, 0);
                }
            }
        }

        @include respond-to(grid-m) {
            .teaser--product {
                width: grid-width(grid-m, 3);

                &:nth-child(2) {
                    margin-right: grid-gutter-width(grid-m);
                }
            }
        }
    }

    &.product-group--wishlist {
        align-items: normal;
    }

    &.product-group--store {
        align-items: stretch;
    }
}

// move product-box items to right if inside main content
.product-group--right {
    .ajax-content & {
        @include respond-to(grid-l) {
            padding-left: grid-width-gutter(grid-l, 1);
        }

        @include respond-to(grid-m) {
            padding-left: grid-width-gutter(grid-m, 2);
        }
    }
}

// Product group action / For example Show all models
.product-group-action {
    @include grid-width(10, 8, 9);
    display: block;
    margin-top: -13em;
    padding-top: 11em;
    position: relative;
    cursor: pointer;

    > div {
        background: $back-color--primary;
        position: relative;
        text-align: center;
        @include grid-padding;
        transition: transform $trans-time--m $trans-func--default;
        transform: translate3d(0, 0, 0);

        &:after {
            @include pseudo();
            top: 0;
            left: 5%;
            right: 5%;
            border-top: 1px solid $border-color--default;
        }
    }

    .btn {
        transition: transform $trans-time--m $trans-func--default;
    }

    .product-group--store + & {
        > div {
            &:after {
                border-color: darken($border-color--secondary, 5);
                mask-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
                left: 0;
                right: 0;
            }
        }
    }

    @include hover {
        > div {
            transition: transform $trans-time--xs $trans-func--default;
            transform: translate3d(0, 2em, 0);
        }

        .btn {
            transition: transform $trans-time--xs $trans-func--default;
            transform: translateY(-15%);
        }
    }

    @include respond-to(grid-l) {
        margin-top: -27em;
        padding-top: 25em;

        .product-group--store + & {
            width: grid-width(grid-l, 7.5) - grid-gutter-width(grid-l) / 2;
            margin-top: -20em;
            padding-top: 16em;
        }
    }

    @include respond-to(grid-m) {
        margin-top: -23em;
        padding-top: 20em;

        .product-group--store + & {
            width: 100%;
            margin-top: -18em;
            padding-top: 14em;
        }
    }
}

// Hide action based on product-group state
.product-group[data-expanded-mobile="true"] + .product-group-action {
    @include respond-to(mobile) {
        display: none;
    }
}

.product-group[data-expanded-decent="true"] + .product-group-action {
    @include respond-to(grid-l) {
        display: none;
    }
}

.product-group[data-expanded-tablet="true"] + .product-group-action {
    @include respond-to(grid-m) {
        display: none;
    }
}
