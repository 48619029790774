// Responsive stuff
.debug-options {
    display: none;

    position: fixed;
    right: 0;
    bottom: 0;
    padding: 2rem;
    z-index: 3000;
    opacity: 1;
    transition: opacity $trans-time--m $trans-func--default, transform $trans-time--m $trans-func--default;;
    transform: translate3d(77%, 0, 0);

    &:hover {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    &:before {
        @include textstyle--s;

        @include respond-to(xl) {
            content: "XL";
        }

        @include respond-to(l) {
            content: "L";
            color: orange;
        }

        @include respond-to(m) {
            content: "M";
            color: red;
        }

        @include respond-to(s) {
            content: "S";
            color: red;
        }
    }

    &:after {
        content: "Breakpoint nicht optimiert";
        position: absolute;
        top: -20em;
        left: calc(-100vw + 14em);
        width: 14em;
        height: 14em;
        text-align: center;
        @include textstyle--s;
        line-height: 13em;
        background: white;
        border-radius: 100%;
        color: $text-color--primary;

        @include respond-to(xl) {
            display: none;
        }

        @include respond-to(l) {
            display: none;
        }
    }

    .advanced-checkbox + label {
        display: inline-block;
    }
}
