// Sepcial container Super 31

// special .product-group
.super-31 {
    --_super-31-grid-cols: 2;
    --_super-31-column-gap: #{grid-gutter-width()};
    --_super-31-row-gap: #{grid-gutter-width()};
    --_super-31-reveal-delay: 0s;

    @include grid-spacing(m);
    display: grid; // overrule .product-group display: flex for better handling
    grid-template-columns: repeat(var(--_super-31-grid-cols), minmax(0, 1fr));
    column-gap: var(--_super-31-column-gap);
    row-gap: var(--_super-31-row-gap);


    @include respond-to(grid-l) {
        --_super-31-grid-cols: 5;
        --_super-31-column-gap: #{grid-gutter-width(grid-l)};
        --_super-31-row-gap: #{grid-gutter-width(grid-l)};
    }

    @include respond-to(grid-m) {
        --_super-31-grid-cols: 3;
        --_super-31-column-gap: #{grid-gutter-width(grid-m)};
        --_super-31-row-gap: #{grid-gutter-width(grid-m)};
    }
}

// Text teaser
.super-31__box {
    padding: 4em 0 6em;
    text-align: center;
    grid-column: span 2 / span 2;
    grid-row-start: 3;

    p {
        @include textstyle--teaser();
        @include textstyle--teaser-xs();
    }

    @include respond-to(decent) {
        padding-top: 10em;
        grid-column: span 1 / span 1;
        grid-row-start: 3;
        grid-column-start: 3;
    }

    @include respond-to(grid-m) {
        grid-column-start: 2;
    }
}

.super-31__switch {
    margin-top: 0.6em;

    .touch & {
        display: none;
    }

    span:last-child {
        display: none;
    }

    .has-revealed-all & {
        span:first-child {
            display: none;
        }

        span:last-child {
            display: block;
        }
    }
}

// overrule teaser settings for display in grid
.super-31__teaser {
    width: 100%;
    margin-right: 0 !important;
    margin-top: 0 !important;

    .price--default {
        display: none; // hide price
    }
}

$_reveal-time: 0.6s;

// flip animation for teaser
.super-31__teaser--flip {
    perspective: 200rem;

    // Initial states
    .super-31__media-box-wrapper {
        overflow: initial; // fix mobile render problem
        transform: translate3d(0, 0, 0); // assuming to fix old safari
        transform-style: preserve-3d;
        transition: transform $_reveal-time $trans-func--default var(--_super-31-reveal-delay);
    }

    .super-31__color-cover {
        transition: opacity $_reveal-time * 0.4 $trans-func--default calc(#{$_reveal-time * 0.2} + var(--_super-31-reveal-delay)),
                    transform $_reveal-time $trans-func--default var(--_super-31-reveal-delay);
    }

    .super-31__color {
        transition: transform $_reveal-time $trans-func--default var(--_super-31-reveal-delay);
    }

    // Initially flip watch media
    // and remove other animations
    .media-box {
        transition: transform $_reveal-time $trans-func--default var(--_super-31-reveal-delay) !important;
        transform: rotateY(180deg) translate3d(0, 0, 0) scale(0.8) !important;
        backface-visibility: hidden;
    }

    // Animated state
    @mixin super-31-revealed {
        .super-31__media-box-wrapper {
            transform: rotateY(180deg) translate3d(0, 0, 0);
        }

        .media-box {
            transform: rotateY(180deg) translate3d(0, 0, 0) !important;
        }

        .super-31__color-cover {
            opacity: 0;
            transform: scale(1.05);
        }

        .super-31__color {
            &:first-child:not(:only-child) {
                transform: scaleX(12);
            }

            &:nth-child(3) {
                transform: scaleX(2);
            }
        }
    }

    // Start unflipped before coming into viewport if intersection observer is available
    .no-touch.intersection-observer & {
        &:not(.intersect-inview):not(.intersect-inview--before) {
            @include super-31-revealed();
        }
    }

    // Reveal if reaching center of viewport on touch
    .touch & {
        &.intersect-inview,
        &.intersect-outofview--top {
            @include super-31-revealed();

            &:nth-child(odd) {
                --_super-31-reveal-delay: 0.15s;
            }

            @include respond-to(grid-l) {
                @for $i from 1 through 5 {
                    &:nth-child(5n+#{$i}) {
                        --_super-31-reveal-delay: #{$i * 0.08s};
                    }
                }

                &:nth-child(6),
                &:nth-child(11) {
                    --_super-31-reveal-delay: #{6 * 0.08s};
                }
            }

            @include respond-to(grid-m) {
                @for $i from 1 through 3 {
                    &:nth-child(3n+#{$i}) {
                        --_super-31-reveal-delay: #{$i * 0.08s};
                    }
                }

                &:nth-child(4),
                &:nth-child(7) {
                    --_super-31-reveal-delay: #{4 * 0.08s};
                }
            }
        }
    }

    // flipping card animation triggered via js
    &.is-revealed {
        @include super-31-revealed();
        --_super-31-reveal-delay: 0s;
    }

    // Show all on toggle button
    .has-revealed-all & {
        @include super-31-revealed();

        @include respond-to(grid-l) {
            // Add some delays starting from the text-box
            @for $i from 0 through 10 {
                &:nth-child(#{$i + 2}) {
                    --_super-31-reveal-delay: #{0.33s - $i * 0.03s};
                }
            }

            @for $i from 0 through 20 {
                &:nth-child(#{$i + 12}) {
                    --_super-31-reveal-delay: #{$i * 0.03s};
                }
            }
        }
    }
}

// ALTERNATE ANIMATION
// reveal animation for teaser
// .super-31__teaser--reveal {
//
//     // Initial states
//     .super-31__media-box-wrapper {
//         overflow: initial; // fix mobile render problem
//         transition: transform $_reveal-time $trans-func--default var(--_super-31-reveal-delay);
//     }
//
//     .super-31__color-cover {
//         transition: opacity $_reveal-time * 0.6 $trans-func--default calc(#{$_reveal-time * 0.2} + var(--_super-31-reveal-delay)),
//                     border-radius $_reveal-time * 0.6 $trans-func--default var(--_super-31-reveal-delay),
//                     transform $_reveal-time $trans-func--default var(--_super-31-reveal-delay);
//     }
//
//     .super-31__color {
//         transition: transform $_reveal-time * 0.6 $trans-func--default var(--_super-31-reveal-delay),
//                     opacity $_reveal-time * 0.6 $trans-func--default var(--_super-31-reveal-delay);
//     }
//
//     // Initially flip watch media
//     // and remove other animations
//     .media-box {
//         opacity: 0;
//         transform: translate3d(0, 0, 0) scale(1.1) !important;
//         transition: transform $_reveal-time $trans-func--default var(--_super-31-reveal-delay),
//                     opacity $_reveal-time * 0.6 $trans-func--default calc(#{$_reveal-time * 0.2} + var(--_super-31-reveal-delay)) !important;
//     }
//
//     // Animated state
//     @mixin super-31-revealed {
//         .media-box {
//             opacity: 1;
//             transform: translate3d(0, 0, 0) !important;
//         }
//
//         .super-31__color-cover {
//             opacity: 0;
//             border-radius: 100%;
//             transform: scale(0.4) rotate(-10deg);
//             transition: opacity $_reveal-time * 0.6 $trans-func--default calc(#{$_reveal-time * 0.2} + var(--_super-31-reveal-delay)),
//                         border-radius $_reveal-time * 0.6 $trans-func--default var(--_super-31-reveal-delay),
//                         transform $_reveal-time $trans-func--default var(--_super-31-reveal-delay);
//         }
//
//         .super-31__color {
//             &:first-child:not(:only-child) {
//                 opacity: 0;
//                 transform: scaleX(12);
//             }
//
//             &:nth-child(3) {
//                 opacity: 0;
//                 transform: scaleX(2);
//             }
//         }
//     }
//
//     // Reveal if reaching center of viewport on touch
//     .touch & {
//         &.intersect-inview,
//         &.intersect-outofview--top {
//             @include super-31-revealed();
//
//             &:nth-child(odd) {
//                 --_super-31-reveal-delay: 0.15s;
//             }
//
//             @include respond-to(grid-l) {
//                 @for $i from 1 through 5 {
//                     &:nth-child(5n+#{$i}) {
//                         --_super-31-reveal-delay: #{$i * 0.08s};
//                     }
//                 }
//             }
//
//             @include respond-to(grid-m) {
//                 @for $i from 1 through 3 {
//                     &:nth-child(3n+#{$i}) {
//                         --_super-31-reveal-delay: #{$i * 0.08s};
//                     }
//                 }
//             }
//         }
//     }
//
//     // flipping card animation triggered via js
//     &.is-revealed {
//         @include super-31-revealed();
//         --_super-31-reveal-delay: 0s;
//     }
//
//     // Show all on toggle button
//     .has-revealed-all & {
//         @include super-31-revealed();
//
//         @include respond-to(grid-l) {
//             // Add some delays starting from the text-box
//             @for $i from 0 through 10 {
//                 &:nth-child(#{$i + 2}) {
//                     --_super-31-reveal-delay: #{0.33s - $i * 0.03s};
//                 }
//             }
//
//             @for $i from 0 through 20 {
//                 &:nth-child(#{$i + 12}) {
//                     --_super-31-reveal-delay: #{$i * 0.03s};
//                 }
//             }
//         }
//     }
// }

.super-31__media-box-wrapper {
    // stack default .media-box and .super-31__colors on top of each other
    display: grid;

    > * {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
}

.super-31__color-cover {
    --_super-31-box-m: #{grid-padding-h(grid-s)};
    z-index: 2;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
    margin: var(--_super-31-box-m);

    @include respond-to(grid-l) {
        --_super-31-box-m: #{grid-padding-h(grid-l)};
    }

    @include respond-to(grid-m) {
        --_super-31-box-m: #{grid-padding-h(grid-m)};
    }

}

.super-31__color {
    width: 100%;
    height: 100%;
    background-color: var(--super-31-color, #eae8df);

    &:first-child:not(:only-child),
    &:nth-child(3) {
        width: 1.5em;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
    }

    &:nth-child(3) {
        right: 0;
        left: auto;
    }
}