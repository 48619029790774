// Logo in main header
.main-logo {
    height: $logo-height-mobile;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $border-color--light-2;
    transform-origin: left center;

    @include respond-to(grid-s) {
        // hide logo if filter is shown
        transition: opacity $menu-trans-timing-in $menu-trans-easing-in;

        .has-filter & {
            transition: opacity $menu-trans-timing-in $menu-trans-easing-in,
                        visibility 0s linear $menu-trans-timing-in;
            opacity: 0;
            visibility: hidden;
        }
    }

    @include respond-to(decent) {
        height: $menu-height-desktop;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        border-bottom: none;
    }

    @include respond-to(grid-m) {
        left: grid-padding-h(grid-m);
        transform: none;

        .svg-ico {
            margin-bottom: 0.3rem;
        }
    }
}
