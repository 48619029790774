// Nomos action buttons
@mixin hide-action-button {
    visibility: hidden;
    transform: scale(1.2);
    height: 0;
    width: 0;
    padding: 0 !important;
    margin: 0 !important;
    pointer-events: none;
}

.action-button {
    z-index: map-get($z-index, action-button);
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding-bottom: 1rem; // make room for shadow
    margin-top: -1rem; // pullup following elements to cover shadow of previous element
    user-select: none;

    &:active {
        outline: none;
    }

    .btn {
        width: $action-button-size;
        height: $action-button-size;
        background: $back-color--default;
        position: relative;
        box-shadow: $box-shadow--s;
        border-top: 1px solid $border-color--light-1;
    }

    // tackle first shown element
    &[aria-hidden="false"]:first-child,
    [aria-hidden="true"]:first-child + &,
    [aria-hidden="true"]:first-child + [aria-hidden="true"] + &,
    [aria-hidden="true"]:first-child + [aria-hidden="true"] + [aria-hidden="true"] + & {
        padding-top: 1rem; // make room for shadow
        margin-top: 0;

        // dont show top border for first element
        .btn {
            border-top: none;
        }
    }

    &[aria-hidden="true"] {
        @include hide-action-button;
    }

    &[aria-hidden="false"] {
        transition: transform $trans-time--s $trans-func--default;
    }

    @include respond-to(decent) {
        align-items: center;
        height: 100%;
        padding-bottom: 0;
        padding-left: 1rem;
        margin-top: 0;

        &:not(:first-child) {
            margin-right: -1rem;
        }

        .btn {
            width: auto;
            display: flex;
            padding-top: 0;
            padding-bottom: 0;
            border-top: none;
            border-right: 1px solid $border-color--light-1;

            > span:not(.svg-ico) {
                width: auto;
            }
        }

        .btn-label {
            @include textstyle--default;
            @include textstyle--button;
            padding-left: 0.8rem;
            line-height: $action-button-size;
            user-select: none;
        }

        // tackle first shown element
        &[aria-hidden="false"]:first-child,
        [aria-hidden="true"]:first-child + &,
        [aria-hidden="true"]:first-child + .hidden-on-desktop + &,
        [aria-hidden="true"]:first-child + .hidden-on-desktop + [aria-hidden="true"] + & {
            padding-top: 0;
            padding-right: 1rem; // make room for shadow
            margin-right: 0;

            // dont show right border for first element
            .btn {
                border-right: none;
            }
        }

        [aria-hidden="false"]:not(.hidden-on-desktop) ~ & {
            .btn {
            }
        }
    }
}

.action-button__count {
    position: absolute;
    top: 0.2rem;
    right: 0.5rem;
    @include textstyle--book();
    font-size: $fontsize--xs;
    color: rgba($text-color--default, 0.9); // rather black
    line-height: $line-height;

    &[aria-hidden="true"] {
        display: none;
    }
}

.action-button--contact {
    @include respond-to(decent) {
        .btn {
            min-width: $quick-contact-width--en;

            html:lang(de) & {
                min-width: $quick-contact-width--de;
            }

            html:lang(fr) & {
                min-width: $quick-contact-width--fr;
            }

            html:lang(es) & {
                min-width: $quick-contact-width--es;
            }

            html:lang(it) & {
                min-width: $quick-contact-width--it;
            }

            html:lang(ja) & {
                min-width: $quick-contact-width--ja;
            }

            html:lang(zh-hans) & {
                min-width: $quick-contact-width--zh-hans;
            }

            html:lang(zh-hant) & {
                min-width: $quick-contact-width--zh-hant;
            }
        }

        .main-menu & {
            padding: 1rem;
            margin-right: calc(#{grid-padding-h(grid-l)} - 1rem);
        }
    }

    @include respond-to(grid-m) {
        .main-menu & {
            margin-right: calc(#{grid-padding-h(grid-m)} - 1rem);
        }
    }
}

.action-button--bag {
    .has-checkout-header &,
    .page-checkout-bag & {
        @include hide-action-button;
    }
}

.action-button--compare {
    // Handle label based on count? Good idea or not?
    &[data-count="0"],
    &[data-count="1"] {
        .btn-label {
            span:last-child {
                display: none;
            }
        }
    }

    &:not([data-count="0"]):not([data-count="1"]) {
        .btn-label {
            span:first-child {
                display: none;
            }
        }
    }
}

.action-button--notepad,
.action-button--compare {
    .has-checkout-header & {
        @include hide-action-button;
    }

    .btn-label {
        padding-right: 0.2rem;
    }
}

.action-button--pulse {
    @include respond-to(grid-s) {
        animation: btn-pulse 0.8s infinite ease-in-out;
    }

    @include respond-to(decent) {
        > span > span {
            animation: btn-pulse-desktop 1.2s infinite ease-in-out;
        }
    }
}

// amin wrapper for contact, compare, wishlist, mobile cart
.action-button-wrapper {
    position: fixed;
    top: $logo-height-mobile - $action-button-size / 2 - 1rem; // center first button on bottom logo line
    right: 0;
    z-index: map-get($z-index, action-button);
    width: calc(#{$navicon-width} + #{grid-padding-h()} * 2 + (#{grid-margin()} * 2)); // center above navicon
    transition: transform $trans-time--s $trans-func--default,
                opacity $trans-time--s $trans-func--default;

    body:not(.has-action-buttons-shown) &,
    .has-filter &,
    .has-popup &,
    .has-main-navi & ,
    .has-gallery-zoom & {
        transform: translate3d(0, -4em, 0);
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: transform $trans-time--s $trans-func--default,
                    opacity $trans-time--s $trans-func--default,
                    visibility $trans-time--s $trans-func--default $trans-time--s;
    }

    body:not(.has-action-buttons-shown) & {
        transform: scale(1.1);
    }

    @include respond-to(grid-s) {
        .has-sidebar & {
            transform: translate3d(0, 12em, 0);
        }

        .has-sidebar-page--compare & {
            transform: translate3d(0, 6em, 0);
        }

        .has-sidebar-page--search & {
            transform: translate3d(0, -4em, 0);
            opacity: 0;
            visibility: hidden;
            transition: transform $trans-time--s $trans-func--default,
                        opacity $trans-time--s $trans-func--default,
                        visibility $trans-time--s $trans-func--default $trans-time--s;
        }
    }

    @include respond-to(decent) {
        width: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row-reverse;
        top: auto;
        bottom: 0;
        right: calc(#{grid-position-right(grid-l)} + #{grid-padding-h(grid-l)} - 1rem); // grid-margin() does not work here because of scrollbar-view-width-issue
        height: $action-bar-height-desktop;

        .has-main-navi & {
            transform: none;
        }

        .has-gallery-zoom &,
        .has-footer-in-view & {
            transform: translate3d(0, 4em, 0);
            opacity: 0;
            pointer-events: none;
        }

        // Hide all action buttons on bag for desktop to prevent covering of main CTA
        .page-checkout-bag & {
            display: none;
        }
    }

    @include respond-to(grid-m) {
        right: calc(#{grid-position-right(grid-m)} + #{grid-padding-h(grid-m)} - 1rem); // grid-margin() does not work here because of scrollbar-view-width-issue
    }
}

// User like button
.userlike-btn {
    position: relative;

    &[aria-busy="true"] {
        &:before {
            @include loading-pulse(rgba(190, 180, 170, 0.6));
            left: auto;
            right: 0;
        }
    }
}
