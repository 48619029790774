// Nomos Magazine

// Articles
.main-content--magazine-article {
    @include respond-to(grid-l) {
        .box-group {
            margin-right: grid-width(grid-l, 1, false, true);
            margin-left: grid-width(grid-l, 1, false, true);

            .modal & {
                margin-left: 0;
            }
        }

        .box-xxxl {
            .modal & {
                margin-right: grid-width(grid-l, 1, false, true);
            }
        }
    }

    @include respond-to(grid-m) {
        .box-group {
            margin-right: grid-width(grid-m, 1, false, true);
            margin-left: grid-width(grid-m, 1, false, true);

            .modal & {
                margin-left: 0;
            }
        }

        .box-xxxl {
            .modal & {
                margin-right: grid-width(grid-m, 1, false, true);
            }
        }
    }

    // Article is loaded in sidebar and has section-fond as last child -> Negate padding through negative margin
    // Compare: source-site/sass/components/_modal.scss:643
    .modal__content & {
        .section-fond:last-child {
            @include respond-to(grid-s) {
                margin-bottom: grid-spacing(grid-s, xxl) * (-1);
            }

            @include respond-to(grid-m) {
                margin-bottom: $action-bar-height-desktop * (-1);
            }

            @include respond-to(decent) {
                margin-bottom: grid-spacing(grid-l, l) * (-1);
            }
        }
    }
}
