// type-box for qoutes and text only teaser
.type-box {
    @include grid-width(9, 10, 10);
    @include grid-spacing(xl);
    padding-left: calc(#{grid-width-gutter(grid-s)} + #{grid-padding(grid-s)});
    padding-bottom: grid-spacing(grid-s, l);
    overflow: hidden; // take care of very long words
    position: relative;
    @include fade-cover(right, 4em);

    .section-fond & {
        @include fade-cover(right, 4em, $back-color--tertiary);
    }

    .section-fond-custom & {
        &:after {
            display: none;
        }
    }

    p {
        width: 100%;
        position: relative;
        display: inline-block;
        font-size: inherit;

        > span {
            @include textstyle--quote;
            hyphens: none;
            display: inline-block;
            position: relative;

            &:not(:last-of-type) {
                padding-right: 0.25em; // the actuall word-spacing will not do since it is coming from the paragraph with very little font-size
            }
        }
    }

    small {
        @include grid-spacing(s);
        margin-left: grid-width-gutter(grid-s);
        display: block;

        span {
            @include textstyle--teaser;
            @include textstyle--m;
        }
    }

    blockquote {
        p {
            // »
            > span:first-of-type {
                display: inline-block;
                position: absolute;
                padding-right: 0;
                top: 0;
                left: -0.6em;
            }

            // «
            > span span:last-of-type {
                padding-left: 0.6rem; // display inline-block kills line-breaks, therefor no transform possible here
            }
        }
    }

    .box-group > & {
        float: left;
    }

    &.type-box--small {
        p > span {
            font-size: $fontsize--quote-s;
        }
    }

    @include respond-to(grid-s) {
        // take care of very long words
        width: grid-width(grid-s, 9, true);
        margin-right: calc(#{grid-margin(grid-s)} * -1);

    }

    @include respond-to(grid-l) {
        padding-left: calc(#{grid-width-gutter(grid-l)} + #{grid-padding(grid-l)});
        padding-right: grid-width-gutter(grid-l, 2);
        position: relative;
        padding-bottom: grid-spacing(grid-l, l);

        p > span {
            font-size: $fontsize--quote-l;
        }

        small {
            margin-left: grid-width-gutter(grid-l);

            span {
                @include textstyle--teaser-m;
            }
        }

        &.type-box--big {
            p > span {
                font-size: $fontsize--quote-xl;
            }
        }

        &.type-box--small {
            p > span {
                font-size: 5.2rem;
            }
        }
    }

    @include respond-to(grid-m) {
        padding-left: calc(#{grid-width-gutter(grid-m)} + #{grid-padding(grid-m)});
        padding-right: grid-width-gutter(grid-m, 2);
        padding-bottom: grid-spacing(grid-m, l);

        small {
            margin-left: grid-width-gutter(grid-m);

            span {
                @include textstyle--l;
            }
        }

        &.type-box--big {
            p > span {
                font-size: $fontsize--quote-l;
            }
        }

        &.type-box--small {
            p > span {
                font-size: $fontsize--quote-default;
            }
        }
    }
}

