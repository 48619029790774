// Nomos zoom image
// Transitioning watch

.zoom-img {
    // big zoom effect for single products
    .no-ie .force-intersect.product-main-info &,
    .no-ie .force-intersect .slideshow &,
    .no-ie .force-intersect .no-slideshow & {
        .media-box {
            // keep initial zoom state
            @supports (animation: animate-element-s-mobile 1s 1 backwards) {
                transform: scale(#{$zoom-scale-inverted-single}) translate3d(0, 1.2%, 0);
            }
        }
    }

    // start zooming if in view and slide is activated
    .no-ie .intersect-inview.product-main-info &,
    .no-ie .intersect-inview .slideshow .is-selected &,
    .no-ie .intersect-outofview--top .slideshow .is-selected &,
    .no-ie .intersect-inview .no-slideshow &,
    .no-ie .intersect-outofview--top .no-slideshow & {
        .media-box {
            animation: zoom-in-single 10s $trans-func--default 1 both;
        }
    }
}

// zoom image for heros and fullscreen images
.no-ie {
    .intersect-inview .zoom-full-img,
    .intersect-inview.zoom-full-img {
        .media-box:not(.is-rellaxing):not(.lazy-child),
        .media-box:not(.is-rellaxing).lazy-child--loaded {
            animation: zoom-in-full 10s $trans-func--default 1 both;
        }
    }
}
