// Show and hide header on scroll

// Mobile behaviour
@include respond-to(grid-s) {
    .main-menu__wrapper {

        // Animate menu out of view as the user scrolles a little
        // Animate menu back in if user goes back up or navi is shown
        .no-ios .is-scrolled-decent.has-scrolled:not(.has-main-navi):not(.is-scrolled-up) &,
        .is-safari.touch .is-scrolled-decent.has-scrolled:not(.has-main-navi):not(.has-ios-toolbar) &,
        .has-sidebar &,
        .has-filter &,
        .has-quick-contact &,
        .has-dropdown & {
            transform: translate3d(0, 100vh, 0);
        }

        // fix for mobile safari vh-issue
        @supports(--css: variable) {
            .is-safari.touch .is-scrolled-decent.has-scrolled:not(.has-main-navi):not(.has-ios-toolbar) &,
            .is-safari.touch .has-sidebar &,
            .is-safari.touch .has-filter &,
            .is-safari.touch .has-quick-contact &,
            .is-safari.touch .has-dropdown & {
                transform: translate3d(0, calc(var(--view-height-offset) * 100vh), 0);
            }
        }
    }

    // Animate navicon out of view as the user scrolles a little
    // Animate navicon back in if user goes back up or navi is shown
    .navicon {
        transition: transform $menu-trans-timing-out $menu-trans-easing-out;

        .no-ios .is-scrolled-decent.has-scrolled:not(.has-main-navi):not(.is-scrolled-up) &,
        .is-safari.touch .is-scrolled-decent.has-scrolled:not(.has-main-navi):not(.has-ios-toolbar) &,
        .has-sidebar &,
        .has-filter &,
        .has-quick-contact &,
        .has-dropdown & {
            transform: translate3d(0, 100%, 0);
        }
    }
}

// Desktop behaviour
@include respond-to(decent) {
    .main-menu__head {
        // Add white background
        &:before {
            @include pseudo();
            background: $back-color--default;
            top: 0;
            left: 0;
            width: 100%;
            height: $menu-height-desktop;
            pointer-events: none;
            opacity: 0;
            transition: transform $trans-time--m $trans-func--default,
                        opacity $trans-time--m $trans-func--default;

            // show white background if scrolled beyond the header
            .is-scrolled-beyond-header & {
                opacity: 1;
                transform: translate3d(0, -16%, 0);
            }

            // Prevent rendering issues on firefox
            .skip-menu-reshow & {
                display: none;
            }
        }
    }

    // If header is out of view, fix it and remove it from viewport
    // without any transition to prevent blinking while getting fixed
    .is-scrolled-beyond-header:not(.skip-menu-reshow) {
        .main-menu__head {
            transform: translate3d(0, -100%, 0); // only remove as far as neccessary
            position: fixed;
            width: 100%;
        }

        .main-logo {
            transform: scale(0.8) translate3d(-50%, -10%, 0);
        }
    }

    // Add the animation as the header is gone far enough
    .is-scrolled-decent:not(.skip-menu-reshow) {
        .main-logo,
        .main-menu__head {
            transition: transform $trans-time--m $trans-func--default;
        }
    }

    // Show header if going back up
    .is-scrolled-up-decent:not(.delay-menu-reshow):not(.has-checkout-header):not(.skip-menu-reshow),
    .is-scrolled-up-much.delay-menu-reshow {
        .main-menu__head {
            transition: transform $trans-time--m $trans-func--easing-out;
            transform: translate3d(0, 0, 0);
            position: fixed;
            width: 100%;
        }

        .main-logo {
            transition: transform $trans-time--m $trans-func--easing-out;
            transform: scale(0.8) translate3d(-50%, -10%, 0);
        }
    }

    // Show header if going back up
    .is-scrolled-up-decent:not(.delay-menu-reshow):not(.is-scrolled-beyond-header):not(.has-checkout-header):not(.skip-menu-reshow),
    .is-scrolled-up-much.delay-menu-reshow:not(.is-scrolled-beyond-header) {
        .main-logo {
            transform: translate3d(-50%, 0, 0);
        }
    }
}

// grid-m fixes
@include respond-to(grid-m) {
    .is-scrolled-beyond-header:not(.skip-menu-reshow) {
        .main-logo {
            transform: scale(0.8) translate3d(0, -10%, 0);
        }
    }

    .is-scrolled-up-decent:not(.delay-menu-reshow):not(.has-checkout-header):not(.skip-menu-reshow),
    .is-scrolled-up-much.delay-menu-reshow {
        .main-logo {
            transform: scale(0.8) translate3d(0, -10%, 0);
        }
    }

    .is-scrolled-up-decent:not(.delay-menu-reshow):not(.is-scrolled-beyond-header):not(.has-checkout-header):not(.skip-menu-reshow),
    .is-scrolled-up-much.delay-menu-reshow:not(.is-scrolled-beyond-header) {
        .main-logo {
            transform: translate3d(0, 0, 0);
        }
    }
}
