// Product listing in store
.store-listing {
    @include clearfix();
    display: block;
}

// Filter Listing container, that holds the results
.store-listing__content {
    transition: opacity $trans-time--m $trans-func--default;
    opacity: 1;

    &[aria-busy="true"] {
        opacity: 0.2;
        pointer-events: none;
    }

    .section-title {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        &:first-child {
            @include grid-spacing(s);
        }

        &:after,
        &:before {
            display: none; // remove clearfix;
        }

        h3 {
            @include textstyle--l;
        }

        i {
            margin-bottom: 0.4rem;
        }

        .is-filtered & {
            // hide additional info if filtered already
            i {
                display: none;
            }

            i + h3 {
                max-width: 100%; // non one knows?
            }
        }
    }

    @include respond-to(decent) {
        .section-title {
            &:first-child {
                margin-top: 0;
            }

            h3 {
                @include textstyle--teaser-xl;
            }

            i {
                margin-bottom: 0.6rem;
            }
        }
    }

    @include respond-to(grid-l) {
        float: right;
        width: grid-width(grid-l, 7.5) - grid-gutter-width(grid-l) / 2;

        // handle sidebar scrolling
        @supports (position: sticky) and (--css: variable) { // limit to css properties
            --sticky-top: 0;
            min-height: calc(100vh - (#{$action-bar-height-desktop} + #{$button-height-s} + 1.4em - 2em)); // prevent staying on bottom if sticked
            padding-bottom: 2em;

            // let filter sidebar scroll on the side
            .has-sticky-listing & {
                position: sticky;
                top: var(--sticky-top);
            }
        }
    }

    @include respond-to(grid-m) {
        .section-title {
            h3 {
                @include textstyle--teaser-l;
            }
        }
    }
}
