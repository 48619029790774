// Store Teaser definitions

.teaser--store {
    position: relative;
    background: $back-color--tertiary;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .text-container {
        z-index: 1;
        position: relative;
        order: 1;
    }

    .teaser__head {
        display: flex;
        justify-content: space-between;
        order: 1;
        z-index: 20; // Video overlaps Head otherwise

        .text-container {
            padding-bottom: 0 !important;
        }
    }

    .teaser__cta {
        order: 3;
        z-index: 21; // Video overlaps CTA otherwise
    }

    .teaser__cta--btn {
        flex: 1 1 auto; // push button to top
        padding-top: 0 !important;
    }

    .media-box {
        order: 2;
        flex: 1 1 auto; // stretch accross full box
    }

    h4 {
        @include textstyle--xs;
        @include textstyle--uppercase;
        text-align: right;
        margin-top: 0.4rem;
        margin-left: 1rem;
        white-space: nowrap;
    }

    @include respond-to(grid-s) {
        .teaser__head .text-container {
            max-width: grid-width(grid-s, 8);
        }
    }

    @include respond-to(grid-l) {
        .text-container {
            padding: grid-padding-v(grid-l) grid-padding-v(grid-l);
        }

        h4 {
            @include textstyle--s;
        }
    }

    @include respond-to(grid-m) {
        .text-container {
            padding: grid-padding-v(grid-m) grid-padding-v(grid-m);
        }
    }
}

.teaser--store-centered {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .teaser__head {
        @include grid-spacing(m);

        h3 {
            text-align: center;
        }
    }

    .teaser__cta {
        @include grid-spacing(s);
        padding: 0;
        order: 2;
    }

    &.teaser--full-bleed-img {
        .teaser__cta {
            flex: 1 1 auto; // push button to top
        }
    }

    &.teaser--store-banner {
        .teaser__cta {
            padding-bottom: 3rem;
            display: flex;
            align-items: center;
            max-width: 100% !important;
        }
    }

    .media-box {
        width: 100%;
        order: 3;
    }
}


.teaser--full-bleed-img {
    .media-box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &.teaser--store-s {
        min-height: 30em;
    }

    &.teaser--store-m {
        min-height: 40em;
    }

    &.teaser--store-l {
        min-height: 50em;
    }

    &.teaser--store-banner {
        min-height: 16em;
    }

    @include respond-to(grid-l) {
        &.teaser--store-s {
            min-height: 50em;
        }

        &.teaser--store-m {
            min-height: 66em;
        }

        &.teaser--store-l {
            min-height: 80em;
        }
    }

    @include respond-to(grid-m) {
        &.teaser--store-s {
            min-height: 42em;
        }

        &.teaser--store-m {
            min-height: 52em;
        }

        &.teaser--store-l {
            min-height: 80em;
        }
    }
}

.teaser--isolated-img {
    .media-box {
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &.teaser--store-banner,
    &.teaser--store-s {
        .media-box {
            min-height: 20em;
        }
    }

    &.teaser--store-m {
        .media-box {
            min-height: 30em;
        }
    }

    &.teaser--store-l {
        .media-box {
            min-height: 36em;
        }
    }

    &.teaser--store-banner {
        .media-box {
            min-height: 20em;
        }
    }

    .teaser__cta {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    @include respond-to(grid-l) {
        &.teaser--store-s {
            .media-box {
                min-height: 36em;
            }
        }

        &.teaser--store-m {
            .media-box {
                min-height: 52em;
            }
        }

        &.teaser--store-l {
            .media-box {
                min-height: 58em;
            }
        }

        &.teaser--store-banner {
            min-height: 16em;

            .media-box {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: grid-width(grid-l, 6);
            }
        }

        .teaser__cta {
            position: relative;
            bottom: auto;
            left: auto;
            right: auto;
        }
    }

    @include respond-to(grid-m) {
        &.teaser--store-banner,
        &.teaser--store-s {
            .media-box {
                min-height: 30em;
            }
        }

        &.teaser--store-m {
            .media-box {
                min-height: 36em;
            }
        }

        &.teaser--store-l {
            .media-box {
                min-height: 58em;
            }
        }
    }
}

.teaser--store-l {
    .teaser__cta {
        order: 2;
    }

    .teaser__cta--btn {
        padding-top: 1rem !important;
    }

    @include respond-to(grid-s) {
        h3 {
            @include textstyle--l;
        }
    }

    @include respond-to(decent) {
        .teaser__cta--btn {
            padding-top: 1.6rem !important;
        }
    }
}


.teaser--store-banner {
    .teaser__cta {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
    }

    .teaser__cta--btn {
        padding-top: 1rem !important;
    }

    @include respond-to(decent) {
        .teaser__cta--btn {
            padding-top: 2rem !important;
        }
    }
}
