// Nomos Welcome popup
.welcome-message {
    position: relative;
    margin: calc(#{grid-gutter(grid-s)} * -1);
    padding: grid-gutter(grid-s);

    .text-container {
        position: relative;
    }

    h2 {
        @include textstyle--m;
    }

    @include respond-to(grid-s) {
        padding-top: 0;
        padding-bottom: grid-width(grid-s, 2.6);
        margin-bottom: 1em;
    }

    @include respond-to(grid-l) {
        min-height: grid-width(grid-l, 3.2);
        margin: calc(#{grid-gutter(grid-l)} * -1);
        padding: grid-gutter(grid-l);

        .text-container {
            width: grid-width(grid-l, 3);
            padding-bottom: grid-spacing(grid-l, l);
        }

        h2 {
            @include textstyle--l;
        }
    }

    @include respond-to(grid-m) {
        margin: calc(#{grid-gutter(grid-m)} * -1);
        padding: grid-gutter(grid-m);

        .text-container {
            width: grid-width(grid-m, 5);
            padding-bottom: grid-width(grid-m, 3.2);
        }

        h2 {
            @include textstyle--l;
        }
    }
}

.welcome-message__img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: grid-width(grid-s, 4);


    @include respond-to(grid-l) {
        width: grid-width(grid-l, 3);
    }

    @include respond-to(grid-m) {
        width: grid-width(grid-m, 4);
    }
}
