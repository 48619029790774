// Nomos watchfinder preview

.watchfinder-teaser {
    // prevent for illusion of completeness
    @include respond-to(decent) {
        @media only screen and (min-aspect-ratio: 20/11) {
            .media-box {
                @include ratio-box($landscape-ratio);
            }
        }
    }
}

.curated-teasers-wrapper {
    @include clearfix();

    @include respond-to(decent) {
        @include grid-spacing(m);
    }
}

.curated-title {
    @include grid-width(10, 2, 2);
    float: left;
    @include grid-spacing(m);
    position: sticky;
    top: calc(#{$menu-height-desktop} - #{grid-padding-h(grid-l)});

    h3 {
        @include textstyle--uppercase;
        @include textstyle--m;
    }

    @include respond-to(grid-s) {

        @include textstyle--s;
    }

    @include respond-to(grid-m) {
        top: calc(#{$menu-height-desktop} - #{grid-padding-h(grid-m)});

        h3 {
            @include textstyle--s;
        }
    }
}

.curated-teasers {
    @include grid-width(10, 8, 6);
    float: right;

    @include respond-to(grid-m) {
        .box-s {
            width: grid-width(grid-m, 4);
        }
    }
}
