/* Don’t Try This At Home KIDS!!!! */

.animate,
.animate-children {
    @include state--initial() {
        &:not(.animate-children), // .animate
        &:not(.animate) > * { // .animate-children > *
            @include hide-before-animation();
        }

        // specific animations
        .watch-modal-button {
            animation: none;

            &:after,
            > div {
                @include hide-before-animation();
            }
        }
    }

    @include state--animated() {
        &:not(.animate-children), // .animate
        &:not(.animate) > * { // .animate-children > *
            @include show-for-animation();
            animation: animate-element-default-mobile $trans-time--l $trans-func--default 1 backwards;

            @include respond-to(decent) {
                animation-name: animate-element-default;
            }
        }

        // smaller default transition
        &.animate--s:not(.animate-children), // .animate
        &.animate--s:not(.animate) > * { // .animate-children > *
            animation-name: animate-element-s-mobile;
            animation-duration: $trans-time--m;

            @include respond-to(decent) {
                animation-name: animate-element-s;
            }
        }

        // tiny default transition
        &.animate--xs:not(.animate-children), // .animate
        &.animate--xs:not(.animate) > * { // .animate-children > *
            animation-name: animate-element-xs-mobile;
            animation-duration: $trans-time--m;

            @include respond-to(decent) {
                animation-name: animate-element-xs;
            }
        }

        &.animate--quote:not(.animate-children), // .animate
        &.animate--quote:not(.animate) > * { // .animate-children > *
            animation-name: animate-element-s-mobile;
            animation-duration: $trans-time--l;
            @include animation-delay(0.05s, 0.2s, 60);

            @include respond-to(decent) {
                animation-name: animate-element-s;
            }
        }

        // cascading delay for animated children children
        &:not(.animate) > * { // .animate-children > *
            @include animation-delay();
        }

        &.animate-children--delay-l:not(.animate) > * { // .animate-children > *
            @include animation-delay(0.2s);
        }

        // specific animations
        .watch-modal-button {
            animation: none;

            &:after,
            > div {
                @include show-for-animation();
                animation: animate-element-default-mobile $trans-time--l $trans-func--default 1 backwards;

                @include respond-to(decent) {
                    animation-name: animate-element-default;
                }
            }
        }

        &.animate-fade > *,
        > .animate-fade {
            animation-name: animate-element-fade-in;
        }
    }
}

// animate lists
.animate-list {
    @include state--initial() {
        > * {
            @include hide-before-animation();
            position: relative;
        }
    }

    @include state--animated() {
        > * {
            @include show-for-animation();
            animation: animate-element-xs-mobile $trans-time--m $trans-func--default 1 backwards;
            @include animation-delay(0.03s, 0.1s);

            @include respond-to(decent) {
                animation-name: animate-element-xs;
            }
        }
    }
}

// delay animations for incoming navigation
.nav-animation-delay {
    // delay intersect animations by delaying visibility
    .no-touch.no-ie.intersection-observer [aria-expanded="false"] & {
        animation: delay-visibility 1000000000000s steps(2) 1 backwards;
    }

    .no-touch.no-ie.intersection-observer [aria-expanded="true"] & {
        animation: delay-visibility 0.35s steps(2) 1 backwards;
    }

    // delay animation for non-intersection devices
    // takes some effort to overwrite
    .no-touch.no-intersection-observer .main-header [aria-expanded="true"] & {
        &.animate-list {
            > * {
                @include animation-delay(0.03s, 0.6s);
            }
        }
    }
}

.nav-animation-delay-children {
    // delay intersect animations by delaying visibility
    .no-touch.no-ie.intersection-observer [aria-expanded="false"] & {
        > * {
            animation: delay-visibility 1000000000000s steps(2) 1 backwards;
        }
    }

    .no-touch.no-ie.intersection-observer [aria-expanded="true"] & {
        @for $i from 1 through 10 {
            > *:nth-child(#{$i}) {
                animation: delay-visibility $i * 0.15s - 0.15s steps(2) 1 backwards;
            }
        }
    }

    // delay animation for non-intersection devices
    // takes some effort to overwrite
    .no-touch.no-intersection-observer .main-header [aria-expanded="true"] & {
        .animate-children:not(.animate) {
            @for $i from 1 through 10 {
                &:nth-child(#{$i}) {
                    @for $s from 0 through 10 {
                        > *:nth-child(#{$s}) {
                            animation-delay: $s * 0.1s + ($i * 0.15s - 0.15s);
                        }
                    }
                }
            }
        }
    }
}

// development hint
.intersect.animate {
    border: 1px dotted red; // leads to problems if scroll stops on the viewport edge
}
