.awards-list-wrapper {
    @include grid-width(10, 8, 6);

    dl {
        @include clearfix;
        padding: 0;
        margin: 0;

        span {
            @include textstyle--default;
        }

        dt {
            margin: 0;
            margin-top: $fontsize--default * $line-height * 0.5; // half a line
            clear: both;
            float: left;
            @include grid-inner-width(2, 1, 1);

            span {
                @include textstyle--tabular-figures;
            }
        }

        dd {
            margin: 0;
            margin-top: $fontsize--default * $line-height * 0.5; // half a line
            @include grid-inner-width(8, 7, 5);
            float: right;
        }

        @include respond-to(grid-s) {
            margin-top: grid-spacing(grid-s, m);
        }
    }

    [aria-expanded="false"] {
        height: $fontsize--default * $line-height * 5.5 + 2rem;  // show 5.5 lines + button offset
        overflow: hidden;

        @include respond-to(decent) {
            height: $fontsize--default * $line-height * 4 + 2rem;  // show 4 lines + button offset
        }
    }
}

// Awards list action
.awards-list-action {
    display: block;
    margin-top: -6em;
    padding-top: 4em;
    position: relative;
    cursor: pointer;

    > div {
        background: $back-color--primary;
        position: relative;
        text-align: center;
        @include grid-padding;
        transition: transform $trans-time--m $trans-func--default;
        transform: translate3d(0, 0, 0);
        border-top: 1px solid $border-color--default;
    }

    .btn {
        transition: transform $trans-time--m $trans-func--default;
    }

    @at-root {
        [aria-expanded="true"] + & {
            display: none;
        }
    }

    @include hover {
        > div {
            transition: transform $trans-time--xs $trans-func--default;
            transform: translate3d(0, 2rem, 0);
        }

        .btn {
            transition: transform $trans-time--xs $trans-func--default;
            transform: translateY(-15%);
        }
    }
}

// Awards on products page
.box-couple.box-couple--awards {
    .text-container {
        @include grid-width(8, 4, 3);

        @include respond-to(grid-l) {
            margin-right: grid-width-gutter(grid-l, 0.5);
        }
    }
}

.awards-box {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    padding: grid-spacing(grid-s, m) 0;

    > div {
        width: grid-width(grid-s, 3.5);
        margin-bottom: grid-gutter(grid-s);

        &:not(:last-child) {
            margin-right: grid-gutter(grid-s);
        }
    }

    @include respond-to(grid-l) {
        padding: grid-padding(grid-l);

        > div {
            width: grid-inner-width(grid-l, 1.5);
            margin-bottom: grid-gutter(grid-l);

            &:not(:last-child) {
                margin-right: grid-gutter(grid-l);
            }
        }
    }

    @include respond-to(grid-m) {
        width: grid-width(grid-m, 6);

        > div {
            width: grid-width(grid-m, 2);
            margin-bottom: grid-gutter(grid-m);
            margin-left: grid-gutter(grid-m);

            &:not(:last-child) {
                margin-right: 0;
            }
        }
    }
}
