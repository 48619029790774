// Nomos catalogue order

.nav-couple {
    @include respond-to(decent) {
        @include clearfix();

        > div {
            width: grid-width(grid-l, 4);
            float: left;
        }

        > .teaser {
            width: grid-width(grid-l, 4);
            position: sticky;
            top: $menu-top-padding-desktop;
            float: right;
        }
    }
}

