.post-checkout-registration {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.post-checkout-registration__head {
    width: 100%;

    .text-container {
        @include grid-width(8, 4, 3);
    }
}

.post-checkout-registration__account {
    width: 100%;

    @include respond-to(grid-l) {
        width: grid-width(grid-l, 3);
    }

    @include respond-to(grid-m) {
        width: grid-width(grid-m, 4.5);
    }
}

.post-checkout-registration__benefits {
    display: none;
    width: 100%;

    h3 {
        @include textstyle--default;
        @include textstyle--xs;
        @include textstyle--uppercase;
    }

    ul {
        margin-top: 0.6rem;
    }

    @include respond-to(grid-l) {
        display: block;
        width: grid-width(grid-l, 2.5);
    }
}

.post-checkout-registration__action {
    width: 100%;
    background: $back-color--default;
    margin-top: 1rem;

    .btn--text {
        padding: 1.6rem 0;
    }
}