// Nomos Wishlist

// Button to toggle add to / delete from wishlist and compare
.add--to-compare,
.add--to-wishlist {
    position: relative;

    .svg-ico--nom-remove-big {
        display: none;
    }

    &.is-on-compare,
    &.is-on-wishlist {
        @include textstyle--book;
        letter-spacing: 0.15rem; // try to have the same letterspacing as before

        .svg-ico--nom-remove-big {
            display: inline-block;
        }

        .svg-ico--nom-like,
        .svg-ico--nom-like-small,
        .svg-ico--nom-compare,
        .svg-ico--nom-compare-small {
            &:not(:last-child) { // dont hide if there is no label and no remove icon
                display: none;
            }
        }

        @include respond-to(decent) {
            &:not(.btn--secondary) .svg-ico--nom-remove-big {
                margin-left: 1.1rem; // compensate for icon switch
            }
        }
    }
}

.remove-from-list {
    position: absolute;
    top: 0;
    right: 0.3rem;
    @include textstyle--book();
    font-size: 1rem;
    color: rgba($text-color--default, 0.8); // rather black
    line-height: 1;

    .teaser--product & {
        display: none;
    }

    .is-on-compare &,
    .is-on-wishlist & {
        .teaser--product & {
            display: block;
        }
    }
}

// wishlist item
.compare-list__item-header,
.wishlist-item-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 grid-padding-h(grid-s);

    > a {
        margin-top: 1rem;
        margin-right: 2rem;
        flex: 1;

        @include respond-to(grid-l) {
            margin-top: 0.4rem;
        }
    }

    > .btn {
        flex: 0;
    }

    h3 {
        @include textstyle--default;
        @include textstyle--s;
        @include textstyle--uppercase;
        text-align: left;

        @include respond-to(grid-l) {
            @include textstyle--l;
        }

        @include respond-to(grid-m) {
            @include textstyle--m;
        }
    }

    p {
        @include textstyle--default;
        margin-top: 0.2em;

        @include respond-to(grid-l) {
            @include textstyle--l;
        }
    }

    p + p {
        margin-top: 0;
    }

    .product--price {
        span {
            @include textstyle--default;

            @include respond-to(grid-l) {
                @include textstyle--l;
            }
        }
    }

    @include respond-to(decent) {
        padding: 0 grid-padding-h(grid-l);
    }
}

.compare-list__item-img,
.wishlist-item-img {
    // @include grid-spacing(xs);
    // show image on top
    order: -1;
}

.compare-list__item-action,
.wishlist-item-action {
    display: flex;
    padding: 0 grid-padding-h(grid-s);
    @include grid-spacing(s);

    > * {
        &:not(:last-child) {
            margin-right: 0.4em;
        }
    }

    @include respond-to(decent) {
        padding: 0 grid-padding-h(grid-l);

        .btn.btn--secondary {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }

    @include respond-to(grid-m) {
        .btn.btn--secondary {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}

.compare-list__item-footer,
.wishlist-item-footer {
    padding: 0 grid-padding-h(grid-s);

    .product__purchase-info {
        @include grid-spacing(xs);
    }

    @include respond-to(decent) {
        padding: 0 grid-padding-h(grid-l);
    }
}

// Watch selection for watches with multiple backs
.compare-selection__head,
.wishlist-selection__head {
    .text-container {
        padding-top: 0;
        max-width: grid-width(grid-l, 4);
    }

    h3 {
        @include textstyle--default;

        @include respond-to(decent) {
            @include textstyle--l;
        }

        @include respond-to(grid-m) {

        }
    }
}

.compare-selection__list,
.wishlist-selection__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include respond-to(grid-s) {
        padding-bottom: grid-spacing(grid-s, s);
    }
}
