// Heidelpay relevant styles
.hp-payment-iframe {
    border: none;
    background: $back-color--default;
    width: 100%;

    &.hp-payment-iframe--cc-inline {
        height: 300px;

        @include respond-to(decent) {
            height: 300px;
        }

        @include respond-to(xl) {
            height: 340px;

            &:lang(ja) {
                height: 350px;
            }
        }
    }
}

.hp-payment-settings {
    opacity: 1;
    transition: opacity $trans-func--default $trans-time--m;

    .hp-payment-settings__reuse,
    .hp-payment-settings__form {
        display: block;

        &[aria-hidden="true"] {
            display: none;
        }
    }

    .tab-list + .hp-payment-settings__form {
        @include grid-spacing(s);
    }

    .hp-payment-settings__reuse {
        dl {
            display: flex;
            flex-wrap: wrap;
        }

        dt {
            @include grid-width(4, 2, 1.5);
            margin: 0;
            margin-right: grid-gutter(grid-s);

            @include respond-to(grid-l) {
                margin-right: 0;
                padding-right: grid-gutter(grid-l);
                width: grid-inner-width(grid-l, 1.5);
            }

            @include respond-to(grid-m) {
               margin-right: grid-gutter(grid-m);
            }
        }

        dd {
            margin: 0;
            @include grid-inner-width(5, 3, 3);
        }

        dt,
        dd {

            span {
                @include textstyle--default;
            }

            @include respond-to(grid-m) {

            }
        }
    }

    .hp-payment-settings__reenter {
       @include grid-spacing(s);
    }

    // Heidelpay provider information for CC + Invoice
    .hp-payment-settings__provider-info {
        @include grid-spacing(m);
        display: flex;
        align-items: center;

        .hp-payment-settings__provider-info__logo {
            @include grid-width(1.25, 1, 0.6);

            .svg-ico {
                width: 100%;
                height: auto;
                fill: currentColor;
                stroke: transparent;
            }
        }

        .hp-payment-settings__provider-info__description {
            flex: 1 1 auto;
            margin-right: grid-gutter(grid-s);

            p {
                @include textstyle--s;
            }
        }

        @include respond-to(grid-l) {
            .hp-payment-settings__provider-info__description {
                margin-right: grid-gutter(grid-l);
            }
        }

        @include respond-to(grid-m) {
            .hp-payment-settings__provider-info__description {
                margin-right: grid-gutter(grid-m);
            }
        }
    }

    // No margin, if provider info comes directly after payment form
    .hp-payment-settings__form[aria-hidden="false"] + .hp-payment-settings__provider-info {
        margin-top: 0;
    }

    &[aria-busy="true"] {
        pointer-events: none;
        opacity: 0.2;
        cursor: wait;
    }
}
