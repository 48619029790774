// Nomos product
.hero--caliber {
    display: flex;
    flex-direction: column;

    .media-box-wrapper {
        overflow: hidden;
        height: 120vw;
        max-height: 60vh; // @todo define image height
        background: $back-color--tertiary;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .media-box {
        @include grid-width(10, 8, 7);
    }

    @include respond-to(decent) {
        flex-direction: column-reverse;

        .media-box-wrapper {
            max-height: 100vh;

            @include respond-to(port) {
                max-height: 60vh;
            }
        }
    }
}

.caliber-description {
    @include grid-spacing(l);
}

.caliber-specs {
    .slide-box {
        @include grid-width(10, 4, 4);
    }

    .text-container {
        @include grid-width(10,3,4);
    }

    @include respond-to(grid-m) {
        .box-group .box-couple .text-container {
            width: grid-width(grid-m, 5);
        }

        .specs-list:last-child {
            width: grid-inner-width(grid-m, 3);
        }
    }

    @include respond-to(grid-s) {
        .box-couple {
            width: 100%;
        }

        .specs-list {
            @include grid-spacing(m, grid-s);

            display: block;
            width: grid-inner-width(grid-s, 5);
            float: left;

            &:last-child {
                float: right;
            }

        }
    }
}
