.select2-container--nomos,
.select2-container--nomos-country {
    @include grid-inner-max-width(8, 3, 3);
    margin-top: 0.5em;

    // main select item
    .select2-selection--single {
        @include textstyle--default;
        color: $text-color--default;
        outline: none;
        width: 100%;
        background: $back-color--secondary;
        height: $input-height-default;
        line-height: $input-height-default;
        padding: 0 1em;
        padding-right: 5rem;
        margin: 0;
        border: 0;
        border-radius: 0;
        box-shadow: none;

        .select2-selection__rendered {
            padding: 0;
        }

        .select2-selection__clear {
            cursor: pointer;
            float: right;
            font-weight: bold;
        }

        .select2-selection__placeholder {
            color: $text-color--secondary;
        }

        .select2-selection__arrow {
            position: absolute;
            top: 0;
            right: 1em;
            height: 100%;
            width: 2rem;
            background: svg-inline(icon--nom-dropdown) center center no-repeat;
            background-size: 1em 1.6em; // @todo calc correct size
        }

        .select2-selection__rendered {
            text-overflow: initial;
            position: relative;

            @include fade-cover(right, 1.6em, $back-color--secondary);
        }
    }

    // style for opened select field
    &.select2-container--open {
        .select2-selection--single {
            .select2-selection__arrow {
                background-image: svg-inline(icon--nom-dropdown-reverse);
            }
        }
    }

    // dropdown wrapper
    .select2-dropdown {
        border: none;
        border-radius: 0;
    }

    // results list
    .select2-results {
        margin-top: -1px; // prevent blitzer

        > .select2-results__options {
            max-height: 20em;
            overflow-y: auto;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;

            @include respond-to(decent) {
                max-height: 26em;
            }
        }
    }

    // wrapper for input field in dropdown
    .select2-search--dropdown {
        padding: 0;
    }

    // input field for text search
    .select2-search__field {
        padding: 0 1em;
        background: $back-color--tertiary svg-inline(icon--nom-search) no-repeat;
        background-size: 1em 1.6em;
        background-position: calc(100% - 1em) center;
        padding-right: 2.4em;
    }

    // results list items
    .select2-results__option {
        padding: 1em 1.7em;

        // secind level > countries
        .select2-results__option {
            margin: 0 -1.7em;
            padding: 0.4em 1.7em;
            @include textstyle--button;
        }

        &.has-store {
            background-image: svg-inline(icon--nom-bag-small);
            background-repeat: no-repeat;
            background-size: 1em 1.6em;
            background-position: calc(100% - 1.7em) center;
            padding-right: 2.4em;
        }

        &[aria-disabled="true"] {
            display: none;
        }
    }

    // continent label
    .select2-results__group {
        cursor: default;
        display: block;
        padding: 0.4em 0;
        @include textstyle--default;
        @include textstyle--xs;
        @include textstyle--uppercase;
    }

    // highlight selection
    .select2-results__option--highlighted {
        background-color: $back-color--secondary;
    }

    // no results text
    .select2-results__message {
        @include textstyle--default;
        @include textstyle--s;
        padding-left: 1.214em;
    }

    // Hide arrow for disabled dropdown
    &.select2-container--disabled {
        .select2-selection {
            cursor: not-allowed;
        }

        .select2-selection__arrow {
            display: none;
        }
    }

    .select2-selection__clear {
        z-index: 2;
        @include textstyle--default;
    }
}

// Country dropdown -> Extension for normal nomos dropdown
.select2-container--nomos-country {
    max-width: none;

    .select2-dropdown {
        display: flex;
        flex-direction: column;
        box-shadow: $box-shadow--s; // Todo: Use proper value for this. We need some border otherwise you'll get lost

        &.select2-dropdown--above {
            flex-direction: column-reverse;
        }
    }

    // results list items
    .select2-results__option {
        margin: 0;
        padding: 0.4em 1em;
        @include textstyle--button;
    }
}

// dropdown for watchfinder sorting only for now
.select2-container--nomos-light {
    @include respond-to(grid-s) {
        left: 0 !important; // overwrite plugin settings
    }

    .select2-selection--single {
        @include textstyle--default;
        color: $text-color--default;
        padding: 0 2.4rem 0 0;
        outline: none;
        width: 100%;
        height: auto;
        background: none;
        border: none;

        .select2-selection__rendered {
            padding: 0;
        }
    }

    .select2-selection__label {
        display: inline-block;
        margin-right: 1rem;
    }

    .select2-selection__arrow {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 2rem;
        background: svg-inline(icon--nom-dropdown) center center no-repeat;
        background-size: 0.8em 1.28em;
        transition: transform $trans-time--s $trans-func--default;
        transform: scaleY(1);
    }

    .select2-dropdown {
        border: none;
        border-radius: 0;

        @include respond-to(grid-s) {
            width: 100vw !important; // overwrite plugin settings
            padding: 0 calc(#{grid-margin()});
        }

        @include respond-to(decent) {
            padding: 1em 0 2em 0;

            // Add a white area under the dropdown arrow
            &:after {
                @include pseudo();
                position: absolute;
                top: 0;
                right: -5rem;
                width: 5rem;
                height: 100%;
                background: $back-color--default;
            }
        }

        @include respond-to(grid-m) {
            // Add a white area under the dropdown arrow
            &:after {
                right: -2rem;
                width: 2rem;
            }
        }
    }

    .select2-results__options {
        @include grid-padding;
        padding-bottom: 4em;
        padding-top: 2em;
    }

    .select2-results__option {
        @include textstyle--button;
        padding: 0.35em 0;

        &[aria-selected="true"] {
            @include textstyle--book;
        }

        @include respond-to(decent) {
            text-align: right;
        }
    }

    &.select2-container--open {
        .select2-selection__arrow {
            transform: scaleY(-1);
        }
    }
}

// Handle invalid state
select.select2:invalid ~ .select2-container--focus {
    .select2-selection--single {
        background-color: $back-color--error; // Use background color in case of select fields
        color: $text-color--error;

        .select2-selection__rendered {
            @include fade-cover(right, 1.6em, $back-color--error);
        }
    }
}