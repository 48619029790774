// Nomos quick contact

.modal--quick-contact {
    .link-list {
        width: grid-width(grid-s, 8);
    }

    .text-container:not(:first-child) {
        padding-top: 0;
    }

    @include respond-to(decent) {
        // reset all modal styles
        position: fixed;
        bottom: calc(#{($action-bar-height-desktop - $action-button-size) / 2 + $action-button-size} - 1px);
        right: grid-position-right(grid-l, false); // grid-margin() does not work here because of scrollbar-view-width-issue
        padding: grid-padding-h(grid-l);
        padding-bottom: 0;
        overflow: hidden;
        visibility: hidden;
        transition: visibility 0s $trans-func--easing-out $trans-time--m;

        // put that thing out of view right away
        body:not(.has-quick-contact) & {
            transform: translate3d(0, 100vh, 0);
            transition: transform 0s linear $menu-trans-easing-out;
        }

        // reset modal styles
        // not used here
        .modal__header {
            display: none;
        }

        // remove transition stuff here
        .modal__body {
            transform: none;

            body:not(.has-quick-contact) & {
                visibility: hidden;
            }

            body.has-quick-contact & {
                visibility: visible;
            }
        }

        // reset .modal-body and children
        // used for dropdowns and dropdown labels
        .modal__body.modal__body--quick-contact {
            position: relative;
            top: auto;
            left: auto;
            width: auto;
            height: auto;
            overflow-y: visible;
            margin-bottom: -1px; // prevent grey line on bottom of modal
        }

        .modal__content--quick-contact {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            min-height: inherit;
            width: auto;
            padding: 0;
            box-shadow: $box-shadow--s;
            padding-bottom: 2rem;
            transform: translate3d(0, 100%, 0) scale(1, 0.3);
            transform-origin: bottom center;
            opacity: 0.5;
            transition: transform $trans-time--s $trans-func--easing-out,
            opacity $trans-time--s $trans-func--easing-out;
        }

        .grid-container {
            width: $quick-contact-width--en;

            html:lang(de) & {
                width: $quick-contact-width--de;
            }

            html:lang(fr) & {
                width: $quick-contact-width--fr;
            }

            html:lang(es) & {
                width: $quick-contact-width--es;
            }

            html:lang(it) & {
                width: $quick-contact-width--it;
            }

            html:lang(ja) & {
                width: $quick-contact-width--ja;
            }

            html:lang(zh-hans) & {
                width: $quick-contact-width--zh-hans;
            }

            html:lang(zh-hant) & {
                width: $quick-contact-width--zh-hant;
            }
        }

        .link-list {
            width: 100%;
        }

        p {
            @include textstyle--s;
        }

        // variation for checkout coming from the top
        .has-checkout-header & {
            bottom: auto;
            top: calc(#{($action-bar-height-desktop - $action-button-size) / 2 + $action-button-size} - 1px);
            padding: grid-padding-h(grid-l);
            transition: visibility 0s $trans-func--easing-out $trans-time--m;

            .modal__content--quick-contact {
                transform: translate3d(0, 4em, 0);
                opacity: 0;
            }
        }

        .has-quick-contact & {
            transition: none;
            visibility: visible;

            .modal__content--quick-contact {
                transition: transform $trans-time--m $trans-func--easing-out,
                    opacity $trans-time--m $trans-func--easing-out;
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }

        // animate content
        ul {
            > * {
                position: relative;

                .has-quick-contact & {
                    animation: animate-element-s $trans-time--m $trans-func--easing-out 1 backwards;
                    @include animation-delay(0.05s);
                }
            }
        }
    }

    @include respond-to(grid-m) {
        // reset all modal styles
        right: grid-position-right(grid-m, false); // grid-margin() does not work here because of scrollbar-view-width-issue
    }
}
