// Nomos img zoom
.has-gallery-zoom,
.has-gallery-zoom:not(.fix-body-scroll-bug) {
    overflow: hidden;
}

// gallery zoom
.gallery-zoom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    overscroll-behavior: contain;
    z-index: map-get($z-index, gallery-zoom);
    background-color: $back-color--tertiary;
    pointer-events: none;
    transition: opacity $trans-time--m $trans-func--default 0s,
                visibility 0s $trans-func--default $trans-time--m;

    // Remove flicker at end of fadeout transition
    .touch.is-safari &, // ios-detection fails on some newer ipads
    .ios & {
        transition: none;
    }

    &[aria-hidden="false"] {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
        transition: opacity $trans-time--m $trans-func--default;

        // Remove flicker at end of fadeout transition
        .touch.is-safari &, // ios-detection fails on some newer ipads
        .ios & {
            transition: opacity $trans-time--m $trans-func--default;
        }

        .gallery-zoom__content {
            opacity: 1;
        }
    }
}

.gallery-zoom__content {
    width: 100vw; // Initial width
    position: relative;
    transition: opacity $trans-time--s $trans-func--default;
    opacity: 0;
    cursor: zoom-out;
    display: flex;
    justify-content: center;
    align-items: center;

    .media-box {
        width: 100%;
        height: auto;

        img {
            position: relative;
            width: 100%;
            top: auto;
            left: auto;
            height: auto;
        }
    }

    // Add transition for smoother scrolling (Chrome only)
    .no-touch.is-chrome & {
        transition: transform $trans-time--m $trans-func--default,
                    opacity $trans-time--s $trans-func--default;
    }

    // Treat differently, if zoom canvas contains a video
    .contains-video & {
        height: 100%;

        .video-box {
            position: relative;
            width: 100%;
            height: 100%;
            max-width: 1920px;
            max-height: 1080px;

            > .media-box {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        .media-box {
            img {
                height: 100%;
            }
        }
    }
}

.gallery-zoom__close {
    position: absolute;
    top: 0;
    right: grid-position-right(grid-s, false);
    height: $modal-header-mobile;
    display: flex;
    align-items: center;
    z-index: 5;

    .btn {
        cursor: zoom-out;
    }

    @include respond-to(decent) {
        height: $modal-header-desktop;
    }

    @include respond-to(grid-m) {
        right: grid-position-right(grid-m, false);
    }

    @include respond-to(grid-l) {
        right: 0;

        .btn.btn--single-icon {
            padding: 4em 4em;
        }
    }
}


// zoom trigger
.zoom-trigger {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    .slide-box & {
        bottom: 6rem;
    }
}

// add zoom icon
.gallery__slideshow .flickity-viewport,
[data-zoom] {
    cursor: pointer;
    appearance: none !important; // is button, but should not have button style

    .no-touch & {
        cursor: zoom-in; // only declare for non touch to not loose iOS interactivity
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 4rem;
        height: 4rem;
        transition: opacity $trans-time--s $trans-func--default,
                    transform $trans-time--s $trans-func--default;
        background: svg-inline(icon--nom-zoom) center center no-repeat;
        background-size: 2rem 2rem;

        @include respond-to(grid-l) {
            width: 6rem;
            height: 6rem;
            background-size: 2.6rem 2.6rem;
        }
    }

    .no-touch & {
        &:after {
            opacity: 0;
            transform: scale(0.5);
        }

        &:hover,
        &:focus {
            &:after {
                opacity: 1;
                transform: none;
            }
        }
    }
}

.gallery__slideshow .flickity-viewport {
    @include respond-to(grid-s) {
        &:after {
            bottom: 0.4em;
            right: 0.6em;
        }
    }
}

[data-zoom-type="main"] {
    &:after {
        display: none;
    }

    @include respond-to(decent) {
        // extend clickarea
        &:before {
            content: "";
            position: absolute;
            top: -50vh;
            left: -50vw;
            bottom: -50vh;
            right: grid-width(grid-l, 0.8) * -1;

            // Don't extend in modal
            .modal--sidebar & {
                right: 0;
            }
        }

        .no-touch & {
            &:hover {
                .gallery-zoom {
                    transform: scale(1.25);

                    span {
                        transform: scale(0.8);
                    }
                }
            }
        }
    }

    @include respond-to(grid-m) {
        // extend clickarea
        &:before {
            right: 0;
        }
    }
}

[data-zoom-type="detail"],
[data-zoom-type="wristshot"],
.slideshow [data-zoom-type="wristshot-video"] {
    &:after {
        bottom: auto;
        right: grid-gutter(grid-s);
        top: calc(#{grid-width(grid-s, 8) * 0.7} - 4rem); // position on bottom right of landscape box

        .flickity__slide:last-child & {
            right: calc(#{grid-margin(grid-s)});
        }

        @include respond-to(grid-s) {
            .flickity__slide:not(.is-selected) & {
                opacity: 0;
                transform: scale(0.5);
            }
        }

        @include respond-to(grid-l) {
            right: (grid-width(grid-l) / 2) + (grid-gutter(grid-l) * 1.5);
            top: calc(#{grid-width(grid-l, 4) * 0.7} - 6rem); // position on bottom right of landscape box

            .flickity__slide:last-child & {
                right: calc(#{grid-margin(grid-l)});
            }

            .modal--sidebar .flickity__slide:not(:last-child) & {
                right: grid-gutter(grid-l) * 1.5;
            }
        }

        @include respond-to(grid-m) {
            right: grid-gutter(grid-m);
            top: calc(#{grid-width(grid-m, 4) * 0.7} - 4rem); // position on bottom right of landscape box

            .flickity__slide:last-child & {
                right: calc(#{grid-margin(grid-m)});
            }
        }
    }
}

[data-zoom-type="wristshot"],
.slideshow [data-zoom-type="wristshot-video"] {
    &:after {
        bottom: auto;
        top: calc(39em - 4rem); // position on bottom right of media-box

        @include respond-to(grid-l) {
            right: (grid-width(grid-l) / 2);
            top: calc(64.5em - 6rem); // position on bottom right of media-box
        }

        @include respond-to(grid-m) {
            top: calc(49em - 4rem); // position on bottom right of media-box
        }
    }
}

[data-zoom-type="wristshot-video"] {
    position: relative;

    &:after {
        right: 0 !important;
        z-index: 4; // overrule player settings
    }
}

[data-zoom-type="specs"] {
    &:after {
        bottom: auto;
        top: 0;
        width: 5rem;
        height: 5rem;

        @include respond-to(grid-l) {
            width: 8rem;
            height: 8rem;
        }

        @include respond-to(grid-m) {
            width: 6rem;
            height: 6rem;
        }
    }
}
