// scss-lint:disable all
.is-development {
    .ri-debug {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1000;
        display: inline-block;
        transform: translate3d(-50%, -50%, 0);

        border: solid 1px #000000;
        background-color: rgba(255, 255, 255, 0.8);

        & + .ri-debug {
            margin-top: 2rem;
        }

        li {
            position: relative;
            display: inline-block;
            font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace;
            font-size: 16px;
            font-weight: normal;
            color: #000000;
            padding: 2px;
            height: 20px;
            line-height: 1;

            &:not(:first-child) {
                margin-left: 5px;
            }

            &.width {
                &:before {
                    content: '￩￫';
                }
            }

            &.height {
                padding-left: 10px;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: '￪';
                }

                &:after {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    content: '￬';
                }
            }
        }
    }
}