// Nomos Breadcrumbs
.breadcrumbs {
    padding-right: 1.6em; // truncate
    height: 100%;
    display: flex;
    align-items: center;

    ul {
        display: flex;
    }

    li:not(:last-child) {
        &:after {
            @include pseudo();
            position: relative;
            @include textstyle--button;
            content: '›';
            padding: 0 0.8rem;
        }
    }

    a {
        @include textstyle--button;
        position: relative;

        &:before {
            @include pseudo();
            bottom: -2px;
            left: 0;
            height: 1px;
            width: 100%;
            background: rgba($text-color--default, 0.7);
        }
    }

    li:last-child {
        a {
            pointer-events: none;

            &:before {
                display: none;
            }
        }
    }

    // show very reduced breadcrumb on mobile
    @include respond-to(grid-s) {
        li:not(:first-child) {
            display: none;
        }

        li:first-child {

            // show arrow to the left here
            &:before {
                @include pseudo();
                position: relative;
                @include textstyle--button;
                content: '‹';
                padding: 0 0.35rem 0 0;
            }


            a {
                @include textstyle--button-s;

                &:before {
                    display: none;
                }
            }

            &:after {
                display: none;
            }
        }
    }

    // show reduced breadcrumb on small tablet
    @include respond-to(grid-m) {
        li:last-child {
            display: none;
        }

        li:nth-last-child(-n+2) {
            &:after {
                display: none;
            }
        }
    }
}
