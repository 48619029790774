// Carousel definitions

.carousel {
    @include grid-width(10,10,10);
    background: $back-color--tertiary;
    position: relative;
}

.carousel__nav-wrapper {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    @include respond-to(decent) {
        right: auto;
        max-height: calc(100% - 20rem);
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 1) 3rem, rgba(0, 0, 0, 1) calc(100% - 15rem), rgba(0, 0, 0, 0)); // fade list

        // handle overscroll on touch
        .touch & {
            overflow: auto;
            -webkit-overflow-scrolling: touch;
        }

        .no-touch & {
            overflow: hidden;
        }
    }

    @include respond-to(grid-l) {
        // intend top on big screens
        @media only screen and (max-aspect-ratio: 1000/540) { // a 1000px viewport should at least be 540px high
            mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 1) 6vh, rgba(0, 0, 0, 1) calc(100% - 15rem), rgba(0, 0, 0, 0)); // fade list
        }
    }
}

.carousel__nav {
    @include respond-to(decent) {
        padding: grid-padding-v(grid-l) 8em 14rem grid-padding-v(grid-l);

        // dont intialize slideshow on decent
        &.slideshow--carousel-nav {
            &:after {
                content: ' ';
            }
        }

        .flickity__slide {
            float: none !important;
        }

        // handle overflow
        @supports (--css: variable) { // limit to css properties
            --nav-replace: 0;

            .no-touch .carousel & {
                transform: translate3d(0, var(--nav-replace), 0);
            }
        }
    }

    @include respond-to(grid-l) {
        // intend top on big screens
        @media only screen and (max-aspect-ratio: 1000/540) { // a 1000px viewport should at least be 540px high
            padding-top: 12vh;
        }
    }
}

.carousel__nav-item {
    display: inline-block;
    padding: 1rem;

    &:focus {
        outline: none;
    }

    span {
        @include textstyle--teaser;
        @include textstyle--m;
        line-height: 1.4;
        position: relative;
        display: inline-block;

        &:before {
            @include pseudo();
            bottom: 0;
            left: 0;
            transform: scale(0, 1);
            height: 1px;
            width: 100%;
            opacity: 0;
            background: rgba($text-color--default, 0.8);
        }

        .is-selected & {
            &:before {
                transform: none;
                opacity: 1;
            }
        }

        html:not(.ie) .is-selected & {
            &:before {
                transition: transform 0.15s $trans-func--default,
                            opacity 0.15s $trans-func--default;
            }
        }
    }

    @include respond-to(decent) {
        padding: 0.4rem 0;

        span {
            @include textstyle--teaser-m;
        }
    }
}

.carousel__label {
    position: absolute;
    top: grid-padding-v(grid-l);
    right: grid-padding-v(grid-l);
    z-index: 1;

    h4 {
        @include textstyle--s;
        @include textstyle--uppercase;
        margin-top: 0.4rem;
    }
}

/* Fading behaviour for flickity */
.carousel__slideshow {
    // Implement fading behaviour for desktop only
    @include respond-to(decent) {
        // fade slideshow
        .flickity__slide {
            pointer-events: none;
            visibility: hidden !important;

            &.is-selected {
                pointer-events: auto;
                visibility: visible !important;
                z-index: 1;
            }
        }
    }
}

.carousel__slide {
    @include grid-width(10,10,10);
    position: relative;

    @include respond-to(decent) {
        .is-selected & {
            animation: animate-element-fade-in 2s $trans-func--default 1 both;
        }
    }
}

.carousel__img {
    position: relative;
    height: 26em;
    overflow: hidden;

    .media-box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: default !important;

        .carousel__slide--with-link & {
            cursor: pointer !important;
        }

        .no-ie .intersect-inview .is-selected & {
            animation: zoom-in-full 10s $trans-func--default 1 both;
        }
    }

    @include respond-to(grid-l) {
        min-height: 50em;
        height: 85vh;
    }

    @include respond-to(grid-m) {
        min-height: 50em;
        height: 90vh;

        @include respond-to(port) {
            height: 70vh;
        }
    }
}

.carousel__tooltip {
    .carousel__slide--with-link & {
        &,
        .media-box {
            cursor: pointer !important;
        }
    }

    @include respond-to(decent) {
        @include grid-width(10,7,4.5);
        background: $back-color--default;
        position: absolute;
        bottom: grid-padding-v(grid-l);
        right: grid-padding-v(grid-l);
        display: flex;
        align-items: center;
        box-shadow: $box-shadow--m;

        // intitially do not show
        opacity: 0;
        transform: translate3d(0, 2rem, 0);

        .is-selected & {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition: transform $trans-time--l $trans-func--easing-out $trans-time--l,
                        opacity $trans-time--s $trans-func--default $trans-time--l;

        }
    }
}

// decent only
.carousel__tooltip-img {
    width: 14em;
    height: 16em;
    margin: 0.4rem 0 0.4rem 0.4rem;
    flex: 0 0 auto;
}

.carousel__info {
    min-height: 14rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
        @include textstyle--default;
    }

    p + .btn,
    .btn +.btn {
        margin-top: 2rem;
    }

    @include respond-to(decent) {
        justify-content: center;
        align-content: flex-start;
        margin-bottom: 0.4rem; // center visually

        .btn {
            align-self: flex-start;
        }
    }
}
