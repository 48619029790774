// Nomos Coupon

.coupon-panel {
    position: relative;
    display: flex;
    align-items: center;
    align-content: stretch;
    border-bottom: 1px solid $border-color--light-1;
    line-height: $input-height-default - $baseline-offset;

    > span {
        @include textstyle--default;
        flex: 1 1 auto;
    }

    .coupon-panel__help .btn--help {
        margin-right: -0.7rem;
    }
}

.coupon-panel__help {
    position: absolute;
    top: 0;
    right: 0;
    line-height: $line-height;

    // push tooltip to the left
    .help__content {
        @include respond-to(decent) {
            right: -3rem;
            width: grid-width(grid-l, 5);

            article {
                padding: 2rem grid-spacing(grid-l, m) 2rem grid-padding-v(grid-l);
            }

            // Caret
            &:before {
                right: 2.8rem;
            }
        }

        @include respond-to(grid-m) {
            right: -2rem;
            width: grid-width(grid-m, 8);

            article {
                padding: 2rem grid-spacing(grid-m, m) 2rem grid-padding-v(grid-m);
            }

            // Caret
            &:before {
                right: 1.9rem;
            }
        }
    }
}




