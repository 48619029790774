// cross-links
.cross-links {
    @include respond-to(grid-l) {
        width: 100%;

        .grid-container {
            justify-content: space-between;

            header {
                width: 50%;
                order: -1;
                display: flex;
                align-items: center; // center with button group
            }

            .btn-group {
                order: -1;
            }
        }
    }

    @include respond-to(grid-m) {
        width: 100%;
    }
}

.cross-links-group {
    display: flex;
    width: 100%; // fix for flex and float parents
    @include grid-spacing(xs);
    flex-wrap: wrap;
    align-items: stretch;

    .teaser--banner {
        @include grid-width(10,5,5);
        padding-bottom: grid-spacing(grid-s, xs); // @todo make slideshow for crossellings ?

        .media-box + .text-container {
            flex-direction: column-reverse;
        }
    }

    .teaser--product-cross-selling {
        @include grid-width(5,3,3);
    }

    &.cross-links-group--products {
        .teaser--banner {
            @include grid-width(10, 4, 4);
        }
    }

    @include respond-to(grid-s) {
        .teaser--product-cross-selling {
            &:not(:last-child) {
                margin-right: grid-gutter-width(grid-s);
            }

            margin-bottom: grid-gutter-width(grid-s);
        }

        // make first item spread across the full width
        &.cross-links-group--products-3 {
            .teaser--product-cross-selling {
                .main-content--product-strap &,
                .main-content--product-other & {
                    &:first-child {
                        margin-right: 0;
                        width: 100%;

                        .text-container {
                            width: 80%;
                            margin: 0 auto;
                        }

                        .media-box-wrapper {
                            width: 70%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }

    @include respond-to(decent) {
        .teaser--banner {
            padding-bottom: 0;

            &:not(:last-child) {
                margin-right: grid-gutter-width(grid-l);
            }
        }

        &.cross-links-group--products {
            .teaser--banner {
                .media-box {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    padding-bottom: 0;
                    height: 100%;
                }
            }
        }

        .modal & {
            .teaser--banner {
                @include grid-width(10, 4, 3);
            }
        }
    }

    @include respond-to(grid-m) {
        .teaser--banner {
            &:not(:last-child) {
                margin-right: grid-gutter-width(grid-m);
            }
        }

        &.cross-links-group--products {
            .teaser--product:not(:last-child) {
                margin-right: grid-gutter-width(grid-m);
            }

            .teaser--product:last-child {
                margin-right: 0 !important; // satt hab ich’s!
            }
        }
    }
}
