// series-preview
.box-group--series {
    > :nth-child(1) {
        @include grid-spacing(xs);
    }

    > :nth-child(2) {
        @include grid-spacing(m);

        @include respond-to(grid-s) {
            @include grid-spacing(xs);
        }
    }
}
