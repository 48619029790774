// Search results modal
.main-search--form {
    position: relative;
    @include grid-width(8,3,2.5);

    input,
    button {
        // place above main-search--results
        z-index: 2;
        position: relative;
    }

    label {
        @include hide;
    }

    .form--ajax-loader {
        display: none; // @todo find loading spinner solution
    }

    fieldset {
        position: relative;
    }

    @include respond-to(grid-s) {
        .modal__header & {
            .input--search {
                @include textstyle--s;
                height: $input-height-s;
                line-height: $input-height-s;
            }
        }
    }
}

.main-search--results {
    position: absolute;
    width: calc(2rem + 100%);
    left: -1rem;
    top: -2rem;
    background: $back-color--default;
    transform: translate3d(0, -1em, 0);
    opacity: 0;
    transition: opacity $trans-time--m $trans-func--easing-out,
    transform $trans-time--m $trans-func--easing-out;

    // place below search input
    z-index: 1;

    &.is--active {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.results--list {
    padding: $input-height-s + 2.4rem 1rem 2rem;

    @include respond-to(decent) {
        padding-top: $input-height + 3.4rem;
    }
}

.result--item {
    overflow: hidden;
    position: relative;
    @include fade-cover();
    transition: background $trans-time--s $trans-func--default;

    &:after {
        transition: background $trans-time--s $trans-func--default;
        width: 4rem;
    }

    a {
        white-space: nowrap;
        @include textstyle--button-s;
        line-height: 2;
        display: block;
        padding: 0 1em;
        overflow: hidden;

        @include respond-to(decent) {
            @include textstyle--button;
        }
    }

    &.entry--no-results {
        @include textstyle--button-s;
        padding: 0 1em;
        line-height: 2;

        @include respond-to(decent) {
            @include textstyle--button;
        }
    }

    &.is--active {
        background: $back-color--tertiary;

        &:after {
            background: linear-gradient(to right, rgba($back-color--tertiary, 0), $back-color--tertiary);
        }
    }

    @include hover {
        background: $back-color--tertiary;

        &:after {
            background: linear-gradient(to right, rgba($back-color--tertiary, 0), $back-color--tertiary);
        }
    }
}

.entry--all-results {
    margin-top: 1rem;

    a {
        @include textstyle--button-s;

        &:after {
            content: '›';
        }
    }
}

// large main search
.main-search--large {
    @include grid-padding-h();
    @include grid-width(10, 8, 6);
    margin-top: -1.4em;

    .input--search {
        background-color: transparent;
        background-position: 100% center;
        padding: 0.5rem 3rem 0.5rem 0;
        height: auto;
        @include textstyle--teaser;
        border-bottom: 1px solid $border-color--default;
    }

    // Place pseudo button in middle
    .input--search + button {
        top: 50%;
        margin-top: -2.2rem;
        padding: 0;
    }

    .main-search--results {
        top: 2rem;
        transform: translate3d(0, -2em, 0);
    }

    .results--list {
        padding: $input-height + 2rem 2rem 2rem;
    }

    .result--item {
        a {
            padding: 0;
            @include textstyle--button;
        }
    }

    .entry--no-results {
        @include textstyle--button;
        padding: 0;
    }

    @include respond-to(decent) {
        margin-top: -3em;

        .main-search--results {
            top: 3rem;
            left: 0;
            width: 100%;
        }

        .input--search {
            @include textstyle--teaser-xl;
            padding: 1rem 5rem 1rem 0;
            background-size: 0.9em 1.44em;
        }

        .results--list {
            padding: $input-height + 5rem 2rem 2rem;
        }

        .result--item {
            a {
                @include textstyle--teaser;
                @include textstyle--teaser-m;
                line-height: 1.6;
                padding: 0;
            }
        }

        .entry--no-results {
            @include textstyle--teaser;
            @include textstyle--teaser-m;
            line-height: 1.6;
            padding: 0;
        }

        .entry--all-results {
            margin-top: 2rem;

            a {
                @include textstyle--button;
            }
        }
    }

    @include respond-to(grid-m) {
        .input--search {
            @include textstyle--teaser-l;
        }
    }
}
