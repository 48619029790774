.module-iframe {
    width: var(--width, 100%);

    @include breakpoint(grid-m) {
        width: var(--width-m, var(--width, 100%));
    }

    @include breakpoint(grid-l) {
        width: var(--width-l, var(--width, 100%));
    }
}

.module-iframe--default {
    border: none;
    height: var(--height, 100%);

    @include breakpoint(grid-m) {
        height: var(--height-m, var(--height, 100%));
    }

    @include breakpoint(grid-l) {
        height: var(--height-l, var(--height, 100%));
    }
}

.module-iframe--timify {
    @include grid-max-width(10, 5, 4);

    border: none;
    transition: height $trans-time--s $trans-func--default;
    width: 100%;
    min-height: 40rem;
    height: var(--adoptable-height, 40rem);
    overflow: hidden;
}