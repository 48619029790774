.payment-method-list {
    @include pseudo-list;
    @include grid-spacing(xs);
    display: flex;
    flex-direction: column;
}

.payment-method-list__item {
    width: 100%;

    &:not(:first-child) {
        @include grid-spacing(xs);
    }

    &.payment-method-list__item--with-logo {
        @include respond-to(grid-s) {
            .payment-method-list__item__box {
                padding: 3rem 0;
            }
        }
    }
}

.payment-method-list__item__box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2.4em 0;
    min-height: 10em;
    border: solid 1px $border-color--secondary;
    transition: border $trans-time--m $trans-func--default;
    cursor: pointer;
    position: relative;

    .payment-method-list__item[aria-selected="true"] & {
        border-color: $border-color--primary;
    }
}

.payment-method-list__item__input {
    width: grid-width-gutter(grid-s, 2);
    position: relative;

    // hide radio button
    input[type="radio"] {
        @include hide;
    }

    // show replacment icon
    &:after {
        @include pseudo();
        top: calc(50% - 1rem);
        left: calc(50% - 1rem);
        display: block;
        width: 2rem;
        height: 2rem;
        background: svg-inline(icon--nom-radio-no-2) center center no-repeat;

        .payment-method-list__item[aria-selected="true"] & {
            background: svg-inline(icon--nom-radio-yes) center center no-repeat;
        }
    }

    @include respond-to(grid-l) {
        width: grid-inner-width(grid-l, 1);
    }
}

.payment-method-list__item__description {
    width: grid-inner-width(grid-s, 7);

    label {
        @include textstyle--default;
        margin: 0;
    }

    p {
        @include textstyle--s;
        margin-top: 0;
    }

    @include respond-to(grid-l) {
        width: grid-inner-width(grid-l, 3);
    }
}

.payment-method-list__item__logo {
    position: absolute;
    top: 0.8em;
    right: 0.2em;

    @include respond-to(grid-l) {
        top: 1.4em;
        right: 0.4em;
    }
}

.payment-method-list__item__template {
    display: none;

    .payment-method-list__item[aria-selected="true"] & {
        display: block;
    }

    .payment-method-list__item:last-child[aria-selected="true"] & {
        border-bottom: solid 1px $border-color--secondary;
        margin-bottom: 1.4em;

        @include respond-to(decent) {
            margin-bottom: 0.4em;
        }
    }

    .payment-method-list__item__template__text {
        @include grid-inner-max-width(8, 5, 4);
    }
}

.payment-method-list__item__template__content {
    @include grid-padding-v;
}
