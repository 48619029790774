// Nomos Cookie message
.cookie-message {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    opacity: 0;
    background-color: $back-color--default;
    box-shadow: $box-shadow--m;
    transition: opacity $trans-time--m $trans-func--default 0s,
                transform $trans-time--m $trans-func--default 0s;
    z-index: map-get($z-index, cookie-message);
    transform: translate3d(0, 100%, 0);
    @include grid-bottom-spacing(s);

    .js body:not(.is-scrolled-decent) & {
        pointer-events: none;

        &[aria-hidden="false"] {
            transform: translate3d(0, 0, 0);
            opacity: 1;
            pointer-events: all;
            transition: opacity $trans-time--m $trans-func--default $trans-time--l,
            transform $trans-time--m $trans-func--default $trans-time--l;
        }
    }

    .grid-container {
        align-items: center;
    }

    .cookie-message__text,
    .cookie-message__action {
        @include grid-padding;
    }

    .cookie-message__text {
        flex: 1 1 auto;
    }

    .cookie-message__action {
        padding-bottom: 0;

        > * {
            margin-bottom: grid-spacing(grid-s, xs);
        }

        .btn:first-child {
            margin-right: 0.6em;
        }
    }

    @include respond-to(decent) {
        .cookie-message__text,
        .cookie-message__action {
            padding-bottom: 0;
        }

        .cookie-message__action {
            > * {
                margin-bottom: 0;
            }
        }
    }
}
