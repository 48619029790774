// Nomos navicon

.navicon {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: map-get($z-index, navicon);
    @include grid-margin-r;

    .btn {
        @include grid-padding-h;
        height: $menu-height-mobile;

        > span:not(.svg-ico) {
            width: $navicon-width;
            height: $navicon-height;
        }
    }

    .svg-ico--nom-close-small {
        display: none;
    }

    .has-main-navi & {
        .svg-ico--nom-navicon {
            display: none;
        }

        .svg-ico--nom-close-small {
            display: block;
        }
    }

    &.show-grid {
        z-index: map-get($z-index, grid-overlay);
    }
}
