.main-menu__section--product-overview {
    > section {
        &:not(:first-child) {
            @include grid-spacing(xl);
        }
    }

    .box-group {
        @include respond-to(decent) {
            @include clearfix();

            // Sectiontitle (Left)
            > *:nth-child(odd) {
                float: left;
                width: grid-width(grid-l, 4);
                position: sticky;
                top: $menu-top-padding-desktop;
                margin-top: 0;
            }

            // Productgroup (Right)
            > *:nth-child(even) {
                float: right;
                width: grid-width(grid-l, 4);
                margin-top: 0;
            }
        }

        @include respond-to(grid-m) {
            // Sectiontitle (Left)
            > *:nth-child(odd) {
                width: grid-width(grid-m, 4);
            }

            // Productgroup (Right)
            > *:nth-child(even) {
                width: grid-width(grid-m, 6);
            }
        }
    }
}