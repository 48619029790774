// Nomos dropdowns

// General dropdown definition
.dropdown {
    position: relative;
    z-index: map-get($z-index, dropdown);

    &.dropdown--account {
        z-index: map-get($z-index, dropdown--account);
    }

    // Hide create account for login account dropdown
    &[data-dropdown-style="center"] {
        .account-login__create-account,
        .account-login__watch-registration {
            display: none;
        }
    }
}

// dropdown Backdrop / mobile only
.dropdown__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $back-color--modal-back;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s $trans-func--default,
                visibility 0s $trans-func--default 0.5s;
    visibility: hidden;

    [aria-hidden="false"] & {
        opacity: 0.87;
        transition: opacity 1s $trans-func--default;
        visibility: inherit;

        @include respond-to(decent) {
            opacity: 0;
            pointer-events: all;
            cursor: pointer;
        }
    }

    [data-dropdown-style="center"][aria-hidden="false"] & {
        @include respond-to(decent) {
            opacity: 0.87;
            transition: opacity 1s $trans-func--default;
        }
    }
}

// dropdown Header / mobile only
.dropdown__header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: $modal-header-mobile;
    display: block;
    pointer-events: none;

    [aria-hidden="true"] & {
        transform: translate3d(0, -100%, 0);
        visibility: hidden;
        transition: transform $menu-trans-timing-out $menu-trans-easing-out,
                    opacity $menu-trans-timing-out $menu-trans-easing-out,
                    visibility 0s $menu-trans-easing-out $menu-trans-timing-out;
        background: $back-color--default;
    }
}

// dropdown Header Wrapper
.dropdown__header__wrapper {
    @include grid-container();
    margin: 0 auto;
    height: 100%;
}

// dropdown Header Content
// hidden on mobile menu
.dropdown__header__content {
    position: relative;
    background: $back-color--default;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    pointer-events: all;

    .btn-group {
        width: auto;
    }
}

// dropdown Header Title
.dropdown__header__title {
    @include grid-padding-h;
    flex: 1 0 grid-width(grid-s, 8); // have a min-width and also take care of .action-bar__buttons overflow on ios
    white-space: nowrap;

    h2 {
        @include textstyle--teaser;
        @include textstyle--s;
        @include textstyle--uppercase;
    }
}

// dropdown title inner wrapper
.dropdown-title {
    white-space: nowrap;
    @include grid-max-width(8,8,8);
}

// dropdown Close Button
.dropdown__close {
    @include respond-to(grid-s) {
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        cursor: pointer;
        height: 12em;
        display: none;
        align-items: center;
        justify-content: center;

        [aria-hidden="false"] & {
            display: flex;
        }
    }

    // close button for centered click dropdown
    @include respond-to(decent) {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;

        &.btn {
            display: none;
        }

        .btn--single-icon {
            padding: 1.5em;
        }

        [data-dropdown-style="center"] & {
            &.btn {
                display: block;
            }
        }
    }
}

// position values
// dropdown needs to be positioned fixed based on the active state of cart and login on desktop
//
$search-icon-width: unquote('2em + 2rem'); // comes first on the right size // already compensates for 1em negative offset
$search-icon-width-m: unquote('2.5em + 2rem'); // comes first on the right size // already compensates for 1em negative offset
$inactive-indent--l: 1em;
$dropdown-account-0--l: calc(#{$search-icon-width} + #{$action-button-size} - #{$inactive-indent--l});
$dropdown-account-1--l: calc(#{$search-icon-width} + #{$action-button-size});
$dropdown-cart-0--l: calc(#{$search-icon-width} - #{$inactive-indent--l});
$dropdown-cart-1--l: calc(#{$search-icon-width});
$dropdown-account--m: calc(#{$search-icon-width-m} + #{$action-button-size});
$dropdown-cart--m: calc(#{$search-icon-width-m});

// dropdown Body
.dropdown__body {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding-bottom: 12em;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    transition: transform $menu-trans-timing-in $menu-trans-easing-in;

    [aria-hidden="true"] & {
        transform: translate3d(0, -100%, 0);
        visibility: hidden;
        transition: transform $menu-trans-timing-out $menu-trans-easing-out,
                    visibility 0s $menu-trans-easing-out $menu-trans-timing-out;
    }

    @include respond-to(decent) {
        height: auto;
        width: auto;
        top: calc(#{($menu-height-desktop - $action-button-size - 2rem) / 2} + #{$action-button-size} - 1em);
        left: auto;
        right: grid-position-right(grid-l, false); // grid-margin() does not work here because of scrollbar-view-width-issue
        padding: 1em;
        z-index: 1;
        display: block;
        overflow-y: visible;
        transition: margin $trans-time--s $trans-func--easing-out;

        // position dropdown the the right in all cases
        // default: account dropdown
        margin-right: $dropdown-account-0--l;

        .has-cart-items & {
            margin-right: $dropdown-account-1--l;
        }

        [aria-hidden="true"] & {
            transform: translate3d(0, 0, 0);
            transition: visibility 0s $menu-trans-easing-out $menu-trans-timing-out;
        }

        [aria-hidden="true"][data-dropdown-style="center"] & {
            transform: translate3d(-50%, -50%, 0);
        }

        [data-dropdown-style="center"] & {
            top: 50%;
            left: 50%;
            right: auto;
            margin-right: 0;
            transform: translate3d(-50%, -50%, 0);
        }
    }

    @include respond-to(grid-m) {
        right: grid-position-right(grid-m, false); // grid-margin() does not work here because of scrollbar-view-width-issue

        // position dropdown the the right in all cases
        // default: account dropdown
        margin-right: $dropdown-account--m;

        .has-cart-items & {
            margin-right: $dropdown-account--m;
        }
    }
}

// dropdown Content
.dropdown__content {
    position: relative;
    background: $back-color--default;
    padding: $modal-header-mobile 0 grid-spacing(grid-s, xxl) 0;

    @include respond-to(grid-s) {
        min-height: calc(100vh - 12em);

        // fix for mobile safari vh-issue
        @supports(--css: variable) {
            .is-safari.touch & {
                min-height: calc((var(--view-height-offset) * 100vh) - 12em);
            }
        }

        .grid-container-dropdown {
            @include grid-container();
            margin: 0 auto;
            padding: grid-padding-h();
            height: 100%;
        }

        fieldset {
            max-width: grid-inner-width(grid-s, 8);
        }
    }

    @include respond-to(decent) {
        padding: 0;
        box-shadow: $box-shadow--s;
        width: grid-width(grid-l, 3);
        transition: transform $menu-trans-timing-in $menu-trans-easing-in,
                    opacity $menu-trans-timing-in $menu-trans-easing-in;

        .grid-container-dropdown {
            padding: 2em;
            overflow: hidden;
        }

        .dropdown--account-loggedin & {
            width: grid-width(grid-l, 2.5);
        }

        [aria-hidden="true"] & {
            transform: translate3d(0, 2em, 0);
            opacity: 0;
            transition: transform $menu-trans-timing-out $menu-trans-easing-out,
                        opacity $menu-trans-timing-out $menu-trans-easing-out;
        }

        [data-dropdown-style="center"] & {
            box-shadow: none;
            width: calc(#{grid-width(grid-l, 3)} + 4em);

            .grid-container-dropdown {
                padding: 4em;
            }
        }

        // animate content
        .account__list,
        fieldset {
            > * {
                animation: animate-element-xs $trans-time--m $trans-func--easing-out 1 backwards;
                @include animation-delay(0.05s);

                [aria-hidden="true"] & {
                    animation: none;
                    position: relative;
                }
            }
        }
    }

    @include respond-to(grid-m) {
        width: grid-width(grid-m, 5);

        [data-dropdown-style="center"] & {
            width: calc(#{grid-width(grid-m, 5)} + 4em);

            .grid-container-dropdown {
                padding: 4em;
            }
        }
    }
}
