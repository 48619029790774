// action-bar--checkout-steps
.action-bar--checkout-steps {
    margin-top: -1em;

    @include respond-to(decent) {
        margin-top: -3em;

        // limit width to show order summary header
        @supports (clip-path: inset(0 0 0 0)) {
            @include respond-to(decent) {
                clip-path: inset(0 calc(#{grid-width(grid-l, 4)} + #{grid-margin(grid-l)}) 0 0);
            }
        }
    }
}

.checkout-steps__list {
    @include pseudo-list;
    @include grid-padding-v--s;
    display: flex;
    background: $back-color--default;
    overflow: hidden;

    @include respond-to(grid-l) {
        max-width: grid-inner-width(grid-l, 5);
    }

    @include respond-to(grid-m) {
        max-width: grid-inner-width(grid-m, 6);
    }
}

.checkout-steps__entry {
    display: inline-block;

    a {
        display: flex;
    }

    span {
        @include textstyle--button-s;
        display: inline-block;

        @include respond-to(grid-l) {
            @include textstyle--button;
        }
    }

    &:not(:last-child) {
        margin-right: 1em;

        @include respond-to(decent) {
            margin-right: 2em;
        }
    }

    &.is-active {
        @include underline;
    }
}

.checkout-steps__index {
    &:after {
        content: '.';
        margin-right: 0.2rem;

        @include respond-to(grid-l) {
            margin-right: 1rem;
        }
    }
}
