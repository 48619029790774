// Checkout and Account panel group
.account-panel-group,
.checkout-panel-group {
    @include grid-spacing(m);

    // add lines to gird-s sections
    @include respond-to(grid-s) {
        margin-top: grid-spacing(grid-s, m);
        padding-top: grid-spacing(grid-s, m);
        border-top: 1px solid $border-color--light-1;
    }

    @include respond-to(grid-m) {
        &.account-panel-group {
            margin-top: grid-spacing(grid-m, l);
        }
    }

    .account-panel-group__headline,
    .checkout-panel-group__headline {
        width: 100%;

        h2 {
            @include textstyle--l;
        }
    }

    .account-panel-group__content,
    .checkout-panel-group__content {
        display: flex;
        flex-wrap: wrap;
        margin-top: grid-spacing(grid-s, m);

        @include respond-to(decent) {
            @include grid-spacing(s);
        }
    }

    .account-panel-group__footer {
        @include grid-spacing(s);
        @include grid-bottom-spacing(s);
    }

    .account-panel,
    .checkout-panel {
        @include grid-inner-width(10, 2.5, 2.5);

        .order-detail & {
            @include grid-inner-width(10, 3, 2);
        }

        &.account-panel--full,
        &.checkout-panel--full {
            width: 100%;
        }

        &.checkout-panel--bigger {
            @include grid-inner-width(10, 4, 4);
        }

        // give em some top space on the second line
        @include respond-to(grid-s) {
            &:nth-child(n + 2) {
                @include grid-spacing(m);
            }
        }

        @include respond-to(decent) {
            &:nth-child(even) {
                margin-left: grid-offset(grid-l, 0.5) + grid-padding(grid-l);

                .order-detail & {
                    margin-left: grid-padding(grid-l) * 2 + grid-gutter-width(grid-l);
                }
            }

            &:nth-child(n + 3) {
                @include grid-spacing(s);
            }
        }

        @include respond-to(grid-m) {
            &:nth-child(even) {
                margin-left: grid-width-gutter(grid-m, 0.5);

                .order-detail & {
                    margin-left: grid-padding(grid-m) * 2 + grid-gutter-width(grid-m);
                }
            }
        }
    }

    .account-panel {
        @include respond-to(grid-m) {
            width: grid-inner-width(grid-m, 3.5);
        }
    }

    &.checkout-panel-group--message,
    &.checkout-panel-group--acount {
        @include respond-to(grid-m) {
            .checkout-panel__body {
               width: grid-inner-width(grid-m, 5);
            }
        }
    }
}

// Checkout panel
.account-panel,
.checkout-panel {
    position: relative;

    .account-panel__header,
    .checkout-panel__header {
        position: relative;

        h3 {
            @include textstyle--default;
            @include textstyle--xs;
            @include textstyle--uppercase;

            @include respond-to(decent) {
                margin-top: 0.8rem; // align multiple rows’ brottext
            }
        }
    }

    .account-panel__body,
    .checkout-panel__body {
        position: relative;
        margin-top: 0.3em;
        overflow-wrap: break-word;

        h4 {
            @include textstyle--default;
        }

        p {
            margin-top: 0;
            @include textstyle--default;

            &.is-small {
                @include textstyle--s;
            }
        }

        textarea {
            margin-top: -0.6em;
            width: 100%;
            height: 18rem;

            @include respond-to(decent) {
                margin-top: 0;
            }
        }

        ul {
            li {
                position: relative;
                display: block;

                &:before {
                    @include pseudo(inline-block, absolute, '\00b7');
                    @include textstyle--default;
                    left: -1.5rem;
                    top: 0;
                }
            }
        }

        .confirm-password-select {
            margin-top: 2em;

            .advanced-checkbox {
                margin-top: 0.5em;
            }
        }

        // Apply margin only when there is a password selection. User might not be able to create an account
        .confirm-password-select + .confirm-newsletter-select {
            margin-top: 2em;

            @include respond-to(decent) {
                margin-top: 4em;
            }
        }
    }

    .checkout-panel__footer {
        @include respond-to(grid-s) {
            position: absolute;
            top: -0.8rem;
            right: 0;
        }

        @include respond-to(decent) {
            margin-top: 0.3em;
        }
    }

    .account-panel__footer {
        @include grid-spacing(xs);
        @include grid-bottom-spacing(xs);

        .btn + .btn {
            margin-left: 0.2em;
        }
    }
}
