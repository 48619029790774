// Nomos movie
.video-box {
    .media-box {
        height: 100%;
    }

    .plyr,
    .plyr__video-wrapper {
        height: 100%;
        position: relative;
    }

    // Make wrapper transparent, in order to show poster
    .plyr__video-wrapper {
        background: transparent;
        transition: background $trans-time--l $trans-func--default;
    }

    // Place video poster underneath plyr in order to show controls
    .plyr,
    .plyr--fallback {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    // custom responsive image video poster
    .video-poster {
        position: relative;
        z-index: 1;
        width: calc(100% + 2px); // prevent blitzer
        height: calc(100% + 2px); // prevent blitzer
        border-bottom: none;
        object-fit: cover;
        object-position: 50% 50%;
        font-family: 'object-fit: cover; object-position: 50% 50%;'; // ie Fix
        display: none;
        pointer-events: none;

        @media screen and (min-aspect-ratio: 14/10) {
            height: calc(100% + 2px); // prevent blitzer
        }
    }

    // Poster has to be placed absolute for non-fullscreen videos
    &:not(.full-bleed-box) {
        .video-poster {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    // show responsive image poster if plyr is initialised
    .video-box[data-player-status] .video-poster,
    .plyr--video + .video-poster,
    .plyr--fallback + .video-poster {
        display: block;
    }

    video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        background: transparent;
        height: calc(100% + 1px); // prevent blitzer
        width: auto;
        opacity: 1;
        transition: opacity $trans-time--l $trans-func--default;

        // some dirty fix for none object-fit: cover supporters
        // @todo: probably fix with js
        // 80% (.full-bleed-box) of 16/9
        @media screen and (min-aspect-ratio: 14/10) {
            height: auto;
            width: 100%;
        }

        // make image fill the box
        @supports (object-fit: cover) {
            width: calc(100% + 2px); // prevent blitzer
            height: calc(100% + 2px); // prevent blitzer
            border-bottom: none;
            object-fit: cover;

            @media screen and (min-aspect-ratio: 14/10) {
                height: calc(100% + 2px); // prevent blitzer
            }
        }
    }

    // Add pointer cursor to video, to make sure item is clickable
    &:not(.is-playing) video {
        cursor: pointer;
    }

    // Hide video on 'plyr' container
    .video-box[data-player-status] > .media-box > video,
    .plyr--video video,
    .plyr--fallback video {
        opacity: 0;
    }

    // Dont show transition if it's an autoplay video
    .plyr--autoplay {
        video {
            transition: none;
        }
    }

    // show black background
    &.text-color--inverted:not(.is-autoplay) .video-poster {
        &:before {
            @include pseudo();
            background: linear-gradient(to top right, rgba(black, 0.8), rgba(black, 0.4));
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            pointer-events: none;
            //transition: opacity $trans-time--l $trans-func--default;
        }
    }

    // click on section title should start video as well
    .section-title {
        z-index: 4;
        pointer-events: none;

        &:not(.is-autoplay) {
            transition: opacity $trans-time--m $trans-func--default,
                        transform $trans-time--m $trans-func--default;
            transform-origin: bottom center;
        }
    }

    // mask text to show play icon better
    // to this for inverted text only
    &.text-color--inverted {
        .head-wrapper {
            // set min height to only crop long texts
            min-height: 14rem;

            // mask text
            mask-image: linear-gradient(rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0.2));
            mask-size: 100% 100%;
        }
    }

    // Fallback Play button if we have not full support
    .video-play-fallback {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 7rem;
        height: 7rem;
        margin: -3.5rem 0 0 -3.5rem;
        z-index: 6;
        pointer-events: all;
        transition: opacity $trans-time--m $trans-func--default;
        opacity: 1;

        button {
            padding: 1rem;
            line-height: 1;
            border: 0;
            border-radius: 0;
        }
    }

    &.is-playing {
        &:not(.is-autoplay) {
            .section-title {
                opacity: 0;
                transform: scale(1.05);
                transition: opacity $trans-time--s $trans-func--default,
                            transform $trans-time--s $trans-func--default;
            }
        }

        video {
            opacity: 1;
        }

        .video-play-fallback {
            opacity: 0;
            pointer-events: none;
        }

        // Uncomment, when needed
        //.plyr__video-wrapper {
        //    background: #000;
        //}
    }

    // Full support video box
    &.video-box--full-support {
        // This should not be in DOM at all on full support. But safety first
        .video-play-fallback {
            display: none;
        }
    }
}

// video controls
.plyr--video {
    .plyr__controls {
        position: absolute;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        transform: translate3d(-50%, -50%, 0);
        z-index: 1;
        background: none;
        padding: 0;

        button {
            padding: 1rem;
            border: 0;
            border-radius: 0;
            margin-left: 0;
            transition: transform $trans-time--s $trans-func--default,
                        opacity $trans-time--s $trans-func--default;
            height: auto;
            line-height: 1;

            .svg-ico {
                display: inline-block;
                fill: transparent;
                stroke: currentColor;
                width: 5rem;
                height: 5rem;
            }

            // show correct mute button status
            &[data-plyr="mute"] {
                &[aria-pressed="true"] {
                    .svg-ico:nth-child(1)  {
                        display: none;
                    }
                }
                &[aria-pressed="false"] {
                    .svg-ico:nth-child(1) {
                        display: inline-block;
                    }
                    .svg-ico:nth-child(2) {
                        display: none;
                    }
                }
            }

            &:hover {
                background: none;
                color: inherit;
            }

            @include hover {
                transform: scale(1.06);
            }
        }

        .no-touch.no-ie.intersection-observer .intersect & {
            @include hide-before-animation();
        }

        .no-touch.no-ie.intersection-observer .intersect-inview & {
            @include show-for-animation();
            animation: animate-element-fade-in $trans-time--l $trans-func--default $trans-time--l 1 both;
        }
    }

    // Hide controls (again)
    // Hiding buttons instead of parents because these get controled via an animation as well
    &.plyr--hide-controls {
        .plyr__controls {
            button {
                pointer-events: none;
                opacity: 0;
            }
        }
    }

    // initially hide all buttons but play button
    &:not(.plyr--playing) {
        .plyr__controls {
            pointer-events: none;

            button:not([data-plyr="play"]) {
                opacity: 0;
                pointer-events: none;
            }

            [data-plyr="play"] {
                pointer-events: all;
            }
        }
    }
}

// hide video chrome for iOS on autoplaying videos
[data-autoplay="true"] {
    &::-webkit-media-controls {
        display: none !important;
        -webkit-appearance: none;
    }
}
