// Specific styles for Header Content Sections

// list-sections

.link-list-wrapper {
    @include respond-to(decent) {
        width: grid-width(grid-l, 6);
        display: flex;
        justify-content: space-between;

        .link-list {
            width: grid-width(grid-l, 3);
        }

        .nav-couple & {
            width: auto;
        }
    }

    @include respond-to(grid-m) {
        width: 100%;

        .link-list {
            width: grid-width(grid-m, 5);
        }
    }
}
