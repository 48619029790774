// Some styles are inherited from checkout.scss
// @see _checkout.scss

.waiting-list-container {
    @include grid-spacing(s);

    @include respond-to(grid-l) {
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row-reverse;
    }
}

.waiting-list__article {
    display: flex;
    flex-direction: column;
    @include grid-width(8, 4, 3);

    p {
        @include textstyle--default;
        margin-top: 0;

        @include respond-to(grid-l) {
            @include textstyle--m;
        }
    }
}

.waiting-list__article-image {
    .media-box {
        height: grid-width(grid-s, 8);
    }

    @include respond-to(grid-l) {
        .media-box {
            height: grid-width(grid-l, 3);
        }
    }

    @include respond-to(grid-m) {
        .media-box {
            height: grid-width(grid-m, 4);
        }
    }
}

h3.waiting-list__article-name {
    @include textstyle--default;
    @include textstyle--s;
    @include textstyle--uppercase;
    text-align: left;

    @include respond-to(grid-l) {
        @include textstyle--m;
    }
}

.waiting-list__article-back {
    margin-top: 0.2em;
}

.waiting-list__form {
    @include grid-spacing(m);
}

.waiting-list__response-success {
    @include textstyle--default;
    max-width: 85%;

    @include respond-to(decent) {
        @include textstyle--teaser;
    }
}