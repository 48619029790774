// Handle groups of boxes
// boxes flex to the sides
// use for teaser-box-groups
.simple-box-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    // revert top margins of boxes
    &:first-child {
        margin-top: grid-spacing(grid-s, m) * -1;
    }

    > .text-super,
    > .media-box,
    > .box-couple,
    > .teaser {
        @include grid-spacing(m);
    }

    @include respond-to(grid-l) {
        // revert top margins of boxes
        &:first-child {
            margin-top: grid-spacing(grid-l, m) * -1;
        }
    }

    @include respond-to(grid-m) {
        // revert top margins of boxes
        &:first-child {
            margin-top: grid-spacing(grid-m, m) * -1;
        }
    }
}

// boxes float left and right, usually creating a box on the left and one on the right
// use for handling box layouts (teaser etc) and their spacing
.box-group {
    @include clearfix();
    width: 100%;

    > * {
        float: left;
        @include grid-spacing(xl);
    }

    > :nth-child(even):not(.type-box) {
        float: right;
    }

    // define default text-teaser sizes
    > .text-super {
        @include grid-width(8, 6, 4); // -> box-m

        @include respond-to(grid-l) {
            min-height: grid-width(grid-l, 4) * 0.75; // at least a 3:4 ratio box to be filled
        }
    }

    @include respond-to(grid-s) {
        // always start with a box on the left on mobile
        nav & {
            > .box-couple:nth-of-type(odd) {
                float: left;
            }

            > .box-couple:nth-of-type(even):not(.type-box) {
                float: right;
            }
        }
    }
}

// handle groups of boxes with small gutter spacing
.box-group--tight {
    > * {
        @include grid-spacing(xs);
    }

    @include respond-to(grid-s) {
        .main-content {
            @include grid-spacing(xl);
        }

        > .box-couple:not(:last-of-type) {
            @include grid-bottom-spacing(l);
        }
    }
}

// have bigger spacing between big media-boxes and text boxes
.box-group--amply {
    @include respond-to(decent) {
        .box-couple {
            &,
            &.box-couple:nth-child(even) {
                .box-xl + .text-container {
                    width: grid-width(grid-l, 3);
                }
            }
        }
    }
}

// reversed box directions
.box-group--reverse {
    > * {
        float: right;
    }

    > :nth-child(even):not(.type-box) {
        float: left;
    }

    /* dismiss for now since it fixes it only half the way
    nav & {
        > * {
            float: left;
        }

        > :nth-child(even):not(.type-box) {
            float: right;
        }
    } */
}

// stop floating left and right
.box-group--straight {
    > * {
        float: none;
    }

    > :nth-child(even):not(.type-box) {
        float: none;
    }
}

// handle a box layout with full bleed
// only used as a secondary hero teaser row for now
.box-group--full-bleed {
    .media-box {
        height: 30rem; // @todo implement correct heights

        picture {
            transition: transform $trans-time--xl $trans-func--default;
            position: relative;
            height: 100%;
        }

        @include hover {
            picture {
                transition: transform $trans-time--l $trans-func--default;
                transform: scale(#{$zoom-scale});
            }
        }
    }

    .teaser {
        @include grid-spacing(m, grid-s);
        width: 100%;
    }

    @include respond-to(grid-l) {
        .teaser {
            @include grid-spacing(xs, grid-l);

            &:first-child {
                width: grid-width(grid-l, 6);

                .media-box {
                    width: grid-width(grid-l, 6, true);
                    margin-left: calc(#{grid-margin(grid-l)} * -1);
                }
            }

            &:last-child {
                width: grid-width(grid-l, 4);

                .media-box {
                    width: grid-width(grid-l, 4, true);
                    margin-right: calc(#{grid-margin(grid-l)} * -1);
                }
            }
        }

        &.box-group--smaller-bigger .teaser {
            &:first-child {
                width: grid-width(grid-l, 4);

                .media-box {
                    width: grid-width(grid-l, 4, true);
                }
            }

            &:last-child {
                width: grid-width(grid-l, 6);

                .media-box {
                    width: grid-width(grid-l, 6, true);
                }
            }
        }

        .media-box {
            height: 60rem;
        }

        h1,
        h2 {
            @include textstyle--teaser-m;
        }

        .modal & {
            .grid-container {
                width: 100%;
                margin: 0;
            }

            .teaser {
                &:first-child {
                    width: grid-width(grid-l, 5);

                    .media-box {
                        width: grid-width(grid-l, 5.5);
                        margin-left: calc(#{grid-width-gutter(grid-l, 0.5)} * -1);
                    }
                }
            }
        }
    }

    @include respond-to(grid-m) {
        .teaser {
            @include grid-spacing(xs, grid-m);

            &:first-child {
                width: grid-width(grid-m, 5);

                .media-box {
                    width: grid-width(grid-m, 5, true);
                    margin-left: calc(#{grid-margin(grid-m)} * -1);
                }
            }

            &:last-child {
                width: grid-width(grid-m, 5);

                .media-box {
                    width: grid-width(grid-m, 5, true);
                    margin-right: calc(#{grid-margin(grid-m)} * -1);
                }
            }
        }

        .media-box {
            height: 40rem; // @todo implement correct heights
        }

        h1,
        h2 {
            @include textstyle--teaser;
        }

        .modal & {
            .grid-container {
                width: 100%;
                margin: 0;
            }

            .teaser {
                &:first-child {
                    width: grid-width(grid-m, 5);

                    .media-box {
                        width: grid-width(grid-m, 5.5);
                        margin-left: calc(#{grid-width-gutter(grid-m, 0.5)} * -1);
                    }
                }

                &:last-child {
                    width: grid-width(grid-m, 4);

                    .media-box {
                        width: grid-width(grid-m, 4, true);
                    }
                }
            }
        }
    }
}
