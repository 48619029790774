@mixin button() {
    display: inline-block;
    @include textstyle--button-s;
    color: $text-color--default;
    line-height: $button-height-default - $baseline-offset - $button-padding * 2;
    padding: $button-padding 0 $baseline-offset + $button-padding 0;
    background: none;
    border: 0;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    box-shadow: none;
    border-radius: 0;
    width: auto;

    @include respond-to(decent) {
        @include textstyle--button;
    }
}

@mixin hide-focus() {
    &:focus,
    &:active {
        outline: none;
    }
}

.btn,
button,
input[type="submit"],
input[type="reset"] {
    @include button();

    // Primary button
    &.btn--super,
    &.btn--primary,
    &.btn--secondary {
        border: 1px solid $border-color--primary;
        padding-left: 1rem;
        padding-right: 1rem;

        @include respond-to(decent) {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    // Secondary button
    &.btn--secondary {
        border: 1px solid $border-color--secondary;
    }

    // Super primary button
    &.btn--super {
        line-height: $button-height-super - $button-padding * 2;
        border: none;
        background: $button-super;
        @include textstyle--book;
    }

    // Secondary button for mobile only
    &.btn--secondary-mobile {
        border: 1px solid $border-color--secondary;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        line-height: $button-height-s - $button-padding * 2;

        @include respond-to(decent) {
            border-color: transparent;
            padding-left: 0;
            padding-right: 0;
            line-height: $button-height-default - $button-padding * 2;
            transition: border $trans-time--s $trans-func--default,
                        padding $trans-time--s $trans-func--default;

            // show outline if only child of stucked action-bar (on family/series detail page)
            &:last-child {
                .is-stuck & {
                    border-color: $border-color--secondary;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }
    }

    // Secondary button for grid-m and grid-s only
    &.btn--secondary-but-desktop {
        @include respond-to(s--m) {
            border: 1px solid $border-color--secondary;
            padding: 0 2rem;
            line-height: $button-height-default - $button-padding * 2;

            .btn-group & {
                &:not(:last-child) {
                    margin-right: 1rem;
                }
            }
        }

        @include respond-to(grid-s) {
            padding-left: 1rem;
            padding-right: 1rem;
            line-height: $button-height-s - $button-padding * 2;

            .btn-group & {
                &:not(:last-child) {
                    margin-right: 0; // probably only one button shown, correct?
                }
            }
        }
    }

    // large button – keeps big type on mobile as well
    &.btn--l {
        @include textstyle--button;

        &.btn--super {
            @include textstyle--book;
        }
    }

    // small button
    &.btn--s {
        @include textstyle--button-s;
        line-height: $button-height-s - $button-padding * 2;
    }

    // full width button
    &.btn--full-width {
        width: 100%;
        text-align: center;
    }

    &.btn--text,
    &.btn--text-label {
        line-height: inherit;
        padding: 0;
    }

    &.btn--text-label {
        @include textstyle--default;
    }

    // button with arrow for direct links
    &.btn--link {
        &:after {
            content: '›';
            display: inline-block;
            transition: transform $trans-time--m $trans-func--default;
        }

        p + &,
        h1 + &,
        h2 + &,
        h3 + & {
            margin-top: 0.25em * $line-height; // half a line

            @include respond-to(decent) {
                margin-top: 0.5em * $line-height; // half a line
            }
        }

        // show plus icon instead if opens inside modal/sidebar
        &[data-modal],
        &[data-action] {
            &:after {
                content: '';
                width: 1em;
                height: 1em;
                background: svg-inline(icon--nom-more-small) no-repeat;

                background-size: 1.2em 1.2em; // @todo calc correct size
                background-position: center center;
                transform: translate3d(-0.1em, 0.22em, 0);
            }
        }

        @include hover {
            &:after {
                transition: transform $trans-time--s $trans-func--default;
                transform: translate3d(-0.25rem, 0, 0);
            }

            &[data-modal],
            &[data-action] {
                &:after {
                    transform: translate3d(-0.35rem, 0.22em, 0);
                }
            }
        }
    }

    // button variation with underline
    &.btn--underline {
        border-bottom: 1px solid rgba($text-color--default, 0.8);
        line-height: inherit;
        padding: 0;
    }

    // Button containing icon
    &.btn--icon {
        width: auto;
        @include hide-focus();

        // wrapper to use flexbox inside buttons
        // flex is not working inside buttons on iOS -> https://github.com/philipwalton/flexbugs#9-some-html-elements-cant-be-flex-containers
        > span:not(.svg-ico) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;

            > span:not(.svg-ico) {
                display: inline-block;
                margin: auto; // needed for grid

                &:last-child {
                    margin-left: 0.5em;
                }

                &:first-child {
                    margin-right: 0.5em;
                }
            }
        }

        &.btn--full-width {
            width: 100%;

            > span:not(.svg-ico) {
                > span:not(.svg-ico) {
                    &:last-child {
                        margin-right: 0;
                    }

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        &.btn--super {
            .svg-ico {
                height: $button-height-super - $button-padding * 2;
            }
        }
    }

    &.btn--product-teaser {
        @include textstyle--xs;
        padding: 0 0.5rem;

        @include respond-to(decent) {
            @include textstyle--s;
            padding: 0.8rem 0.5rem;
            margin-bottom: -0.8rem;
        }
    }

    &.btn--single-icon {
        line-height: 1;
        width: auto;
        @include hide-focus();
        @include grid-padding;
        font-size: inherit;

        // wrapper to use flexbox inside buttons
        // flex is not working inside buttons on iOS -> https://github.com/philipwalton/flexbugs#9-some-html-elements-cant-be-flex-containers
        > span:not(.svg-ico) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
        }

        nav & {
            height: 100%;
        }
    }

    &.btn--remove {
        border: 1px solid $border-color--light-1;
        padding: 1em;
    }

    // Button containing icon only
    &.btn--outline-icon {
        width: 100%;
        height: 100%;
        font-size: inherit;

        > span:not(.svg-ico) {
            background: $back-color--default;
            border: 1px solid $border-color--light-1;
        }
    }

    // increae / decrease icons (+/-)
    &.btn--change-qty {
        line-height: 1;
        font-size: inherit;
        width: 3rem;
        height: 3rem;
        @include hide-focus();
        border: 1px solid $border-color--light-1;

        // wrapper to use flexbox inside buttons
        // flex is not working inside buttons on iOS -> https://github.com/philipwalton/flexbugs#9-some-html-elements-cant-be-flex-containers
        > span:not(.svg-ico) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
        }
    }

    // help tooltip button
    &.btn--help {
        display: inline-block;
        width: 2.8rem; // correct icon size
        height: $input-height-default - $baseline-offset; // extend clickarea
        font-size: inherit;
        background: svg-inline(icon--nom-help) center center no-repeat;
        overflow: hidden;
        text-indent: -999em;
        padding: 0;
        margin-left: 1.2em;
        margin-bottom: $baseline-offset;
        flex: 0 0 auto; // dont let it shrink

        .select--s + & {
            height: $input-height-s - $baseline-offset;
            margin-left: 1em;
        }

        .help-small & {
            height: 3rem;
            margin-bottom: 0;
            margin-left: 0.5em;
            width: 2.2rem; // smaller icon size
        }

        .help-medium & {
            height: 2.3rem;
            margin-bottom: 0;
            margin-left: 0.5em;
            width: 2.2rem; // smaller icon size
        }
    }

    // home button on mobile steps navigation for checkout
    &.btn--home {
        padding: 0;
        transition: opacity $trans-time--s $trans-func--default;
        opacity: 0;
        pointer-events: none;

        > span:not(.svg-ico) {
            width: $navicon-width;
            height: $navicon-height;
        }

        .is-stuck & {
            opacity: 1;
            pointer-events: all;
        }
    }

    // home button on desktop header for checkout
    &.btn--return-to-store {
        @include textstyle--button;

        &:before {
            content: '‹';
            display: inline-block;
            transition: transform $trans-time--m $trans-func--default;
        }

        @include hover {
            &:before {
                transition: transform $trans-time--s $trans-func--default;
                transform: translate3d(0.25rem, 0, 0);
            }
        }
    }

    &.btn--danger {
        color: $text-color--error;
    }

    &[disabled] {
        color: $text-color--secondary;
        border-color: $border-color--secondary;
        cursor: default;
        cursor: not-allowed;
    }
}

.awesomplete + button,
.awesomplete + input[type="submit"],
.input--search + button {
    height: $input-height-default;
    line-height: $input-height-default;
    // text-indent: -9999rem; // destroys animations -> therefore color: transparent
    color: transparent;
    user-select: none;
    font-size: 0%;
    width: $input-height-default;
    position: absolute;
    top: 0;
    right: 0;
}

.btn-group {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;

    .btn:not(:last-child) {
        margin-right: 1.5rem;
    }

    .btn--text {
        height: auto;
        line-height: 1.6em;
        padding: 0.3em 0;
    }

    @include respond-to(decent) {
        width: auto;

        .btn:not(:last-child) {
            margin-right: 3rem;

            &.btn--secondary {
                margin-right: 1.5rem;
            }
        }
    }

    @include respond-to(grid-m) {
        .btn:not(:last-child) {
            margin-right: 2.5rem;

            &.btn--secondary {
                margin-right: 1rem;
            }
        }
    }
}

.btn-group--list {
    flex-direction: column;
    align-items: flex-start;
}

.text-ultra + .btn-group {
    @include grid-spacing(m);

    @include respond-to(grid-m) {
        @include grid-spacing(l, grid-m);
    }
}
