// Topic nav

.topics {
    display: flex;
}

.topic {
    @include textstyle--teaser;
    @include textstyle--s;
    line-height: 1.6;
    display: inline-block;
    margin-right: 1.5rem;
    @include underline-active;

    @include respond-to(decent) {
        @include textstyle--m;
        margin-right: 2rem;
    }
}

.topic-nav {
    overflow: hidden;
    display: flex;
    align-items: baseline;
    margin-left: calc((#{grid-margin(grid-s)} + #{grid-padding-h()}) * -1); // indent to the left viewport site
    margin-right: calc((#{grid-margin(grid-s)} + #{grid-padding-h()}) * -1); // indent to the right viewport site
    padding-left: calc((#{grid-margin(grid-s)} + #{grid-padding-h()})); // compensate for indent
    padding-right: calc((#{grid-margin(grid-s)} + #{grid-padding-h()})); // compensate for indent
    mask-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 3rem); // mask overflow text

    .touch & {
        // make toc-animation interactable only on touch
        overflow: visible;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        // hide scrollbars
        @include hide-scrollbars();
    }

    .topics {
        white-space: nowrap; // display flex will prevent ul from being wider than the .toc-nav parent

        // force some space after last li
        &:after {
            content: '.';
            color: transparent;
            pointer-events: none;
            display: inline-block;
        }
    }

    @include respond-to(decent) {
        margin-left: calc((#{grid-margin(grid-l)} + #{grid-padding-h(grid-l)}) * -1);
        margin-right: calc((#{grid-margin(grid-l)} + #{grid-padding-h(grid-l)}) * -1);
        padding-left: calc((#{grid-margin(grid-l)} + #{grid-padding-h(grid-l)})); // compensate for indent
        padding-right: calc((#{grid-margin(grid-l)} + #{grid-padding-h(grid-l)})); // compensate for indent
    }

    @include respond-to(grid-m) {
        margin-left: calc((#{grid-margin(grid-m)} + #{grid-padding-h(grid-m)}) * -1);
        margin-right: calc((#{grid-margin(grid-m)} + #{grid-padding-h(grid-m)}) * -1);
        padding-left: calc((#{grid-margin(grid-m)} + #{grid-padding-h(grid-m)})); // compensate for indent
        padding-right: calc((#{grid-margin(grid-m)} + #{grid-padding-h(grid-m)})); // compensate for indent
    }
}

