// Nomos filter

// Main Filter Wrapper
// is .modal as well but should be treated like one on mobile only
.filter {
    h4 {
        @include textstyle--default;
        @include textstyle--s;
    }

    @include respond-to(grid-l) {
        background: transparent;
        float: left;
        // margin-top: 2.2em; // margin here leads to miscalcs
        margin-top: 0;
        width: grid-width(grid-l, 2);
        padding: 1.8rem 0 4rem grid-padding-h(grid-l);
        z-index: map-get($z-index, filter-desktop);

        // handle sidebar scrolling
        @supports (position: sticky) and (--css: variable) { // limit to css properties
            .no-touch.no-edge & {
                --sticky-top: 0;
                position: sticky;
                top: var(--sticky-top);
                will-change: top;
            }

            // position absolute if scrolling up
            .no-touch.no-edge .has-locked-sidebar & {
                --absolute-top: 0;
                position: absolute;
                top: var(--absolute-top);
            }

            // animate sidebar changes
            .no-touch.no-edge .has-sidebar-animated & {
                transition: top $trans-time--m $trans-func--default;
            }
        }

        // implement sticky solution for touch devices
        @supports (position: sticky) { // limit to css properties
            .touch & {
                position: sticky;
                top: 0;
                width: grid-width(grid-l, 2) + 3em; // make room for scrollbar

                // add fade cover
                .modal__body--filter {
                    // bottom
                    @include fade-cover(bottom, 7em);

                    // top
                    &:before {
                        content: '';
                        position: absolute;
                        z-index: 1;
                        pointer-events: none;
                        background: linear-gradient(to bottom, $back-color--default, rgba($back-color--default, 0));
                        left: 0;
                        right: 0;
                        top: 0;
                        height: 2rem;
                    }
                }

                .modal__content--filter {
                    padding-right: 3em; // make room for scrollbar
                    max-height: calc(100vh - 2rem);
                    overflow: auto;
                    -webkit-overflow-scrolling: touch;
                    padding-bottom: 7em;
                }
            }

            .is-safari.touch & {
                .modal__content--filter {
                    max-height: calc((var(--view-height-offset) * 100vh) - 2rem);
                }
            }
        }

        // reset .modal stuff

        // remove transition stuff here
        .modal__body {
            transform: none;

            body:not(.has-filter) & {
                transform: none;
                visibility: visible;
            }
        }

        // reset .modal-body and children
        // used for dropdowns and dropdown labels
        .modal__body.modal__body--filter {
            position: relative;
            top: auto;
            left: auto;
            width: auto;
            height: auto;
            overflow-y: visible;
        }

        .modal__content--filter {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            min-height: inherit;
            width: auto;
            padding: 0;
            display: block;
        }
    }
}

// Filter Navigation Wrapper
.filter__nav {
    > ul {
        padding: 0 calc(#{grid-margin(grid-s)} + #{grid-padding(grid-s)});
        @include grid-spacing(m);
    }

    @include respond-to(decent) {
        > ul {
            margin-top: 0;
            padding: 0;
        }
    }

    @include respond-to(grid-m) {
        > ul {
            padding: 0 grid-padding(grid-m);
        }
    }
}

// single filter: new
.filter__panel--single {
}

.filter__panel {
    padding-right: grid-width-gutter(grid-s, 2);

    @include respond-to(grid-l) {
        padding-right: 0;
    }

    @include respond-to(grid-m) {
        padding-right: grid-width-gutter(grid-m);
    }
}

// Titles for Filter Panels
.filter__panel-title {
    padding-bottom: 1.4em;
    padding-top: 1.4em;
    user-select: none;

    label {
        @include textstyle--s;
        @include textstyle--uppercase;
    }

    @include respond-to(decent) {
        padding-bottom: 1.2em;
        padding-top: 1.2em;
    }
}

// Filter Panel option Lists
.filter__panel-option-list {
    padding-bottom: grid-spacing(grid-s, l);
}

.filter__panel--direct {
    padding-top: 1.4em;
    padding-bottom: grid-spacing(grid-s, m);
    user-select: none;

    @include respond-to(decent) {
        padding-top: 0.8em;
    }
}

// Filter Panel options
.filter__panel-option {
    margin-left: 0 !important; // overwrite .link-list default

    // Hide checkbox
    input[type="checkbox"] {
        @include hide;
        top: 2.2rem; // position for correct browser validate hint placement
        left: 1rem;
    }
}

.filter__panel-label {
    @include textstyle--default;
    @include textstyle--button;
    cursor: pointer;
    position: relative;
    display: flex;
    user-select: none;
    padding: 0 0 0 2.2rem;

    // count
    small {
        @include textstyle--s;
        @include textstyle--tabular-figures;
        display: inline-block;
        margin-left: 0.4rem;
        transition: opacity 1s $trans-func--default;

        &:empty {
            opacity: 0;
        }
    }

    // icon
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 1.4rem;
        height: 2rem;
        transform: translateY(0.1em);
        background: svg-inline(icon--nom-filter-no) center center no-repeat;
    }

    // checkbox is checked
    input[type="checkbox"]:checked + & {
        @include textstyle--book;
        letter-spacing: 0.15rem; // compensate for thicker font

        // reset count
        small {
            font-weight: $type-style--light;
            letter-spacing: 0.16rem;
        }

        &:after {
            background: svg-inline(icon--nom-filter-yes) center center no-repeat;
        }
    }

    // checkbox is disabled
    input[type="checkbox"]:disabled + & {
        color: $text-color--secondary;
        cursor: default;

        // fade icon
        &:after {
            opacity: 0.4;
        }
    }
}

// close filter on mobile / tablett
.filter__close-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $back-color--default;
    z-index: map-get($z-index, modal-header);
    transition: transform $menu-trans-timing-out $menu-trans-easing-out;

    body:not(.has-filter) & {
        transform: translate3d(0, 100% ,0);
    }

    .grid-container {
        min-height: $menu-height-mobile;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        padding: grid-spacing(grid-s, xs);
    }

    // Drop Shadow for mobile nav
    &:before {
        content: '';
        height: 0.3em;
        background: black;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        margin-top: -0.3em;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.03) 50%, rgba(0, 0, 0, 0.08));
    }

    @include respond-to(grid-m) {
        left: auto;
        width: calc(#{grid-width(grid-m, 5)} + #{grid-margin(grid-m, false)} + #{grid-width-gutter(grid-m, 0.5)});

        .grid-container {
            padding: grid-spacing(grid-m, xs) grid-width-gutter(grid-m, 0.5) + grid-padding-h(grid-m);
        }

        body:not(.has-filter) & {
            transform: translate3d(100%, 100% ,0);
        }
    }
}
