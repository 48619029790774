// Contact panels
.contact-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%; // fix for flex and float parents

    > div {
        @include grid-width(8, 5, 3);

        @include respond-to(grid-s) {
            padding-bottom: grid-spacing(grid-s, m);
        }

        @include respond-to(grid-l) {
            &:not(:nth-child(3n)) {
                margin-right: grid-gutter-width(grid-l);
            }
        }

        @include respond-to(grid-m) {
            &:not(:nth-child(2n)) {
                margin-right: grid-gutter-width(grid-m);
            }
        }
    }

    address {
        @include grid-spacing(s);
        line-height: 1.6;

        > * {
            display: block;
        }
    }
}
