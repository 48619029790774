.sidebar-cart {
    position: relative;
    height: 100%; // Use height from parent flex box to enable sticky footer

    // Add possibility to add grey background without padding left and right on mobile
    @include respond-to(grid-s) {
        width: 100vw;
        margin-left: calc(#{grid-margin()} * -1);
        margin-right: calc(#{grid-margin()} * -1);
        transition: background $trans-time--s $trans-func--default;

        > *:not(.sidebar-cart__footer-wrapper) {
            @include grid-container();
            margin: 0 auto;
        }
    }

    // handle expandability
    &[aria-expanded="false"] {
        @include respond-to(grid-s) {
            body:not(.page-checkout-confirm) & {
                background-color: $back-color--tertiary;

                .sidebar-cart__header {
                    &:before {
                        display: none;
                    }
                }

                .sidebar-cart__sub-header {
                    opacity: 1;
                    pointer-events: all;
                }

                .sidebar-cart__content {
                    max-height: 12em;
                    overflow: hidden;
                    margin-top: 0;
                }

                .sidebar-cart-action {
                    display: block;
                }

                .sidebar-cart__summary {
                    display: none;
                }
            }
        }

        @include respond-to(decent) {
            .expandable-sidebar-cart {
                max-height: 38em;
                overflow: hidden;
            }

            // show expand btn if more than 2 items
            .expandable-sidebar-cart + .sidebar-cart-action {
                display: block;
            }
        }
    }

    &.sidebar-cart--order {
        @include respond-to(grid-s) {
            margin-top: grid-spacing(grid-s, s);

            .sidebar-cart__content {
                margin-top: -1em;
            }
        }

        @include respond-to(grid-l) {
            width: grid-width(grid-l, 4);
        }

        @include respond-to(grid-m) {
            width: grid-width(grid-m, 6);
        }
    }

    // Sidebar cart header
    .sidebar-cart__header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 3em;

        h2 {
            @include textstyle--s;
            @include textstyle--uppercase;
        }

        a {
            margin: 0;
        }

        @include respond-to(grid-s) {
            &:before {
                @include pseudo();
                top: 0;
                left: grid-padding-h();
                right: grid-padding-h();
                height: 1px;
                background: $border-color--light-1;
            }

            .page-checkout-confirm & {
                h2 {
                    @include textstyle--teaser;
                }
            }
        }

        @include respond-to(decent) {
            height: $action-bar-height-desktop;
            background: $back-color--default;
            position: sticky;
            top: -1px;
            z-index: map-get($z-index, action-bar);
            margin-top: 0;
            padding-top: 2em;

            .order-detail & {
                position: relative;
                top: auto;
                padding-bottom: 0;
                height: auto;
            }
        }

        @include respond-to(grid-l) {
            h2 {
                @include textstyle--default;
                @include textstyle--uppercase;
            }
        }

        @include respond-to(grid-m) {
            .order-detail & {
                margin-top: grid-spacing(grid-m, m);
            }
        }
    }

    // sub header hint for mobile only
    .sidebar-cart__sub-header {
        padding-top: 0;
        padding-bottom: 0;

        // hide if not expanded
        opacity: 0;
        pointer-events: none;
    }

    .sidebar-cart__content {
        @include respond-to(grid-s) {
            margin-top: -4em;
            transition: margin $trans-time--m $trans-func--easing-out; // animate margin because transform conflicts with multiplying

            .page-checkout-confirm & {
                margin-top: -3em;
            }
        }
    }

    .sidebar-cart__content__list {
        @include pseudo-list;
    }

    .sidebar-cart__content__item {
        @include grid-padding-v--s;

        &:not(:last-child) {
            border-bottom: solid 1px $border-color--light-1;
        }

        @include respond-to(s--m) {
            @include clearfix();
        }

        @include respond-to(grid-l) {
            flex-wrap: nowrap;
            display: flex;
            justify-content: space-between;
        }

        @include respond-to(decent) {
            .order-detail & {
                &:first-child {
                    padding-top: 0;
                }
            }
        }
    }

    // Basket Item / Image
    .sidebar-cart__content__item__image {
        width: grid-width(grid-s, 4);
        margin-left: grid-padding(grid-s) * -1;
        float: left;

        .media-box {
            height: grid-width(grid-s, 4);
        }

        @include respond-to(grid-s) {
            .is-scrolled-decent & { // prevent startoff animation from breaking
                mix-blend-mode: multiply;
            }
        }

        @include respond-to(grid-l) {
            margin-left: grid-padding(grid-l) * -1;
            float: none;
            mix-blend-mode: unset;
            flex: 0 0 grid-width(grid-l, 1.5);

            .media-box {
                height: grid-width(grid-l, 1.5);
            }
        }

        @include respond-to(grid-m) {
            width: grid-width(grid-m, 1.5) - grid-gutter-width(grid-l);
            margin-left: grid-padding(grid-m) * -1;

            .media-box {
                height: grid-width(grid-m, 1.5) - grid-gutter-width(grid-l);
            }
        }
    }

    .sidebar-cart__content__item--service {
        .sidebar-cart__content__item__image {
            a {
                display: block;
                @include grid-padding-h();
            }
        }

    }

    // Basket Item / Name, Description etc.
    .sidebar-cart__content__item__description {
        margin-top: 2.6em;
        width: grid-inner-width(grid-s, 6);
        float: right;

        .cart-item-name {
            @include textstyle--teaser;
            @include textstyle--s;
            @include textstyle--uppercase;
            overflow-wrap: break-word; // fix for long product names
            hyphens: auto;
        }

        p {
            margin-top: 0;
        }

        .cart-item-back,
        .cart-item-reference,
        .cart-item-alternative-strap,
        .cart-item-alternative-clasp,
        .cart-item-strap-size {
            @include textstyle--default;
        }

        .cart-item-reference {
            padding-top: 0.2em;
        }

        @include respond-to(grid-l) {
            margin-top: 3em;
            margin-left: grid-gutter-width(grid-l);
            float: none;
            flex: 0 1 grid-width(grid-l, 1.5);

            .cart-item-name {
                @include textstyle--teaser-xs;
            }

            .cart-item-back {
                padding-top: 0.2em;
            }

            .cart-item-reference {
                padding-top: 0;
            }
        }

        @include respond-to(grid-m) {
            width: grid-inner-width(grid-m, 2.5);

            .order-detail & {
                float: left;
                width: grid-inner-width(grid-m, 3);
                margin-left: grid-gutter-width(grid-m);
            }
        }
    }

    // Basket Item / Price, Quantity
    .sidebar-cart__content__item__price {
        margin-top: 1.5em;
        width: grid-inner-width(grid-s, 6);
        float: right;

        p {
            margin-top: 0;
        }

        .cart-item-price {
            @include textstyle--default;
        }

        .cart-item-quantity {
            @include textstyle--s;
        }

        @include respond-to(grid-l) {
            margin-top: 3em;
            float: none;
            text-align: right;
            padding-left: grid-padding(grid-l);
            flex: 1 0 grid-inner-width(grid-l, 1) + grid-padding(grid-l);

            p {
                white-space: nowrap;
            }
        }

        @include respond-to(grid-m) {
            width: grid-inner-width(grid-m, 2.5);

            .order-detail & {
                margin-top: 2.2em;
                width: grid-inner-width(grid-m, 2) + grid-padding(grid-m);
                text-align: right;

                p {
                    white-space: nowrap;
                }
            }
        }
    }

    // Specific styles for engraving
    .sidebar-cart__content__item--engraving {
        border-top: 3px solid $back-color--primary;
        margin-top: -2px;

        @include respond-to(grid-m) {
            padding-top: 0;
        }

        @include respond-to(grid-l) {
            padding-bottom: grid-spacing(grid-l, m);
        }

        .sidebar-cart__content__item__description {
            margin-top: 0;
        }

        .cart-item-name {
            @include textstyle--xs;

            @include respond-to(decent) {
                @include textstyle--s;
            }
        }

        .sidebar-cart__content__item__price {
            @include respond-to(grid-l) {
                margin-top: 0;
                position: relative;

                &:before {
                    @include pseudo();
                    top: -2em;
                    right: 0;
                    border-top: solid 1px $border-color--light-1;
                    width: grid-width(grid-l, 2.5) - 3em;
                }
            }
        }
    }

    // sidebar mobile edit link
    .sidebar-cart__link {
        border-top: solid 1px $border-color--light-1;
        padding-top: 1em;
        padding-bottom: 1em;
    }

    // side-bar cart action / show full cart
    .sidebar-cart-action {
        margin-top: -12em;
        margin-bottom: -2em;
        padding-top: 10em;
        position: relative;
        cursor: pointer;
        display: none;

        > div {
            position: relative;
            text-align: center;
            background: $back-color--tertiary;
            @include grid-padding;
            transition: transform $trans-time--m $trans-func--default;
            transform: translate3d(0, 0, 0);

            &:after {
                @include pseudo();
                top: 0;
                left: 3%;
                right: 3%;
                border-top: 1px solid $border-color--default;
            }
        }

        .btn {
            transition: transform $trans-time--m $trans-func--default;
        }

        @include hover {
            > div {
                transition: transform $trans-time--xs $trans-func--default;
                transform: translate3d(0, 2em, 0);
            }

            .btn {
                transition: transform $trans-time--xs $trans-func--default;
                transform: translateY(-15%);
            }
        }

        @include respond-to(decent) {
            margin-bottom: 0;

            > div {
                background: $back-color--default;
                padding-bottom: 4em;

                &:after {
                    left: 4.5%;
                    right: 4.5%;
                }
            }
        }
    }

    // Sidebar cart footer wrapper
    .sidebar-cart__footer-wrapper {
        @include respond-to(grid-s) {
            background: $back-color--tertiary;
            border-bottom: 1px solid $border-color--secondary;
            padding: 0 calc(#{grid-margin()});
            @include grid-bottom-spacing(xxl);

            .order-detail & {
                background: transparent;
                border-bottom: none;
                padding-bottom: 0;
            }
        }

        @include respond-to(grid-m) {
            padding: 0 grid-padding-h(grid-m);
        }

        @include respond-to(grid-l) {
            position: sticky;
            top: $action-bar-height-desktop;
            padding: 0 grid-padding-h(grid-l);

            .order-detail & {
                top: auto;
                bottom: -1px;
            }
        }
    }

    // Sidebar cart footer
    .sidebar-cart__footer {
        @include grid-padding;
        padding-top: 3em;
        background-color: $back-color--tertiary;
        position: relative;

        .order-detail & {
            background-color: transparent;
            padding-top: 0;

            @include respond-to(decent) {
                background-color: $back-color--primary;
                padding-bottom: $action-bar-height-desktop;
                padding-left: 0;
                padding-right: 0;
            }
        }

        // Sidebar cart footer / Summary
        .sidebar-cart__summary {
            dl {
                display: flex;
                padding: 0;
                margin: 0;
                justify-content: space-between;

                dd {
                    text-align: right;
                    margin: 0 0 0 2em;

                    span {
                        white-space: nowrap;
                    }
                }

                span {
                    @include textstyle--default;
                    display: block;

                    &.is-small {
                        @include textstyle--xs;
                    }
                }
            }

            .sidebar-cart__summary__entry--voucher {
                span {
                    display: inline-block;
                }

                .voucher-code {
                    // margin-left: 2rem;
                }
            }

            .sidebar-cart__summary__entry--total,
            .sidebar-cart__summary__entry--price-to-pay {
                margin-top: 1em;

                span {
                    @include textstyle--book;
                }

                p {
                    @include textstyle--s;
                }
            }

            .sidebar-cart__summary__entry__hint {
                @include grid-spacing(s);

                @include respond-to(decent) {
                    margin-top: 2em;
                }

                @include respond-to(grid-l) {
                    max-width: grid-inner-width(grid-l, 3);
                }
            }

            .sidebar-cart__summary__splitit-info {
                display: flex;
                justify-content: flex-end;

                p {
                    text-align: right;
                }

                .btn--help {
                    margin-right: -0.4em;
                }
            }

            .sidebar-cart__summary__entry--fee + .sidebar-cart__summary__entry--subtotal,
            .sidebar-cart__summary__entry--discount-us + .sidebar-cart__summary__entry--subtotal {
                margin-top: 1em;
            }

            .order-detail & {
                border-top: solid 1px $border-color--light-1;
                padding-top: grid-padding-h(grid-s);

                @include respond-to(decent) {
                    padding-top: grid-padding-h(grid-l);
                    padding-bottom: grid-padding-h(grid-l);
                }
            }
        }
    }

    // Sidebar cart footer / proceed
    .sidebar-cart__proceed {
        @include grid-spacing(m);
        @include grid-bottom-spacing(s);

        p {
            @include textstyle--s;
        }

        .btn + .btn--secondary {
            margin-top: 1rem;
        }

        &.sidebar-cart__proceed--restricted {
            @include grid-spacing(s);

            p {
                @include textstyle--m;
                color: $text-color--error;
            }
        }
    }
}
