// Typography

/*
 * Interstate Gravur V01:
 *
 * optimierte Schrift für Lasergravuren für Bö6den,
 * Schrift basiert auf Interstate Regular,
 * Laufweite bei Ziffern wurde angepasst,
 * die 1 wurde ausgetauscht durch das Zeichen der Interstate Mono ersetzt
 *
 * bei Fragen: Ove Numrich, Thomas Höhnel}
 */

/* Generated by Font Squirrel (https://www.fontsquirrel.com) on April 24, 2019 */
@font-face {
    font-family: 'interstate_gravur_v01regular';
    src: url('fonts/interstategravurv01-regular-webfont.woff2') format('woff2'),
         url('fonts/interstategravurv01-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// Font stacks
$font-stack-grotesk: Helvetica, Arial, sans-serif;
// $font-stack-grotesk-narrow: Arial Narrow, sans-serif; // not needed any more after alignment with email design

// Type families
$type-fam--gotham: "Gotham A", "Gotham B", $font-stack-grotesk;
$type-fam--gotham-narrow: "Gotham Narrow A", "Gotham Narrow B", $font-stack-grotesk;
$type-fam--gotham-narrow-tabular: "Gotham Narrow Tabular A", "Gotham Narrow Tabular B", "Gotham Narrow A", "Gotham Narrow B", $font-stack-grotesk;
$type-fam--gotham-narrow-engraving: "Gotham Narrow Engraving A", "Gotham Narrow Engraving B", "Gotham Narrow A", "Gotham Narrow B", $font-stack-grotesk;
$type-fam--franklin-gothic-book: "franklin-gothic-urw", "Gotham Narrow A", "Gotham Narrow B", $font-stack-grotesk;
$type-fam--interstate-gravur: "interstate_gravur_v01regular", "Gotham Narrow A", "Gotham Narrow B", $font-stack-grotesk;

// Type weights
$type-style--extra-light: 200;
$type-style--light: 300;
$type-style--book: 400;

// Size variables
$line-height: 1.4;

// sizes for functional texts
$fontsize--default: 1.7rem;
$fontsize--xs: 1.2rem;
$fontsize--s: 1.4rem;
$fontsize--m: $fontsize--default;
$fontsize--l: 2.2rem;

// inbetween fontsize inside magazine
$fontsize--mag: 1.9rem;

// sizes for teaser and headline texts
$fontsize--teaser-default: $fontsize--l; // InDesign: Teasertext - klein
$fontsize--teaser-xs: $fontsize--default; // InDesign: Teaser - m
$fontsize--teaser-s: $fontsize--teaser-default; // InDesign: Teasertext - klein
$fontsize--teaser-m: 2.9rem; // InDesign: Teasertext
$fontsize--teaser-l: 3.4rem; // InDesign: Teasertext - Halbgroß
$fontsize--teaser-xl: 4.2rem; // InDesign: Teasertext - Groß

// sizes for quotes and text teaser
$fontsize--quote-default: 3.4rem; // InDesign: Zitat - m // made smaller here -> $fontsize--teaser-l
$fontsize--quote-s: 2.9rem; // $fontsize--teaser-m
$fontsize--quote-m: 4.2rem; // $fontsize--teaser-xl
$fontsize--quote-l: 6.4rem; // InDesign: Zitat - kleiner
$fontsize--quote-xl: 8rem; // InDesign: Zitat

// Font formats
@mixin textstyle--default {
    font-family: $type-fam--gotham-narrow;
    font-size: $fontsize--default;
    font-weight: $type-style--light;
    text-transform: none;
    letter-spacing: 0;
}

@mixin textstyle--xs {
    font-size: $fontsize--xs;
}

@mixin textstyle--s {
    font-size: $fontsize--s;
}

@mixin textstyle--m {
    font-size: $fontsize--m;
}

@mixin textstyle--l {
    font-size: $fontsize--l;
}

@mixin textstyle--teaser {
    font-family: $type-fam--gotham;
    font-size: $fontsize--teaser-default;
    text-transform: none;
    font-weight: $type-style--light;
    letter-spacing: 0;
}

@mixin textstyle--book {
    font-weight: $type-style--book;
}

@mixin textstyle--teaser-xs {
    font-size: $fontsize--teaser-xs;
}

@mixin textstyle--teaser-m {
    font-size: $fontsize--teaser-m;
}

@mixin textstyle--teaser-l {
    font-size: $fontsize--teaser-l;
}

@mixin textstyle--teaser-xl {
    font-size: $fontsize--teaser-xl;
}

@mixin textstyle--quote {
    font-family: $type-fam--gotham;
    font-size: $fontsize--quote-default;
    text-transform: none;
    font-weight: $type-style--extra-light;
}

@mixin textstyle--button {
    font-family: $type-fam--gotham-narrow;
    font-weight: $type-style--light;
    letter-spacing: 0.16rem;
    font-variant-ligatures: none;
    font-size: $fontsize--default;
}

@mixin textstyle--button-s {
    font-family: $type-fam--gotham-narrow;
    font-weight: $type-style--light;
    letter-spacing: 0.18rem;
    font-variant-ligatures: none;
    font-size: $fontsize--s;
}

@mixin textstyle--uppercase {
    font-family: $type-fam--gotham;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-weight: $type-style--light;
}

@mixin textstyle--tabular-figures {
    font-family: $type-fam--gotham-narrow-tabular !important;
}

@mixin textstyle--engraving-figures {
    font-family: $type-fam--gotham-narrow-engraving !important;
}

@mixin textstyle--engraving-preview {
    font-family: $type-fam--franklin-gothic-book !important;
}

@mixin textstyle--radial-engraving-preview {
    font-family: $type-fam--interstate-gravur !important;
}

// Defaults
body {
    -webkit-font-smoothing: antialiased;
    font-family: $type-fam--gotham-narrow;
    font-weight: $type-style--light;
    line-height: $line-height;
    color: $text-color--primary;
}

p {
    margin-top: 0;
    margin-bottom: 0;
    // hyphens: auto; // client does not want to use hyphens at all for now
    @include textstyle--default;
    @include textstyle--s;

    * + & {
        margin-top: 0.5em * $line-height; // half a line
    }

    @include respond-to(decent) {
        @include textstyle--default;
    }

    // bigger type
    &.text-big,
    .text-big & {
        @include textstyle--default;

        @include respond-to(decent) {
            @include textstyle--l;
        }
    }

    // smaller type
    &.text-small,
    .text-small & {
        @include textstyle--xs;

        @include respond-to(decent) {
            @include textstyle--s;
        }
    }

    // bigger type, teasing (not necessarily links – but with regular face instead of narrow)
    &.text-super,
    .text-super & {
        @include textstyle--teaser;
        @include textstyle--teaser-xs;
        hyphens: none;

        @include respond-to(grid-l) {
            @include textstyle--teaser-m;

            .hero--magazine-lander & {
                @include textstyle--teaser;
            }
        }

        @include respond-to(grid-m) {
            @include textstyle--teaser;
        }
    }

    // hudge type, teasing (not necessarily links – but with regular face instead of narrow)
    &.text-ultra,
    .text-ultra & {
        @include textstyle--teaser;
        hyphens: none;

        @include respond-to(grid-l) {
            @include textstyle--teaser-xl;
        }

        @include respond-to(grid-m) {
            @include textstyle--teaser-l;
        }
    }

    .section-title & {
        @include textstyle--default;

        @include respond-to(decent) {
            @include textstyle--l;
        }
    }

    // magazine type
    &.text-magazine,
    .text-magazine & {
        @include textstyle--teaser;

        @include respond-to(grid-s) {
            @include textstyle--m;
        }
    }

    &.text-magazine-answer,
    .text-magazine-answer & {
        @include textstyle--m;

        @include respond-to(decent) {
            @include textstyle--l;
        }
    }

    &.text-magazine-small,
    .text-magazine-small & {
        @include textstyle--teaser;
        @include textstyle--s;

        @include respond-to(decent) {
            @include textstyle--m;
        }
    }

    &.text-magazine-intro,
    .text-magazine-intro & {
        @include textstyle--teaser;
        @include textstyle--teaser-m;

        @include respond-to(s--m) {
            // add new size here for better readability
            font-size: $fontsize--mag;
        }
    }
}

a {
    color: inherit;
    text-decoration: none;

    h1 &,
    h2 &,
    h3 &,
    h4 &,
    p & {
        .hero &,
        .main-content &,
        .main-menu__section &,
        .alert &,
        .cookie-dialog & {
            border-bottom: 1px solid rgba($text-color--default, 0.8);
        }
    }
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: normal;
    margin: 0;
    @include textstyle--teaser;
    @include textstyle--teaser-xs;
}

h1,
h2,
h3 {
    @include respond-to(grid-s) {
        .teaser & {
            br {
                display: none;
            }
        }
    }
}

h1 {
    @include textstyle--teaser;

    .hero--magazine-lander &,
    .hero--magazine-article & {
        @include textstyle--teaser-l;
        font-weight: $type-style--extra-light;
    }

    &.display-head {
        @include textstyle--uppercase;
        @include textstyle--m;

        .hero--magazine-lander & {
            @include textstyle--teaser-l;
            line-height: 1.2;
            font-weight: $type-style--extra-light;
        }
    }

    @include respond-to(grid-l) {
        .page-title & {
            @include textstyle--teaser-l;
        }

        .page-title--s &,
        &.display-head {
            @include textstyle--teaser-m;
        }

        .hero--magazine-lander &,
        .hero--magazine-article & {
            &,
            &.display-head {
                font-size: $fontsize--quote-l;
            }
        }
    }

    @include respond-to(grid-m) {
        .page-title & {
            @include textstyle--teaser-m;
        }

        .page-title--s &,
        &.display-head {
            @include textstyle--l;
        }

        .hero--magazine-lander &,
        .hero--magazine-article & {
            &,
            &.display-head {
                font-size: $fontsize--quote-m;
            }
        }
    }
}

h2 {
    .text-container > &,
    .section-title & {
        @include textstyle--teaser;

        @include respond-to(grid-l) {
            @include textstyle--teaser-xl;
        }

        @include respond-to(grid-m) {
            @include textstyle--teaser-l;
        }
    }
}

h3 {
    .banner &,
    .section-title &,
    .text-container > & {
        @include textstyle--teaser-xs;

        @include respond-to(grid-l) {
            @include textstyle--teaser-m;
        }

        @include respond-to(grid-m) {
            @include textstyle--l;
        }
    }
}

h4 {
    .banner &,
    .section-title & {
        @include textstyle--uppercase;
        @include textstyle--s;

        @include respond-to(decent) {
            @include textstyle--m;
        }
    }

    .global-banner & {
        @include textstyle--xs;

        @include respond-to(decent) {
            @include textstyle--s;
        }
    }
}

h5 {
    .section-title & {
        @include textstyle--uppercase;
        @include textstyle--xs;

        @include respond-to(decent) {
            @include textstyle--s;
        }
    }
}

i {
    @include textstyle--default;
    @include textstyle--s;
    font-style: normal;

    @include respond-to(grid-l) {
        @include textstyle--m;
    }

    &.h5 {
        @include textstyle--uppercase;
        @include textstyle--xs;
        padding-bottom: 0.5em * $line-height; // half a line
        display: inline-block;
    }
}

.text-color--inverted {
    color: $text-color--inverted;
}

.no-mobile-breaks {
    @include respond-to(grid-s) {
        br {
            display: none;
        }
    }
}
