// Nomos Engraving
.engraving--content {
    > .grid-container {
        &:first-child {
            @include grid-bottom-spacing(m);
        }
    }
}

.engraving-panel {
    border-bottom: 1px solid $border-color--light-1;
    line-height: $input-height-default - $baseline-offset;

    // show top line if no other dropdown here
    .product__price + &,
    .product__price + .product__splitit-price + &,
    .product__price + .product__splitit-price + *[aria-hidden="true"] + &,
    .product__price + *[aria-hidden="true"] + & {
        border-top: 1px solid $border-color--light-1;
    }

    &[aria-hidden="true"] {
        display: none;
    }
}

.engraving-panel--add {
    a {
        display: block;
        background: svg-inline(icon--nom-more-small) no-repeat;
        background-size: 1.2em 1.2em;
        background-position: right center;
        width: calc(100% + 0.3rem); // center icon below select arrow

        span {
            @include textstyle--default;
            @include textstyle--xs;

            @include respond-to(grid-l) {
                @include textstyle--s;
            }
        }
    }
}

.engraving-panel--edit {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    a {
        white-space: nowrap;
    }

    [data-action="engraving:remove"] {
        margin-right: -0.2rem; // center icon below select arrow
        padding-right: 2rem;
        position: relative;

        // remove icon
        &:after {
            content: '';
            position: absolute;
            top: 1.2rem;
            right: 0;
            display: block;
            width: 2rem;
            height: 2rem;
            background: svg-inline(icon--nom-remove) center center no-repeat;
        }

        // skips to the next row
        @include respond-to(grid-m) {
            margin-top: -1rem;
        }
    }

    // skips to the next row
    @include respond-to(grid-m) {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;

        [data-action="engraving:remove"] {
            margin-top: -1rem;
        }
    }
}

// engraving preview
.engraving-configurator {
    width: 100%;
    @include grid-spacing(m);
}

.engraving-configurator__preview-area {

    figure {
        @include grid-width(10, 5, 5);

        .media-box:first-child {
            transform: scale(0.9);

            .engraving--form-filled & {
                transform: scale(0.95);
            }
        }
    }

    @include respond-to(grid-s) {
        figure {
            margin-top: grid-spacing(grid-s, m);
        }

        .text-container:last-child {
            margin-top: grid-spacing(grid-s, s);
        }
    }

    @include respond-to(decent) {
        @include clearfix();

        figure {
            float: left;
        }

        .text-container {
            float: right;
        }
    }

    @include respond-to(grid-m) {
        .form-grid__section {
            width: grid-inner-width(grid-m, 4);
        }

        .text-container:last-child {
            padding-top: grid-spacing(grid-m, m);
            float: none;
            clear: both;
        }
    }
}
.engraving-configurator__preview-area-ref,
.engraving-configurator__preview-logo {
    display: none;
}

.engraving-print-btn {
    position: absolute;
    bottom: grid-padding(grid-s);
    right: grid-padding(grid-s);
    opacity: 0;
    visibility: hidden;
    transition: opacity $trans-time--l $trans-func--default;

    .engraving--form-filled & {
        opacity: 1;
        visibility: visible;
    }

    // print looks dirty on mobile and IE
    // hide for now
    @include respond-to(grid-s) {
        display: none;
    }

    .ie & {
        display: none;
    }

    @include respond-to(grid-l) {
        bottom: grid-padding(grid-l);
        right: grid-padding(grid-l);
    }

    @include respond-to(grid-m) {
        bottom: grid-padding(grid-m);
        right: grid-padding(grid-m);
    }
}

.engraving-configurator__preview-area-content {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    margin-top: -0.6em; // center visually on visually centered watches

    &.engraving-configurator__preview-area-radial {
        margin-top: 0; // radial engravings are centered manually
    }

    > div, // Legacy
    .engraving-configurator__preview-area-text {
        position: relative;
        // align fontstyle to http://ihre-gravur.nomos-glashuette.com/gravur
        line-height: 1.12;
        color: #868686;
        fill: #868686; // If SVG element
        font-weight: 400;
        opacity: 0.7;
    }

    // Linear Engraving
    div.engraving-configurator__preview-area-text {
        // Use font "franklin gothic compressed" from typekit
        letter-spacing: 0.027em;
        @include textstyle--engraving-preview; // Only Font-Family
        font-size: 1.6em; // needs to be em to scale with the watch instead of the other fonts
        text-shadow: 0 0 0.2px rgba(0, 0, 0, 0.6); // add some 3d
        white-space: pre; // Sequences of white space are preserved

        // Adjust text for mobile
        @include breakpoint(s) {
            font-size: 0.85em;
        }

        // Adjust text for table portrait
        @include breakpoint(m) {
            font-size: 1.1em;
        }
    }

    // Radial Engraving
    text.engraving-configurator__preview-area-text {
        // Use customized font "interstate regular"
        // letterspacing via attr dx on text element
        @include textstyle--radial-engraving-preview; // Only Font-Family
        font-size: 1.01em; // will be overwritten by inline style
        text-shadow: 0 0 0.4px rgba(0, 0, 0, 0.6); // add some 3d

        > textPath {
            white-space: pre; // Sequences of white space are preserved
        }

        // Make font weight appear a little lighter on firefox
        @at-root .firefox & {
            stroke: white;
            stroke-width: 0.05px;
        }
    }

    svg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        transform: scale(0.9);
        transition: transform $trans-time--l $trans-func--default;

        .engraving--form-filled & {
            transform: scale(0.95);
        }

        path {
            fill: none;
            stroke: none;
            width: 100%;
            height: auto;
        }
    }

    div:empty:after {
        content :"\00a0";
    }

    @include respond-to(grid-l) {
        //transform: translateY(-1.2em); // center visually on visually centered watches
        margin-top: -1.2em; // center visually on visually centered watches

        > div,
        > .engraving-configurator__preview-area-text {
            font-size: 1.87em;
        }
    }

    @include respond-to(grid-m) {
        > div,
        > .engraving-configurator__preview-area-text {
            font-size: 1.27em;
        }
    }
}

input.input--engraving,
textarea.input--engraving {
    // Use font with extended charset
    @include textstyle--engraving-figures;

    &:invalid {
        @extend input.is-invalid;
    }
}

textarea.input--engraving {
    // Height will be set as 'rows' according to max chars
    min-height: auto;
    height: auto;
}

// Engraving suggestion
.engraving-configurator__suggestion-row {
    flex-wrap: nowrap;
}

.engraving-configurator__suggestion-select {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    height: 5rem;
    border-top: solid 1px $border-color--secondary;
    border-bottom: solid 1px $border-color--secondary;

    span {
        @include textstyle--default;
        display: inline-block;
    }

    .select {
        margin-top: 0;
        margin-left: 1.5rem;
    }
}

.engraving-configurator__suggestion-action {
    width: auto;
    margin-left: 2rem;
    display: none;

    .btn {
        width: 5rem;
        height: 5rem;
        padding: 0;
    }

    .has-occasion-selected & {
        display: inline-block;
    }
}

@media print {
    .modal__backdrop,
    .modal__content.modal__content--sidebar,
    body {
        min-height: 0;
        background: white;
    }

    .has-sidebar-page--engraving {
        margin: 0;
        padding: 0;

        .text-container {
            padding: 0;
        }

        .form-grid label {
            margin-left: 0;
        }

        /* MS EDGE requires important to be functional.... lame.. */
        > * { display: none !important; }

        > .modal--sidebar {
            display: block !important;

            // Hide unneeded backdrop for sharper print quality
            .modal__backdrop {
                display: none !important;
            }

            .modal__header {
                position: relative;
                height: auto;

                .modal-title,
                button {
                    display: none;
                }

                .modal__header__wrapper {
                    width: auto;
                    padding: 3rem 3rem 2rem 3rem;
                    height: auto;
                    margin-top: 8rem;
                }

                .print-title {
                    h2 {
                        @include textstyle--teaser;
                    }

                    p {
                        @include textstyle--default;
                        margin-top: 0;
                    }
                }
            }

            .modal__body {
                overflow: hidden;
                display: block;

                &.modal__body--sidebar {
                    position: relative;
                    top: auto;
                    left: auto;
                    width: auto;
                    height: auto;
                }

                > * { display: none; }

                > .modal__content {
                    display: block;
                    transform: none !important;

                    > * { display: none; }

                    > .engraving--content {
                        display: block;

                        .grid-container {
                            width: auto;

                            .form-grid__row {
                                margin-bottom: 3rem;
                            }
                        }

                        .engraving-configurator {
                            margin-top: 0;
                        }

                        .engraving-configurator__preview-logo {
                            display: block;

                            svg {
                                display: block;
                                position: fixed;
                                top: 2rem;
                                left: 50%;
                                width: 15rem;
                                height: 4rem;
                                margin-left: -7.5rem;
                            }
                        }

                        .engraving-configurator__preview-area-ref {
                            display: block;
                        }

                        .engraving-configurator__preview-area {
                            margin-top: 0;
                            padding: 0 3rem; // Why?? MZ
                            display: flex;
                            align-items: flex-start;

                            > * {
                                display: none;
                            }

                            .engraving-configurator__preview-area-content {
                                position: absolute;
                                bottom: 50%;
                                opacity: 0.5;

                                .engraving-configurator__preview-area-text {
                                    text-shadow: none;
                                    color: #000;
                                    fill: #000; // If SVG element
                                    opacity: 1;
                                    text-rendering: geometricPrecision;
                                }

                                // Linear Engraving
                                div.engraving-configurator__preview-area-text {
                                    font-size: 5.7pt;
                                    // line-height: 1.12; // Seems redundant MZ
                                    // letter-spacing: 0.027em; // Seems redundant MZ
                                }

                                // Radial Engraving
                                text.engraving-configurator__preview-area-text {
                                    // Font size is defined as inline style
                                }

                                svg {
                                    transform: scale(1);
                                }
                            }

                            .engraving-configurator__preview-area-inputs {
                                display: block;
                                order: 2;

                                input,
                                textarea {
                                    background: none;
                                    padding: 0;
                                    line-height: 2.0rem;

                                    &::placeholder {
                                        color: transparent;
                                    }
                                }

                                input {
                                    height: 2.0rem;
                                }

                                .form-grid__row {
                                    margin-bottom: 2rem;
                                }
                            }

                            .slide-box {
                                order: 1;
                                margin-right: 2rem;
                                display: block;
                                width: 25rem;
                                flex-shrink: 0;

                                .engraving-print-btn {
                                    display: none;
                                }
                            }

                            figure.slide-box {
                                fieldset {
                                    padding-bottom: 202%;

                                    .media-box {
                                        //transform: scale(1) translate3d(0, 5%, 0);
                                        transform: none; // No Transforms here: Fixes rendering bug
                                        height: 50%;
                                    }
                                    .media-box:nth-child(2) {
                                        //transform: scale(1) translate3d(0, 95%, 0);
                                        transform: none; // No Transforms here: Fixes rendering bug
                                        margin-top: 100%;
                                        height: 50%;
                                        display: block;
                                    }
                                }

                            }
                        }

                        .engraving-cms-content {
                            display: none;
                        }
                    }
                }
            }
        }

        .engraving--form-buttons {
            display: none;
        }
    }
}
