////
/// Collection of useful functions
/// @group Functions
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

/// Strips the unit from a size statement
///
/// @group humans-machines
/// @param {number} $value - The number to strip
/// @return {number} - The stripped value
@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

/// Replace `$search` with `$replace` in `$string`
///
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

/// Returns a setting for the given key and grid size
///
/// @group humans-machines
/// @param {string} $size [grid-s] - The grid size
/// @param {string} $key [columns] - The key of the setting
/// @return {mixed} - The value for the key and size
@function grid-setting($size: grid-s, $key: columns) {
    $value: 0;

    @if map-has-key($grid-config, $size) {
        $config: map-get($grid-config, $size);

        @if map-has-key($config, $key) {
            $value: map-get($config, $key);
        } @else {
            @warn 'Ooooops ... Could not find setting `#{$key}` in config for grid size `#{$size}`!';
        }
    } @else {
        @warn 'Ooooops ... `#{$size}` grid size is not defined!';
    }

    @return $value;
}

/// Returns the width of a grid container for the given size
///
/// @group humans-machines
/// @param {string} $size [grid-s] - The grid size
/// @return {number} - The width of the grid
@function grid-container($size: grid-s) {
    // Fetch settings based on grid size
    $column-count: grid-setting($size, columns);
    $gutter-count: ceil($column-count) - 1;
    $cell-width: grid-setting($size, cell-width);
    $gutter-width: grid-setting($size, gutter-width);

    @return $column-count * $cell-width + $gutter-count * $gutter-width;
}

/// Returns the size of the grid margin, based on the breakpoint
///
/// @group humans-machines
/// @param {string} $size [grid-s] - The grid size
/// @param {boolean} $raw [true] - Return raw expression?
/// @return {number} - The calc width expression or the raw expression
@function grid-margin($size: grid-s, $raw: true) {
    $width: grid-container($size);

    @if $raw {
        @return unquote('((100vw - #{$width}) / 2)');
    } @else {
        @return calc((100vw - #{$width}) / 2);
    }
}

/// Returns the right position of the grid / Takes scrollbar-vs-viewwidth issue
///
/// @group humans-machines
/// @param {string} $size [grid-s] - The grid size
/// @param {boolean} $raw [true] - Return raw expression?
/// @return {number} - The calc width expression or the raw expression
@function grid-position-right($size: grid-s, $raw: true) {
    @if $raw {
        @return unquote('50% - #{grid-width($size, 5)} - #{grid-gutter-width($size) * 0.5}');
    } @else {
        @return calc(50% - #{grid-width($size, 5)} - #{grid-gutter-width($size) * 0.5});
    }
}

/// Returns the size of the grid gutter, based on the breakpoint
///
/// @group humans-machines
/// @param {string} $size [grid-s] - The grid size
/// @return {number} - The gutter width
@function grid-gutter-width($size: grid-s) {
    @return grid-setting($size, gutter-width);
}

/// Returns the width of a grid cell for the given cell count and breakpoint
///
/// @group humans-machines
/// @param {string} $size [grid-s] - The grid size
/// @param {number} $cell-count [1] - The cell count
/// @param {boolean} $overlaps [false] - Indicates, if the element overlaps the grid margins.
/// @param {boolean} $extra-gutter [false] - Include extra gutter for width calculation?
/// @return {number} - The calc width expression or the raw expression
/// @return {number} - The width for the cell
@function grid-width($size: grid-s, $cell-count: 1, $overlaps: false, $extra-gutter: false, $raw: false) {
    // Use half cell?
    @if ($cell-count == half) {
        $cell-count: 0.5;
    }

    // Define gutter count
    $gutter-count: ceil($cell-count) - 1;

    // Check if an extra gutter space should be added
    @if $extra-gutter {
        $gutter-count: $gutter-count + 1;
    }

    // Fetch settings
    $cell-width: grid-setting($size, cell-width);
    $gutter-width: grid-setting($size, gutter-width);

    // Calculate cell width
    $width: $cell-count * $cell-width + $gutter-count * $gutter-width;

    // Return overlapping value?
    @if $overlaps {
        $overlap-value: grid-margin($size);
        @if $raw {
            @return unquote('(#{$overlap-value} + #{$width})');
        } @else {
            @return calc(#{$overlap-value} + #{$width});
        }
    } @else {
        @return $width;
    }
}

/// Returns the width of a inner grid cell for the given cell count and breakpoint
///
/// @group humans-machines
/// @param {string} $size [grid-s] - The grid size
/// @param {number} $cell-count [1] - The cell count
/// @return {number} - The width for the cell
@function grid-inner-width($size: grid-s, $cell-count: 1) {
    // Use half cell?
    @if ($cell-count == half) {
        $cell-count: 0.5;
    }

    // Define gutter count
    $gutter-count: ceil($cell-count) - 1;

    // Fetch settings
    $cell-width: grid-setting($size, cell-width);
    $gutter-width: grid-setting($size, gutter-width);

    // Calculate inner sizes
    $cell-width: $cell-width - grid-padding($size) * 2;
    $gutter-width: $gutter-width + grid-padding($size) * 2;

    // Calculate cell width
    $width: $cell-count * $cell-width + $gutter-count * $gutter-width;

    // Return width
    @return $width;
}

/// Returns the vertical spacing for the given size and
///
/// @group humans-machines
/// @param {string} $spacing [s] - Spacing size
/// @param {string} $size [grid-s] - The grid size
/// @return {number} - The spacing for the desired size
@function grid-spacing($size: grid-s, $spacing: xs) {
    // Fetch settings based on grid size
    $v-space: grid-setting($size, v-space);
    $space: map-get($v-space, $spacing);

    @return $space;
}

/// Shorthand method to get the width of a grid cell with extra gutter
///
/// @group humans-machines
/// @param {string} $size [grid-s] - The grid size
/// @param {number} $cell-count [1] - The cell count
/// @return {number} - The width for the cell with extra gutter
@function grid-width-gutter($size: grid-s, $cell-count: 1) {
    @return grid-width($size, $cell-count, false, true);
}

/// Returns the value for a cell offset
///
/// @group humans-machines
/// @param {string} $size [grid-s] - The grid size
/// @param {number} $cell-count [1] - The cell count
/// @return {number} - The offset for a cell
@function grid-offset($size: grid-s, $cell-count: 1) {
    @return grid-width($size, $cell-count, false, true);
}

/// Returns the value for a grid gutter width
///
/// @group humans-machines
/// @param {string} $size [grid-s] - The grid size
/// @return {number} - The width of the gutter
@function grid-gutter($size: grid-s) {
    @return grid-setting($size, gutter-width);
}

/// Returns the value for a cell offset
///
/// @group humans-machines
/// @param {string} $size [grid-s] - The grid size
/// @param {string} $type [horizontal] - The padding type to return
/// @return {number} - The padding for the size and type
@function grid-padding($size: grid-s, $type: horizontal) {
    // Define some aliases
    $type-alias: (
        top: 'vertical',
        left: 'horizontal',
        right: 'horizontal',
        bottom: 'vertical',
    );

    // Get key from alias
    @if map-has-key($type-alias, $type) {
        $type: map-get($type-alias, $type);
    }

    // Setup setting key
    $setting-key: 'padding-#{$type}';

    @return grid-setting($size, $setting-key);
}

/// Shorthand method for getting horizontal padding
///
/// @group humans-machines
/// @param {string} $size [grid-s] - The grid size
/// @return {number} - The horizontal padding for the size
@function grid-padding-h($size: grid-s) {
    @return grid-padding($size, horizontal);
}

/// Shorthand method for getting vertical padding
///
/// @group humans-machines
/// @param {string} $size [grid-s] - The grid size
/// @return {number} - The vertical padding for the size
@function grid-padding-v($size: grid-s) {
    @return grid-padding($size, vertical);
}

/// Returns the opposite direction of each direction in a list
/// @group humans-machines
/// @param {List} $directions - List of initial directions
/// @return {List} - List of opposite directions
@function opposite-direction($directions) {
    $opposite-directions: ();
    $direction-map: (
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
        center: 'center',
        ltr: 'rtl',
        rtl: 'ltr'
    );

    @each $direction in $directions {
        $direction: to-lower-case($direction);

        @if map-has-key($direction-map, $direction) {
            $opposite-directions: append($opposite-directions, unquote(map-get($direction-map, $direction)));
        } @else {
            @warn 'No opposite direction can be found for `#{$direction}`. Direction omitted.';
        }
    }

    @return $opposite-directions;
}
