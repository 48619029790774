// Social Share

.article-share {
    @include grid-spacing(xxl);
    @include grid-bottom-spacing(l);
}

.share__list {
    position: relative;
    display: flex;
    list-style-type: none;
    margin: 0;

    li {
        display: inline-block;
        line-height: 1;
    }

    a {
        padding: 0 0.8em;
        display: block;
    }
}

.share__dropdown {
    position: absolute;
    top: 5rem;
    right: -2rem;
    z-index: 1;
    background: #fff;
    box-shadow: $box-shadow--m;
    transform: translate3d(0, -2rem, 0);
    transition: transform $trans-time--s $trans-func--easing-out,
                opacity $trans-time--s $trans-func--easing-out;
    opacity: 0;
    pointer-events: none;

    .share__dropdown__backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        visibility: hidden;
        transform: translate3d(-100vw, -100vh, 0);
    }

    .share__list {
        z-index: 3;
        overflow: hidden;
        padding: 2em 2em;
    }

    li {
        opacity: 0;
        transition: opacity $trans-time--m $trans-func--linear;
    }

    // Caret
    &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: calc(2px - 2rem);
        right: 5rem;
        margin-left: 0.8em;
        border-style: solid;
        border-width: 1em;
        border-color: transparent transparent $back-color--default $back-color--default;
        transform-origin: 100% 100%;
        transform: rotate(118deg) scale(1, 0.8) skew(-30deg);
        box-shadow: -0.3em 0.3em 0.3em 0 rgba(0, 0, 0, 0.03);
    }

    &[aria-hidden="false"] {
        pointer-events: all;
        opacity: 1;
        transform: none;

        li {
            opacity: 1;
            @include transition-delay(0.05s, 0s, 4);
        }

        .share__dropdown__backdrop {
            visibility: visible;
            transform: none;
        }
    }
}