// Nomos Accordion

// accordions are mobile only by default
// being used inside the main menu and product pages, eg
.accordion:not(.big-accordion) {
    @include respond-to(grid-s) {
        border-top: 1px solid $border-color--light-1;
        border-bottom: 1px solid $border-color--light-1;
        position: relative;

        // lines are handled differently here
        .main-menu & { // main menu borders are handled in the header declarations since these are needed for non-accordion links as well
            border: none;
        }

        & + & {
            border-top: none;
        }

        .accordion__head {
            cursor: pointer; // make this clickable on iOS

            &.section-title {
                margin-top: 0;
                padding-top: 0;
                padding-bottom: 0;
                height: $menu-height-mobile;
                display: flex;
                align-items: center;
            }

            h3,
            h4,
            label {
                width: 100%;
                @include textstyle--default;
                @include textstyle--button;
                @include dropdown-arrow(transparent, false, true);
            }
        }

        .accordion__body {
            overflow: hidden;

            .main-menu & {
                border: none;
            }
        }

        &[aria-expanded="false"] {
            .accordion__body {
                visibility: hidden;
                height: 0;
            }

            &.section-fond {
                background: transparent;
            }

            .accordion__head {
                h3,
                h4,
                label {
                    @include dropdown-arrow();
                }
            }
        }
    }
}

// filter accordion – is an accordion on desktop as well
.accordion.accordion--filter:not(.big-accordion) { // we need some more specifity here
    border: none; // lines are handled differently here

    // show a line below -> Line looks shitty
    &:after {
        @include pseudo();
        position: absolute;
        bottom: 0;
        left: 0;
        width: grid-inner-width(grid-s, 8);
        height: 1px;
        background: $border-color--light-1;
        z-index: 1;
    }

    .accordion__head {
        label {
            cursor: pointer;
        }
    }

    // extend accordion styles to desktop for  filter
    @include respond-to(decent) {
        position: relative;

        // show a line below
        &:after {
            display: none; // Line looks shitty
        }

        .accordion__head {
            cursor: pointer; // make this clickable on iOS

            label {
                width: 100%;
                @include dropdown-arrow(transparent, false, true);
                padding-right: 1.2rem;
            }
        }

        .accordion__body {
            overflow: hidden;
        }

        &[aria-expanded="false"] {
            .accordion__body {
                visibility: hidden;
                height: 0;
            }

            .accordion__head {
                label {
                    @include dropdown-arrow();
                }
            }
        }
    }
}

// fix for flexbox content
.accordion__body {
    width: 100%;
}

// needed on filter for correct height calculation on web animations
.accordion__body-inner {
    overflow: hidden;
}

// big accordion for all viewports
// mainly used in FAQ-sections
.big-accordion {
    position: relative;
    border-bottom: 1px solid $border-color--light-1;
    @include grid-inner-width(10, 8, 6);
}

.big-accordion__head {
    padding: grid-spacing(grid-s, m) 3em grid-spacing(grid-s, m) 0;
    cursor: pointer;

    // add icon
    @include dropdown-arrow(transparent,false,true);
    background-size: 1.7em 2.72em; // mixin size x 1.7
    background-position: 100% grid-spacing(grid-s, m);

    [aria-expanded="false"] & {
        background-image: svg-inline(icon--nom-dropdown);
    }

    .head-wrapper {
        @include grid-inner-width(8, 5, 4);
    }

    h3 {
        @include textstyle--m;
    }

    @include respond-to(decent) {
        background-image: svg-inline(icon--nom-dropdown-big-reverse);
        padding: grid-spacing(grid-l, m) 5em grid-spacing(grid-l, m) 0;
        background-position: 100% 5em;
        background-size: 3em 2em;

        [aria-expanded="false"] & {
            background-image: svg-inline(icon--nom-dropdown-big);
        }

        h3 {
            @include textstyle--teaser-m;
        }
    }

    @include respond-to(grid-m) {
        padding: grid-spacing(grid-m, m) 5em grid-spacing(grid-m, m) 0;
        background-position: 100% 4em;

        h3 {
            @include textstyle--l;
        }
    }
}

.big-accordion__body {
    overflow: hidden;

    h3 {
        @include textstyle--m;
    }

    p {
        @include textstyle--default;
    }

    > div { // additional wrapper for correct calculation on webanimation API animation
        > div {
            @include grid-spacing(s);
        }

        > div:first-child {
            margin-top: 0;
        }

        > div:last-child {
            @include grid-bottom-spacing(xl);
        }
    }

    [aria-expanded="false"] & {
        max-height: 0;
        visibility: hidden;
    }

    [aria-expanded="true"] & {
        max-height: none;
    }

    @include respond-to(decent) {
        .text-big {
            h3 {
                @include textstyle--l;
            }

            p {
                @include textstyle--l;
            }
        }
    }
}
