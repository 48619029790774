// nomos footer
.footer--main {
    background: $back-color--tertiary;
    border-top: $border-color--light-1;
    overflow: hidden;

    // prevent footer beeing squeezed with sticky footer hack on .ajax-container parent
    @include respond-to(decent) {
        flex: 0 0 auto;
    }

    .grid-container {
        flex-direction: column;
    }
}

.footer--main__top {
    @include grid-spacing(m);

    @include respond-to(decent) {
        display: flex;
        justify-content: space-between;
        @include grid-bottom-spacing(l);
    }
}

.newsletter-signup {
    @include grid-inner-width(8, 4, 3);

    p {
        @include textstyle--teaser;
        @include textstyle--teaser-xs;
    }

    @include respond-to(grid-s) {
        padding-bottom: 2em;
    }

    @include respond-to(decent) {
        p {
            @include textstyle--teaser;
        }
    }

    @include respond-to(grid-m) {
        p {
            @include textstyle--m;
        }
    }

    form {
        margin-top: 1.5em;
    }
}

.newsletter-email-label {
    @include hide;
}

.newsletter-signup__extend {
    display: none; // @todo make newsletter panel extendable
}

.nav-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    @include grid-spacing(l);
    @include grid-inner-width(8, 5, 4);

    ul {
        @include grid-inner-width(4, 3, 2);
        @include grid-bottom-spacing(s);
    }

    li {
        @include list-hover();
    }

    a {
        @include textstyle--button-s;
        line-height: 2;
        display: block;
    }

    @include respond-to(grid-s) {
        ul {
            padding-bottom: 0;
        }
    }

    @include respond-to(decent) {
        margin-top: 0;
        margin-right: grid-width-gutter(grid-l, 1);

        a {
            @include textstyle--button;
        }
    }

    @include respond-to(grid-l) {
        // different setup here
        @include clearfix;
        display: block;

        .nav-footer__main {
            float: left;
        }

        .nav-footer__legal,
        .nav-footer__social {
            float: right;
        }

        .nav-footer__social {
            padding-bottom: $fontsize--default * 2; // one line
        }
    }

    @include respond-to(grid-m) {
        margin-right: 0;

        .nav-footer__main {
            max-width: grid-inner-width(grid-m, 3);
            padding-bottom: $fontsize--default * 1.5;
        }

        .nav-footer__social {
            max-width: grid-inner-width(grid-m, 2);
        }
    }
}

.footer--main__bottom {
    padding-bottom: $menu-height-mobile + 1rem;

    @include respond-to(decent) {
        margin-top: 0;
        @include grid-bottom-spacing(m);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @include respond-to(grid-l) {
        align-items: flex-end;
    }
}

.localisation-select {
    a {
        display: block;
        @include textstyle--default;
        @include textstyle--s;
        line-height: 1.6;
    }

    .country-select,
    .lang-select {
        display: block;
    }

    .country-select__label,
    .lang-select__label {
        display: inline-block;
        padding-right: 0.2rem;
    }

    .country-select__selection,
    .lang-select__selection {
        &:after {
            content: '›';
            display: inline-block;
            padding-left: 0.25em;
        }

        > span {

            &:before {
                content: '/';
                display: inline-block;
                padding-right: 0.1em;
            }
        }
    }

    @include respond-to(grid-s) {
        .svg-ico {
            display: none;
        }

        .country-select__selection {
            .main-menu & {
                &:after {
                    display: none;
                }
            }
        }
    }

    @include respond-to(decent) {
        a {
            @include textstyle--default;
            line-height: 2;
        }

        .svg-ico {
            vertical-align: text-bottom; // dont try this at home kids
            transform: scale(0.9);
            margin: 0 -0.1em;
            display: inline-block;

            svg {
                vertical-align: super; // dont try this at home kids
            }
        }

        .country-select,
        .lang-select {
            display: inline-block;
            padding-left: 0.2rem;
        }

        .lang-select {
            padding-left: 1.2rem;
        }
    }
}
