// Nomos product slider

// General gallery definition
.gallery {
    position: relative;
    width: 100vw;
    --thumb-size: 15vw;

    // is loading new variant?
    .is-variant-loading & {
        // fade out gallery
        opacity: 0.6;
        transition: opacity $trans-time--m $trans-func--default;

        // show loading spinner after a while
        &:after {
            @include loading-pulse(darken($back-color--secondary, 4%), 2rem, 1s, 2s);
            z-index: 1;
        }
    }

    @include respond-to(decent) {
        height: 100%;
        width: grid-width(grid-l, 6.5);

        .modal & {
            width: grid-width(grid-l, 5.8);
        }
    }

    @include respond-to(grid-l) {
        --thumb-size: 6em;
    }

    @include respond-to(grid-m) {
        --thumb-size: 8.4vw;
        width: grid-width(grid-m, 6);
        display: flex;
        flex-direction: column;

        .modal & {
            width: grid-width(grid-m, 5);
        }
    }
}

.gallery__slideshow {
    height: 125vw; // force portrait img
    position: relative;

    .flickity-viewport {
        height: 100% !important; // overwrite flickity calculations to not need recalculations on window resize

        &:after {
            pointer-events: none; // take care of zoom icon
        }
    }

    .flickity__slide {
        width: 100vw;
        height: 100%;
    }

    // Prevent lazy loading of slideshow images
    // by replacing them way out of the viewport
    &:not(.is-started) {
        > figure:not(:first-child),
        .flickity__slide:not(:first-child) figure {
            transform: translate3d(-80000%, 0, 0);
        }
    }

    figure {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $back-color--tertiary;

        .video-box,
        .media-box {
            flex: 1 1 auto;
            width: 100%;
            height: 100%;

            .no-touch & {
                cursor: zoom-in !important; // only declare for non touch to not loose iOS interactivity
            }
        }
    }

    video {
        transition: none !important; // no transition here
    }

    @include respond-to(decent) {
        // fade slideshow
        .flickity__slide {
            pointer-events: none;

            &.is-selected {
                pointer-events: auto;
                z-index: 1;
            }
        }
    }

    @include respond-to(grid-l) {
        height: 100%;

        .flickity__slide {
            width: grid-width(grid-l, 6.5);

            .modal & {
                width: grid-width(grid-l, 5.8);
            }
        }
    }

    @include respond-to(grid-m) {
        height: auto;
        flex: 1 1 auto;

        .flickity__slide {
            width: grid-width(grid-m, 6);

            .modal & {
                width: grid-width(grid-m, 5);
            }
        }
    }
}

// Custom img treatments
.gallery__main-img,
.gallery__soldier-img {
    .media-box {
        max-height: 80%; // take care of bog watches such as reg. 782
        margin-top: 3em; // make room for product-actions
        margin-bottom: 2vh; // center vertically
    }

    @include respond-to(grid-l) {
        .media-box {
            margin-top: 0;

            img {
                object-fit: contain;
            }
        }
    }
}

.gallery__hero-img {
    .media-box {
        max-height: 75%;
    }

    @include respond-to(grid-l) {
        .media-box {
            max-height: 100%;

            img {
                object-fit: contain;
            }
        }
    }
}

.gallery__caliber-img {
    .media-box {
        max-height: 90%;
        margin-top: 15%;
    }

    @include respond-to(decent) {
        .media-box {
            margin-top: 10%;

            img {
                object-fit: contain;
            }
        }
    }
}

.gallery__thumbs {
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    figure {
        height: var(--thumb-size);
        width: var(--thumb-size);
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 3px 3px 1rem 0;
        position: relative;

        &.is-selected {
            &:after {
                @include pseudo();
                bottom: -0.6rem;
                left: 20%;
                right: 20%;
                height: 1px;
                background: $text-color--default;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .media-box {
        flex: 1 1 auto;
        width: 100%;
        height: 100%;

        // decrease loading pulse size
        &:after {
            $_pulse-size: 1rem;
            top: calc(50% - #{$_pulse-size / 2}) !important;
            left: calc(50% - #{$_pulse-size / 2}) !important;
            width: $_pulse-size !important;
            height: $_pulse-size !important;
        }
    }

    @include respond-to(grid-l) {
        max-height: calc(100% - 6em);
        height: auto;
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        left: 3em;
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 1) 2em, rgba(0, 0, 0, 1) calc(100% - 3em), rgba(0, 0, 0, 0)); // fade list
        z-index: 2;

        // no scrolling on touch
        .no-touch & {
            overflow: hidden;
        }

        figure {
            margin: 0 1rem 0 0 !important;
            transition: opacity $trans-time--m $trans-func--default;
            opacity: 0.5;
            flex: 0 0 auto;
            cursor: pointer;

            @include hover() {
                opacity: 1;
            }

            &.is-selected {
                opacity: 1;
            }

            &:after {
                display: none !important;
            }
        }
    }

    @include respond-to(grid-m) {
    }
}

.gallery__thumb--video {
    .media-box {
        &:before {
            @include pseudo();
            background: linear-gradient(to top right, rgba(black, 0.45), rgba(black, 0.1));
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
    }

    .svg-ico {
        position: absolute;
        top: 30%;
        left: 30%;
        bottom: 30%;
        right: 30%;
        width: 40%;
        height: 40%;
        z-index: 1;
        stroke-width: 2px;
    }
}

.gallery__thumbs-inner {
    display: flex;
    transform: translate3d(-8000%, 0, 0); // offset thumbs to wait for slideshow before images load

    .flickity-enabled ~ .gallery__thumbs & {
        transform: none; // load lazy thumbs as slideshow is build
    }

    @include respond-to(grid-l) {
        flex-direction: column;
        align-items: center;
        flex: 0 0 auto;
        padding-top: 2em;

        // force bottom gap in auto scroll
        &:after {
            content: '';
            display: block;
            height: 3em;
            flex: 1 0 auto;
            width: 100%;
        }


        // handle overflow
        @supports (--css: variable) { // limit to css properties
            --nav-replace: 0;

            .no-touch & {
                transform: translate3d(0, var(--nav-replace), 0) !important;
            }
        }
    }
}
