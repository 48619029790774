.is-development {
    .asset-selection {
        position: fixed;
        bottom: 30vh;
        right: 4vw;
        max-width: 3rem;
        height: 3rem;
        border-radius: 100%;
        margin: 0;
        overflow: hidden;
        transition: max-width $trans-time--m $trans-func--default,
                    border $trans-time--m $trans-func--default;
        z-index: map-get($z-index, action-button);

        select {
            height: 3rem;
            line-height: 1;
        }

        &:hover {
            max-width: 16rem;
            border-radius: 0;
        }
    }
}
