// Teaser definitions
.teaser-line {
    span {
        &:before {
            content: ' — ';
        }
    }
}

.teaser {
    position: relative;
    display: flex;
    flex-direction: column;

    &:not(.no-teaser) {
        cursor: pointer;

        .hero & {
            cursor: auto;

            .text-container,
            .media-box {
                cursor: pointer;
            }
        }
    }

    // prices and more meta infos for products
    i {
        @include textstyle--button;
        @include textstyle--xs;
        text-align: center;
        position: relative;

        span {
            display: block;
            line-height: 1.6;
        }
    }

    // add basic rollover action
    // limit to grid-boxes because of parallax and over problems
    &.portrait-box, .portrait-box,
    &.portrait-box-l, .portrait-box-l,
    &.landscape-box, .landscape-box,
    &.landscape-box-l, .landscape-box-l,
    &.square-box, .square-box,
    &.wide-screen-box, .wide-screen-box {
        overflow: hidden;

        .media-box {
            transition: transform $trans-time--l $trans-func--default,
                        background $trans-time--l $trans-func--default;
        }

        @include hover {
            &:not(.no-teaser):not(.zoom-img) {
                .media-box {
                    transition: transform $trans-time--m $trans-func--default;
                    transform: scale(#{$zoom-scale-s});
                }
            }
        }
    }

    @include respond-to(decent) {
        // limit text box width
        &:not(.type-box):not(.teaser--xl):not(.teaser--product) {
            .text-container:not(.btn-group) {
                @include grid-max-width(10, 8, 4);
            }
        }

        // limit text box with for hudge teaser
        &.teaser--xl {
            .text-container {
                @include respond-to(grid-l) {
                    max-width: 90rem; // 8 rows on indesign viewport (1400px)
                }

                @include respond-to(grid-m) {
                    max-width: 65rem; // 9 rows on smallest viewport (1000px)
                }
            }
        }

        h1,
        h2,
        h3 {
            @include textstyle--teaser;
        }

        i {
            @include textstyle--s;
        }
    }
}

.teaser--m {
    @include respond-to(grid-l) {
        h1,
        h2,
        h3 {
            @include textstyle--teaser-m;
        }
    }

    @include respond-to(grid-m) {
        h1,
        h2,
        h3 {
            @include textstyle--teaser;
        }
    }
}

.teaser--l {
    p {
        @include textstyle--teaser;
        @include textstyle--teaser-xs;
        hyphens: none;
    }

    @include respond-to(grid-l) {
        h1,
        h2,
        h3 {
            @include textstyle--teaser-l;
        }

        p {
            @include textstyle--teaser-m;
        }

        &.box-couple {
            .text-container {
                @include grid-spacing(m, grid-l);
            }
        }
    }

    @include respond-to(grid-m) {
        h1,
        h2,
        h3 {
            @include textstyle--teaser-m;
        }

        p {
            @include textstyle--teaser;
        }
    }
}

.teaser--xl {
    @include respond-to(grid-l) {
        h1,
        h2,
        h3 {
            @include textstyle--teaser-xl;
        }
    }

    @include respond-to(grid-m) {
        h1,
        h2,
        h3 {
            @include textstyle--teaser-l;
        }
    }
}

.teaser--product {
    @include grid-width(5, 4, 3);
    position: relative;
    overflow: hidden;

    .slideshow &,
    .no-slideshow &,
    .product-group & {
        @include grid-spacing(m);
    }

    h3,
    h4,
    h5 {
        @include textstyle--button-s;
        text-align: center;
        margin: 0;
    }

    i {
        display: block;

        // visually center cta with arrow
        &.btn--link {
            transform: translateX(0.4em);

            &:after {
                margin-right: 0;
            }
        }
    }

    .teaser__ref {
        transition: opacity $trans-time--m $trans-func--default,
                    transform $trans-time--m $trans-func--default;
    }

    // like and compare buttons
    .teaser__actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 0.5rem;

        @include respond-to(decent) {
            margin-top: 0;

            // center teaser-actions on Reference info and fade in on rollover for non-touch devices
            .no-mobile.no-touch & {
                opacity: 0;
                // transform: scale(0.95) translate3d(0, -200%, 0);
                transform: translate3d(0.5em, -100%, 0);
                transition: opacity $trans-time--s $trans-func--default,
                            transform $trans-time--s $trans-func--default;
                margin-bottom: -2rem; // compensate for misplacement
                transform-origin: center bottom;
                flex-wrap: nowrap; // prevent line break destroying animation

                .btn {
                    margin: -0.8rem 0; // compensate for button padding
                }
            }
        }
    }

    @include hover {
        @include respond-to(decent) {
            .teaser__ref {
                transition: opacity $trans-time--xs $trans-func--default,
                            transform $trans-time--xs $trans-func--default;
                opacity: 0;
                // transform: scale(1.05);
                transform: translate3d(-0.5em, 0, 0);
            }

            .teaser__actions {
                opacity: 1;
                transform: translate3d(0, -100%, 0);
                transition: opacity $trans-time--l $trans-func--default,
                            transform $trans-time--l $trans-func--default;
            }
        }
    }

    // handle replacement for products with price tag
    &.with-price {
        .teaser__actions {
            @include respond-to(decent) {
                .no-mobile.no-touch & {
                    transform: translate3d(0.5em, -200%, 0);
                }
            }
        }

        @include hover {
            @include respond-to(decent) {
                .teaser__actions {
                    transform: translate3d(0, -200%, 0);
                }
            }
        }
    }

    .media-box {
        background: transparent;
        @include ratio-box(100%);
        transition: transform $trans-time--l $trans-func--default;
        transform: translate3d(0, 0, 0);
    }

    .text-container {
        position: relative;
    }

    .teaser-title + .media-box {
        @include grid-spacing(s);
    }

    .teaser-title {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        span {
            @include textstyle--s;
        }
    }

    // add rollover action
    @include hover {
        &:not(.zoom-img) {
            .media-box {
                transition: transform $trans-time--m $trans-func--default;
                transform: scale(#{$zoom-scale}) translate3d(0, -1%, 0);
            }
        }
    }

    @include respond-to(grid-s) {
        &:not(:nth-child(2n)):not(.teaser--product-single):not(.teaser--product-double):not(.teaser--product-cross-selling):not(.teaser--product-select) {
            margin-right: grid-gutter-width(grid-s);
        }
    }

    @include respond-to(grid-l) {
        &:not(:nth-child(3n)):not(.teaser--product-single):not(.teaser--product-double):not(.teaser--product-overview):not(.teaser--product-select) {
            margin-right: grid-gutter-width(grid-l);
        }

        h3,
        h4,
        h5 {
            @include textstyle--button;
        }

        .teaser-title {
            span {
                @include textstyle--default;
            }
        }
    }

    @include respond-to(grid-m) {
        &:not(:nth-child(2n)):not(.teaser--product-store):not(.teaser--product-single):not(.teaser--product-double):not(.teaser--product-overview):not(.teaser--product-select) {
            margin-right: grid-gutter-width(grid-m);
        }
    }
}

// Product teaser in watchfinder
.teaser--product-store {
    background: $back-color--tertiary;
    overflow: hidden;

    .product-group & {
        @include grid-spacing(xs);
    }

    @include respond-to(mobile) {
        // hide if product group is not expanded yet
        &[data-hidden-mobile="true"] {
            display: none;

            .no-touch.no-ie.intersection-observer & {
                display: flex;
                position: absolute; // dont just hide element / reposition it to trigger intersect correctly
                bottom: 0;
                left: -10000vw; // exaggerate to not trigger lazy load
                visibility: hidden;
                pointer-events: none;
            }
        }

        // show cropped if within last row of non-expanded product group
        @for $i from 4 through 30 {
            [data-expanded-mobile="false"][data-show-mobile="#{$i}"] & {
                &:nth-child(#{$i}),
                &:nth-child(#{$i - 1}) {
                    @include product-crop;
                }
            }
        }
    }

    @include respond-to(grid-l) {
        width: grid-width(grid-l, 2.5) - grid-gutter-width(grid-l) / 2;

        &:nth-child(3n) {
            margin-right: -1px; // fix subpixel rendering issue causing problems in firefox
        }

        &[data-hidden-decent="true"] {
            display: none;

            .no-touch.no-ie.intersection-observer & {
                position: absolute; // dont just hide element / reposition it to trigger intersect correctly
                bottom: 0;
                left: -10000vw; // exaggerate to not trigger lazy load
                visibility: hidden;
                pointer-events: none;
            }
        }

        // show cropped if within last row of non-expanded product group // 3 per row
        @for $i from 6 through 90 {
            [data-expanded-decent="false"][data-show-decent="#{$i}"] & {
                &:nth-child(#{$i}),
                &:nth-child(#{$i - 1}),
                &:nth-child(#{$i - 2}) {
                    @include product-crop;
                }
            }
        }
    }

    @include respond-to(grid-m) {
        width: grid-width(grid-m, 3.333) - grid-gutter-width(grid-m) / 3 * 2;

        &:not(:nth-child(3n)) {
            margin-right: grid-gutter-width(grid-m);
        }

        &:nth-child(3n) {
            margin-right: -1px; // fix subpixel rendering issue causing problems in firefox
        }

        &[data-hidden-tablet="true"] {
            display: none;

            .no-touch.no-ie.intersection-observer & {
                position: absolute; // dont just hide element / reposition it to trigger intersect correctly
                bottom: 0;
                left: -10000vw; // exaggerate to not trigger lazy load
                visibility: hidden;
                pointer-events: none;
            }
        }

        // show cropped if within last row of non-expanded product group // 3 per row
        @for $i from 6 through 60 {
            [data-expanded-tablet="false"][data-show-tablet="#{$i}"] & {
                &:nth-child(#{$i}),
                &:nth-child(#{$i - 1}),
                &:nth-child(#{$i - 2}) {
                    @include product-crop;
                }
            }
        }
    }
}

// wishlist product get bigger on mobile
.teaser--product-compare,
.teaser--product-wishlist {
    display: flex;
    flex-direction: column;

    > * {
        flex: 0 0 auto;
        width: 100%;

        &:first-child {
            flex: 1 1 auto;
        }
    }

    @include respond-to(grid-s) {
        width: grid-width(grid-s, 8);
        margin-right: 0 !important;

        .product-group & {
            @include after-first(1) {
                margin-top: grid-spacing(grid-s, xl);
            }
        }
    }

    @include respond-to(decent) {
        .modal & {
            &:nth-child(even) {
                margin-right: 0 !important;
            }

            &:nth-child(odd) {
                margin-right: grid-gutter-width(grid-l);
            }
        }
    }

    @include respond-to(grid-l) {
        .ajax-content .product-group & {
            @include after-first(3) {
                margin-top: grid-spacing(grid-l, l);
            }
        }

        .modal .product-group & {
            @include after-first(2) {
                margin-top: grid-spacing(grid-l, l);
            }
        }
    }

    @include respond-to(grid-m) {
        .modal & {
            &:nth-child(odd) {
                margin-right: grid-gutter-width(grid-m);
            }
        }

        .product-group & {
            @include after-first(2) {
                margin-top: grid-spacing(grid-m, xl);
            }
        }
    }
}

.teaser--product-compare {
    @include respond-to(grid-s) {
        width: grid-width(grid-s, 10);
    }
}

// take care of soldier images
.teaser--soldier {
    // redefine rollover
    // because this might not be a teaser--product and therefore has no transition yet
    .media-box {
        transition: transform $trans-time--l $trans-func--default;
    }

    // add rollover action
    @include hover {
        &:not(.cart-listing__item__cell__image) {
            .media-box {
                transition: transform $trans-time--m $trans-func--default;
                transform: scale(#{$zoom-scale}) translate3d(0, -1%, 0);
            }
        }
    }

    // increase mobile size
    @include respond-to(grid-s) {
        .media-box-wrapper {
            overflow: hidden;
        }

        &:not(.cart-listing__item__cell__image) {
            .media-box {
                margin-left: -1.6em;
                margin-right: -1.6em;
            }
        }
    }

    @include respond-to(decent) {
        .text-container {
            padding-top: 1em; // show text closer to image
        }

        // upscale decent img as well if needed
        // then take care of @mixin product-crop
        &:not(.cart-listing__item__cell__image) {
            .media-box {
                // margin-left: -1.6em;
                // margin-right: -1.6em;
            }
        }
    }

    @include respond-to(grid-l) {
        // limit soldier teaser to text scaling // with the new soldiers we dont do this no more
        &:not(.cart-listing__item__cell__image) {
            .media-box {
                // margin: calc(4em - 3.6rem) auto; // give some vertical room based on differnece between text and grid scaling
                // width: calc((34rem + 38em) / 2); // take half text scaling and half grid scaling
            }
        }
    }
}

.teaser--product-single,
.teaser--product-double {
    @include grid-width(10, 8, 6.5);

    .media-box {
        @include ratio-box(70%);
    }

    .text-container {
        margin: auto;
        position: relative;
    }

    // add rollover action
    @include hover {
        &:not(.zoom-img) {
            .media-box {
                transform: scale(#{$zoom-scale-s}) translate3d(0, -0.5%, 0);
            }
        }
    }
}

.teaser--product-double {
    @include grid-width(10, 5, 4.3);

    .modal--sidebar & {
        @include respond-to(grid-l) {
            width: grid-width(grid-l, 4.5);
        }

        @include respond-to(grid-m) {
            width: grid-width(grid-m, 4);
        }
    }
}

.teaser--product-small,
.teaser--product-family {
    @include grid-width(6, 3, 2);
    margin-right: grid-gutter(grid-s);

    @include respond-to(decent) {
        .touch & {
            .btn.btn--product-teaser {
                padding-top: 0;
            }
        }
    }

    @include respond-to(grid-l) {
        margin-right: grid-gutter(grid-l);

        // make soldiers bigger in small overviews
        &.teaser--soldier {
            .media-box {
                margin-left: -1.6em;
                margin-right: -1.6em;
            }
        }
    }

    @include respond-to(grid-m) {
        margin-right: grid-gutter(grid-m);
    }
}

.teaser--product-caliber,
.teaser--product-family {
    h3,
    h4,
    h5 {
        @include textstyle--uppercase;
    }

    .btn.btn--link {
        text-align: center;
        width: 100%;
        margin-top: 0.2rem;

        &:after {
            display: none;
        }
    }

    @include respond-to(grid-l) {
        i,
        .btn {
            @include textstyle--m;
        }
    }
}

.teaser--product-caliber {
    @include grid-width(5, 4, 3);

    .text-container {
        padding-top: 0;
    }
}

.teaser--product-cross-selling {
    @include grid-width(8, 4, 3);
    background: $back-color--tertiary;
    overflow: hidden;

    .product-group & {
        @include grid-spacing(s);
    }

    // no need for teaser-actions here
    .touch & {
        .teaser__actions {
            display: none;
        }
    }

    .text-container:not(.teaser-title) {
        // @include grid-negative-spacing(s);
        @include grid-bottom-spacing(m);
    }

    &.teaser--soldier {
        .media-box {
            @include grid-spacing(s);
        }

        @include respond-to(grid-l) {
            .media-box {
                margin-left: 4em;
                margin-right: 4em;
            }
        }
    }
}

// .teaser--soldier inside quick view modals
.teaser--product-overview {
    @include grid-width(5, 3, 2);

    @include respond-to(decent) {
        .product-group--navigation-overview & {
            // top spacing for all but the first two
            &:not(:nth-child(-n + 2)) {
                @include grid-spacing(l);
            }

            // right spacing for all even items // two rows
            &:not(:nth-child(2n)) {
                margin-right: grid-gutter-width(grid-l);
            }
        }

        // make soldiers bigger in small overviews
        &.teaser--soldier {
            .media-box {
                margin-left: -1.6em;
                margin-right: -1.6em;
            }
        }

        i {
            @include textstyle--s;
        }
    }

    @include respond-to(grid-l) {
        .quickview-teasers & {
            // right spacing all but third items // three rows
            &:not(:nth-child(3n)) {
                margin-right: grid-gutter-width(grid-l);
            }
        }

        // limit soldier teaser to text scaling // with the new soldiers we dont do this no more
        &.teaser--soldier {
            .media-box {
                // margin: calc(2.666em - 2.4rem) auto; // give some vertical room based on differnece between text and grid scaling
                // width: calc((22.666rem + 25.333em) / 2); // take half text scaling and half grid scaling
            }
        }
    }

    @include respond-to(grid-m) {
        // two per rows
        .main-menu & {
            &:not(:nth-child(2n)) {
                margin-right: grid-gutter-width(grid-m);
            }
        }

        // three per row
        .modal--sidebar &,
        .main-content & {
            &:not(:nth-child(3n)) {
                margin-right: grid-gutter-width(grid-m);
            }
        }

        .quickview-teasers & {
            width: grid-width(grid-m, 4);
        }
    }

    @include respond-to(xl) {
        // limit product teaser to text scaling // with the new soldiers we dont do this no more
        .media-box {
            // width: calc((25rem + 24em) / 2); // take half text scaling and half grid scaling
            // margin: 0 auto;
        }
    }
}

// selecting variants for compare and wishlist
.teaser--product-select {
    .text-container {
        position: relative;
        @include fade-cover();
    }

    @include respond-to(grid-s) {
        width: grid-width(grid-s, 4);
    }

    @include respond-to(grid-m) {
        width: grid-width(grid-m, 2.5);
    }
}

.teaser--article {
    width: 100%;
    @include grid-spacing(l);
    position: relative;
    overflow: hidden;

    h2 {
        @include textstyle--teaser;
        margin-top: 1rem;
    }

    p {
        @include textstyle--teaser;
        @include textstyle--m;
    }

    // add rollover action
    @include hover {
        .media-box {
            transition: transform $trans-time--m $trans-func--default;
            transform: scale(#{$zoom-scale-s}) !important;
        }
    }

    @include respond-to(grid-l) {
        width: grid-width(grid-l, 3) + grid-width(grid-l, 1, false, true) / 3;

        &:not(:nth-child(3n)) {
            margin-right: grid-gutter-width(grid-l);
        }

        &:nth-child(3n) {
            margin-right: -1px; // fix subpixel rendering issue causing problems in firefox
        }

        h2 {
            @include textstyle--teaser-m;
        }

        p {
            @include textstyle--l;
        }

        .text-container {
            max-width: 42rem !important;
        }

        .modal & {
            width: grid-width(grid-l, 8) / 3 - grid-gutter-width(grid-l) * 2 / 3;
        }
    }

    @include respond-to(grid-m) {
        width: grid-width(grid-m, 5);

        &:not(:nth-child(2n)) {
            margin-right: grid-gutter-width(grid-m);
        }

        &:nth-child(2n) {
            margin-right: -1px; // fix subpixel rendering issue causing problems in firefox
        }

        h2 {
            @include textstyle--teaser-m;
        }

        p {
            @include textstyle--teaser;
        }

        .modal & {
            width: grid-width(grid-m, 6);
        }
    }
}

.teaser--hero-article {
    width: 100%;
    @include grid-spacing(m);
    position: relative;
    flex-direction: column !important;

    .text-container {
        max-width: 100% !important;
        @include grid-spacing(s);
        @include grid-bottom-spacing(l);
    }

    h2 {
        @include textstyle--teaser-m;
    }

    p {
        @include textstyle--teaser;
    }

    // add rollover action
    @include hover {
        .media-box {
            transition: transform $trans-time--m $trans-func--default;
            transform: scale(#{$zoom-scale-s}) !important;
        }
    }

    @include respond-to(grid-l) {
        h2 {
            @include textstyle--teaser-xl;
        }

        p {
            @include textstyle--teaser-m;
        }
    }

    @include respond-to(grid-m) {
        h2 {
            @include textstyle--teaser-l;
        }
    }
}

.teaser--banner {
    position: relative;

    .media-box + .text-container,
    .media-box-wrapper + .text-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: grid-padding-v(grid-s) grid-padding-v(grid-s);
        z-index: 1; // prevent animation issues

        h4 {
            @include textstyle--uppercase;

            @include respond-to(grid-s) {
                font-size: $fontsize--s;
            }
        }
    }

    // Teaser banner might contain video -> Text container should be on top
    &.teaser--banner .text-container {
        z-index: 5;
    }

    @include respond-to(grid-l) {
        .media-box + .text-container,
        .media-box-wrapper + .text-container {
            padding: grid-padding-v(grid-l) grid-padding-v(grid-l);

            //  h4 {
            //      @include textstyle--teaser;
            //      @include textstyle--uppercase;
            //  }
        }

        &.box-indent .media-box + .text-container,
        &.box-indent .media-box-wrapper + .text-container {
            top: grid-spacing(grid-l, xxl);
        }
    }

    @include respond-to(grid-m) {
        .media-box + .text-container,
        .media-box-wrapper + .text-container {
            padding: grid-padding-v(grid-m) grid-padding-v(grid-m);
        }
    }
}

.teaser--advent-modal,
.teaser--watch-modal {
    @include grid-width(10, 3, 3);
    @include grid-spacing(m);
    position: relative;
    margin-top: -10em;
    padding-top: 10em;

    .svg-ico,
    .media-box {
        display: none;
    }

    .watch-modal-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: $back-color--default;
        position: relative;

        &:after {
            @include pseudo();
            top: 0;
            left: 5%;
            right: 5%;
            border-top: 1px solid $border-color--default;
        }

        .section-fond & {
            background: $back-color--tertiary;
        }
    }

    @include respond-to(decent) {
        margin-top: grid-spacing(grid-l, m);
        padding-top: 0;

        .media-box {
            display: block;
            @include ratio-box(100%);
            transition: transform $trans-time--m $trans-func--default;
        }

        .watch-modal-button {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            width: calc(50% +  1px);
            transform: translate3d(0, 0, 0);
            transition: transform $trans-time--m $trans-func--default;

            &:after {
                @include pseudo();
                top: 8%;
                left: 0;
                bottom: 6%;
                border-top: 0;
                border-left: 1px solid $border-color--default;
                transition: transform $trans-time--s $trans-func--default;
            }

            // wrapper for .btn and .svg-icon
            > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                transform: translate3d(0, 0, 0);
                transition: transform $trans-time--m $trans-func--default;
            }
        }

        // dont show line before image is loaded
        .lazy-child:not(.lazy-child--loaded) + .text-container {
            &:after {
                transform: scale(1, 0);
            }
        }

        .svg-ico {
            margin-bottom: 2em;
            display: inline-block;
        }

        .btn {
            text-align: center;
            width: 100%;
        }

        // Add interaction hint
        @include hover {
            .media-box {
                transform: translate3d(-2em, 0, 0);
                transition: transform $trans-time--s $trans-func--default;
            }

            .watch-modal-button {
                transform: translate3d(1em, 0, 0);
                transition: transform $trans-time--s $trans-func--default;

                // give inner wrapper secondary transition
                > div {
                    transform: translate3d(2em, 0, 0);
                    transition: transform $trans-time--s $trans-func--default;
                }
            }
        }
    }

    @include respond-to(grid-m) {
        margin-top: grid-spacing(grid-m, m);

        .svg-ico {
            margin-left: 0;
        }

        .btn {
            margin-left: 0;
        }
    }
}

.teaser--advent {
    @include grid-width(5, 4, 3);
    margin-right: grid-gutter-width(grid-s);
    margin-bottom: grid-gutter-width(grid-s);

    .text-container {
        h3,
        h4 {
            text-align: right;
        }
    }

    @include respond-to(grid-s) {
        &:nth-child(2n) {
            margin-right: 0;
        }
    }

    @include respond-to(grid-l) {
        margin-right: grid-gutter-width(grid-l);
        margin-bottom: grid-gutter-width(grid-l);

        &:nth-child(3n) {
            margin-right: 0;
        }
    }

    @include respond-to(grid-m) {
        margin-right: grid-gutter-width(grid-m);
        margin-bottom: grid-gutter-width(grid-m);

        &:nth-child(2n) {
            margin-right: 0;
        }
    }
}

.teaser--advent-modal {
    .watch-modal-button {
        &:after {
            left: 0;
            right: 0;
        }
    }

    .media-box-wrapper {
        overflow: hidden; // fix animation to not overlap previous items
    }

    @include respond-to(decent) {
        .watch-modal-button {
            top: -1rem;
            bottom: -1rem;

            &:after {
                @include pseudo();
                top: 0;
                left: 0;
                bottom: 0;
            }
        }
    }
}

.teaser--advent-group {
    .text-container {
        h3,
        h4 {
            text-align: right;
        }
    }
}

.teaser--press {
    @include grid-width(4, 3, 2);
    align-items: flex-start;
    @include grid-spacing(l);
    margin-right: grid-gutter-width(grid-s);

    h3,
    h4,
    h5,
    span {
        @include textstyle--button-s;
        margin: 0;
    }

    .media-box-wrapper {
        height: 14rem;
        width: 100%;
        margin: 0 grid-padding-h();
        position: relative;
        perspective: 1000;
    }

    .media-box {
        position: absolute;
        bottom: 0;
        left: -0.6rem;
        width: 80%;
        line-height: 0; // remove bottom spacing
        max-height: 110%; // prevent for real big images
        transform-origin: center center;
        background: transparent;
        overflow: inherit;

        // drop-shadow
        &:before {
            @include pseudo();
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(0,0,0,0.06);
            transform: translate3d(0.6rem, 0.6rem, 0);
            opacity: 0;
            transition: opacity $trans-time--m $trans-func--default,
            filter $trans-time--m $trans-func--default;
        }

        &.lazy-child--loaded {
            transition: transform $trans-time--m $trans-func--default,
                        background $trans-time--l $trans-func--default;

            &:before {
                opacity: 1;
            }
        }
    }

    // press-images should remain in the original image size
    img {
        position: relative;
        top: auto;
        left: auto;
        width: 100%;
        height: auto;
        object-fit: contain;
        border: 1px solid $border-color--light-1;
    }

    .text-container {
        @include grid-spacing(xs);
        width: 100%;
        overflow: hidden;
        position: relative;
        @include fade-cover();

        .section-fond & {
            &:after {
                background: linear-gradient(to right, rgba($back-color--tertiary, 0), $back-color--tertiary);
            }
        }
    }

    .press-group & {
        @include grid-width(5, 4, 3);

        .media-box-wrapper {
            height: 17rem;
            perspective: 1500;
        }

        .media-box {
            left: -0.9rem;

            &:before {
                transform: translate3d(0.9rem, 0.9rem, 0);
            }
        }

        @include respond-to(grid-s) {
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }

    @include hover {
        .media-box {
            transition: transform $trans-time--s $trans-func--default;
            transform: rotateX(-10deg) scale(1.06, 1.04) translateY(0.6em);

            &:before {
                transition: filter $trans-time--s $trans-func--default;
                filter: blur(0.6rem);
            }
        }

        .press-group & {
            transform: rotateX(-10deg) scale(1.04, 1.025) translateY(1em);

            &:before {
                filter: blur(0.9rem);
            }
        }
    }

    @include respond-to(grid-l) {
        margin-right: grid-gutter-width(grid-l);

        h3,
        h4,
        h5,
        span {
            @include textstyle--button;
        }

        .media-box-wrapper {
            height: 24rem;
            margin: 0 grid-padding-h(grid-l);
        }

        .media-box {
            width: 70%;
        }

        .press-group & {
            &:nth-child(3n) {
                margin-right: 0;
            }

            .media-box-wrapper {
                height: 36rem;
            }
        }
    }

    @include respond-to(grid-m) {
        margin-right: grid-gutter-width(grid-m);

        .media-box-wrapper {
            height: 22rem;
            margin: 0 grid-padding-h(grid-m);
        }

        .media-box {
            width: 74%;
        }

        .press-group & {
            .media-box-wrapper {
                height: 29rem;
            }
        }
    }
}

