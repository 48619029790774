// Nomos tag style

.tag {
    background: $back-color--tertiary;
    border-radius: 2px;
    @include textstyle--button-s;
    @include textstyle--book;
    display: inline-block;
    line-height: $button-height-s - $baseline-offset;
    padding: 0 0.5em $baseline-offset 0.5em;
    margin: 0.2em 0;

    &:not(:last-child) {
        margin-right: 0.2em;
    }

    // hide count
    small {
        display: none;
    }

    &.tag--reset {
        cursor: pointer;
        padding-right: 0.2em;

        span {
            display: flex;
            align-items: center;

            // remove filter icon
            &:after {
                content: '';
                display: inline-block;
                margin-top: 0.1em; // position vertically
                width: 2rem;
                height: 2rem;
                background: svg-inline(icon--nom-remove) center center no-repeat;
            }
        }
    }

    @include respond-to(decent) {
        @include textstyle--button;
        @include textstyle--book;
        line-height: $button-height - $baseline-offset;
        padding: 0 0.8em $baseline-offset 0.8em;

        &.tag--reset {
            padding-right: 0.4em;
        }
    }
}