////
/// Collection of useful mixins
/// @group Mixins
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

/// Mixin for input placeholder styling
///
/// @group humans-machines
/// @content
@mixin placeholder {
    ::placeholder {@content}
}

/// Mixin for nested input placeholder styling
///
/// @group humans-machines
/// @content
@mixin placeholder-nested {
    &::placeholder {@content}
}

/// Mixin for including mobile safe hover effects
///
/// @group humans-machines
/// @content
@mixin hover {
    .no-mobile.no-touch & {
        &:hover {
            @content;
        }
    }
}

/// Mixin for applying mobile specific css
///
/// @group humans-machines
/// @content
@mixin no-hover {
    .mobile &,
    .touch & {
        @content;
    }
}

/// Mixin for pseudo elements
///
/// @group humans-machines
/// @param {string} $display [inline-block] - How to display the pseudo element
/// @param {string} $pos     [absolute]     - Position of the pseudo element
/// @param {string} $content [empty]        - Initial content to set
@mixin pseudo($display: inline-block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}

/// Mixin for motion-design declarations
///
// motion design for animated elements
// global definitions
@mixin state--initial() {
    .touch .main-header [aria-expanded="false"] &, // fallback animation for main menu accordion
    .no-touch.no-intersection-observer .main-header [aria-expanded="false"] &, // fallback animation for main menu accordion
    .js .filter [aria-expanded="false"] &, // decent filter
    .no-touch.no-ie.intersection-observer .intersect & {
        // animate/animate-children on child element of intersect
        @content;
    }

    &.intersect {
        // intersect and animate/animate-children on same element
        .no-touch.no-ie.intersection-observer & {
            @content;
        }
    }
}

@mixin state--animated() {
    .touch .main-header [aria-expanded="true"] &, // fallback animation for main menu accordion
    .no-touch.no-intersection-observer .main-header [aria-expanded="true"] &, // fallback animation for main menu accordion
    .js .filter [aria-expanded="true"] &, // decent filter
    .no-touch.no-ie.intersection-observer .intersect-inview--before &,
    .no-touch.no-ie.intersection-observer .intersect-inview & {
        // animate/animate-children on child element of intersect
        @content;
    }

    &.intersect-inview--before,
    &.intersect-inview {
        // intersect and animate/animate-children on same element
        .no-touch.no-ie.intersection-observer & {
            @content;
        }
    }
}

/// Mixin to truncate a text
///
/// @group humans-machines
/// @param {number} $truncation-boundary - The max width for truncate
@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/// Mixin to fade elements against background with a gradient
///
/// @group humans-machines
/// @param {number} $side - side of the cover element
/// @param {number} $width - width of the overlaying gradient
/// @param {number} $color - background-color of the overlaying gradient
@mixin fade-cover($side: right, $size: 1.6em, $color: $back-color--default) {
    &:after {
        content: '';
        position: absolute;
        z-index: 1;
        pointer-events: none;

        // gradient on the right - default
        @if $side == 'right' {
            background: linear-gradient(to right, rgba($color, 0), $color);
            top: 0;
            bottom: 0;
            width: $size;
            right: 0;

            // gradient on the left
        } @else if $side == 'left' {
            background: linear-gradient(to right, $color, rgba($color, 0));
            left: 0;
            top: 0;
            bottom: 0;
            width: $size;

            // gradient on the bottom
        } @else if $side == 'bottom' {
            background: linear-gradient(to top, $color, rgba($color, 0));
            left: 0;
            right: 0;
            bottom: 0;
            height: $size;

            // gradient on the top
        } @else if $side == 'top' {
            background: linear-gradient(to top, $color, rgba($color, 0));
            left: 0;
            right: 0;
            top: $size * -1;
            height: $size;
        }
    }
}

/// Mixin for a pseudo list like a navigation
///
/// @group humans-machines
@mixin pseudo-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

/// Mixin for a clearfix, since flexbox we might not need it anymore
///
/// @group humans-machines
@mixin clearfix {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

/// Mixin to maintain an aspect ratio of a container
///
/// @group humans-machines
/// @param {number} $width - The width ratio of the container
/// @param {number} $height - The height ratio of the container
@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > * {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

/// Mixin for fluid typography
///
/// @group humans-machines
/// @param {list} $properties - The properties to apply the calculation e.g. font-size, padding, ...
/// @param {number} $min-vw - The min viewport width in pixel
/// @param {number} $max-vw - The max viewport width in pixel
/// @param {number} $min-value - The min value to apply
/// @param {number} $max-value - The max value to apply
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
    @each $property in $properties {
        #{$property}: $min-value;
    }

    @media screen and (min-width: $min-vw) {
        @each $property in $properties {
            #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
        }
    }

    @media screen and (min-width: $max-vw) {
        @each $property in $properties {
            #{$property}: $max-value;
        }
    }
}

/// Mixin for applying an order index for the given selector
///
/// @group humans-machines
/// @param {list} $selectors - The list with the quoted selectors
@mixin flex-order($selectors) {
    $counter: 1;
    @each $selector in $selectors {
        $selector: unquote($selector);

        #{$selector} {
            order: $counter;
        }

        $counter: $counter + 1;
    }
}

/// Mixin for setting up a fullsize box in an relativate element
///
/// @group humans-machines
@mixin fullsize-box() {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/// Shorthand mixin to set up the container size
///
/// @group humans-machines
@mixin grid-container() {
    @each $grid-size in map-keys($grid-config) {
        $config: map-get($grid-config, $grid-size);
        $breakpoint: map-get($config, breakpoint);

        @if $breakpoint == 's' {
            width: grid-container($grid-size);
        } @else {
            @include respond-to($breakpoint) {
                width: grid-container($grid-size);
            }
        }
    }
}

/// Shorthand mixin to set up an element size based on the inner grid
///
/// @group humans-machines
/// @param {list} $cell-counts - The cell counts for s, m, l
@mixin grid-inner-width($cell-counts...) {
    // Set counter to loop over cell counts
    $counter: 1;

    // Loop through grid config
    @each $grid-size in map-keys($grid-config) {
        $config: map-get($grid-config, $grid-size);
        $breakpoint: map-get($config, breakpoint);
        $cell-count: nth($cell-counts, $counter);

        @if $breakpoint == 's' {
            width: grid-inner-width($grid-size, $cell-count);
        } @else {
            @include respond-to($breakpoint) {
                width: grid-inner-width($grid-size, $cell-count);
            }
        }

        // Increase arg counter
        $counter: $counter + 1;
    }
}

/// Shorthand mixin to set up an element size based on the grid
///
/// @group humans-machines
/// @param {list} $cell-counts - The cell counts for s, m, l
@mixin grid-width($cell-counts...) {
    // Set counter to loop over cell counts
    $counter: 1;

    // Loop through grid config
    @each $grid-size in map-keys($grid-config) {
        $config: map-get($grid-config, $grid-size);
        $breakpoint: map-get($config, breakpoint);
        $cell-count: nth($cell-counts, $counter);

        @if $breakpoint == 's' {
            width: grid-width($grid-size, $cell-count);
        } @else {
            @include respond-to($breakpoint) {
                width: grid-width($grid-size, $cell-count);
            }
        }

        // Increase arg counter
        $counter: $counter + 1;
    }
}

/// Shorthand mixin to set up an element max size based on the grid
///
/// @group humans-machines
/// @param {list} $cell-counts - The cell counts for s, m, l
@mixin grid-max-width($cell-counts...) {
    // Set counter to loop over cell counts
    $counter: 1;

    // Loop through grid config
    @each $grid-size in map-keys($grid-config) {
        $config: map-get($grid-config, $grid-size);
        $breakpoint: map-get($config, breakpoint);
        $cell-count: nth($cell-counts, $counter);

        @if $breakpoint == 's' {
            max-width: grid-width($grid-size, $cell-count);
        } @else {
            @include respond-to($breakpoint) {
                max-width: grid-width($grid-size, $cell-count);
            }
        }

        // Increase arg counter
        $counter: $counter + 1;
    }
}

/// Shorthand mixin to set up an element size based on the grid
///
/// @group humans-machines
/// @param {list} $cell-counts - The cell counts for s, m, l
@mixin grid-inner-max-width($cell-counts...) {
    // Set counter to loop over cell counts
    $counter: 1;

    // Loop through grid config
    @each $grid-size in map-keys($grid-config) {
        $config: map-get($grid-config, $grid-size);
        $breakpoint: map-get($config, breakpoint);
        $cell-count: nth($cell-counts, $counter);

        @if $breakpoint == 's' {
            max-width: grid-inner-width($grid-size, $cell-count);
        } @else {
            @include respond-to($breakpoint) {
                max-width: grid-inner-width($grid-size, $cell-count);
            }
        }

        // Increase arg counter
        $counter: $counter + 1;
    }
}

/// Shorthand mixin to set up the padding of an element
///
/// @group humans-machines
@mixin grid-padding() {
    // Loop through grid config
    @each $grid-size in map-keys($grid-config) {
        $config: map-get($grid-config, $grid-size);
        $breakpoint: map-get($config, breakpoint);

        @if $breakpoint == 's' {
            padding: grid-padding-h($grid-size) grid-padding-h($grid-size);
        } @else {
            @include respond-to($breakpoint) {
                padding: grid-padding-h($grid-size) grid-padding-h($grid-size);
            }
        }
    }
}

/// Shorthand mixin to set up vertical spacing for an element
///
/// @group humans-machines
@mixin grid-spacing($spacing: xs, $size: '') {
    @if $size == '' {

        // Loop through grid config
        @each $grid-size in map-keys($grid-config) {
            $config: map-get($grid-config, $grid-size);
            $breakpoint: map-get($config, breakpoint);

            @if $breakpoint == 's' {
                margin-top: grid-spacing($grid-size, $spacing);
            } @else {
                @include respond-to($breakpoint) {
                    margin-top: grid-spacing($grid-size, $spacing);
                }
            }
        }
    } @else {
        @if $size == 's' or $size == 'grid-s' {
            margin-top: grid-spacing($size, $spacing);
        } @else {
            @include respond-to($size) {
                margin-top: grid-spacing($size, $spacing);
            }
        }
    }
}

/// Shorthand mixin to set up vertical inner spacing for an element
///
/// @group humans-machines
@mixin grid-padding-spacing($spacing: xs, $size: '') {
    @if $size == '' {

        // Loop through grid config
        @each $grid-size in map-keys($grid-config) {
            $config: map-get($grid-config, $grid-size);
            $breakpoint: map-get($config, breakpoint);

            @if $breakpoint == 's' {
                padding-top: grid-spacing($grid-size, $spacing);
            } @else {
                @include respond-to($breakpoint) {
                    padding-top: grid-spacing($grid-size, $spacing);
                }
            }
        }
    } @else {
        @if $size == 's' or $size == 'grid-s' {
            padding-top: grid-spacing($size, $spacing);
        } @else {
            @include respond-to($size) {
                padding-top: grid-spacing($size, $spacing);
            }
        }
    }
}


/// Shorthand mixin to set up vertical bottom spacing for an element (via padding)
///
/// @group humans-machines
@mixin grid-bottom-spacing($spacing: xs, $size: '') {
    @if $size == '' {

        // Loop through grid config
        @each $grid-size in map-keys($grid-config) {
            $config: map-get($grid-config, $grid-size);
            $breakpoint: map-get($config, breakpoint);

            @if $breakpoint == 's' {
                padding-bottom: grid-spacing($grid-size, $spacing);
            } @else {
                @include respond-to($breakpoint) {
                    padding-bottom: grid-spacing($grid-size, $spacing);
                }
            }
        }
    } @else {
        @if $size == 's' or $size == 'grid-s' {
            padding-bottom: grid-spacing($size, $spacing);
        } @else {
            @include respond-to($size) {
                padding-bottom: grid-spacing($size, $spacing);
            }
        }
    }
}

/// Shorthand mixin to set up negative vertical spacing for an element
///
/// @group humans-machines
@mixin grid-negative-spacing($spacing: xs, $size: '') {
    @if $size == '' {

        // Loop through grid config
        @each $grid-size in map-keys($grid-config) {
            $config: map-get($grid-config, $grid-size);
            $breakpoint: map-get($config, breakpoint);

            @if $breakpoint == 's' {
                margin-top: grid-spacing($grid-size, $spacing) * -1;
            } @else {
                @include respond-to($breakpoint) {
                    margin-top: grid-spacing($grid-size, $spacing) * -1;
                }
            }
        }
    } @else {
        @if $size == 's' or $size == 'grid-s' {
            margin-top: grid-spacing($size, $spacing) * -1;
        } @else {
            @include respond-to($size) {
                margin-top: grid-spacing($size, $spacing) * -1;
            }
        }
    }
}

/// Shorthand mixin to set up extended padding of an element
///
/// @group humans-machines
@mixin grid-padding--l() {
    // Loop through grid config
    @each $grid-size in map-keys($grid-config) {
        $config: map-get($grid-config, $grid-size);
        $breakpoint: map-get($config, breakpoint);

        @if $breakpoint == 's' {
            padding: grid-padding-v($grid-size) grid-padding-h($grid-size);
        } @else {
            @include respond-to($breakpoint) {
                padding: grid-padding-v($grid-size) grid-padding-h($grid-size);
            }
        }
    }
}

/// Shorthand mixin to set up the horizontal padding of an element
///
/// @group humans-machines
@mixin grid-padding-h() {
    // Loop through grid config
    @each $grid-size in map-keys($grid-config) {
        $config: map-get($grid-config, $grid-size);
        $breakpoint: map-get($config, breakpoint);

        @if $breakpoint == 's' {
            padding-left: grid-padding-h($grid-size);
            padding-right: grid-padding-h($grid-size);
        } @else {
            @include respond-to($breakpoint) {
                padding-left: grid-padding-h($grid-size);
                padding-right: grid-padding-h($grid-size);
            }
        }
    }
}

/// Shorthand mixin to set up the vertical padding of an element
///
/// @group humans-machines
@mixin grid-padding-v() {
    // Loop through grid config
    @each $grid-size in map-keys($grid-config) {
        $config: map-get($grid-config, $grid-size);
        $breakpoint: map-get($config, breakpoint);

        @if $breakpoint == 's' {
            padding-top: grid-padding-v($grid-size);
            padding-bottom: grid-padding-v($grid-size);
        } @else {
            @include respond-to($breakpoint) {
                padding-top: grid-padding-v($grid-size);
                padding-bottom: grid-padding-v($grid-size);
            }
        }
    }
}

/// Shorthand mixin to set up the vertical padding of an element
///
/// @group humans-machines
@mixin grid-padding-v--s() {
    // Loop through grid config
    @each $grid-size in map-keys($grid-config) {
        $config: map-get($grid-config, $grid-size);
        $breakpoint: map-get($config, breakpoint);

        @if $breakpoint == 's' {
            padding-top: grid-padding-h($grid-size);
            padding-bottom: grid-padding-h($grid-size);
        } @else {
            @include respond-to($breakpoint) {
                padding-top: grid-padding-h($grid-size);
                padding-bottom: grid-padding-h($grid-size);
            }
        }
    }
}

/// Shorthand mixin to add the body margin
///
/// @group humans-machines
@mixin grid-margin() {
    // Loop through grid config
    @each $grid-size in map-keys($grid-config) {
        $config: map-get($grid-config, $grid-size);
        $breakpoint: map-get($config, breakpoint);

        @if $breakpoint == 's' {
            margin-left: grid-margin($grid-size, false);
            margin-right: grid-margin($grid-size, false);
        } @else {
            @include respond-to($breakpoint) {
                margin-left: grid-margin($grid-size, false);
                margin-right: grid-margin($grid-size, false);
            }
        }
    }
}

/// Shorthand mixin to add the body margin to the right
///
/// @group humans-machines
@mixin grid-margin-r() {
    // Loop through grid config
    @each $grid-size in map-keys($grid-config) {
        $config: map-get($grid-config, $grid-size);
        $breakpoint: map-get($config, breakpoint);

        @if $breakpoint == 's' {
            margin-right: grid-margin($grid-size, false);
        } @else {
            @include respond-to($breakpoint) {
                margin-right: grid-margin($grid-size, false);
            }
        }
    }
}

/// Shorthand mixin to add the body margin to the left
///
/// @group humans-machines
@mixin grid-margin-l() {
    // Loop through grid config
    @each $grid-size in map-keys($grid-config) {
        $config: map-get($grid-config, $grid-size);
        $breakpoint: map-get($config, breakpoint);

        @if $breakpoint == 's' {
            margin-left: grid-margin($grid-size, false);
        } @else {
            @include respond-to($breakpoint) {
                margin-left: grid-margin($grid-size, false);
            }
        }
    }
}

/// modal transition stuff
///
/// @group humans-machines
@mixin modal-transition($class, $direction: 'left') {
    transition: transform $menu-trans-timing-in $menu-trans-easing-in,
    opacity $menu-trans-timing-in $menu-trans-easing-in;

    @if str-slice($class, 0, 1) != '.' {
        $class: '.' + $class;
    }

    body:not(#{$class}) & {
        @if $direction == 'left' {
            transform: translate3d(-100%, 0, 0);
        } @else if $direction == 'right' {
            transform: translate3d(100%, 0, 0);
        } @else if $direction == 'bottom' {
            transform: translate3d(0, 100vh, 0);
        } @else if $direction == 'top' {
            transform: translate3d(0, -100%, 0);
        } @else if $direction == 'popup' {
            transform: translate3d(0, 4em, 0);
            opacity: 0;
        }

        visibility: hidden;
        transition: transform $menu-trans-timing-out $menu-trans-easing-out,
        opacity $menu-trans-timing-out $menu-trans-easing-out,
        visibility 0s $menu-trans-easing-out $menu-trans-timing-out;
    }
}

/// Transition mixin for the modal backdrop
///
/// @group humans-machines
@mixin modal-backdrop-transition($class, $z-index: false) {
    @if $z-index != false {
        z-index: $z-index;
    }

    transition: opacity 0.5s $trans-func--default,
    visibility 0s $trans-func--default 0.5s;
    visibility: hidden;

    #{$class} & {
        opacity: 0.87;
        transition: opacity 1s $trans-func--default;
        visibility: inherit;
    }
}

/// Nice mixins to have a delay cascade
///
/// @group humans-machines
@mixin transition-delay($delay: 0.1s, $global-delay: 0s, $items: 20) {
    @for $i from 0 through $items {
        &:nth-child(#{$i}) {
            transition-delay: $i * $delay + $global-delay;
        }
    }
}

/// Nice mixins to have a delay cascade
///
/// @group humans-machines
@mixin animation-delay($delay: 0.1s, $global-delay: 0s, $items: 20) {
    @for $i from 0 through $items {
        &:nth-child(#{$i}) {
            animation-delay: $i * $delay + $global-delay;
        }
    }
}

/// hide elements via animation to only hide if animation is possible
///
/// @group humans-machines
@mixin hide-before-animation() {
    @supports (animation: animate-element-s-mobile 1s 1 backwards) {
        opacity: 0;
        visibility: hidden;
    }
}

@mixin show-for-animation() {
    @supports (animation: animate-element-s-mobile 1s 1 backwards) {
        opacity: 1;
        visibility: visible;
    }
}

/// Nice mixins to fade push an object
///
/// @group humans-machines
//  @mixin animate-fade-trans($class, $distance: 3em, $timing: $trans-time--m) {
//      opacity: 0;
//      transform: translateY($distance);
//
//      @at-root {
//          #{$class} & {
//              transition: transform $timing $menu-trans-easing-out;
//              opacity: 1;
//              transform: translateY(0);
//          }
//      }
//  }

/// Nice mixins to crop a product
///
/// @group humans-machines
@mixin product-crop {
    .text-container {
        display: none;
    }

    .media-box {
        margin-bottom: -35%;
    }

    @include respond-to(decent) {
        .media-box {
            margin-bottom: -30%;
        }
    }
}

/// Nice mixins to have an underline
///
/// @group humans-machines
@mixin underline {
    position: relative;

    &:before {
        @include pseudo();
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background: rgba($text-color--default, 0.8);
    }
}

@mixin underline-active {
    position: relative;

    &:before {
        @include pseudo();
        bottom: 0;
        left: 0;
        transform: scale(0, 1);
        height: 1px;
        width: 100%;
        opacity: 0;
        background: rgba($text-color--default, 0.8);
    }

    &.active,
    &.is-active {
        &:before {
            transform: none;
            opacity: 1;

            html:not(.ie) & {
                transition: transform 0.15s $trans-func--default,
                            opacity 0.15s $trans-func--default;
            }
        }
    }
}

/// Nice mixins to add a loading pulse animation
///
/// @group humans-machines
@mixin loading-pulse($pulse-color: darken($back-color--secondary, 4%), $pulse-size: 2rem, $duration: 0.6s, $delay: 0.6s) {
    content: "";
    position: absolute;
    top: calc(50% - #{$pulse-size / 2});
    left: calc(50% - #{$pulse-size / 2});
    background: $pulse-color;
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: $pulse-size;
    height: $pulse-size;
    pointer-events: none;
    opacity: 0;
    display: block;
    animation: animate-element-fade-in 0.75s $delay ease-out forwards,
    loading-pulse $duration ease-in-out $delay infinite;
}


/// Nice mixins to animate list items on hover
///
/// @group humans-machines
@mixin list-hover($offset: -0.1em) {
    a {
        .no-mobile.no-touch & {
            display: block;

            span {
                position: relative;

                &:before {
                    @include pseudo();
                    bottom: $offset;
                    left: 50%;
                    transform: translateX(-50%) scaleX(0);
                    height: 1px;
                    width: 100%;
                    opacity: 0;
                    background: rgba($text-color--default, 0.8);
                    transition: transform 0.15s $trans-func--default, opacity 0.15s $trans-func--default;
                }
            }
        }

        @include hover {
            span {
                &:before {
                    // width: 100%;
                    transform: translateX(-50%) scaleX(1);
                    opacity: 1;
                    transition: transform 0.15s $trans-func--default 0.05s, opacity 0.15s $trans-func--default 0.05s;
                }
            }
        }

        .ie &,
        .edge & {
            span:before {
                transition: none !important;
            }
        }
    }
}

/// Great a media box with a specific ratio
///
/// @group humans-machines
@mixin ratio-box($ratio: $landscape-ratio) {
    .media-box,
    &.media-box {
        overflow: hidden;

        // needs to be wrapped with a before because otherwise it will break on firefox and edge -> flex-box issue
        &:before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: $ratio;
            height: 0;
        }
    }
}

/// Nice mixins to add an arrow to the end of an accordion or select field
///
/// @group humans-machines
@mixin dropdown-arrow($color: transparent, $indent: false, $reverse: false) {
    @if $reverse != false {
        background: $color svg-inline(icon--nom-dropdown-reverse) no-repeat;
    } @else {
        background: $color svg-inline(icon--nom-dropdown) no-repeat;
    }

    background-size: 0.8em 1.28em;

    @if $indent != false {
        background-position: calc(100% - 1em) center; // @todo make this right
    } @else {
        background-position: 100% center;
    }
}

/// Hie scrollbars on ios and other webkits
///
/// @group humans-machines
@mixin hide-scrollbars() {
    &::-webkit-scrollbar {
        height: 0;  /* remove scrollbar space */
        width: 0;  /* remove scrollbar space */
        background: transparent;  /* optional: just make scrollbar invisible */
        display: none;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        display: none;
    }
}


/// BEM Element
/// @param {String} $element - Element's name
/// @param {String} $root [&] - (Optional) The root to use
///
/// @group humans-machines
@mixin bem-e($element, $root: '&') {
    @if $root != '&' and str-slice($root, 0, 1) != '.'{
        $root: '.' + $root;
    }

    //@at-root {
    #{$root}__#{$element} {
        @content;
    }
    //}
}

/// BEM Modifier
/// @access public
/// @param {String} $modifier - Modifier's name
///
/// @group humans-machines
@mixin bem-m($modifier) {
    &--#{$modifier} {
        @content;
    }
}

/// Debug info
///
/// @group humans-machines
@mixin show-style-info() {
    &:before {
        @include pseudo();
        @include textstyle--teaser;
        padding: 2rem;
        outline: 1px solid $border-color--light-1;
        background: $back-color--default;
        content: attr(data-style-info);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        pointer-events: none;
        z-index: 9999;
        font-variant-numeric: tabular-nums;
        opacity: 0;
        transition: opacity 0.1s linear;

        .section-fond & {
            background: $back-color--tertiary;
        }
    }

    // only show if debugging is true
    @if($debugging == true) {
        position: relative;

        &:hover {
            outline: 1px solid $border-color--light-1;

            &:before {
                opacity: 1;
            }
        }
    }
}

/// Hide Elements Visually without loosing accessebility
/// Ref: https://css-tricks.com/places-its-tempting-to-use-display-none-but-dont/
///
/// @group humans-machines
@mixin hide {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px !important;
    padding: 0;
    border: 0;
}
