// Show an inverted header if appropriate

// Desktop behaviour
@include respond-to(grid-l) {
    body.logo-inverted:not(.is-scrolled-beyond-header) {
        .main-menu__head-secondary {
            color: $text-color--inverted;

            .svg-ico {
                stroke: $text-color--inverted;
            }

            .is-active {
                stroke: $text-color--default;
                box-shadow: $box-shadow--s-inverted !important;
            }

            .dropdown {
                color: $text-color--default;
            }

            .action-button__count {
                color: inherit;
            }
        }

        .svg-ico--nom-logo {
            fill: $text-color--inverted;
        }
    }
}

// Additional background hero styles
@include respond-to(decent) {
    body.has-background-hero.logo-inverted:not(.is-scrolled-beyond-header) {
        .main-menu__head-primary {
            color: $text-color--inverted;
        }
    }

    body.has-background-hero.logo-inverted {
        .ajax-content .hero h1 {
            color: $text-color--inverted;
        }
    }
}
