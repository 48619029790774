// Styling for a real list
ul,
li {
    @include pseudo-list;
}

.link-list {
    > li {
        margin: grid-spacing(grid-s, s) 0 0 0;

        .accordion & {
            margin: grid-spacing(grid-s, s) 0 0 grid-width-gutter(grid-s);
        }

        // indent as far as icon would go
        &.link-list-indent {
            padding-left: 3rem;
        }
    }

    button,
    a {
        @include textstyle--button;
        display: block;
        padding: 0;
    }

    // cart etc counter
    i span {
        @include textstyle--tabular-figures;
        display: inline-block;
        padding: 0 0.08em;
    }

    /* probably better dont
    li.is-active {
        button,
        a {
            @include textstyle--book;
        }
    }*/

    button {
        width: 100%;

        &:focus {
            outline: none;
        }
    }

    .svg-ico {
        vertical-align: text-bottom;
        transform: scale(0.9);
        margin: 0 -0.1em;

        &:first-child {
            margin-right: 0.5rem;
            width: 2.2rem;
        }
    }

    h4 {
        @include textstyle--s;
        @include textstyle--uppercase;
        margin-top: 0.5em;
        margin-bottom: 1em;
    }

    .list-gap-m {
        border-top: 1px solid $border-color--light-1;
        padding-top: grid-spacing(grid-s, s);
    }

    .list-gap-l {
        border-top: 1px solid $border-color--light-1;
        margin-top: grid-spacing(grid-s, m);
        padding-top: grid-spacing(grid-s, m);
    }

    @include respond-to(decent) {
        &:not(.filter__panel-option-list):not(.link-list--s) {
            > li {
                border-top: 1px solid $border-color--light-1;
                padding: grid-spacing(grid-s, s) 0;
                margin: 0;

                .accordion & {
                    margin: 0;
                }

                &:last-child {
                    border-bottom: 1px solid $border-color--light-1;
                }

                ul {
                    margin-bottom: grid-spacing(grid-s, s);
                }
            }

            .list-gap-l {
                border-bottom: 1px solid $border-color--light-1;
            }

            .list-gap-l + * {
                margin-top: calc(#{grid-spacing(grid-s, s) * 2} + 2.2rem);
            }
        }

        &.filter__panel-option-list {
            > li {
                padding: grid-spacing(grid-s, xs) 0;
                margin: 0;
            }
        }

        &:not(.link-list--s) {
            a {
                @include textstyle--teaser;
            }

            .is-active {
                a {
                    position: relative;

                    &:after {
                        content: '—';
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }
            }
        }
    }
}

// specs list
.specs-list {
    dl,
    dt,
    dd {
        margin: 0;
        word-wrap:break-word; // prevent overflowing
    }

    dt {
        @include textstyle--xs;
        @include textstyle--uppercase;
        line-height: $line-height * 1.4 / 1.2; // have same line-height as dd ($fontsize--s / $fontsize--xs)
    }

    dd {
        @include textstyle--s;
        // font-variant-numeric: tabular-nums; // cannot be done with cloud.typography
        @include textstyle--tabular-figures;
        margin-bottom: 1em * $line-height; // one line
    }

    .list-couple & {
        @include grid-inner-width(5,2,2);
    }

    @include respond-to(decent) {
        dd {
            @include textstyle--default;
        }

        dt {
            @include textstyle--s;
            line-height: $line-height * 1.7 / 1.4; // have same line-height as dd ($fontsize--m / $fontsize--s)
        }
    }
}

.specs-list--ref {
    // number
    dd {
        @include textstyle--l;
        letter-spacing: 0.08em;
    }

    @include respond-to(decent) {
        dl {
            display: flex;
            align-items: baseline;
        }

        // ref
        dt {
            @include textstyle--teaser;
            padding-right: 0.2em;
        }

        // number
        dd {
            @include textstyle--default;
            @include textstyle--l;
            letter-spacing: 0.04em;
        }
    }
}

.list-couple {
    display: flex;
    justify-content: space-between;
}

.bullet-list {
    li {
        @include textstyle--default;
        @include textstyle--m;
        list-style-type: '· ';

        .help__content & {
            @include textstyle--s;
        }
    }
}