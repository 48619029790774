/* stylelint-disable */

.is-development {
    .show-grid {
        position: fixed;
        bottom: 35vh;
        right: 4vw;
        width: 3em;
        height: 3em;
        background: pink;
        border-radius: 100%;
        margin: 0;
        cursor: help;
        z-index: map-get($z-index, action-button);

        .touch & {
            cursor: pointer;
        }

        .no-touch & {
            &:active + .grid-overlay,
            &:focus + .grid-overlay,
            &:hover + .grid-overlay {
                opacity: 0.8;
            }
        }
    }

    // Grid overlay
    .grid-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: map-get($z-index, grid-overlay);
        pointer-events: none;
        opacity: 0;
        transition: opacity $trans-time--s $trans-func--default;

        .grid {
            height: 0;
            border-top: solid 1px rgba(purple, 0.5);
            border-bottom: solid 1px rgba(purple, 0.5);
            display: none;

            //&:before {
            //    left: grid-width(0.5);
            //}
            //
            //&:after {
            //    right: grid-width(0.5);
            //}
        }

        [class^="cell--"] {
            position: relative;
            background-color: rgba(magenta, 0.02);
            border-left: solid 1px rgba(magenta, 0.4);
            border-right: solid 1px rgba(magenta, 0.4);

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
                height: 100%;
                width: 1px;
                background-color: rgba(aqua, 0.4);
            }
        }

        // Loop over grid configs
        @each $grid-size in map-keys($grid-config) {
            $config: map-get($grid-config, $grid-size);
            $short-name: map-get($config, short-name);
            $breakpoint: map-get($config, breakpoint);

            .grid--#{$short-name} {
                width: grid-container($grid-size);

                [class^="cell--"] {
                    width: grid-width($grid-size);

                    &:not(:last-child) {
                        margin-right: grid-gutter($grid-size);
                    }

                    &:before {
                        left: grid-padding-h($grid-size);
                    }

                    &:after {
                        right: grid-padding-h($grid-size);
                    }
                }

                @include respond-to($breakpoint) {
                    display: flex;
                    height: 100%;
                }
            }
        }
    }

    // Make grid visible
    &.is-grid-visible {
        .show-grid {
            &:after {
                @include pseudo();
                top: 50%;
                left: 50%;
                background: black;
                border-radius: 50%;
                width: 0.6em;
                height: 0.6em;
                transform: translate3d(-50%, -50%, 0);
            }
        }

        .grid-overlay {
            opacity: 1;
        }
    }
}
/* stylelint-enable */
