// Nomos retailer

.hero--retailer {
    height: grid-spacing(grid-s, s);

    @include respond-to(decent) {
        min-height: calc(#{$hero-top-height-default} - 4em);
    }
}

// dont have gap to footer if there is no more CMS content
.page-retailer {
    @include respond-to(decent) {
        .main-content > :last-child:first-child {
            margin-bottom: 0;
        }
    }
}

// main retailer panel
.retailer {
    display: block;

    @include respond-to(decent) {
        display: flex;
        justify-content: space-between;
    }

    // loading styles
    @include respond-to(grid-s) {
        &:before {
            @include pseudo();
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba($back-color--primary, 0.95);
            pointer-events: none;
            opacity: 0;
            z-index: map-get($z-index, loading-spinner);
            transition: opacity $trans-time--m $trans-func--default;

            .is-map-loading.is-not-initial-load:not(.has-visible-map) & {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}

.retailer-list-wrapper {
    @include grid-width(10, 4, 4);

    @include respond-to(grid-s) {
        padding-bottom: grid-spacing(grid-s, l);
    }

    @include respond-to(decent) {
        h2 {
            @include textstyle--teaser-m;
        }
    }

    @include respond-to(grid-m) {
        h2 {
            @include textstyle--teaser;
        }
    }
}

.retailer-search-wrapper { // wrapping in .retailer-search-wrapper needs to be done for element in .module-form
    @include respond-to(decent) {
        min-height: 100vh;
    }

    .retailer-search-top {
        @include grid-spacing(m);

        @include respond-to(decent) {
            margin-top: grid-spacing(grid-l, s);
        }

        @include respond-to(grid-m) {
            margin-top: grid-spacing(grid-m, s);
        }
    }

    .retailer-search {
        padding-top: grid-padding();
        padding-left: calc((#{grid-padding-h(grid-s)} + #{grid-margin(grid-s)}));
        padding-right: calc((#{grid-padding-h(grid-s)} + #{grid-margin(grid-s)}));
        @include grid-bottom-spacing(xs);
        max-width: 200%;
        width: 100vw;
        margin-left: calc((#{grid-padding-h(grid-s)} + #{grid-margin(grid-s)}) * -1);
        margin-right: calc((#{grid-padding-h(grid-s)} + #{grid-margin(grid-s)}) * -1);
        background: $back-color--default;
        position: sticky;
        top: -1px;
        z-index: 1;

        fieldset {
            position: relative;
        }

        @include respond-to(grid-s) {
            // search-box and map button wrapper
            .btn-wrapper {
                display: flex;
                justify-content: space-between;

                > div {
                    position: relative;
                    flex: 1 1 auto;
                }

                .btn--secondary {
                    margin-left: 0.6rem;
                    flex: 0 0 auto;

                    // prevent overflow with too long button texts
                    span {
                        white-space: nowrap;
                        max-width: 35vw;
                        overflow: hidden;
                        display: block;
                    }
                }

                .has-action-buttons-shown & {
                    max-width: grid-width(grid-s, 8);
                }
            }

            .awesomplete > ul {
                width: grid-inner-width(grid-s, 10);
            }
        }

        @include respond-to(decent) {
            width: grid-width(grid-l, 4) + grid-gutter-width(grid-l);
            margin-left: grid-padding-h(grid-l) * -1;
            margin-right: (grid-padding-h(grid-l) + grid-gutter-width(grid-l)) * -1;
            padding-top: 3em;
            padding-left: grid-padding-h(grid-l);
            padding-right: grid-padding-h(grid-l) * 2 + grid-width(grid-l, 1);
            padding-bottom: 2em;
        }

        @include respond-to(grid-m) {
            width: calc(#{grid-margin(grid-m)} + #{grid-width(grid-m, 4)} + #{grid-gutter-width(grid-m)});
            margin-left: calc((#{grid-margin(grid-m)} + #{grid-padding-h(grid-m)}) * -1);
            margin-right: (grid-padding-h(grid-m) + grid-gutter-width(grid-m)) * -1;
            padding-top: 3em;
            padding-left: calc(#{grid-margin(grid-m)} + #{grid-padding-h(grid-m)});
            padding-right: grid-padding-h(grid-m) + grid-gutter-width(grid-l);
            padding-bottom: 2em;
        }
    }
}

.retailer-locate-me {
    margin-top: 0.6em;
}

// Wrapper for results heading, filter and results
.retailer-result-wrapper {
    @include grid-spacing(l);
    @include grid-bottom-spacing(l);
    visibility: hidden;
    position: absolute; // take away from flow
    opacity: 0;
    transform: translate3d(0, 3em, 0);
    transition: opacity $trans-time--m $trans-func--default,
    transform $trans-time--m $trans-func--default;

    // show results if there are some
    .has-result & {
        position: relative;
        visibility: visible;
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    h3 {
        @include textstyle--m;
    }

    @include respond-to(decent) {
        padding-bottom: 40vh;

        h3 {
            @include textstyle--l;
        }
    }

    @include respond-to(grid-m) {
        h3 {
            @include textstyle--m;
        }
    }
}

// retailer results
.retailer-list {
    @include grid-spacing(m);
}

// retailer results item
.retailer-list-item {
    cursor: pointer;
    border-bottom: 1px solid $border-color--light-1;
    padding: 1.6em 0 2em;
    position: relative;

    &:first-child {
        border-top: 1px solid $border-color--light-1;
    }

    h3 {
        @include textstyle--default;
        @include textstyle--l;
        margin-right: 4rem;
        position: relative;
    }

    .distance-wrapper + h3 {
        margin-right: 7rem;

        &.has-retailer-icons {
            margin-right: 8.4rem;
        }
    }

    address {
        @include textstyle--default;
        @include textstyle--l;
        position: relative;
    }

    button {
        position: relative;
    }

    // active state
    &:before {
        @include pseudo();
        border-top: 1px solid $border-color--light-1;
        border-bottom: 1px solid $border-color--light-1;
        top: -1px;
        left: calc((#{grid-padding-h(grid-s)} + #{grid-margin(grid-s)}) * -1);
        right: calc((#{grid-padding-h(grid-s)} + #{grid-margin(grid-s)}) * -1);
        bottom: -1px;
        background: $back-color--tertiary;
        opacity: 0;

        @include respond-to(grid-l) {
            // left: calc((#{grid-padding-h(grid-l)} + #{grid-margin(grid-l)}) * -1); // full width?
            left: grid-padding-h(grid-l) * -1;
            right: calc((#{grid-padding-h(grid-l)} + #{grid-margin(grid-l)}) * -1);
        }

        @include respond-to(grid-m) {
            left: calc((#{grid-padding-h(grid-m)} + #{grid-margin(grid-m)}) * -1);
            right: calc((#{grid-padding-h(grid-m)} + #{grid-margin(grid-m)}) * -1);
        }
    }

    &.is-active {
        &:before {
            transition: opacity $trans-time--l $trans-func--default;
            opacity: 1;
        }
    }
}

// Distance
.distance-wrapper {
    position: absolute;
    top: 1.8em;
    right: 0;

    .distance {
        @include textstyle--default;
        @include textstyle--s;
    }
}

// Map wrapper
.retailer-map-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: map-get($z-index, map);
    // background: rgb(229, 227, 223); // taken from map style, needs to be updated if mapstyle changes
    background: $back-color--tertiary;
    transition: opacity $trans-time--s $trans-func--default,
    transform $trans-time--s $trans-func--default,
    visibility 0s $trans-func--default;


    body:not(.has-visible-map) & {
        opacity: 0;
        visibility: hidden;
        transform: translate3d(0, 100%, 0);
        transition: opacity $trans-time--m $trans-func--default,
        transform $trans-time--m $trans-func--default,
        visibility 0s $trans-func--default $trans-time--m;
    }


    @include respond-to(decent) {
        width: grid-width(grid-l, 6, true);
        margin-right: calc(#{grid-margin(grid-l)} * -1);
        height: calc(100vh + 2px);
        position: relative; // Fallback for browser, that dont support sticky
        position: sticky;
        top: -1px;
        left: auto;
        bottom: auto;
        right: auto;
        z-index: auto;

        body:not(.has-visible-map) & {
            opacity: 1;
            visibility: inherit;
            transform: none;
        }
    }

    @include respond-to(grid-m) {
        width: grid-width(grid-m, 6, true);
        margin-right: calc(#{grid-margin(grid-m)} * -1);
    }
}

// Pump up map if visible on mobile
body {
    @include respond-to(grid-s) {
        &.has-visible-map {
            height: 100%;
            overflow: hidden;

            .main-content {
                z-index: map-get($z-index, map);
                position: relative;
            }
        }
    }
}

// close map on mobile
.retailer-map-close {
    position: fixed;
    top: 0;
    right: grid-margin(grid-s, false);
    z-index: 5;

    .btn {
        padding: grid-padding(grid-s);
        height: $menu-height-mobile;

        > span:not(.svg-ico) {
            width: 3.4rem;
            height: 3rem;
        }
    }
}

// Map canvas
.retailer-map-canvas {
    width: 100%;
    height: 100%;
    position: relative;

    .is-map-loading & {
        &:after {
            @include loading-pulse($text-color--default, 1.2rem, 0.8s, 1s);
            z-index: 1;
            left: auto;
            transform: none;
            top: 4rem;
            right: 4.75rem;
        }
    }
}

// Dealer Window
.retailer-map-info {
    width: 100vw;
    position: absolute;
    top: calc(50% + 2.5em);
    left: 50%;
    background: $back-color--default;
    box-shadow: $box-shadow--s;
    transform: translate3d(-50%, 100%, 0);
    transition: transform $trans-time--s $trans-func--default,
    opacity $trans-time--s $trans-func--default;
    height: calc(50vh - 2.5em);
    opacity: 0;
    pointer-events: none;

    h3 {
        @include textstyle--default;
        @include textstyle--l;

        // verkehrte welte
        @include respond-to(grid-l) {
            @include textstyle--m;
        }
    }

    address {
        @include textstyle--l;

        // verkehrte welte
        @include respond-to(grid-l) {
            @include textstyle--m;
        }
    }

    .shopfinder-retailer-info-actions {
        @include grid-spacing(xs);
        display: block;
    }

    .btn {
        @include textstyle--button;
        line-height: 1.6;

        // verkehrte welte
        @include respond-to(grid-l) {
            @include textstyle--button-s;
        }
    }

    .shopfinder-retailer-info-icons {
        @include grid-spacing(xs);
        @include textstyle--default;
        display: block;

        > * {
            display: inline-block;
            padding-right: 1rem;
        }

        // verkehrte welte
        @include respond-to(grid-l) {
            @include textstyle--s;

            > * {
                padding-right: 0.8rem;
            }
        }
    }

    // Caret
    &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 1px; // prevent blitzer
        left: 50%;
        margin-left: 0.8em;
        border-style: solid;
        border-width: 1em;
        border-color: transparent transparent $back-color--default $back-color--default;
        transform-origin: 0 0;
        transform: rotate(118deg) scale(1, 0.8) skew(-30deg);
        box-shadow: -0.2em 0.2em 0.3em 0 rgba(0, 0, 0, 0.15);
    }

    // loading pulse
    &.is-loading {
        &:after {
            @include loading-pulse();
        }
    }

    &.is-visible {
        transform: translate3d(-50%, 0, 0);
        opacity: 1;
        pointer-events: all;
    }

    @include respond-to(decent) {
        @include grid-width(10, 4, 2.5);
        box-shadow: $box-shadow--m;
        top: calc(50% - 6rem);
        height: auto;
        min-height: 24rem;
        transform: translate3d(-50%, -100%, 0);
        border-radius: 1px;

        &:before {
            top: auto;
            bottom: 1px;
            left: calc(50% - 2em);
            transform-origin: 100% 100%;
            transform: rotate(298deg) scale(1, 0.8) skew(-30deg);
            box-shadow: -0.4em 0.4em 0.6em 0 rgba(0, 0, 0, 0.1);
        }

        &.is-visible {
            transform: translate3d(-50%, -100%, 0);
            transition: transform $trans-time--l $trans-func--default,
            opacity $trans-time--l $trans-func--default;
        }

        &.is-loading {
            transform: translate3d(-50%, calc(-100% - 2em), 0);
            transition: transform $trans-time--s $trans-func--default,
            opacity $trans-time--s $trans-func--default;
        }
    }
}

.retailer-map-info-content {
    @include grid-padding();
    @include grid-spacing(s);
    @include grid-bottom-spacing(l);
    padding-right: grid-width-gutter(grid-s, 1);
    margin-left: grid-margin(grid-s, false);
    margin-right: grid-margin(grid-s, false);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;

    @include respond-to(decent) {
        margin: 0;
        padding: grid-padding-v(grid-l) grid-spacing(grid-l, m) grid-spacing(grid-l, m) grid-padding-v(grid-l);
    }

    [data-action="map:radius"] {
        margin-top: 1em;
    }
}

.retailer-map-info-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.5rem 1.5rem 0 0;
    z-index: 1;

    .svg-ico {
        width: 1.5rem;
        height: 1.5rem;
    }

    @include respond-to(decent) {
        padding-top: 1rem;
    }

}

.retailer-map-controls { // desktop only
    position: sticky;
    bottom: 4rem;
    float: right;
    z-index: 10;
    margin: -14rem 3rem 0 0;
    box-shadow: $box-shadow--s;
    transition: opacity $trans-time--m $trans-func--default;

    .has-action-buttons-shown & {
        transition: bottom $trans-time--s $trans-func--default;
        bottom: 2rem + $action-bar-height-desktop;
    }

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5rem;
        height: 5rem;
        background: $back-color--primary;

        .svg-ico {
            width: 100%; // fix for iOS
        }

        &:last-child {
            border-top: 1px solid $border-color--light-2;
        }
    }
}

// Hide control buttons if map is not active
.retailer-map-canvas[data-enabled="false"] ~ .retailer-map-controls {
    opacity: 0;
    pointer-events: none;
}

// Retailer icons
.retailer-icons {
    display: inline-block;
    word-spacing: -0.5rem;
    margin-right: -18rem; // prevent linebreaks
}

//.has-neomatik,
//.has-gold {
//    display: inline-block;
//    color: rgb(252, 98, 61);
//
//    &:after {
//        content: '•';
//        position: relative;
//        display: inline-block;
//        transform: scale(1.6);
//
//        .retailer-icons & {
//            transform: scale(1.2);
//        }
//    }
//}
//
//.has-gold {
//    color: rgb(156, 122, 63);
//}

.retailer-filter-wrapper {
    transition: opacity $trans-time--s $trans-func--default;

    &[aria-hidden="true"] {
        opacity: 0;
        pointer-events: none;
    }
}

.has-super31 {
    display: inline-block;
    color: #007862;

    &:after {
        content: '•';
        position: relative;
        display: inline-block;
        transform: scale(1.6);

        .retailer-icons & {
            transform: scale(1.2);
        }
    }
}

.retailer-map-enable-overlay {
    @include grid-padding();
    position: absolute;
    z-index: 3;
    inset: 0 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity $trans-time--m $trans-func--default;

    &[aria-hidden="true"] {
        opacity: 0;
        pointer-events: none;
    }

    @include breakpoint(decent) {
        align-items: flex-start;
    }
}

.retailer-map-enable-overlay__content {
    @include grid-width(10, 4, 4);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    p {
        text-align: center;
    }

    .btn-group {
        @include grid-spacing(m);
        justify-content: center;
    }

    @include breakpoint(decent) {
        position: sticky;
        top: 0;
        padding-top: 6em;
    }
}