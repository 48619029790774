/// Shorthand Method for keyframes
/// Needed here since mixins need animations and therefore come next in mains.scss
///
/// @group humans-machines
/// @content
/// @param {string} $name - The name for the keyframes
@mixin keyframes($name) {
    @keyframes #{$name} {
        @content;
    }
}

// default animations
@include keyframes(animate-element-default) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        opacity: 0;
    }
    1% {
        transform: translate3d(0, 8rem, 0); // was 5em
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(animate-element-default-mobile) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        opacity: 0;
    }
    1% {
        transform: translate3d(0, 4rem, 0); // was 5em
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(animate-element-s) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        opacity: 0;
    }
    1% {
        transform: translate3d(0, 6rem, 0); // was 3em
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(animate-element-s-mobile) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        opacity: 0;
    }
    1% {
        transform: translate3d(0, 3rem, 0); // was 3em
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(animate-element-xs) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        opacity: 0;
    }
    1% {
        transform: translate3d(0, 4rem, 0); // was 3em
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(animate-element-xs-mobile) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        opacity: 0;
    }
    1% {
        transform: translate3d(0, 1rem, 0); // was 3em
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(animate-element-fade-in) {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(fade-in-main-bottom) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        opacity: 0;
    }
    1% {
        transform: translate3d(0, 6em, 0);
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(fade-in-main-bottom-mobile) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        opacity: 0;
    }
    1% {
        transform: translate3d(0, $top-animation + 4em, 0);
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@include keyframes(fade-in-main-bottom-modal) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        opacity: 0;
    }
    1% {
        transform: translate3d(0, 2em, 0);
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(fade-in-hero) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        opacity: 0;
    }
    1% {
        transform: scale(1.1);
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(fade-in-hero-mobile) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        opacity: 0;
    }
    1% {
        // transform: scale(1.1) translate3d(0, 8em, 0);
        transform: translate3d(0, $top-animation, 0);
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(fade-in-logo) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        opacity: 0;
    }
    1% {
        transform: scale(1.15) translate3d(0, $top-animation * -1 / 2, 0);
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(fade-in-logo-wrapper) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        opacity: 0;
    }
    1% {
        transform: translate3d(0, $top-animation, 0);
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(fade-in-main-menu) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        opacity: 0;
    }
    1% {
        transform: translate3d(0, -2em, 0);
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(fade-in-main-menu-mobile) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        visibility: hidden;
    }
    1% {
        transform: translate3d(0, #{$menu-height-mobile}, 0);
        visibility: visible;
    }
    100% {
        visibility: visible;
    }
}

@include keyframes(fade-in-hero-headlines) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        opacity: 0;
    }
    1% {
        transform: translate3d(0, -2rem, 0);
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(fade-in-hero-headlines-mobile) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        opacity: 0;
    }
    1% {
        transform: translate3d(0, 2rem, 0);
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(fade-out-body) {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@include keyframes(zoom-in-single) {
    0% {
        transform: scale(#{$zoom-scale-inverted-single}) translate3d(0, 2%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@include keyframes(zoom-in-full) {
    0% {
        opacity: 1;
        transform: none;
    }
    100% {
        opacity: 1;
        transform: scale(1.06);
    }
}

@include keyframes(loading-pulse) {
    0%, 100% {
        opacity: 0.3;
        transform: scale(0.8);
    }
    50% {
        opacity: 1;
        transform: scale(1);
    }
}

@include keyframes(btn-pulse) {
    0%, 100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }
}

@include keyframes(btn-pulse-desktop) {
    0%, 100% {
        opacity: 1;
    }

    60% {
        opacity: 0.3;
    }
}

/* helper animations */
// adjsut visibility to dealy intersect trigger
@include keyframes(delay-visibility) {
    0% {
        transform: translate3d(0, 100vh, 0);
        display: none;
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

// if things are loaded into the sidebar they sometimes don’t appear if they are sticky
// here is a fix
@include keyframes(fix-sticky) {
    0% {
        position: relative;
    }
    100% {
        position: sticky;
    }
}

// helper for sticky stuff in accordions
@include keyframes(remove-overflow-hidden) {
    0% {
        overflow: hidden;
    }
    100% {
        overflow: visible;
    }
}

