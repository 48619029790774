.hero {
    position: relative;

    // main teaser
    // and slideshow teaser

    > .teaser {
        .media-box-wrapper {
            overflow: hidden;
            height: 120vw;
            max-height: 60vh; // @todo define image height
        }

        .media-box {
            height: 100%;
        }

        // media-box is rellax box
        .rellax {
            // is initiated?
            .has-rellax .ajax-content & {
                // transitions -20px / +20px with speed -2 no matter which viewport
                margin-top: -20px;
                height: calc(100% + 40px);
            }
        }
    }

    &:not(.hero--product) {
        .slideshow {
            .media-box {
                height: 120vw;
                max-height: 60vh; // @todo define image height
            }
        }
    }

    &.has-background {
        @supports(--css: variable) {
            --hero-background: $back-color--secondary;
        }

        &:before {
            @include pseudo();
            top: grid-spacing(grid-s, l) * -1;
            left: 0;
            right: 0;
            bottom: -18em; // flow behind content
            background: $back-color--secondary;

            @supports(--css: variable) {
                background: var(--hero-background);
            }
        }
    }

    @include respond-to(decent) {
        // page title / hero title
        .teaser-title,
        .hero__title,
        .page-title {
            min-height: calc(#{$hero-top-height-default});
            padding-top: $menu-height-desktop;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        // limit hero headline width
        h1,
        h2 {
            max-width: 86rem; // 7 rows on indesign viewport (1400px)

            .modal & {
                max-width: 73rem; // 6 rows on indesign viewport (1400px)
            }
        }

        // main single teaser
        > .teaser {
            // show text before media
            flex-direction: column-reverse;
            cursor: default;

            .media-box-wrapper {
                margin-top: grid-spacing(grid-l, s);
                max-height: 100vh;

                @include respond-to(port) {
                    max-height: 60vh;
                }
            }
        }

        // slideshow teaser
        &:not(.hero--product) {
            .slideshow {
                // limit hero headline width
                h1,
                h2 {
                    max-width: 73rem; // 6 rows on indesign viewport (1400px)
                }

                .teaser {
                    // show text before media
                    flex-direction: column-reverse;
                }

                .media-box {
                    margin-top: grid-spacing(grid-l, s);
                    max-height: calc(100vh - 20rem); // limit slideshow height to fit media and text into viewport

                    // fix for mobile safari vh-issue
                    @supports (--css: variable) {
                        .is-safari.touch & {
                            max-height: calc((var(--view-height-offset) * 100vh) - 16rem);
                        }
                    }
                }
            }
        }

        &.has-background {
            &:before {
                top: 0;
                bottom: -40em;
            }
        }
    }

    @include respond-to(grid-m) {
        // limit hero headline width
        h1,
        h2 {
            max-width: 61rem; // 9 rows on breakpoint start (600px)

            .modal & {
                max-width: 54rem; // 8 rows on breakpoint start (600px)
            }
        }

        // main single teaser
        > .teaser {
            .media-box-wrapper {
                margin-top: grid-spacing(grid-m, s);
            }
        }

        &:not(.hero--product) {
            .slideshow {
                h1,
                h2 {
                    max-width: 54rem; // 8 rows on breakpoint start (600px)
                }

                .media-box {
                    margin-top: grid-spacing(grid-m, s);
                }
            }
        }
    }
}

.hero--lander {
    @include respond-to(grid-s) {
        // Prevent headlines being overlayed by action buttons
        h1,
        h2,
        p {
            .ajax-content & {
                max-width: 85%;
            }
        }
    }

    @include respond-to(decent) {
        h1,
        h2 {
            @include textstyle--teaser-xl;
        }

        // page title / hero title
        .teaser-title,
        .hero__title,
        .page-title {
            min-height: calc(#{$hero-top-height-l});
        }
    }

    @include respond-to(grid-m) {
        h1,
        h2 {
            @include textstyle--teaser-l;
        }
    }
}

.hero--lander-high {
    @include respond-to(decent) {
        > .teaser {
            .media-box-wrapper {
                height: #{103em / 0.8};
                @include grid-width(10, 8, 8);
                margin: grid-spacing(grid-l, m) auto;
                max-height: 200vh;

                @include respond-to(port) {
                    max-height: 200vh;
                }

                .modal & {
                    @include grid-width(10, 7, 7);
                    height: #{90em / 0.8};
                    margin-left: grid-width-gutter(grid-l, 1.5);
                }
            }
        }
    }

    @include respond-to(grid-m) {
        > .teaser {
            .media-box-wrapper {
                height: #{69.6em / 0.8};
                margin: grid-spacing(grid-m, m) auto;

                .modal & {
                    height: #{60.8em / 0.8};
                    margin-left: grid-width-gutter(grid-m, 1.5);
                }
            }
        }
    }
}

.hero--basic {
    margin-top: grid-spacing(grid-s, l);

    .text-ultra {
        padding-top: 0;
    }

    @include respond-to(grid-s) {
        // Prevent headlines being overlayed by action buttons
        h1,
        h2 {
            max-width: 85%;
        }

        p {
            .modal & {
                max-width: 85%;
            }
        }
    }

    @include respond-to(decent) {
        margin-top: 0;
    }
}

.hero--magazine-lander {
    text-align: center;

    .page-title {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: flex-end;
    }

    &.has-background {
        &:before {
            bottom: 0;
        }
    }

    // no hero teaser below
    &:last-of-type {
        padding-bottom: 3em;

        &.has-background {
            &:before {
                bottom: -24em;
            }
        }
    }

    @include respond-to(grid-s) {
        padding-top: grid-spacing(grid-s, m);

        &.has-background {
            &:before {
                top: 0;
            }
        }
    }

    @include respond-to(decent) {
        .text-container + .text-container {
            padding-top: 0;
        }

        // no hero teaser below
        &:last-of-type {
            padding-bottom: 6em;

            &.has-background {
                &:before {
                    bottom: -30em;
                }
            }
        }
    }
}

.hero--magazine-teaser {
    &.has-background {
        &:before {
            top: -2.4em;
            bottom: -24em;
        }
    }

    @include respond-to(decent) {
        &.has-background {
            &:before {
                top: 0;
                bottom: -32em;
            }
        }
    }
}

.hero--magazine-article {
    .hero__head {
        min-height: 14em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }

    .hero__title {
        @include grid-spacing(m);
        @include grid-bottom-spacing(s);
    }

    .media-box-wrapper {
        background: #000;
        width: 100%;
    }

    @include respond-to(decent) {
        .hero__title {
            min-height: 1px;
        }

        .modal & {
            .hero__head {
                margin-right: grid-width(grid-l, 1, false, true);
            }

            .media-box-wrapper {
                margin-right: grid-width(grid-l, 1, false, true);
            }
        }
    }

    @include respond-to(grid-m) {
        .modal & {
            .hero__head {
                margin-right: grid-width(grid-m, 1, false, true);
            }

            .media-box-wrapper {
                margin-right: grid-width(grid-m, 1, false, true);
            }
        }
    }
}

.hero--back-img {
    .media-box {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .page-title {
        height: 60vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    // fix for video box titles
    &.video-box {
        .page-title {
            z-index: 2;
        }
    }

    @include respond-to(decent) {
        @include grid-bottom-spacing(m);

        .page-title {
            min-height: calc(#{$hero-top-height-l});
            height: 60vh;
        }
    }

    @include respond-to(port) {
        .page-title {
            height: 40vh;
        }
    }
}

.hero--split-screen {

    h1 {
        @include textstyle--uppercase;
    }

    .grid-container {
        @include grid-spacing(xl);
    }

    // make fit for rellax
    .media-box-wrapper {
        overflow: hidden;
        @include grid-spacing(s);
        height: 120vw;
        max-height: 80vh;
    }

    .media-box {
        height: 100%;
    }

    // media-box is rellax box
    .rellax {
        // is initiated?
        .has-rellax .ajax-content & {
            // transitions -20px / +20px with speed -2 no matter which viewport
            margin-top: -20px;
            height: calc(100% + 40px);
        }
    }

    .head-wrapper {
        @include grid-spacing(s);
    }

    @include respond-to(grid-l) {
        display: flex;
        height: 30vw;
        min-height: 100vh;
        transition: min-height 1.5s $trans-func--easing-out;

        .show-hint & {
            min-height: calc(100vh - #{$action-bar-height-desktop});
        }

        // fix for mobile safari vh-issue
        @supports(--css: variable) {
            .is-safari.touch & {
                min-height: calc(var(--view-height-offset) * 100vh);
            }

            .is-safari.touch .show-hint & {
                min-height: calc((var(--view-height-offset) * 100vh) - #{$action-bar-height-desktop});
            }
        }

        h1 {
            @include textstyle--teaser-l;
        }

        h2 {
            @include textstyle--teaser-m;
        }

        // interim-solution for line-breaks
        .head-wrapper {
            max-width: 46rem; // @todo: find solution for line breaks
        }

        .grid-container {
            padding: 0 grid-gutter-width(grid-l) 0 grid-margin(grid-l, false);
            width: calc(#{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 4)});
        }

        .text-container {
            margin-top: calc(90vh - 34rem);
            transition: transform 1.5s $trans-func--easing-out;

            @supports(--css: variable) {
                .is-safari.touch & {
                    margin-top: calc((var(--view-height-offset) * 90vh) - 34rem);
                }
            }

            .show-hint & {
                transform: translate3d(0, $action-bar-height-desktop * -0.4, 0);
            }
        }

        .media-box-wrapper {
            width: calc(#{grid-margin(grid-l)} + #{grid-width(grid-l, 6)});
            height: 100%;
            max-height: none;
            margin-top: 0;
        }

        // reset rellax stuff since image starts at the top anyways
        // media-box is rellax box
        .rellax {
            // is initiated?
            .has-rellax .ajax-content & {
                margin-top: 0;
                height: 100%;
            }
        }
    }

    @include respond-to(grid-m) {
        h1 {
            @include textstyle--teaser-m; // smaller variation
        }

        h2 {
            @include textstyle--teaser; // smaller variation
        }

        .grid-container {
            margin-top: 0;
            min-height: calc(#{$hero-top-height-l});
            display: flex;
            align-items: flex-end;
        }

        .head-wrapper {
            width: grid-width(grid-m, 6);
        }
    }
}

.hero--caliber,
.hero--product {
    // does nothing yet
    // all other declarations inside _product.scss / _caliber.scss
}

.hero__caption {
    @include grid-max-width(8, 6, 6);
}
