.tab-list {
    display: flex;
    width: 100%;
    border-bottom: solid 1px $border-color--secondary;
    padding-left: 1rem;
}

.tab-list__tab {
    @include textstyle--button-s;
    font-size: $fontsize--xs;
    display: inline-block;
    line-height: $button-height-default - $baseline-offset - $button-padding * 2;
    padding: $button-padding 1rem $baseline-offset + $button-padding 1rem;
    cursor: pointer;

    @include respond-to(grid-l) {
        font-size: $fontsize--s;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    &[aria-selected="true"] {
        border: solid 1px $border-color--secondary;
        border-bottom: solid 1px #fff;
        margin-bottom: -1px;
    }

    &[aria-selected="false"] {
        color: $text-color--secondary;
    }
}