// Nomos modal title

// modal title inner wrapper
.modal-title {
    white-space: nowrap;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    max-width: calc(#{grid-inner-width(grid-s, 10)} - 3.5em); // dont let long content push close button to the side

    .btn-wrapper {
        position: relative;
        display: none;
        flex: 0 0 auto;
        padding-left: 1rem;
    }

    .head-wrapper {
        padding-right: 1.6em;
        flex: 0 1 auto;
        display: flex;
        overflow: hidden;
        align-items: baseline;

        i {
            margin-left: 1.5rem;
        }
    }

    h2,
    h3 {
        @include textstyle--teaser;
        @include textstyle--s;
        @include textstyle--uppercase;
    }

    &.modal-title--magazine {
        h2 {
            @include textstyle--teaser;
            @include textstyle--s;
        }
    }

    &.modal-title--product {
        .btn-wrapper {
            display: flex;
        }
    }

    @include respond-to(grid-s) {
        .nav-product-actions {
            display: none;
        }

        &.modal-title--product {
            .btn-wrapper {
                .btn:not(:last-child) {
                    margin-right: 0.4em;
                }
            }
        }
    }

    @include respond-to(decent) {
        height: calc(#{$button-height-default} + 4px); // always show button outline
        @include grid-max-width(8,8,8);

        .has-sidebar-page--quickview &,
        .has-sidebar-page--note &,
        .has-sidebar-page--profile & {
            max-width: 66em; // dont let long content push close button to the side
        }

        h2,
        h3 {
            @include textstyle--teaser-xs;
        }

        .head-wrapper {
            i {
                margin-left: 2.5rem;
            }
        }

        .btn-wrapper {
            position: relative;
            display: block;
            flex: 0 0 auto;
            padding-left: 2rem;
            margin-left: auto;
            margin-right: grid-padding(grid-l) * 2; // compensate for grid-max-width of parent

            // title more space on smaller modal
            .has-sidebar-page--quickview & {
                margin-right: 0;
            }
        }

        // dont show family button if modal is shown above specific family site
        .btn-wrapper--family-btn {
            .page-series-detail &,
            .page-family-detail & {
                display: none;
            }
        }

        // Hide mobile only buttons
        .btn--family-overview {
            display: none;
        }

        // search bar
        .modal-title__search {
            margin-right: auto;
        }

        // magazine specifics
        &.modal-title--magazine {
            h2 {
                @include textstyle--m;
            }
        }

        .modal-title__label {
            margin-right: 2rem;

            @include textstyle--teaser;
            @include textstyle--xs;
            @include textstyle--uppercase;
        }

        // product specifics
        &.modal-title--product {
            .head-wrapper {
                position: relative;
                width: grid-inner-width(grid-l, 5);
                flex: 0 0 auto;
                height: 3rem; // force height for truncate style
                overflow: visible;

                // truncate full title if scrolled
                .is-modal-scrolled & {
                    overflow: hidden;
                    @include fade-cover(right, 5em);

                    @supports (mask-image: none) {
                        overflow: inherit;
                    }
                }
            }

            // position headline absolute to alow line-breaks
            h2 {
                position: absolute;
                top: 0;
                left: 0;
                max-width: 100%;
                white-space: normal;
                @include textstyle--l;
                line-height: $line-height;

                @supports (clip-path: inset(0 0 0 0)) {
                    transition: clip-path 0.15s linear;
                    clip-path: inset(0 0 0 0);

                    .is-modal-scrolled & {
                        clip-path: inset(0 0 calc(100% - 3rem) 0);
                    }
                }
            }

            .btn-wrapper {
                margin-right: -3.2em; // move closer to the close button
            }

            .btn-group {
                .btn {
                    margin-right: 2rem;
                }
            }
        }

        // Bag button in modal
        .modal-title__bag {
            transition: margin $trans-time--s $trans-func--easing-out,
                        background $trans-time--s $trans-func--default;
            margin-left: -0.5rem;
            margin-right: -0.5rem;

            .btn.btn--single-icon {
                padding: 0;
                width: $action-button-size;
                height: $action-button-size;
            }

            &.is-active {
                box-shadow: $box-shadow--s;
                margin-left: 0;
                margin-right: 0;
                z-index: 1;
            }
        }
    }

    @include respond-to(grid-m) {
        .has-sidebar-page--quickview &,
        .has-sidebar-page--note &,
        .has-sidebar-page--profile & {
            max-width: 68em; // dont let long content push close button to the side
        }

        .btn-wrapper {
            margin-right: 0; // compensate for grid-max-width of parent
        }

        &.modal-title--product {
            .nav-product-actions {
                display: none;
            }

            .btn-wrapper {
                margin-right: -1.6em; // move closer to the close button
            }

            .head-wrapper {
                position: relative;
                width: grid-inner-width(grid-m, 7);
            }
        }
    }
}
