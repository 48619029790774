// Debug panels
.debug {
    position: relative;
    z-index: 1;
}

.debug__inner {
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    box-shadow: 0 0 0.25rem rgba(0,0,0,0.25);
    border-radius: 0.2rem;
    padding: 0.4rem 0.6rem;
    opacity: 0.9;
    transform: translate3d(0.6rem, 0.6rem, 0);

    .debug--bottom & {
        top: auto;
        bottom: 0;
        transform: translate3d(0.6rem, -0.6rem, 0);
    }

    > * {
        @include textstyle--xs;
        @include textstyle--book;
        color: red;
        display: block;

        &:not(:first-child) {
            margin-top: 0.4rem;
        }
    }
}
