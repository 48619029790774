.mediadb-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include grid-spacing(s);
}

.mediadb-entries {
    @include grid-width(10, 8, 6);
}

// Categories list
// hidden on all other but grid-l
.mediadb-categories {
    width: grid-width(grid-l, 3);
    padding-right: grid-padding-h(grid-l);
    padding-left: grid-padding-h(grid-l);
}

// General entries list
.mediadb-entries__list {
    @include pseudo-list;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    @include grid-bottom-spacing(m);
}

.mediadb-entries__list__item {
    @include grid-width(5, 4, 3);
    @include grid-bottom-spacing(m);

    &.mediadb-entries__list__item--download {
        @include grid-width(8, 4, 3);
    }
}

// Categories on right side
.mediadb-categories__list {
    @include pseudo-list;
}

.mediadb-categories__list__item {
    display: block;
    border-top: solid 1px $border-color--light-1;

    &:last-child {
        border-bottom: solid 1px $border-color--light-1;
    }
}

// Single entry
.mediadb-entry {
    h3 {
        @include textstyle--teaser;
        @include textstyle--teaser-xs;
    }

    &.mediadb-entry--download {
        .mediadb-entry__image {
            @include grid-padding;
            background-color: $back-color--tertiary;
        }
    }

    @include respond-to(decent) {
        h3 {
            @include textstyle--teaser;
        }
    }
}

.mediadb-entry__content {
    @include grid-padding;
}

.mediadb-terms-of-use {
    @include grid-spacing(s);
}
