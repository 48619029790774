// Variables

// debugging?
$debugging: true !default;

// Text colors / Main
$text-color--default: #111;
$text-color--primary: $text-color--default;
$text-color--secondary: #8c8c8c;
$text-color--secondary-dark: darken(#8c8c8c, 10%); // to be used on fond color
$text-color--inverted: #fafafa;
$text-color--price-saved: #19ac4c;

// Background colors / Main
$back-color--default: #ffffff;
$back-color--primary: $back-color--default;
$back-color--secondary: #f2f2f2; // darker light grey
$back-color--secondary-dark: darken($back-color--secondary, 2%);
$back-color--tertiary: #f7f7f7; // lighter light grey
$back-color--warm-grey: #f1f1ef; // warm grey
$back-color--dark-grey: #8c8c8c; // warm grey
$back-color--dark: #1e1e1e;
$back-color--modal-back: #f2f2f2;

// Border colors
$border-color--default: $text-color--default;
$border-color--primary: $border-color--default;
$border-color--secondary: lighten(#c8c8c8, 10%); // compensate for thinner lines in InDesign
$border-color--light-1: $border-color--secondary;
$border-color--tertiary: #f5f5f5; // @todo
$border-color--light-2: $border-color--tertiary;

// button colors
$button-super: #bfbfbf;

// Validate colors
$text-color--error: #e72645;
$back-color--error: #fbdfe4;

// webfont baseline offset (webfont has a broken baseline)
$baseline-offset: 0.15rem;

// icon colors
$icon-color--default: $text-color--default;
$icon-color--payment: #ccc;

// fading objects if disabled
$fade-disable: 0.5;

// shadow
$box-shadow--s: 0 0 0.5em rgba(0, 0, 0, 0.15);
$box-shadow--s-dark: 0 0 0.5em rgba(0, 0, 0, 0.25);
$box-shadow--s-inverted: 0 0 0.35em rgba(255, 255, 255, 0.6);
$box-shadow--m: 0 0.5em 5em rgba(0, 0, 0, 0.15);
$drop-shadow: 0.6rem 0.6rem 0 0 rgba(0,0,0,0.06);
$drop-shadow-big: 0.9rem 0.9rem 0 0 rgba(0,0,0,0.06);
$drop-shadow-hover: 1rem 1rem 0.8em 0 rgba(0,0,0,0.04);
$drop-shadow-big-hover: 1.2rem 1.2rem 0.8em 0 rgba(0,0,0,0.04);

// Vertical rhythm
// --> see $grid-config

// how long to wait befor js is loaded
// if js is not there by then we will show stuff anyways
// as js is there still wait some time for animation to happen
$js-wait: 3s;
$init-wait: 0.8s;
$ios-wait: 0.2s;

// Transition times // todo
$trans-time--xs: 0.125s;
$trans-time--s: 0.25s;
$trans-time--m: 0.5s;
$trans-time--l: 1s;
$trans-time--xl: 2s;
$trans-time--xxl: 3.5s;

$trans-time--startoff: 0.8s;

// Transition funcs // todo
$trans-func--default: cubic-bezier(.1,.6,.4,1);
$trans-func--easing-out: cubic-bezier(.1,.9,.3,1);
$trans-func--linear: linear;
// $trans-func--animate: cubic-bezier(.48,.4,0,.99);

// Transitions / Menu
$menu-trans-timing-in: 0.4s;
$menu-trans-timing-out: 0.2s;
$menu-trans-easing-in: $trans-func--easing-out;
$menu-trans-easing-out: $trans-func--default;

// scale soldaten images -> watches front
// $soldier-scale: 0.85; // remove soldier scale for new soldiers
// $soldier-overview-scale: 0.92; // Make images bigger on small overview sizes // remove soldier scale for new soldiers
// $soldier-crosseling-scale-m: 0.85;
// $soldier-crosseling-scale-d: 0.9; // was .85

// Scale for lazy load and rollover
$zoom-scale: 1.025;
$zoom-scale-s: 1.025;
$zoom-scale-inverted: 0.95;
$zoom-scale-inverted-single: 0.88;

// media-box image ratios
$portrait-ratio: 5 / 4 * 100%; // 1.25 → 125%
$portrait-ratio-l: 10 / 7 * 100%; // √2:1 (DIN) → 7/10 is pretty close → 1.414 → 141%
$landscape-ratio: 7 / 10 * 100%; // √2:1 (DIN) → 7/10 is pretty close → 1.414 → 70%
$landscape-ratio-l: 4 / 5 * 100%; // 1.25 → 80%
$square-ratio: 102%; // optical square
$wide-screen-ratio: 50%; // 1:2 → 50% // mainly used for movie boxes

// sizes and heights
$button-height-default: 3.6rem;
$button-height: $button-height-default;
$button-height-s: 3rem;
$button-height-super: 5.4rem;
$button-padding: 0.6rem;

$input-height-default: 4.4rem;
$input-height: $button-height-default;
$input-height-s: 3.6rem;

$action-button-size: 4.8rem;
$navicon-width: 3.4rem;
$navicon-height: 3rem;

$menu-height-mobile: 5.4rem;
$menu-height-desktop: 10rem;
$menu-top-padding-desktop: 18em;
$modal-top-padding-desktop: 16em;
$modal-header-mobile: 5rem;
$modal-header-desktop: 8rem;
$menu-peak-mobile: 7em; // viewable part of background on shown mobile navi

// leading whitespace on desktop heros
$hero-top-height-vari: 10vh; // add a viewport based factor
$hero-top-height-default: (18em + #{$hero-top-height-vari});
$hero-top-height-l: (28em + #{$hero-top-height-vari});
$hero-top-height-xl: (34em + #{$hero-top-height-vari}); // magazine
$hero-top-height-product: (12em + #{$hero-top-height-vari});
$hero-top-height-product-grid-m: $hero-top-height-default;
$hero-top-height-product-modal: (8em + #{$hero-top-height-vari});

// media boxes on desktop heros
$hero-media-height-default: 16em;
$hero-media-height-l: 28em;
$hero-media-height-product: 12em;

// action bar
$action-bar-height-mobile: $modal-header-mobile;
$action-bar-height-desktop: 8rem;

// various
$logo-height-mobile: 10rem;
$top-animation: 20em;

// banner
$banner-offset: 3.2rem;
$banner-height-l: 32rem;
$banner-height-m: 25rem;

$global-banner-height-s: 3rem;
$global-banner-height-l: 3.6rem;

// quick-contact width
$quick-contact-width--en: 31rem;
$quick-contact-width--de: 26rem;
$quick-contact-width--fr: 30rem;
$quick-contact-width--es: 26rem;
$quick-contact-width--it: 26rem;
$quick-contact-width--ja: 26rem;
$quick-contact-width--zh-hans: 26rem;
$quick-contact-width--zh-hant: 26rem;

// $quick-contact-width--en--m: rem;
// $quick-contact-width--de--m: rem;
// $quick-contact-width--fr--m: rem;
// $quick-contact-width--es--m: rem;
// $quick-contact-width--it--m: rem;
// $quick-contact-width--ja--m: rem;
// $quick-contact-width--zh-hans--m: rem;
// $quick-contact-width--zh-hant--m: rem;



// Grid, Breakpoints and Responsiveness
$breakpoint-names: (
        xs: xs,

        s: s,
        mobile: s,
        small: s,
        grid-s: s,

        m: m,
        medium: m,
        tablet-portrait: m,
        grid-m: m,

        l: l,
        large: l,
        desktop: l,
        table-portrait: l,

        lm: lm,
        large-m: lm,
        tablet: lm,

        xl: xl,
        extra-large: xl,
        desktop-large: xl,

        grid-l: grid-l,
        decent: decent,
        port: port,
);

// Setup grid config
$grid-config: (
        // Small Grid (Mobile)
        grid-s: (
                columns: 10,
                cell-width: 2.86em,
                gutter-width: 0.6em,
                padding-horizontal: 1em,
                padding-vertical: 1.6em, // extended padding, default: padding-horizontal
                v-space: (
                        xs: 0.6em, // gutter
                        s: 1.2em,
                        m: 2.4em,
                        l: 3.6em,
                        xl: 4.8em,
                        xxl: 7.2em,
                ),
                breakpoint: s,
                short-name: s,
        ),

        // Medium grid (Tablet)
        grid-m: (
                columns: 10,
                cell-width: 8em,
                gutter-width: 0.8em,
                padding-horizontal: 2em,
                padding-vertical: 3em, // extended padding, default: padding-horizontal
                v-space: (
                        xs: 0.8em, // gutter
                        s: 1.6em,
                        m: 3.2em,
                        l: 4.8em,
                        xl: 8em,
                        xxl: 11.2em,
                ),
                breakpoint: m,
                short-name: m,
        ),

        // Large grid (Desktop)
        grid-l: (
                columns: 10,
                cell-width: 12em,
                gutter-width: 1em,
                padding-horizontal: 2em,
                padding-vertical: 3em, // extended padding, default: padding-horizontal
                v-space: (
                        xs: 1em, // gutter
                        s: 2em,
                        m: 4em,
                        l: 8em,
                        xl: 12em,
                        xxl: 16em,
                ),
                breakpoint: l--xl,
                short-name: l,
        ),
) !default;

// Setup grid config
$media-ratio: (
        portrait-s: 1,
);

// z-indexing
$z-index: (
    some-z: 1,
    global-banner: 300,
    action-bar: 450,
    filter-desktop: 450,
    section-sticky: 449,
    select-dropdown: 460,
    header: 500,
    slideshow-nav: 550,
    navicon: 600,
    dropdown: 650,
    modal: 700,
    modal-header: 701,
    dropdown--account: 710,
    map: 750,
    popup: 800,
    help: 860,
    gallery-zoom: 865,
    alert-desktop: 870,
    action-button: 880,
    quick-contact: 881,
    cookie-message: 890,
    alert: 891,
    loading-spinner: 900,
    grid-overlay: 2000, // For debug reasons only
);

