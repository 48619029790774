.link {
    &:after {
        content: '›';
        display: inline-block;
        padding-left: 0.35em;
    }

    // @include underline;
}

//.link {
//    @include textstyle--default;
//    position: relative;
//    color: $text-color--secondary;
//    text-decoration: none;
//    // outline: none;
//
//    &:after {
//        @include pseudo;
//        left: 0;
//        bottom: -0.15em;
//        width: 100%;
//        height: 1px;
//        background: currentColor;
//        opacity: 0.3;
//        transition: opacity $trans-time--s $trans-func--default;
//        z-index: 1;
//
//        dd & {
//            bottom: 0;
//        }
//    }
//
//    &:hover {
//        &:after {
//            opacity: 0.7;
//        }
//    }
//
//    // Clean link without underline
//    &.link--clean {
//        &:after {
//            display: none;
//        }
//    }
//
//    &.link--form {
//        @include textstyle--s;
//    }
//
//    &.link--contains-icon {
//        display: flex;
//        align-items: center;
//
//        span {
//            display: inline-block;
//            margin-left: 1rem;
//        }
//    }
//
//    &.link--block {
//        display: block;
//        text-decoration: none;
//        color: inherit;
//
//        &:after {
//            display: none;
//        }
//    }
//}
//
//// Set proper color for links in texts
//.content-wrapper p a {
//    position: relative;
//    color: currentColor;
//
//    &:after {
//        @include pseudo;
//        left: 0;
//        bottom: -0.15em;
//        width: 100%;
//        height: 1px;
//        background: currentColor;
//        opacity: 0;
//        transition: opacity $trans-time--s $trans-func--default;
//        z-index: 1;
//    }
//
//    &:hover {
//        &:after {
//            opacity: 0.7;
//        }
//    }
//}
