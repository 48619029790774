// Nomos Alert
.alert {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: $back-color--default;
    box-shadow: $box-shadow--m;
    transition: opacity $trans-time--m $trans-func--default 0s,
                transform $trans-time--m $trans-func--easing-out 0s;
    z-index: map-get($z-index, alert);
    transform: translate3d(0, -100%, 0);
    padding-bottom: 0.2rem; // center content visually

    // Add custom styles for alert levels here
    &.alert--info {
    }

    &.alert--warn {
    }

    &.alert--success {
    }

    &.alert--error {
        color: $text-color--error;
    }

    .js & {
        pointer-events: none;

        &[aria-hidden="false"] {
            transform: translate3d(0, 0, 0);
            opacity: 1;
            pointer-events: all;
            transition: opacity $trans-time--m $trans-func--default 0s,
                        transform $trans-time--m $trans-func--easing-out 0s;

            .alert__close {
                opacity: 1;
            }
        }
    }

    .grid-container {
        align-items: center;
    }

    .alert__text {
        @include grid-padding;
        flex: 1 1 auto;
        @include grid-max-width(9, 6, 7);
    }

    .alert__close {
        margin-top: 0.2em;
        position: absolute;
        opacity: 0;
        top: 0;
        right: 0;
        transition: opacity $trans-time--l $trans-func--default $trans-time--m;
    }

    &.alert--auto-close {
        .alert__close {
            display: none;
        }
    }

    // HTML alert content
    .alert__content {
        display: flex;
        align-items: center;

        .alert__content__image {
            flex: 0 0 auto;
            @include grid-width(2, 1, 0.5);
            margin-left: grid-padding-h(grid-s) * -1;

            > div {
                padding-bottom: 100%;
                position: relative;

                &:before {
                    @include loading-pulse(darken($back-color--secondary, 4%), 1.4rem, 1s, 1s);
                }
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
            }
        }

        &.alert__content--l {
            .alert__content__image {
                @include grid-width(2, 2, 1);
            }
        }

        .alert__content__message {
            flex: 1 1 auto;
            margin-left: grid-padding-h(grid-s) + grid-gutter-width(grid-s);
        }
    }

    @include respond-to(decent) {
        top: auto;
        bottom: 0;
        transform: translate3d(0, 4em, 0);
        margin-top: 0.4em;
        z-index: map-get($z-index, alert-desktop);

        .grid-container {
            min-height: $action-bar-height-desktop;
        }

        p {
            @include textstyle--m;
        }

        .alert__content {
            min-height: 3rem;
        }

        // give non auto-close alerts min-height cause otherwise close-btn might be covered by action-btns
        &:not(.alert--auto-close) .alert__text {
            .has-action-buttons-shown & {
                min-height: 12rem;
            }
        }

        .alert__content__image {
            margin-top: -1.6em; // center text on the right vertically visually
            margin-bottom: grid-padding-h(grid-l) * -1;
        }

        &.alert--cart {
            top: 9rem;
            left: auto;
            right: calc(#{grid-margin(grid-l)} + 3em + 2rem); // aligned with button
            bottom: auto;
            width: grid-width(grid-l, 4);
            transform: translate3d(0, 0, 0) scale(1.05);
            cursor: pointer;

            .grid-container {
                width: auto;
            }

            .alert__content__image {
                margin: 0;
            }

            .alert__content__image + .alert__content__message {
                margin-bottom: 0.4em; // center vertically visually
            }

            .alert__text {
                padding-right: 5em;
            }
        }

        .alert__content__image + .alert__content__message {
            margin-left: 1.5em;
        }
    }

    @include respond-to(grid-l) {
        // take care of action buttons covering alert
        .has-action-buttons-shown & {
            .alert__text {
                max-width: grid-width(grid-l, 4.5);
            }
        }
    }

    @include respond-to(grid-m) {
        .alert__content__image {
            margin: 0;
        }

        // handle alerts covered by action-btns
        &:not(.alert--cart) .alert__text {
            .has-action-buttons-shown & {
                margin-bottom: $action-bar-height-desktop;
            }
        }

        &.alert--cart {
            right: grid-margin(grid-m, false);
            width: grid-width(grid-m, 6);
        }
    }
}
