// Nomos Clock Spinner / loading icon

// style the clock
$clock-color: darken($border-color--secondary, 8%);
$clock-stroke: 1.2rem;
$clock-size: 60em;
$clock-scale: 0.1; // take stroke, size and scale into account to end up with a stroke width in between 1px and 2px
$clock-minute: 80%;
$clock-hour: 55%;
$clock-timing: 0.8s;
$clock-easing-hours: cubic-bezier(.55,1.61,.59,.74);
$clock-easing-minutes: cubic-bezier(.11,.51,.52,.92);

$spinner-loading-threshold: 1s;

// rotate animation
@keyframes rotateMinutes {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

// rotate Hours
@keyframes rotateHours {
    @for $i from 0 through 12 {
        $percent: $i * 100 / 12 * 1%;
        $rotation: $i * 30deg;
        #{$percent} {
            transform: rotate(#{$rotation});
        }
    }
}

// rotate Hours
@keyframes showSpinner {
    from {
        transform: scale($clock-scale) translate3d(-50%, 0, 0);
        opacity: 0;
        visibility: hidden;
    }
    to {
        transform: scale($clock-scale) translate3d(-50%, -50%, 0);
        opacity: 1;
        visibility: visible;
    }
}

.loading-spinner {
    position: fixed;
    top: 47%;
    left: 50%;
    width: $clock-size;
    height: $clock-size;
    border: $clock-stroke $clock-color solid;
    border-radius: 50%;
    transform: scale($clock-scale) translate3d(-50%, -50%, 0);
    transform-origin: top left;
    visibility: hidden;
    z-index: map-get($z-index, loading-spinner);
    pointer-events: none;

    // handles
    &:before,
    &:after {
        content: '';
        position: absolute;
        bottom: 50%;
        left: 50%;
        margin-left: $clock-stroke / 2 * -1;
        height: $clock-minute / 2;
        width: $clock-stroke;
        background: $clock-color;
        transform-origin: 50% bottom;
    }

    .is-map-loading.is-not-initial-load &,
    .is-loading & {
        // fade in spinner after threshold
        animation: showSpinner $trans-time--m $trans-func--default $spinner-loading-threshold 1;
        animation-fill-mode: both;

        // minute handle
        &:before {
            animation: rotateMinutes $clock-timing infinite $clock-easing-minutes;
        }

        // hour handle
        &:after {
            height: $clock-hour / 2;
            animation: rotateHours $clock-timing * 12 infinite $clock-easing-hours;
        }
    }

    // extended loading threshold — just a fallback here
    .page-product-stream.is-loading &,
    .page-watchfinder.is-loading & {
        background: rgba($back-color--primary, 0.94);

        // fade in spinner after threshold
        animation: showSpinner $trans-time--m $trans-func--default 3s 1;
        animation-fill-mode: both;
    }

    @include respond-to(grid-s) {
        .is-map-loading.is-not-initial-load & {
            animation: showSpinner $trans-time--m $trans-func--default 0s 1;
            animation-fill-mode: both;
        }

        .has-visible-map.is-map-loading.is-not-initial-load & {
            animation: none;
        }

        .has-main-navi & {
            display: none; // spinner does not make sense with non-opening accordions, correct? // @todo find custom solution
        }
    }

    @include respond-to(decent) {
        .is-map-loading.is-not-initial-load & {
            animation: none;
        }
    }

    @include respond-to(grid-l) {
        .has-main-navi:not(.has-main-navi-page--family-overview):not(.has-main-navi-page--quickview) & {
            margin-left: grid-width(grid-l, 1) * -1;
        }

        .page-watchfinder:not(.has-sidebar) &,
        .page-product-stream:not(.has-sidebar) & {
            margin-left: grid-width(grid-l, 1.25);
        }

        .has-sidebar & {
            margin-left: grid-width(grid-l, 1) * 0.5;
        }

        .has-sidebar-page--note &,
        .has-sidebar-page--profile &,
        .has-sidebar-page--quickview & {
            margin-left: grid-width(grid-l, 2);
        }
    }

    @include respond-to(grid-m) {
        .has-sidebar & {
            margin-left: grid-width(grid-m, 1) * 0.5;
        }
    }
}


