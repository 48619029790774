.cookie-settings {
    .cookie-settings__head + .cookie-settings__table {
        @include grid-spacing(xs);
    }

    .cookie-settings__table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
            text-align: left;
            position: relative;
        }

        th,
        td {
            @include textstyle--s;
            vertical-align: top;
        }

        .cookie-settings__link {
            word-wrap: break-word;
            word-break: break-all;
        }

        .cookie-settings__link--function {
            &:after {
                opacity: 0;
                content: ' 	✓';
                transition: opacity $trans-time--s $trans-func--default;
            }

            &.is-active:after {
                opacity: 1;
            }
        }

        @include respond-to(s) {
            thead {
                display: none;
            }

            tr {
                display: flex;
                flex-wrap: wrap;
            }

            td {
                &:nth-child(odd) {
                    width: 40%;
                    padding-right: 1em;
                    word-wrap: break-word;
                    word-break: break-all;
                }

                &:nth-child(even) {
                    width: 60%;
                }
            }

            td:before {
                @include textstyle--xs;
                display: block;
                font-family: $type-fam--gotham-narrow;
                content: attr(data-label);
            }

            tr:first-child td:nth-child(1),
            tr:first-child td:nth-child(2) {
                border-top: solid 1px $border-color--secondary;
            }

            td {
                padding-top: 1rem;

                &:nth-child(3),
                &:nth-child(4) {
                    border-bottom: solid 1px $border-color--secondary;
                    padding-bottom: 1rem;
                }
            }

            .toggle-checkbox {
                margin-top: grid-spacing(grid-s, xs);
            }
        }

        @include respond-to(decent) {
            table-layout: fixed;

            th {
                font-family: $type-fam--gotham-narrow;
                font-weight: normal;
                text-transform: uppercase;
            }

            th,
            td {
                padding: 0.5rem 2rem 0.5rem 0.25rem;
            }

            tr {
                th {
                    border-bottom: solid 1px $border-color--default;
                }

                td {
                    border-bottom: solid 1px $border-color--secondary;
                }
            }

            th:nth-child(1) {
                width: 20%;
            }

            th:nth-child(2) {
                width: 34%;
            }

            th:nth-child(3) {
                width: 18%;
            }

            th:nth-child(4) {
                width: 28%;
            }
        }
    }
}