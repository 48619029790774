// Nomos modals

// Body states
body {
    &.has-main-navi,
    &.has-sidebar,
    &.has-popup {
        height: 100%;
        overflow: hidden;
    }

    // mobile only modals
    @include respond-to(grid-s) {
        &.has-quick-contact {
            height: 100%;
            overflow: hidden;
        }
    }

    // mobile / tablet only modals
    @include respond-to(s--m) {
        &.has-filter {
            height: 100%;
            overflow: hidden;
        }
    }

    // only do this on mobile-safari. Mac-Chrome causes BIG performance-Problems if you use clip-path on a large scale
    .ios & {
        // clip hero, footer, and main-content to make it easier for browser to render
        @supports (clip-path: inset(0 0 0 0)) {
            @include respond-to(decent) {
                &.has-main-navi--fully-opened {
                    .ajax-content {
                        .main-menu__head,
                        .hero,
                        .main-content,
                        .footer {
                            clip-path: inset(0 0 0 calc(#{grid-width(grid-l, 8)} + #{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 0.5)}));
                        }
                    }

                    .modal__backdrop {
                        clip-path: inset(0 0 0 calc(#{grid-width(grid-l, 8)} + #{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 0.5)} - 1px));
                    }
                }

                &.has-sidebar--fully-opened {
                    .ajax-content {
                        .main-menu__head,
                        .hero,
                        .main-content,
                        .footer {
                            clip-path: inset(0 calc(#{grid-width(grid-l, 9)} + #{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 0.5)}) 0 0);
                        }
                    }

                    .modal__backdrop {
                        clip-path: inset(0 calc(#{grid-width(grid-l, 9)} + #{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 0.5)} - 1px) 0 0);
                    }
                }
            }

            @include respond-to(grid-l) {
                // smaller sidebar
                &.has-sidebar.has-sidebar--fully-opened {
                    &.has-sidebar-page--quickview,
                    &.has-sidebar-page--note,
                    &.has-sidebar-page--profile {
                        .ajax-content {
                            .main-menu__head,
                            .hero,
                            .main-content,
                            .footer {
                                clip-path: inset(0 calc(#{grid-width(grid-l, 6)} + #{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 0.5)}) 0 0);
                            }
                        }

                        .modal__backdrop {
                            clip-path: inset(0 calc(#{grid-width(grid-l, 6)} + #{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 0.5)} - 1px) 0 0);
                        }
                    }
                }
            }

            @include respond-to(grid-m) {
                &.has-main-navi--fully-opened {
                    .ajax-content {
                        .main-menu__head,
                        .hero,
                        .main-content,
                        .footer,
                        .modal__backdrop {
                            clip-path: inset(0 0 0 100vw);
                        }
                    }
                }

                &.has-sidebar--fully-opened {
                    .ajax-content {
                        .main-menu__head,
                        .hero,
                        .main-content,
                        .footer {
                            clip-path: inset(0 calc(#{grid-width(grid-m, 9)} + #{grid-margin(grid-m)} + #{grid-width-gutter(grid-m, 0.5)}) 0 0);
                        }
                    }

                    .modal__backdrop {
                        clip-path: inset(0 calc(#{grid-width(grid-m, 9)} + #{grid-margin(grid-m)} + #{grid-width-gutter(grid-m, 0.5)} - 1px) 0 0);
                    }
                }
            }
        }
    }
}

// iOS modal issue fix
// fix input fields inside modals by making main content fix
html {
    --body-scroll-offset: 0;
}

.ajax-content {
    @supports (--css: variable) {
        // scope sidebar fix to engraving page
        .touch.ios .has-gallery-zoom &,
        .touch.ios .has-sidebar.has-sidebar-page--engraving & {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;

            .ajax-container {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                transform: translate3d(0, var(--body-scroll-offset), 0);
            }
        }

        // dropdown fix mobile only
        @include respond-to(grid-s) {
            .touch.ios .has-dropdown & {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: hidden;

                .ajax-container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    transform: translate3d(0, var(--body-scroll-offset), 0);
                }
            }
        }
    }
}

// General modal definition
.modal {
    position: relative;
    z-index: map-get($z-index, modal);

    &.modal--quick-contact {
        z-index: map-get($z-index, quick-contact);
    }

    // Fade out content, when another page is loaded into sidebar
    &.modal--sidebar {
        .modal__header__content,
        .modal__content--sidebar {
            > * {
                transition: opacity $trans-time--m $trans-func--linear;

                &:not(.modal__content-header-cover):not(.compare-head) {
                    position: relative; // Fix for Safari
                }
            }
        }

        &[aria-busy="true"] {
            cursor: wait;

            .modal__header__content,
            .modal__content--sidebar {
                > * {
                    opacity: 0;
                    pointer-events: none;
                }
            }
        }
    }
}

// Modal Backdrop
.modal__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $back-color--modal-back;
    pointer-events: none;
    opacity: 0;

    // show main menu backdrop
    &.modal__backdrop--main-menu {
        @include modal-backdrop-transition('.has-main-navi');
    }

    // show sidebar backdrop
    &.modal__backdrop--sidebar {
        @include modal-backdrop-transition('.has-sidebar');
    }

    // show popup backdrop
    &.modal__backdrop--popup {
        @include modal-backdrop-transition('.has-popup');
    }

    // show cookie-dialog backdrop
    &.modal__backdrop--cookie-dialog {
        @include modal-backdrop-transition('.has-cookie-dialog');
    }

    // show filter backdrop
    &.modal__backdrop--filter {
        @include modal-backdrop-transition('.has-filter');
    }

    // show quick contact backdrop
    &.modal__backdrop--quick-contact {
        @include modal-backdrop-transition('.has-quick-contact');
    }

    // dropdown__backdrops behave differently on decent
    @include respond-to(decent) {
        &.dropdown__backdrop {
            position: absolute;
            top: $action-bar-height-desktop;
            bottom: auto;
            left: calc((#{grid-padding(grid-l)} + #{grid-margin(grid-l)}) * -1);
            height: calc(100vh - #{$action-bar-height-desktop});
            width: 100vw;
            // transform: translate3d(0, 50vw, 0);
        }
    }

    @include respond-to(grid-m) {
        &.dropdown__backdrop {
            left: calc((#{grid-padding(grid-m)} + #{grid-margin(grid-m)}) * -1);
        }
    }
}

// Modal Header
.modal__header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: $modal-header-mobile;
    display: block;
    pointer-events: none;

    // background color handling
    .modal--sidebar &,
    .modal--filter &,
    .modal--quick-contact & {
        background: $back-color--default;
    }

    // Sidebar modal coming from the top on mobile
    .modal--sidebar & {
        @include modal-transition('has-sidebar', 'top');
    }

    // Filter modal coming from the top on mobile
    .modal--filter & {
        @include modal-transition('has-filter', 'top');
    }

    // Quick contact modal coming from the top on mobile
    .modal--quick-contact & {
        @include modal-transition('has-quick-contact', 'top');
    }

    @include respond-to(decent) {
        height: $modal-header-desktop;

        // background color handling
        .modal--sidebar &,
        .modal--filter &,
        .modal--quick-contact & {
            background: transparent;
        }

        // Main Menu Modal coming from the left on desktop
        .modal--main-menu & {
            @include modal-transition('has-main-navi', 'left');
        }

        // Sidebar Modal coming from the right on desktop
        .modal--sidebar & {
            @include modal-transition('has-sidebar', 'right');
        }
    }

    @include respond-to(grid-m) {
        // Filter Modal coming from the right on grid-m
        .modal--filter & {
            @include modal-transition('has-filter', 'right');
        }
    }
}

// Modal Header Wrapper
.modal__header__wrapper {
    // center mobile sidebar header
    .modal--filter &,
    .modal--quick-contact &,
    .modal--sidebar & {
        @include grid-container();
        margin: 0 auto;
        height: 100%;
    }

    @include respond-to(decent) {
        display: inline-block;
        position: absolute;
        top: 0;
        pointer-events: none;
        height: 100%;

        // Main Menu Modal sitting on the left
        .modal--main-menu & {
            left: 0;
            width: calc(#{grid-width(grid-l, 8)} + #{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 0.5)});
            padding: 0 grid-width-gutter(grid-l, 0.5) 0 grid-margin(grid-l, false);
        }

        // Sidebar Modal sitting on the right
        .modal--sidebar & {
            right: 0;
            margin: 0;
            width: calc(#{grid-width(grid-l, 9)} + #{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 0.5)});
            padding: 0 grid-margin(grid-l, false) 0 grid-width-gutter(grid-l, 0.5);
            background: $back-color--default;

            // Handle background better with .modal__content-header-cover
            // because background for header covers non-permanent scrollbars (eg OS X)
            // only apply if position sticky is supported
            // breaks in safari and ios, do not apply here
            @supports (position: sticky) {
                .no-scrollbars & {
                    background: transparent;
                }
            }
        }
    }

    @include respond-to(grid-l) {
        // smaller sidebar
        .has-sidebar-page--quickview .modal--sidebar &,
        .has-sidebar-page--note .modal--sidebar &,
        .has-sidebar-page--profile .modal--sidebar & {
            width: calc(#{grid-width(grid-l, 6)} + #{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 0.5)});
        }
    }

    @include respond-to(grid-m) {
        // Main Menu Modal sitting on the left
        .modal--main-menu & {
            left: 0;
            width: 100vw;
            padding: 0 grid-margin(grid-m, false);
            margin: 0 auto;
        }

        // Sidebar Modal sitting on the right
        .modal--sidebar & {
            width: calc(#{grid-width(grid-m, 9)} + #{grid-margin(grid-m)} + #{grid-width-gutter(grid-m, 0.5)});
            padding: 0 grid-margin(grid-m, false) 0 grid-width-gutter(grid-m, 0.5);
        }

        // Filter Modal sitting on the right
        .modal--filter & {
            right: 0;
            margin: 0;
            width: calc(#{grid-width(grid-m, 5)} + #{grid-margin(grid-m)} + #{grid-width-gutter(grid-m, 0.5)});
            padding: 0 grid-margin(grid-m, false) 0 grid-width-gutter(grid-m, 0.5);
        }
    }
}

// Modal Header Content
// hidden on mobile menu
.modal__header__content {
    position: relative;
    background: $back-color--default;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    pointer-events: all;

    .btn-group {
        width: auto;
        flex: 0 0 auto;
    }

    // Modal close button
    .modal__btn-close {
        margin-right: 0 !important;
    }

    // Modal cancel button
    .modal__btn-cancel {
        display: none;
    }

    // show cancel button on dedicated pages
    .page-store-index.has-sidebar-page--engraving:not(.is-loading) &,
    .page-product-stream.has-sidebar-page--engraving:not(.is-loading) & {
        .modal__btn-cancel {
            display: block;
        }

        .modal__btn-close {
            display: none;
        }

        .btn-group {
            padding-right: grid-padding-h(grid-s);
        }
    }

    @include respond-to(grid-l) {
        .page-store-index.has-sidebar-page--engraving:not(.is-loading) &,
        .page-product-stream.has-sidebar-page--engraving:not(.is-loading) & {
            .btn-group {
                padding-right: grid-padding-h(grid-l);
            }
        }
    }

    @include respond-to(grid-m) {
        .page-store-index.has-sidebar-page--engraving:not(.is-loading) &,
        .page-product-stream.has-sidebar-page--engraving:not(.is-loading) & {
            .btn-group {
                padding-right: grid-padding-h(grid-m);
            }
        }
    }
}

// Modal Header Title
.modal__header__title {
    @include grid-padding-h;
    flex: 1 1 auto; // have a min-width and also take care of .action-bar__buttons overflow on ios

    @include respond-to(grid-m) {
        .modal--filter & {
            flex: 1 0 auto;
        }
    }
}

// Modal Close Area
.modal__close {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    display: none;

    .has-main-navi .modal--main-menu &,
    .has-sidebar .modal--sidebar &,
    .has-popup .modal--popup &,
    .has-quick-contact .modal--quick-contact &,
    .has-filter .modal--filter & {
        display: block;
    }

    @include respond-to(grid-s) {
        .modal--quick-contact &,
        .modal--sidebar &,
        .modal--filter & {
            top: auto;
            left: 0;
            bottom: 0;
            height: 12em;
            display: none;
            align-items: center;
            justify-content: center;
        }

        .has-quick-contact .modal--quick-contact &,
        .has-sidebar .modal--sidebar &,
        .has-filter .modal--filter & {
            display: flex;
        }
    }
}

.modal__popup-close,
.modal__cookie-dialog-close {
    height: $modal-header-mobile;
    margin-bottom: $modal-header-mobile * -1;
    z-index: 1;
    @include grid-width(9, 7, 7);
    text-align: right;

    .has-popup-page--delete-account & {
        @include grid-width(9, 6, 4);
    }

    .has-popup-page--complete-account-registration & {
        @include grid-width(9, 6, 5);
    }
}

// Modal Body
.modal__body {
    // only relevant to sidebar and filter modal
    &.modal__body--filter,
    &.modal__body--quick-contact,
    &.modal__body--sidebar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    &.modal__body--sidebar {
        // Sidebar modal coming from the bottom on mobile
        @include modal-transition('has-sidebar', 'top');
    }

    &.modal__body--filter {
        // Filter modal coming from the bottom on mobile
        @include modal-transition('has-filter', 'top');
    }

    &.modal__body--quick-contact {
        // quick-contact modal coming from the bottom on mobile
        @include modal-transition('has-quick-contact', 'top');
    }

    &.modal__body--popup,
    &.modal__body--cookie-dialog {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transform: translate3d(0, 0, 0);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow: hidden;
    }

    &.modal__body--popup {
        // Pop pretty much fading in
        @include modal-transition('has-popup', 'popup');
    }

    &.modal__body--cookie-dialog {
        // Pop pretty much fading in
        @include modal-transition('has-cookie-dialog', 'popup');
    }

    @include respond-to(grid-s) {
        // show modal__close below content body
        &.modal__body--quick-contact,
        &.modal__body--sidebar {
            padding-bottom: 12em;
        }
    }

    @include respond-to(decent) {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        // Main Menu Modal coming from the left on desktop
        .modal--main-menu & {
            @include modal-transition('has-main-navi', 'left');
        }

        // Sidebar Modal coming from the right on desktop
        .modal--sidebar & {
            @include modal-transition('has-sidebar', 'right');
        }
    }

    @include respond-to(grid-m) {
        &.modal__body--filter {
            // Filter modal coming from the side on tablet
            @include modal-transition('has-filter', 'right');
        }
    }
}

// Modal Content
.modal__content {
    position: relative;
    background: $back-color--default;

    // Fixes an issue in Chrome, where content in an overlay is flickering while scrolling
    .is-chrome & {
        transform: translate3d(0, 0, 0);
    }

    &.modal__content--filter,
    &.modal__content--sidebar {
        min-height: 100vh;
    }

    &.modal__content--popup,
    &.modal__content--cookie-dialog {
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior: contain;
        height: 78vh;
        position: relative;
        top: auto;
        right: auto;
        padding: 4rem grid-width-gutter(grid-s) * 0.5 0;
        @include grid-width(9, 7, 7);

        .has-popup-page--compare-select &,
        .has-popup-page--wishlist-select &,
        .show-welcome-message & {
            height: auto;
        }

        .has-popup-page--delete-account & {
            @include grid-width(9, 6, 4);
            height: auto;
        }

        .has-popup-page--complete-account-registration & {
            @include grid-width(9, 6, 5);
            padding-bottom: grid-width-gutter(grid-s) * 0.5;
            height: auto;
        }
    }

    // only relevant to sidebar modal
    @include respond-to(grid-s) {
        &.modal__content--quick-contact,
        &.modal__content--sidebar {
            padding: $modal-header-mobile 0 grid-spacing(grid-s, xxl) 0;
        }

        &.modal__content--filter {
            padding-bottom: 24em; // prevent blitzer on iOS
        }
    }

    // only relevant to sidebar modal
    @include respond-to(s--m) {
        &.modal__content--filter {
            padding: $modal-header-mobile 0 grid-spacing(grid-s, xxl) 0;
            padding-bottom: 24em; // prevent blitzer on iOS
        }
    }

    @include respond-to(decent) {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        width: calc(#{grid-width(grid-l, 9)} + #{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 0.5)});
        padding: 0 0 grid-spacing(grid-l, l) 0;

        &.modal__content--popup,
        &.modal__content--cookie-dialog {
            height: 70em;
            max-height: 96vh;
            padding: 6em grid-width-gutter(grid-l) * 0.5;

            .has-popup-page--compare-select &,
            .has-popup-page--wishlist-select &,
            .has-popup-page--checkout-registration &,
            .show-welcome-message & {
                height: auto;
            }
        }
    }

    @include respond-to(grid-l) {
        // smaller sidebar
        &.modal__content--sidebar {
            .has-sidebar-page--quickview &,
            .has-sidebar-page--note &,
            .has-sidebar-page--profile & {
                width: calc(#{grid-width(grid-l, 6)} + #{grid-margin(grid-l)} + #{grid-width-gutter(grid-l, 0.5)});
            }
        }
    }

    @include respond-to(grid-m) {
        width: calc(#{grid-width(grid-m, 9)} + #{grid-margin(grid-m)} + #{grid-width-gutter(grid-m, 0.5)});
        padding: 0 0 grid-spacing(grid-m, l) 0;

        .has-action-buttons-shown & {
            &.modal__content--sidebar {
                padding-bottom: $action-bar-height-desktop;
            }
        }

        &.modal__content--filter {
            width: calc(#{grid-width(grid-m, 5)} + #{grid-margin(grid-m)} + #{grid-width-gutter(grid-m, 0.5)});
            padding: $modal-header-desktop + 2rem grid-margin(grid-m, false) $menu-height-mobile + 4rem grid-width-gutter(grid-m, 0.5);
        }

        &.modal__content--popup,
        &.modal__content--cookie-dialog {
            height: auto;
            min-height: 40rem;

            .has-popup-page--compare-select &,
            .has-popup-page--wishlist-select &,
            .show-welcome-message & {
                min-height: 1px;
            }
        }
    }
}

// give header a fullbleed white background
// because background for header covers non-permanent scrollbars (eg OS X)
// only apply if position sticky is supported
// breaks in safari and ios, do not apply here
.modal__content-header-cover {
    transition: none !important; // Fix safari and ios issue

    .modal--sidebar & {
        @supports (position: sticky) {
            .no-scrollbars & {
                background: $back-color--default;
                height: $modal-header-mobile;
                margin-top: $modal-header-mobile * -1;
                position: sticky;
                top: 0;
                z-index: 10;
            }

            .has-sidebar & {
                .no-scrollbars & {
                    animation: fix-sticky 1s linear 1;
                    animation-fill-mode: backwards;
                }
            }

            @include respond-to(decent) {
                .no-scrollbars & {
                    height: $modal-header-desktop;
                    margin-top: 0;
                    margin-bottom: $modal-header-desktop * -1;
                }
            }
        }
    }
}

