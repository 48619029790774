// Advanced text-box for interviews etc
.advanced-text-box {
    // shown in box-group layouts with a reduced width
    .box-group & {
        @include grid-width(8, 4, 4);

        &.advanced-text-box--small {
            @include grid-width(8, 4, 3);
        }
    }

    h3 {
        @include textstyle--m;
    }

    * + p {
        margin-top: 0.5em * $line-height; // half a line
    }

    .btn {
        margin-top: 0.5em * $line-height; // half a line
    }

    > div + div {
        @include grid-spacing(l);
    }

    @include respond-to(decent) {
        h3 {
            @include textstyle--l;
        }

        & + .box-s,
        & + .box-m,
        & + .box-l,
        & + .box-default,
        & + .box-indent,
        & + figure {
            position: sticky;
            top: $menu-height-desktop;

            // if things are loaded into the sidebar they sometimes don’t appear if they are sticky
            // here is a fix
            .has-sidebar & {
                animation: fix-sticky 1s linear 1;
                animation-fill-mode: backwards;
            }
        }
    }
}
