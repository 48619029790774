// Nomos boxes

// box sizes
// box-s / 3 columns on desktop
.box-s {
    @include grid-width(5, 3, 3);
    max-width: 100%;

    &.box--bleed {
        width: grid-width(grid-s, 5, true);
    }

    @include respond-to(grid-l) {
        &.box--bleed {
            width: grid-width(grid-l, 3, true);
        }
    }

    @include respond-to(grid-m) {
        &.box--bleed {
            width: grid-width(grid-m, 3, true);
        }
    }

    // boxes inside text-boxes, eg. head-wrapper
    // ! has different widths than normal box-s
    .text-container & {
        @include grid-inner-width(10, 6, 4);
    }
}

// box-m / 4 columns on desktop
.box-m {
    @include grid-width(8, 6, 4);
    max-width: 100%;

    .store-box-group & {
        width: 100%;
    }

    &.box--bleed {
        width: grid-width(grid-s, 8, true);
    }

    @include respond-to(grid-l) {
        &.box--bleed {
            width: grid-width(grid-l, 4, true);
        }

        .store-box-group & {
            width: grid-width(grid-l, 4);
        }
    }

    @include respond-to(grid-m) {
        .box-couple & {
            width: grid-width(grid-m, 5);
        }

        &.box--bleed {
            width: grid-width(grid-m, 6, true);
        }

        .store-box-group & {
            width: grid-width(grid-m, 4);
        }
    }

    // boxes inside text-boxes, eg. head-wrapper
    // ! has different widths than normal box-m
    .text-container & {
        @include grid-inner-width(10, 7, 5);
    }
}

// box-l / 6 columns on desktop
.box-l {
    @include grid-width(8, 6, 5);
    max-width: 100%;

    .store-box-group & {
        width: 100%;
    }

    &.box--bleed {
        width: grid-width(grid-s, 8, true);
    }

    @include respond-to(grid-l) {
        &.box--bleed {
            width: grid-width(grid-l, 5, true);
        }

        .store-box-group & {
            width: grid-width(grid-l, 5);
        }
    }

    @include respond-to(grid-m) {
        &.box--bleed {
            width: grid-width(grid-m, 6, true);
        }

        .store-box-group & {
            width: grid-width(grid-m, 5);
        }
    }

    // boxes inside text-boxes, eg. head-wrapper
    // ! has different widths than normal box-l
    .text-container & {
        @include grid-inner-width(10, 8, 6);
    }
}

// box-xl / 6 columns on desktop
.box-xl {
    @include grid-width(8, 6, 6);
    max-width: 100%;

    .store-box-group & {
        width: 100%;
    }

    &.box--bleed {
        width: grid-width(grid-s, 8, true);
    }

    @include respond-to(grid-l) {
        &.box--bleed {
            width: grid-width(grid-l, 6, true);
        }

        .store-box-group & {
            width: grid-width(grid-l, 6);
        }
    }

    @include respond-to(grid-m) {
        .box-couple & {
            width: grid-width(grid-m, 5);
        }

        &.box--bleed {
            width: grid-width(grid-m, 6, true);
        }

        .store-box-group & {
            width: grid-width(grid-m, 6);
        }
    }

    // boxes inside text-boxes should use box-s/m/l
    .text-container & {
        --error: "Määp. Please use box-s/m/l";
        cursor: help;
        outline: 1px dotted red;
    }
}

// box-xxl / 8 columns on desktop
.box-xxl {
    @include grid-width(10, 8, 8);
    max-width: 100%;

    @include respond-to(grid-l) {
        .box-couple & {
            &.box-xxl--s {
                width: grid-width(grid-l, 7);
            }
        }
    }

    @include respond-to(grid-m) {
        .box-couple & {
            width: grid-width(grid-m, 7);
        }
    }
}

// full grid width
.box-xxxl {
    width: 100%;
}

// bloxes bleeding to the side
.box--bleed {
    margin-left: calc(#{grid-margin(grid-s)} * -1);
    margin-right: calc(#{grid-margin(grid-s)} * -1);

    @include respond-to(grid-l) {
        &.box--bleed {
            margin-left: calc(#{grid-margin(grid-l)} * -1);
            margin-right: calc(#{grid-margin(grid-l)} * -1);
        }
    }

    @include respond-to(grid-m) {
        &.box--bleed {
            margin-left: calc(#{grid-margin(grid-m)} * -1);
            margin-right: calc(#{grid-margin(grid-m)} * -1);
        }
    }
}

// box with full height
.box-full-height,
.teaser.box-full-height {
    .media-box {
        width: 100%;
        padding-bottom: 100%;
    }

    @include respond-to(decent) {
        height: 100vh;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        min-height: 40rem;
        max-height: 56vw; // prevent aqward ratios

        .media-box {
            width: grid-width(grid-l, 5);
            height: 100%;
            padding-bottom: 0;
        }

        .text-container {
            width: grid-width(grid-l, 5);
            display: flex;
            flex-direction: column;
        }

        &.teaser {
            .text-container {
                justify-content: space-between;
                @include grid-spacing(m, grid-l);
            }
        }

        nav & {
            height: calc(100vh - #{$menu-top-padding-desktop} - #{grid-spacing(grid-l, l)});

            .media-box {
                width: grid-width(grid-l, 4);
                height: 100%;
            }

            .text-container {
                width: grid-width(grid-l, 4);
            }
        }

        // fix for mobile safari vh-issue
        @supports (--css: variable) {
            .is-safari.touch nav & {
                height: calc((var(--view-height-offset) * 100vh) - #{$menu-top-padding-desktop} - #{grid-spacing(grid-l, l)});
            }
        }
    }

    @include respond-to(grid-m) {
        .media-box {
            width: grid-width(grid-m, 5);
        }

        .text-container {
            width: grid-width(grid-m, 5);
        }

        &.teaser {
            .text-container {
                justify-content: flex-start;
                margin-top: 0;
            }
        }

        nav & {
            height: calc(100vh - #{$menu-top-padding-desktop} - #{grid-spacing(grid-m, l)});

            .media-box {
                width: grid-width(grid-m, 5);
            }

            .text-container {
                width: grid-width(grid-m, 5);
            }
        }

        // fix for mobile safari vh-issue
        @supports (--css: variable) {
            .is-safari.touch nav & {
                height: calc((var(--view-height-offset) * 100vh) - #{$menu-top-padding-desktop} - #{grid-spacing(grid-m, l)});
            }
        }
    }
}

// indent box vertically from corresponding box
.box-indent {
    @include respond-to(grid-l) {
        .media-box {
            @include grid-spacing(xxl);
        }
    }
}
