// Contains Register and Account
.account-login,
.account-lostpw,
.account-temp-register {
    h3 + .form-grid__row {
        @include grid-spacing(s);
    }
}

.account-login {
    .form-grid__row.form-grid__row--action {
        @include grid-spacing(s);
    }

    hr {
        @include grid-spacing(s);
        @include grid-bottom-spacing(s);
        margin-bottom: 0;
        border: none;
        border-top: 1px solid $border-color--light-1;
    }
}

.account-temp-register,
.account-lostpw {
    .form-grid__row:last-child {
        @include grid-spacing(s);
    }
}

.account-temp-register {
    &[data-state="pending"] {
        .register-completed-text {
            display: none;
        }
    }

    &[data-state="completed"] {
        .register-pending-text,
        .form-grid__row--action {
            display: none;
        }
    }
}

.form-grid--account-login,
.form-grid--account-lostpw,
.form-grid--account-temp-register,
.complete-temp-account-register-form {
    &[aria-hidden="true"] {
        display: none;
    }

    .form-response {
        &[aria-hidden="false"] {
            @include grid-spacing(xs);
        }
    }
}

// Checkout / Registration
.address__fieldset {
    // Billing address fieldset
    &.address__fieldset--billing-address {
        .form-grid__row:not(.form-grid__row--toggle) {
            display: none;
        }

        &[aria-expanded="true"] {
            .form-grid__row {
                display: flex;
            }
        }
    }
}

// Dropdown Customer Account
.dropdown--account {
    .account__list {
        @include pseudo-list;
        display: flex;
        flex-direction: column;
    }

    .account__list__item:not(.account__list__item--logout) {
        display: block;
        width: 100%;
        @include list-hover();

        a {
            @include textstyle--button;
            line-height: 2;
        }
    }

    .account__list__item--logout {
        @include grid-spacing(s);

        a {
            @include textstyle--button-s;
        }
    }

    .account__logout {
        @include grid-spacing(m);
    }
}

// Account Page
.page-account {
    .text-ultra p,
    p.text-ultra {
        @include respond-to(grid-l) {
            @include textstyle--teaser-l;
        }

        @include respond-to(grid-m) {
            @include textstyle--teaser-m;
        }
    }
}

// Main account wrapper
.account {
    @include respond-to(grid-l) {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

.account__main {
    @include respond-to(grid-l) {
        width: grid-width(grid-l, 6);
    }
}

.account__sidebar {
    @include respond-to(grid-l) {
        width: grid-width(grid-l, 3);
    }
}

.account__intro {
    @include respond-to(grid-l) {
        max-width: grid-inner-width(grid-l, 5);

        p {
            @include textstyle--teaser-l;
        }
    }

    @include respond-to(grid-m) {
        max-width: grid-inner-width(grid-m, 8);

        p {
            @include textstyle--teaser-m;
        }
    }
}

// account profile
.hero--account-profile {
    .page-title {
        @include respond-to(grid-m) {
            max-width: grid-width(grid-m, 6);
        }
    }
}

.account-profile {
    .text-container:first-child {
        .account-panel-group {
            margin-top: 0;
        }
    }

    .account-panel-group__content {
        margin-top: grid-spacing(grid-s, s);

        @include respond-to(decent) {
            @include grid-spacing(xs);
        }
    }

    @include respond-to(grid-l) {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .account-panel-group {
            margin-top: 0;
        }
    }
}

// account address
.hero--account-edit-address {
    .page-title {
        @include respond-to(grid-l) {
            max-width: grid-width(grid-l, 4);
        }

        @include respond-to(grid-m) {
            max-width: grid-width(grid-m, 6);
        }
    }
}

// order detail
.order-detail {
    @include respond-to(grid-s) {
        .account-panel-group__content {
            margin-top: 0;
        }
    }

    @include respond-to(grid-l) {
        justify-content: space-between;
    }
}

.hero--order-detail {
    @include respond-to(grid-l) {
        h1 {
            @include textstyle--teaser-l;
        }
    }

    @include respond-to(grid-m) {
        h1 {
            @include textstyle--teaser-m;
        }
    }

    .btn-group {
        > * {
            margin-top: 0.8em;
        }
    }
}

// Delete account modal/form
.delete-account-modal-wrapper {
    display: none;
}

.delete-account-form {
    padding-bottom: 4rem;

    h2 {
        @include textstyle--l;
    }

    .form-grid__row {
        @include grid-spacing(s);
    }

    .account-panel__footer {
        @include grid-spacing(s);
    }

    @include breakpoint(decent) {
        padding-bottom: 0;
    }
}

// Fix country selection to be full width
.register-container {
    .select2-container--nomos {
        max-width: none;
    }
}