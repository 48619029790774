// Nomos services

.switch-model {
    @include grid-padding;

    &[aria-hidden='true'] {
        display: none;
    }

    h4 {
        @include textstyle--uppercase;
        @include textstyle--m;
    }
}

.switch-model__box {
    @include grid-spacing(m);
}

.services-step {
    &[aria-hidden='true'] {
        display: none; // hide not needed service steps
    }
}


.service__select-registration-number {
    background: $back-color--default;

    .service__select-registration-number-inner {
        @include grid-padding;
        @include grid-spacing(s);
        @include grid-bottom-spacing(m);
    }

    label {
        @include textstyle--uppercase;
        @include textstyle--m;
    }

    .form-grid__row {
        @include grid-inner-width(10, 7, 5);
    }

    &[aria-expanded="false"] {
        display: none;
    }
}

.service__other-reason-comment {
    transition: opacity $trans-time--m $trans-func--default,
                max-height $trans-time--m $trans-func--default;

    .service__other-reason-comment-inner {
        @include grid-spacing(s);
        @include grid-bottom-spacing(m);
    }

    label {
        @include textstyle--uppercase;
        @include textstyle--m;
    }

    .form-grid__row {
        @include grid-inner-width(10, 7, 5);
    }

    &[aria-expanded="false"] {
        opacity: 0;
        overflow: hidden;
        max-height: 0;
    }

    &[aria-expanded="true"] {
        max-height: 32rem;
    }
}